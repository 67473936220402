import './FlightResults.scss';
import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import 'react-datepicker/dist/react-datepicker.css';
import flightLogo from '../../../../assets/images/flight-logo.gif';
import iconFL from '../../../../assets/images/icons-fl.png';



const FlightResults = () => {
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {

    }, [])



    return (
        <>
        <section className="inner-title-block stay-search-form-block inner-mrt-80">
            <div className="flight-h-block container-lg">
                <div className="flight-h-left">
                    <h5>Berlin All Airports, Germany - Warsaw, Poland(One Way)</h5>
                    <p>Class:  Economy / Travellers:  1 Passenger/s / Dates:  Tue, 10 Jan 2023</p>
                </div>
                <div className="flight-h-right">
                    <button type="button" className="btn btn-primary-color"><i className="fa fa-caret-left"></i>&nbsp;&nbsp;Previous Day</button>
                    <button type="button" className="btn btn-primary-color">Next Day&nbsp;&nbsp;<i className="fa fa-caret-right"></i></button>
                </div>
            </div>
        </section>
        <section className="flight-result-block">
            <div className="container-lg d-flex justify-content-between">
                <div className="flight-filter-left">
                    <div className="flight-filter-header">
                        <span className="txt-upper">filter by:</span>
                        <a href="javascript:void(0);" className="btn btn-primary-color ResetAll">Reset All</a>
                    </div>
                    <Accordion alwaysOpen>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Price</Accordion.Header>
                            <Accordion.Body>
                                CDazsf
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>NO. OF STOPS</Accordion.Header>
                            <Accordion.Body>
                                dfgfd
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>FARE TYPE</Accordion.Header>
                            <Accordion.Body>
                                dfgfd
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>BRAND TYPE</Accordion.Header>
                            <Accordion.Body>
                                dfgfd
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>LAYOVER TIME</Accordion.Header>
                            <Accordion.Body>
                                dfgd
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header>TRAVEL TIME</Accordion.Header>
                            <Accordion.Body>
                                dfgd
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="6">
                            <Accordion.Header>AIRLINES</Accordion.Header>
                            <Accordion.Body>
                                fdg
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="7">
                            <Accordion.Header>LAYOVER AIRPORTS</Accordion.Header>
                            <Accordion.Body>
                                fgh
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
                <div className="flight-filter-right">
                    <div className="filter-top-block">
                        <div className="top-block-item">
                            <div className="top-block-span">
                                <span className="af-btn cor-1">All Flights</span>
                                <span className="text">171 Flights</span>
                            </div>
                        </div>
                        <div className="top-block-item">
                            <div className="top-block-span">
                                <span className="af-btn cor-2">Fastest</span>
                                <span className="text">02 h 35 m</span>
                            </div>
                        </div>
                        <div className="top-block-item">
                            <div className="top-block-span">
                                <span className="af-btn cor-3">Best Value</span>
                                <span className="text">INR 61</span>
                            </div>
                        </div>
                        <div className="top-block-item">
                            <div className="top-block-span">
                                <span className="af-btn cor-4">Cheapest</span>
                                <span className="text">INR 61</span>
                            </div>
                        </div>
                    </div>
                    <div className="flight-sort-block">
                        <div className="sort-block-item">
                            <div className="sort-block-asc">
                                <span className="text">DEPART</span>
                                <span>
                                    <i className="fa fa-sort-asc" aria-hidden="true"></i>
                                    <i className="fa fa-sort-desc" aria-hidden="true"></i>
                                </span>
                            </div>
                        </div>
                        <div className="sort-block-item">
                            <div className="sort-block-asc">
                                <span className="text">DURATION</span>
                                <span>
                                    <i className="fa fa-sort-asc" aria-hidden="true"></i>
                                    <i className="fa fa-sort-desc" aria-hidden="true"></i>
                                </span>
                            </div>
                        </div>
                        <div className="sort-block-item">
                            <div className="sort-block-asc">
                                <span className="text">ARRIVE</span>
                                <span>
                                    <i className="fa fa-sort-asc" aria-hidden="true"></i>
                                    <i className="fa fa-sort-desc" aria-hidden="true"></i>
                                </span>
                            </div>
                        </div>
                        <div className="sort-block-item">
                            <div className="sort-block-asc">
                                <span className="text">PRICE/PASSENGER</span>
                                <span>
                                    <i className="fa fa-sort-asc" aria-hidden="true"></i>
                                    <i className="fa fa-sort-desc" aria-hidden="true"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="flight-search-list-item">
                        <div className="flsl-left">
                            <div className="row dtl">
                                <div className="col-2 col-sm-2 col-md-2 logo-area no-padd">
                                    <div className="logo-sec">
                                        <img src={flightLogo} className="result-logo" />
                                    </div>
                                    <div className="flight-name">
                                        <span className="code txt-small">UX - 1502</span>
                                    </div>
                                </div>
                                <div className="col-10 col-md-10 col-sm-10 dtl-area">
                                    <div className="row">
                                        <div className="col-11 col-md-11 col-sm-12">
                                            <div className="row timing">
                                                <div className="tooltip1 col-2 col-sm-2 col-md-2 no-padd">
                                                    <span className="times">10:50</span>
                                                    <p className="dest">FRA</p>
                                                    <span className="code txt-small">Wed, 11 Jan</span>
                                                </div>

                                                <div className="col-8 col-sm-8 col-md-8 no-padd">
                                                    <div className="tim-top">
                                                        <span className="stop txt-small">Non-Stop</span>
                                                    </div>
                                                    <div className="tim-mid">
                                                        <img alt="icons" src={iconFL} />
                                                    </div>
                                                    <div className="tim-bot">
                                                        <span className="txt-small">Trip Duration&nbsp;<span>02h 45m</span></span>
                                                    </div>
                                                </div>

                                                <div className="col-2 col-sm-2 col-md-2 no-padd tooltip1">
                                                    <span className="arr times">13:35</span>
                                                    <p className="dest">MAD</p>
                                                    <span className="code txt-small">Wed, 11 Jan</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="hidden-xs col-sm-1 col-md-1 no-padd txt-center plus">
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="text-center">
                                <ul className="nav details-fare-sec">
                                    <li>
                                        <i className="fa fa-plane" aria-hidden="true"></i>&nbsp;&nbsp;Itinerary
                                            </li>
                                    <li>
                                        <i className="fa fa-suitcase" aria-hidden="true"></i>&nbsp;&nbsp;Baggage
                                            </li>
                                    <li>
                                        <i className="fa fa-cog" aria-hidden="true"></i>&nbsp;&nbsp;Fare Breakups &amp; Rules
                                            </li>
                                    <li>
                                        <i className="fa fa-check-circle"></i> REFUNDABLE
                                            </li>
                                </ul>
                            </div>
                        </div>
                        <div className="flsl-right">
                            <div className="flsl-prices">
                                <div className="price">€ 61</div>
                                <div className="total-price">Total : € 61</div>
                                <div className="total-passan">(For 1 Passenger/s)</div>
                            </div>
                            <div className="book-btn">
                                <button className="btn btn-primary-color">Book Now</button>
                            </div>
                        </div>
                    </div>
                    <div className="flight-search-list-item">
                        <div className="flsl-left">
                            <div className="row dtl">
                                <div className="col-2 col-sm-2 col-md-2 logo-area no-padd">
                                    <div className="logo-sec">
                                        <img src={flightLogo} className="result-logo" />
                                    </div>
                                    <div className="flight-name">
                                        <span className="code txt-small">UX - 1502</span>
                                    </div>
                                </div>
                                <div className="col-10 col-md-10 col-sm-10 dtl-area">
                                    <div className="row">
                                        <div className="col-11 col-md-11 col-sm-12">
                                            <div className="row timing">
                                                <div className="tooltip1 col-2 col-sm-2 col-md-2 no-padd">
                                                    <span className="times">10:50</span>
                                                    <p className="dest">FRA</p>
                                                    <span className="code txt-small">Wed, 11 Jan</span>
                                                </div>

                                                <div className="col-8 col-sm-8 col-md-8 no-padd">
                                                    <div className="tim-top">
                                                        <span className="stop txt-small">Non-Stop</span>
                                                    </div>
                                                    <div className="tim-mid">
                                                        <img alt="icons" src={iconFL} />
                                                    </div>
                                                    <div className="tim-bot">
                                                        <span className="txt-small">Trip Duration&nbsp;<span>02h 45m</span></span>
                                                    </div>
                                                </div>

                                                <div className="col-2 col-sm-2 col-md-2 no-padd tooltip1">
                                                    <span className="arr times">13:35</span>
                                                    <p className="dest">MAD</p>
                                                    <span className="code txt-small">Wed, 11 Jan</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="hidden-xs col-sm-1 col-md-1 no-padd txt-center plus">
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="text-center">
                                <ul className="nav details-fare-sec">
                                    <li>
                                        <i className="fa fa-plane" aria-hidden="true"></i>&nbsp;&nbsp;Itinerary
                                            </li>
                                    <li>
                                        <i className="fa fa-suitcase" aria-hidden="true"></i>&nbsp;&nbsp;Baggage
                                            </li>
                                    <li>
                                        <i className="fa fa-cog" aria-hidden="true"></i>&nbsp;&nbsp;Fare Breakups &amp; Rules
                                            </li>
                                    <li>
                                        <i className="fa fa-check-circle"></i> REFUNDABLE
                                            </li>
                                </ul>
                            </div>
                        </div>
                        <div className="flsl-right">
                            <div className="flsl-prices">
                                <div className="price">€ 61</div>
                                <div className="total-price">Total : € 61</div>
                                <div className="total-passan">(For 1 Passenger/s)</div>
                            </div>
                            <div className="book-btn">
                                <button className="btn btn-primary-color">Book Now</button>
                            </div>
                        </div>
                    </div>
                    <div className="flight-search-list-item">
                        <div className="flsl-left">
                            <div className="row dtl">
                                <div className="col-2 col-sm-2 col-md-2 logo-area no-padd">
                                    <div className="logo-sec">
                                        <img src={flightLogo} className="result-logo" />
                                    </div>
                                    <div className="flight-name">
                                        <span className="code txt-small">UX - 1502</span>
                                    </div>
                                </div>
                                <div className="col-10 col-md-10 col-sm-10 dtl-area">
                                    <div className="row">
                                        <div className="col-11 col-md-11 col-sm-12">
                                            <div className="row timing">
                                                <div className="tooltip1 col-2 col-sm-2 col-md-2 no-padd">
                                                    <span className="times">10:50</span>
                                                    <p className="dest">FRA</p>
                                                    <span className="code txt-small">Wed, 11 Jan</span>
                                                </div>

                                                <div className="col-8 col-sm-8 col-md-8 no-padd">
                                                    <div className="tim-top">
                                                        <span className="stop txt-small">Non-Stop</span>
                                                    </div>
                                                    <div className="tim-mid">
                                                        <img alt="icons" src={iconFL} />
                                                    </div>
                                                    <div className="tim-bot">
                                                        <span className="txt-small">Trip Duration&nbsp;<span>02h 45m</span></span>
                                                    </div>
                                                </div>

                                                <div className="col-2 col-sm-2 col-md-2 no-padd tooltip1">
                                                    <span className="arr times">13:35</span>
                                                    <p className="dest">MAD</p>
                                                    <span className="code txt-small">Wed, 11 Jan</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="hidden-xs col-sm-1 col-md-1 no-padd txt-center plus">
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="text-center">
                                <ul className="nav details-fare-sec">
                                    <li>
                                        <i className="fa fa-plane" aria-hidden="true"></i>&nbsp;&nbsp;Itinerary
                                            </li>
                                    <li>
                                        <i className="fa fa-suitcase" aria-hidden="true"></i>&nbsp;&nbsp;Baggage
                                            </li>
                                    <li>
                                        <i className="fa fa-cog" aria-hidden="true"></i>&nbsp;&nbsp;Fare Breakups &amp; Rules
                                            </li>
                                    <li>
                                        <i className="fa fa-check-circle"></i> REFUNDABLE
                                            </li>
                                </ul>
                            </div>
                        </div>
                        <div className="flsl-right">
                            <div className="flsl-prices">
                                <div className="price">€ 61</div>
                                <div className="total-price">Total : € 61</div>
                                <div className="total-passan">(For 1 Passenger/s)</div>
                            </div>
                            <div className="book-btn">
                                <button className="btn btn-primary-color">Book Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}

export default FlightResults;