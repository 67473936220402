import React, { useState, useEffect } from 'react';
import "./PrivacyPolicy.scss";
import {GetPrivacyPolicyService} from '../../../services/DataService';
import {LoadingSpinnerOne} from "../../../loader/Loader";

const PrivacyPolicy = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [userData, setUserData] = useState();

    useEffect(()=>{
        getPrivacyPolicytApi();
    },[]);

    const getPrivacyPolicytApi = async () => {
        setIsLoading(true);
        await GetPrivacyPolicyService("pivacypolicy", async (res) => {
        if (res.status === 404) console.log("Something went wrong");
            setUserData(res.data.data.data);
            setIsLoading(false);
        });
    };

    return ( 
        <>
        <section className="inner-title-block inner-mrt-80">
            <div className="container-lg text-center">
                <h1>Privacy Policy</h1>
            </div>
        </section>
        <section className="cmn-info-wrapper padd-top-70 padd-bottom-70">
            {isLoading ? <LoadingSpinnerOne/> : !userData ? <p className="text-center">No data found.</p> : null}
            <div className="container-lg">
                {!userData ? null :<div dangerouslySetInnerHTML={{ __html: userData.content }}></div>}
            </div>
        </section>
        </>
    );
}
 
export default PrivacyPolicy;