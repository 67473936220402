import "./PublicItinerary.scss";
import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from "react";
import { Link, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Tooltip from 'react-bootstrap/Tooltip';
import Dropdown from 'react-bootstrap/Dropdown';
import Collapse from 'react-bootstrap/Collapse';
import ReactStars from "react-rating-stars-component";
import TextareaAutosize from 'react-textarea-autosize';
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon,
    LinkedinShareButton,
    LinkedinIcon
} from "react-share";
import likeIcon from '../../../../../assets/images/like-icon.png';
import likeIconBlue from '../../../../../assets/images/like-icon-blue.png';
import chatIcon from '../../../../../assets/images/chat-icon.png';
import starIcon from '../../../../../assets/images/star-icon.png';
import noPictureAvailable from '../../../../../assets/images/no-picture-available.jpg';
import { useAuth } from "../../../../../contexts/AuthProvider";
import { googleApiKey, googlePhotoUrl, imageUrlOthers } from "../../../../../services/CommonUrl";
import { addItineraryCommentApi, addItineraryLikeApi, addItineraryRatingApi, getAllItineraryApi } from "../../../../../services/DataService";
import { LoadingSpinnerTwo } from "../../../../../loader/Loader";
import { addExistingTripApi, getUserItineraryListApi, mergeItineraryApi } from "../../../../../services/AdminService";

import Accordion from 'react-bootstrap/Accordion';
import InputRange from 'react-input-range';
import { Country, City } from 'country-state-city';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { MultiSelect } from "react-multi-select-component";
import { getCityWiseItinerary, getCountryWiseItinerary, getDateWiseItinerary, getDaysWiseItinerary, getPriceWiseItinerary, getRatingWiseItinerary, getTravellersWiseItinerary } from "../../../../../helpers/ItineraryFilter";
import { toast } from "react-toastify";


const PublicItinerary = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        clearPublicFilter() {
            clearAllData();
        }
    }));
    const { authed, currencyData } = useAuth();
    const history = useHistory();
    const photoUrl = `${googlePhotoUrl}`;
    const apikey = `&key=${googleApiKey}`;
    const [isLoading, setIsLoading] = useState(false);
    const [itineraryPublicData, setItineraryPublicData] = useState([]);
    const totalStars = 5;
    const [isOpenCollapse, setIsOpenCollapse] = useState(false);
    const [newRatingValue, setNewRatingValue] = useState({ rating: "", userId: "", itineraryId: "" });
    const itineraryRef = useRef([]);


    const getItineraryPublicData = async () => {
        setIsLoading(true);
        await getAllItineraryApi({ type_of_itinerary: "Public", newCurrency: currencyData.currency }, async (res) => {
            console.log("res getAllItineraryApi ====", res);
            if (res.status === 404) {
                console.log('Something went wrong');
                setIsLoading(false);
            }
            if (res.status === 200) {
                // console.log(res.data.data.message);
                if (res.data.data.data === null || res.data.data.data === undefined) {
                    setMinMaxPriceValue({ min: 0, max: 0 });
                    setFilterPriceValue({ min: 0, max: 0 });
                    setItineraryPublicData([]);
                    setIsLoading(false);
                } else {
                    const result = res.data.data.data.sort((a, b) => new Date(a) < new Date(b) ? 1 : -1);
                    setItineraryPublicData(result);
                    setIsLoading(false);
                    const smallAmount = Math.min(...result.map(p => p.currencyConvert?.new_amount));
                    const largeAmount = Math.max(...result.map(p => p.currencyConvert?.new_amount));
                    setMinMaxPriceValue({ min: Math.floor(smallAmount), max: Math.ceil(largeAmount) });
                    setFilterPriceValue({ min: Math.floor(smallAmount), max: Math.ceil(largeAmount) });
                }
            }
        });
    }

    // For public pagination
    const [sizePublicPaginate, setSizePublicPaginate] = useState(10);
    const [currentPublicPaginate, setCurrentPublicPaginate] = useState(1);
    const perPageChangePublic = (value) => {
        setSizePublicPaginate(value);
        const newPerPage = Math.ceil(cityWiseItinerary?.length / value);
        if (currentPublicPaginate > newPerPage) {
            setCurrentPublicPaginate(newPerPage);
        }
    }
    const publicPaginationChange = (page, pageSize) => {
        setCurrentPublicPaginate(page);
        setSizePublicPaginate(pageSize);
    }
    const changeRecentSort = async () => {
        const recentSort = itineraryPublicData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        await setItineraryPublicData([...recentSort]);
    }
    const changePopularitySort = async () => {
        const popularSort = itineraryPublicData.sort((a, b) => b.total_rating - a.total_rating)
        await setItineraryPublicData([...popularSort]);
    }

    const changePriceLh = async () => {
        const lhSort = itineraryPublicData.sort((a, b) => a.currencyConvert?.new_amount - b.currencyConvert?.new_amount)
        await setItineraryPublicData([...lhSort]);
    }

    const changePriceHl = async () => {
        const hlSort = itineraryPublicData.sort((a, b) => b.currencyConvert?.new_amount - a.currencyConvert?.new_amount)
        await setItineraryPublicData([...hlSort]);
    }
    const handleItineraryUnlike = async (data) => {
        let unlikePosts = itineraryPublicData.map((post) => {
            if (post._id === data._id) {
                return {
                    ...post,
                    likeData: post.likeData.filter((item) => item.userId !== authed?.user?._id),
                    total_like: post.total_like - 1
                }
            }
            return post;
        })
        setItineraryPublicData([...unlikePosts]);
        // console.log("unlike posts", unlikePosts)
        await addItineraryLikeApi({ userId: authed?.user?._id, itineraryId: data._id }, async (res) => {
            if (res.status === 404) console.log('Something went wrong');
            if (res.status === 200) console.log("Like data fetch successfully!");
        });
    }
    const handleItineraryLike = async (data) => {
        // console.log(data)
        let likePosts = itineraryPublicData.map(post => {
            if (post._id === data._id) {
                return {
                    ...post,
                    likeData: !!data._id ? post.likeData.concat({
                        like: true,
                        isActive: true,
                        userId: authed?.user?._id,
                        itineraryId: data._id
                    }) : [],
                    total_like: post.total_like + 1
                }
            }
            return post;
        })
        setItineraryPublicData([...likePosts]);
        // console.log("like posts", likePosts)
        await addItineraryLikeApi({ userId: authed?.user?._id, itineraryId: data._id }, async (res) => {
            if (res.status === 404) console.log('Something went wrong');
            if (res.status === 200) console.log("Like data fetch successfully!");
        });
    }
    const likeArrData = (likeData, itemdata, index) => {
        const filterData = likeData.filter(item => item.userId === authed?.user?._id);
        return (
            <>
                {filterData.length > 0 ?
                    <button type="button" className={`btn btn-border-color h-like-btn ${filterData.length > 0 ? "active" : "inactive"}`} onClick={(e) => handleItineraryUnlike(itemdata, index)}>
                        <img width="15" src={likeIconBlue} alt="Like icon" /> ({itemdata?.likeData?.length})
                    </button>
                    :
                    <button type="button" className={`btn btn-border-color h-like-btn ${filterData.length > 0 ? "active" : "inactive"}`} onClick={(e) => handleItineraryLike(itemdata, index)}>
                        <img width="15" src={likeIcon} alt="Like icon" /> ({itemdata?.likeData?.length})
                    </button>
                }
            </>
        )
        // if (filterData !== null && filterData !== undefined && filterData.length > 0) {
        //     return (<>
        //         <button type="button" className={`btn btn-border-color h-like-btn ${filterData[0]?.like === true ? "active" : "inactive"}`} onClick={(e) => handleItineraryLike(itemdata, index)}>
        //             {filterData[0]?.like === true ? <img width="15" src={likeIconBlue} alt="Like icon" /> : <img width="15" src={likeIcon} alt="Like icon" />} ({itemdata?.likeData?.length})
        //         </button>
        //         <button type="button" className={`btn btn-border-color h-like-btn ${filterData[0]?.like === true ? "active" : "inactive"}`} onClick={(e) => handleItineraryUnlike(itemdata, index)}>
        //             {filterData[0]?.like === true ? <img width="15" src={likeIconBlue} alt="Like icon" /> : <img width="15" src={likeIcon} alt="Like icon" />} ({itemdata?.likeData?.length})
        //         </button>
        //     </>)
        // } else {
        //     return (<>
        //         <button type="button" className={`btn btn-border-color h-like-btn`} onClick={(e) => handleItineraryLike(itemdata, index)}>
        //             <img width="15" src={likeIcon} alt="Like icon" /> ({itemdata?.likeData?.length})
        //         </button>
        //     </>)
        // }
    }
    const handleItineraryRating = (data) => {
        // setShowReviewModal(true);
        setNewRatingValue({ ...newRatingValue, userId: authed?.user?._id, itineraryId: data._id });
    }
    const ratingChanged = async (newRating) => {
        setNewRatingValue({ ...newRatingValue, rating: newRating });
        let ratingPosts = itineraryPublicData.map(post => {
            let checkData = post.ratingata.filter(item => item?.userId === authed?.user?._id)
            if (post._id === newRatingValue.itineraryId) {
                if (!checkData?.length || checkData === undefined) {
                    return {
                        ...post,
                        ratingata: post.ratingata.concat({
                            rating: newRating,
                            isActive: true,
                            userId: authed?.user?._id,
                            itineraryId: newRatingValue?.itineraryId
                        }),
                        total_rating: post.total_rating + 1
                    }
                }
            }
            return post;
        })
        setItineraryPublicData([...ratingPosts]);
        await addItineraryRatingApi({
            rating: newRating,
            userId: authed?.user?._id,
            itineraryId: newRatingValue?.itineraryId
        }, async (res) => {
            if (res.status === 404) console.log('Something went wrong');
            if (res.status === 200) {
                console.log(res.data.data);
                if (res.data.data.message) {
                    toast.warning(res.data.data.message);
                }
            }
        });
    }

    const handleItinerariesComment = (data, e, i) => {
        //setShowCommentModal(true);
        commentFromik.values.userId = authed?.user?._id
        commentFromik.values.itineraryId = data._id
        if (isOpenCollapse === i) {
            setIsOpenCollapse(false);
        } else {
            setIsOpenCollapse(i);
            commentFromik.resetForm();
        }
    }
    const commentFromik = useFormik({
        initialValues: {
            comment: "",
            itineraryId: "",
            userId: ""
        },
        validationSchema: Yup.object({
            comment: Yup.string()
                .required('Please enter your comments.'),
        }),
        onSubmit: async (values) => {
            //console.log('comment value:', values);
            let commentPosts = itineraryPublicData.map(post => {
                let checkData = post.commentData.filter(item => item?.userId?._id === authed?.user?._id)
                if (post._id === values.itineraryId) {
                    if (!checkData?.length || checkData === undefined) {
                        return {
                            ...post,
                            commentData: post.commentData.concat({
                                comment: values.comment,
                                isActive: true,
                                userId: { _id: values.userId },
                                itineraryId: values.itineraryId
                            }),
                            total_comment: post.total_comment + 1
                        }
                    }
                }
                return post;
            })
            setItineraryPublicData([...commentPosts]);
            await addItineraryCommentApi(values, async (res) => {
                if (res.status === 404) console.log('Something went wrong');
                if (res.status === 200) {
                    if (res.data.data.message) {
                        toast.warning(res.data.data.message);
                    }
                    commentFromik.resetForm();
                }
            });
            commentFromik.resetForm();
        }
    });

    const handleItinerariesDetails = (data) => {
        history.push({
            pathname: '/public-itinarary-details/',
            search: (`itineraryId=${data._id}`),
        });

    }
    const handleItinerariesEdit = async (data) => {
        const obj = {
            userId: authed?.user?._id,
            itineraryId: data._id,
            currency: currencyData.currency,
        }
        // console.log(obj)
        // return
        await addExistingTripApi(obj, async (res) => {
            if (res.status === 404) {
                console.log('Something went wrong')
            }
            if (res.status === 200) {
                // toast.success(res.data.data.message, options)
                document.body.click();
                history.push({
                    pathname: '/admin/create-itinerary/',
                    search: (`userId=${authed?.user?._id}&&itineraryId=${res.data.data.data._id}`)
                });
            }
        });
    }
    const handleItineraryQuote = (data, e) => {
        history.push({
            pathname: '/admin/request-quote/',
            search: (`itineraryId=${data._id}`),
        });
    }
    const [itemItineraryId, setItemItineraryId] = useState({
        defaultItineraryId: "",
        itineraryId: "",
    });
    const [isMergeLoading, setIsMargeLoading] = useState(false);
    const handleGetItinerayId = (id) => {
        setItemItineraryId({ ...itemItineraryId, itineraryId: id, defaultItineraryId: "" });
        setFilterItineraryData(itineraryDraftList);
    }
    const handleMergeItinerary = async () => {
        setIsMargeLoading(true);
        const Obj = {
            defaultItineraryId: itemItineraryId.defaultItineraryId,
            itineraryId: itemItineraryId.itineraryId,
            currency: currencyData.currency
        }
        // console.log("itinerary Arr", Obj)
        await mergeItineraryApi(Obj, async (res) => {
            // console.log("res======", res);
            if (res.status === 404) {
                console.log('Something went wrong');
            }
            if (res.status === 200) {
                // console.log("res merge ======", res);
                setIsMargeLoading(false);
                history.push({
                    pathname: '/admin/create-itinerary/',
                    search: (`userId=${authed?.user?._id}&&itineraryId=${res.data.data.data._id}`),
                });
            }
        });
    }

    const [itineraryDraftList, setItineraryDraftList] = useState([]);
    const [filterItineraryData, setFilterItineraryData] = useState([]);
    const [searchItineraryQuery, setSearchItineraryQuery] = useState("");
    const getItineraryDraftList = async () => {
        await getUserItineraryListApi({ userId: authed?.user?._id }, async (res) => {
            // console.log("API Get itinerary list response: ", res);
            if (res.status === 404) return
            if (res.status === 200) {
                const result = res.data.data.data.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1)
                setItineraryDraftList(result);
                setFilterItineraryData(result);
            }
        });
    };

    const handleSearchItinerary = async (e) => {
        setSearchItineraryQuery(e.target.value);
        const filterResult = itineraryDraftList.filter(item => {
            if (e.target.value === "") return item
            return item.tour_name.toLowerCase().includes(e.target.value.toLowerCase())
        })
        await setFilterItineraryData(filterResult);
        await setItemItineraryId({ ...itemItineraryId, defaultItineraryId: "" });
        for (let i = 0; i < itineraryRef.current.length; i++) {
            if (itineraryRef.current[i] && itineraryRef.current[i].checked) {
                itineraryRef.current[i].checked = false;
            }
        }
    }

    const handleSelectItinerary = (e, item) => {
        setSearchItineraryQuery(e.target.value);
        setItemItineraryId({ ...itemItineraryId, defaultItineraryId: item._id });
    }

    const handleClearSearch = () => {
        setSearchItineraryQuery("");
        setFilterItineraryData(itineraryDraftList);
        setItemItineraryId({ ...itemItineraryId, defaultItineraryId: "" });
        for (let i = 0; i < itineraryRef.current.length; i++) {
            if (itineraryRef.current[i] && itineraryRef.current[i].checked) {
                itineraryRef.current[i].checked = false;
            }
        }
    }
    const handleMergeItineraryClose = () => {
        // document.body.click();
        setIsMargeLoading(false);
        setItemItineraryId({ defaultItineraryId: "", itineraryId: "" });
        setSearchItineraryQuery("");
        for (let i = 0; i < itineraryRef.current.length; i++) {
            if (itineraryRef.current[i] && itineraryRef.current[i].checked) {
                itineraryRef.current[i].checked = false;
            }
        }
    }

    const [travellerTypeArr, setTravellerTypeArr] = useState([]);
    const [travellerRatingArr, setTravellerRatingArr] = useState([]);
    const [countryIsoCode, setCountryIsoCode] = useState();
    const [searchCountryQuery, setSearchCountryQuery] = useState("");
    const [countryName, setCountryName] = useState("");
    const countryData = Country.getAllCountries();
    const cityData = City.getCitiesOfCountry(countryIsoCode ? countryIsoCode : "");
    const [filterCountryList, setFilterCountryList] = useState(countryData);
    const ratingRef = useRef([]);
    const travellersRef = useRef([]);
    const countryRef = useRef([]);
    const cityRef = useRef([]);
    const [filterPriceValue, setFilterPriceValue] = useState({ min: 0, max: 0 });
    const [minMaxPriceValue, setMinMaxPriceValue] = useState({ min: 0, max: 0 });
    const [filterDaysValue, setFilterDaysValue] = useState({ min: 1, max: 60 });
    const [stateDate, setStateDate] = useState([{ startDate: new Date(), endDate: new Date(), key: 'selection', status: false }]);
    const [selectedCityData, setSelectedCityData] = useState([]);
    const updatedCity = cityData.map((city) => ({
        label: city.name,
        value: city.name,
        ...city
    }));

    let priceDefault = { ...filterPriceValue };
    const handlePriceFilterComplete = (value) => {
        priceDefault = value;
        setFilterPriceValue(priceDefault);
        setCurrentPublicPaginate(1);
    }

    let daysDefault = { ...filterDaysValue };
    const handleDaysFilterComplete = (value) => {
        daysDefault = value;
        setFilterDaysValue(daysDefault);
        setCurrentPublicPaginate(1);
    }

    let updateDateFilter = [...stateDate];
    const handleDateFilter = (item) => {
        updateDateFilter = [{ startDate: item.selection.startDate, endDate: item.selection.endDate, key: 'selection', status: true }];
        setStateDate([{ startDate: item.selection.startDate, endDate: item.selection.endDate, key: 'selection', status: true }]);
        setCurrentPublicPaginate(1);
    }

    let updateTravelerFilter = [...travellerTypeArr];
    const handleTravellerChange = async (event) => {
        if (event.target.checked) {
            updateTravelerFilter = [...travellerTypeArr, event.target.value]
        } else {
            updateTravelerFilter.splice(travellerTypeArr.indexOf(event.target.value), 1);
        }
        await setTravellerTypeArr(updateTravelerFilter);
        setCurrentPublicPaginate(1);
    };

    let updateRatingFilter = [...travellerRatingArr];
    const handleTravellerRatingChange = async (event) => {
        if (event.target.checked) {
            updateRatingFilter = [...travellerRatingArr, event.target.value];
        } else {
            updateRatingFilter.splice(travellerRatingArr.indexOf(event.target.value), 1);
        }
        await setTravellerRatingArr(updateRatingFilter);
        setCurrentPublicPaginate(1);
    };

    const handleCountryChange = async (e, data) => {
        setCountryIsoCode(data.isoCode);
        setSearchCountryQuery(data.name);
        setCountryName(data.name);
        setCurrentPublicPaginate(1);
        for (let i = 0; i < cityRef.current.length; i++) {
            if (cityRef.current[i] && cityRef.current[i].checked) {
                cityRef.current[i].checked = false;
            }
        }
    };

    const handleSearchCountryChange = (e) => {
        setSearchCountryQuery(e.target.value);
        const results = countryData.filter(item => {
            if (e.target.value === "") return countryData;
            return item.name.toLowerCase().includes(e.target.value.toLowerCase());
        });
        setFilterCountryList(results);
        setCurrentPublicPaginate(1);
    }

    let updateCityFilter = [...selectedCityData];
    const handleCityChange = (item) => {
        updateCityFilter = item;
        setSelectedCityData(item);
        setCurrentPublicPaginate(1);
    }

    const handleSearchClear = () => {
        setSearchCountryQuery("");
        setCountryIsoCode("");
        setCountryName("");
        setFilterCountryList(countryData);
        setSelectedCityData([]);
        setCurrentPublicPaginate(1);
        for (let i = 0; i < countryRef.current.length; i++) {
            if (countryRef.current[i] && countryRef.current[i].checked) {
                countryRef.current[i].checked = false;
            }
        }
    }

    const clearAllData = async () => {
        priceDefault = { min: minMaxPriceValue.min, max: minMaxPriceValue.max };
        setFilterPriceValue(priceDefault);
        daysDefault = { min: 1, max: 60 };
        setFilterDaysValue(daysDefault);
        updateTravelerFilter = [];
        setTravellerTypeArr(updateTravelerFilter);
        updateRatingFilter = [];
        setTravellerRatingArr(updateRatingFilter);
        setCountryName("");
        setSearchCountryQuery("");
        setFilterCountryList(countryData);
        updateDateFilter = [{ startDate: new Date(), endDate: new Date(), key: 'selection', status: false }];
        setStateDate(updateDateFilter);
        setCountryIsoCode("");
        setSelectedCityData([]);
        setCurrentPublicPaginate(1);
        for (let i = 0; i < ratingRef.current.length; i++) {
            ratingRef.current[i].checked = false;
        }
        for (let i = 0; i < travellersRef.current.length; i++) {
            travellersRef.current[i].checked = false;
        }
        for (let i = 0; i < countryRef.current.length; i++) {
            if (countryRef.current[i] && countryRef.current[i].checked) {
                countryRef.current[i].checked = false;
            }
        }
        getItineraryPublicData();
    }
    const handleClearAll = () => {
        clearAllData();
    }

    const priceWiseItinerary = getPriceWiseItinerary(itineraryPublicData, priceDefault);
    const daysWiseItinerary = getDaysWiseItinerary(priceWiseItinerary, daysDefault);
    const ratingWiseItinerary = getRatingWiseItinerary(daysWiseItinerary, updateRatingFilter);
    const travellersWiseItinerary = getTravellersWiseItinerary(ratingWiseItinerary, updateTravelerFilter);
    const datesWiseItinerary = getDateWiseItinerary(travellersWiseItinerary, updateDateFilter);
    const countryWiseItinerary = getCountryWiseItinerary(datesWiseItinerary, countryName);
    const cityWiseItinerary = getCityWiseItinerary(itineraryPublicData, updateCityFilter);
    console.log("Public Itinerary", cityWiseItinerary)


    useEffect(() => {
        getItineraryPublicData();
        getItineraryDraftList();
    }, [])

    return (
        <>
            <div className="row">
                <div className="col-lg-3">
                    <div className="filters-bar">
                        <div className="filterbar-header">
                            <h5>Filter</h5>
                            <button type="button" onClick={handleClearAll} className="btn btn-border-color clear-all">Clear all</button>
                        </div>
                        <Accordion alwaysOpen>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Price</Accordion.Header>
                                <Accordion.Body>
                                    {itineraryPublicData.length > 0 ? <> <InputRange
                                        step={1}
                                        // formatLabel={value => `${value} ${currencyData?.currency}`}
                                        formatLabel={value => null}
                                        draggableTrack={false}
                                        allowSameValues={false}
                                        minValue={minMaxPriceValue.min}
                                        maxValue={minMaxPriceValue.max}
                                        value={filterPriceValue}
                                        onChange={value => setFilterPriceValue(value)}
                                        onChangeComplete={value => handlePriceFilterComplete(value)}
                                    />
                                        <div className="pricerange-minmax">
                                            <span>{currencyData?.currency} {filterPriceValue.min} </span>
                                            <span>{currencyData?.currency} {filterPriceValue.max} </span>
                                        </div> </> : null}
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Country</Accordion.Header>
                                <Accordion.Body>
                                    <div className="filter-search-box">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Search country ..."
                                            value={searchCountryQuery}
                                            onChange={handleSearchCountryChange}
                                        />
                                        {searchCountryQuery === "" ? "" : <button onClick={handleSearchClear} type="button" className="btn btn-clear"><i className="bi bi-x"></i></button>}
                                    </div>
                                    <ul className="filter-list-ul">
                                        {filterCountryList && filterCountryList.map((country, i) => (
                                            <li className="CountryList" key={country.name}>
                                                <div className="form-check">
                                                    <input ref={(element) => { countryRef.current[i] = element }} onChange={(e) => handleCountryChange(e, country)} className="form-check-input" type="radio" name="countryFilter" value={country.name} id={`countryName${i}`} />
                                                    <label className="form-check-label" htmlFor={`countryName${i}`}>
                                                        {country.name}
                                                    </label>
                                                </div>
                                            </li>
                                        ))}
                                        {filterCountryList.length === 0 && (
                                            <li className="no-result text-center">No country found.</li>
                                        )}
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>City</Accordion.Header>
                                <Accordion.Body>
                                    <MultiSelect
                                        className="custom-multi-select"
                                        options={updatedCity}
                                        value={selectedCityData}
                                        onChange={handleCityChange}
                                        labelledBy="Select city"
                                        hasSelectAll={false}
                                        overrideStrings={{
                                            selectSomeItems: "Select city",
                                            noOptions: "City not found.",
                                        }}
                                        ClearIcon={<i className="bi bi-x"></i>}
                                        ClearSelectedIcon={<i className="bi bi-x"></i>}
                                    />
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>Days</Accordion.Header>
                                <Accordion.Body>
                                    <InputRange
                                        step={1}
                                        formatLabel={value => null}
                                        draggableTrack={false}
                                        allowSameValues={false}
                                        value={filterDaysValue}
                                        minValue={1}
                                        maxValue={60}
                                        onChange={value => setFilterDaysValue(value)}
                                        onChangeComplete={value => handleDaysFilterComplete(value)}
                                    />
                                    <div className="pricerange-minmax">
                                        <span>{filterDaysValue.min} days</span>
                                        <span>{filterDaysValue.max} days</span>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>Date</Accordion.Header>
                                <Accordion.Body className="date-accordian-bx">
                                    <DateRange
                                        editableDateInputs={true}
                                        onChange={item => handleDateFilter(item)}
                                        moveRangeOnFirstSelection={false}
                                        ranges={stateDate}
                                        rangeColors={["#004aad"]}
                                    />
                                    {/* <DatePicker
                        selected={toDateFilter}
                        onChange={date => handleDateFilter(date)}
                        inline
                        selectsStart
                        selectsEnd
                        startDate={new Date()}
          endDate={new Date()}
                        /> */}
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header>Rating</Accordion.Header>
                                <Accordion.Body>
                                    <ul className="filter-list-ul">
                                        <li>
                                            <div className="form-check">
                                                <input ref={(element) => { ratingRef.current[0] = element }} onChange={handleTravellerRatingChange} className="form-check-input" type="checkbox" value="5" id="Rating5" />
                                                <label className="form-check-label rating-filter" htmlFor="Rating5">
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="form-check">
                                                <input ref={(element) => { ratingRef.current[1] = element }} onChange={handleTravellerRatingChange} className="form-check-input" type="checkbox" value="4" id="Rating4" />
                                                <label className="form-check-label rating-filter" htmlFor="Rating4">
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star"></i>
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="form-check">
                                                <input ref={(element) => { ratingRef.current[2] = element }} onChange={handleTravellerRatingChange} className="form-check-input" type="checkbox" value="3" id="Rating3" />
                                                <label className="form-check-label rating-filter" htmlFor="Rating3">
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star"></i>
                                                    <i className="bi bi-star"></i>
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="form-check">
                                                <input ref={(element) => { ratingRef.current[3] = element }} onChange={handleTravellerRatingChange} className="form-check-input" type="checkbox" value="2" id="Rating2" />
                                                <label className="form-check-label rating-filter" htmlFor="Rating2">
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star"></i>
                                                    <i className="bi bi-star"></i>
                                                    <i className="bi bi-star"></i>
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="form-check">
                                                <input ref={(element) => { ratingRef.current[4] = element }} onChange={handleTravellerRatingChange} className="form-check-input" type="checkbox" value="1" id="Rating1" />
                                                <label className="form-check-label rating-filter" htmlFor="Rating1">
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star"></i>
                                                    <i className="bi bi-star"></i>
                                                    <i className="bi bi-star"></i>
                                                    <i className="bi bi-star"></i>
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="6">
                                <Accordion.Header>Travellers</Accordion.Header>
                                <Accordion.Body>
                                    <ul className="filter-list-ul">
                                        <li>
                                            <div className="form-check">
                                                <input ref={(element) => { travellersRef.current[0] = element }} onChange={handleTravellerChange} className="form-check-input" type="checkbox" value="Solo" id="Solo" />
                                                <label className="form-check-label" htmlFor="Solo">
                                                    Solo
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="form-check">
                                                <input ref={(element) => { travellersRef.current[1] = element }} onChange={handleTravellerChange} className="form-check-input" type="checkbox" value="Couple" id="Couple" />
                                                <label className="form-check-label" htmlFor="Couple">
                                                    Couple
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="form-check">
                                                <input ref={(element) => { travellersRef.current[2] = element }} onChange={handleTravellerChange} className="form-check-input" type="checkbox" value="Family" id="Family" />
                                                <label className="form-check-label" htmlFor="Family">
                                                    Family
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="form-check">
                                                <input ref={(element) => { travellersRef.current[3] = element }} onChange={handleTravellerChange} className="form-check-input" type="checkbox" value="Group" id="Group" />
                                                <label className="form-check-label" htmlFor="Group">
                                                    Group
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </div>
                <div className="col-lg-9 mt-4 mt-lg-0">
                    <Pagination
                        className="pagination-style paginate-item-none text-end mb-2"
                        showTitle={false}
                        showTotal={(total, range) => `(Showing ${range[0]} - ${range[1]} itineraries of ${total} itineraries)`}
                        onChange={publicPaginationChange}
                        total={cityWiseItinerary?.length}
                        current={currentPublicPaginate}
                        pageSize={sizePublicPaginate}
                        showSizeChanger={false}
                        onShowSizeChange={perPageChangePublic}
                        hideOnSinglePage={false}
                    />
                    <div className="sortby-select-bx">
                        <span className="p-sortby-label">Sort By:</span>
                        <button type="button" className="p-sort-item" onClick={changeRecentSort}>Recent</button>
                        <button type="button" className="p-sort-item" onClick={changePopularitySort}>Popularity</button>
                        <button type="button" className="p-sort-item" onClick={changePriceLh}>Price - Low to High</button>
                        <button type="button" className="p-sort-item" onClick={changePriceHl}>Price - High to Low</button>
                    </div>
                    {isLoading ? <LoadingSpinnerTwo /> : cityWiseItinerary.length === 0 ? <p className="text-center">No itinerary found.</p> :
                        cityWiseItinerary && cityWiseItinerary?.slice((currentPublicPaginate - 1) * sizePublicPaginate, currentPublicPaginate * sizePublicPaginate).map((item, i) => {
                            const imgArrl = [];
                            return (
                                <>
                                    {item.type_of_itinerary === "Public" ?
                                        <div className="itineraries-serach-item mt-40">
                                            <div className="itineraries-img-scrollbar">
                                                <div className="itineraries-img-wrap" onClick={(e) => handleItinerariesDetails(item, e)}>
                                                    {item.destinationCityDetails.map((distitem, a) => {
                                                        return (<>
                                                            {distitem.itineraryDatys.map((daysitem, b) => {
                                                                return (<>
                                                                    {daysitem.itineraryDaysDetails.map((dtitem, c) => {
                                                                        imgArrl.push("");
                                                                        return (<>
                                                                            {dtitem.days_type === "Hotel" ?
                                                                                <>
                                                                                    {dtitem.hotelDetails.response_data.hotelImages.map((hotelImg, h) => {
                                                                                        return (
                                                                                            <>
                                                                                                {h === 0 ?
                                                                                                    <div className="itineraries-item-box">
                                                                                                        {/* {hotelImg.url === ""? <div className="itineraries-item-img" style={{backgroundImage: `url(${noPictureAvailable})`}}></div> : null} */}
                                                                                                        <div className="itineraries-item-img" style={{ backgroundImage: `url(${hotelImg.url})` }}></div>
                                                                                                    </div>
                                                                                                    : null}
                                                                                            </>)
                                                                                    })}
                                                                                </>
                                                                                : null}
                                                                            {dtitem.days_type === "Food" ?
                                                                                <div className="itineraries-item-box">
                                                                                    {dtitem.icon === "" ? <div className="itineraries-item-img" style={{ backgroundImage: `url(${noPictureAvailable})` }}></div> : <div className="itineraries-item-img" style={{ backgroundImage: `url(${photoUrl}maxwidth=400&photoreference=${dtitem.icon}${apikey})` }}></div>}
                                                                                </div>
                                                                                : null}
                                                                            {dtitem.days_type === "Activity" ?
                                                                                <div className="itineraries-item-box">
                                                                                    {dtitem.icon === "" ? <div className="itineraries-item-img" style={{ backgroundImage: `url(${noPictureAvailable})` }}></div> : <div className="itineraries-item-img" style={{ backgroundImage: `url(${photoUrl}maxwidth=400&photoreference=${dtitem.icon}${apikey})` }}></div>}
                                                                                </div>
                                                                                : null}
                                                                            {dtitem.days_type === "Others" ?
                                                                                <div className="itineraries-item-box">
                                                                                    {dtitem.icon === "" ? <div className="itineraries-item-img" style={{ backgroundImage: `url(${noPictureAvailable})` }}></div> : <div className="itineraries-item-img" style={{ backgroundImage: `url(${imageUrlOthers + dtitem.icon})` }}></div>}
                                                                                </div>
                                                                                : null}
                                                                        </>)
                                                                    })}
                                                                </>)
                                                            })}
                                                        </>)
                                                    })}
                                                    {imgArrl.length > 7 ?
                                                        <div className="itineraries-item-box iti-img-count-item">
                                                            <div className="iti-img-count-item-in">
                                                                <span>+{Number(imgArrl.length) - 7}</span>
                                                            </div>
                                                        </div>
                                                        : ""}
                                                </div>
                                            </div>
                                            <div className="itineraries-captions-wrap">
                                                <div className="itineraries-title">
                                                    <h4><span>@{item?.userId?.name}</span> built a <span>{item?.destinationCityDetails?.reduce((accumulator, current) => accumulator + current.itineraryDatys.length, 0)} Days</span> itinerary</h4>
                                                    <div className="itineraries-ratings">
                                                        {[...Array(totalStars)].map((star, index) => {
                                                            return index < item.avg_rating && index + 1 > item.avg_rating ? <i className="bi bi-star-half"></i> : index < item.avg_rating ? <i className="bi bi-star-fill"></i> : <i className="bi bi-star"></i>;
                                                        })}
                                                    </div>
                                                </div>
                                                <p>{item.destinationCityDetails.map((desitem, d) => { return <>{desitem.destination_city.country}, {desitem.destination_city.city}<span>&nbsp;|&nbsp;</span></> })}{item.no_adult_travellers} adults {item.no_children_travellers.no_children === 0 ? null : "and " + item.no_children_travellers.no_children + " children"} <span>|</span> Estimated cost: {Math.floor(item.currencyConvert?.new_amount)} {item.currencyConvert?.new_currency} </p>
                                                <div className="itineraries-btns">
                                                    <div className="itineraries-btn-left">
                                                        {authed?.isAuth === true ? (
                                                            <>
                                                                {item && likeArrData(item?.likeData, item, i)}
                                                                <button type="button" onClick={(e) => handleItinerariesComment(item, e, i)} className="btn btn-border-color"><img width="20" src={chatIcon} alt="Chat icon" /> ({item?.commentData?.length})</button>
                                                                <Dropdown className="rating-itinerary-drp">
                                                                    <Dropdown.Toggle className="btn btn-border-color">
                                                                        <span onClick={(e) => handleItineraryRating(item, e)}>
                                                                            <img width="15" src={starIcon} alt="Stat icon" /> ({item?.ratingata?.length})
                                                                        </span>
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu>
                                                                        <Dropdown.Item>
                                                                            <ReactStars
                                                                                count={5}
                                                                                onChange={ratingChanged}
                                                                                size={16}
                                                                                isHalf={false}
                                                                                emptyIcon={<i className="bi bi-star"></i>}
                                                                                halfIcon={<i className="bi bi-star-half"></i>}
                                                                                filledIcon={<i className="bi bi-star-fill"></i>}
                                                                                activeColor="#FFA800"
                                                                            />
                                                                        </Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <button type="button" onClick={() => history.push(`/signin`)} className="btn btn-border-color"><img width="15" src={likeIcon} alt="Like icon" /> ({item?.likeData?.length})</button>
                                                                <button type="button" onClick={() => history.push(`/signin`)} className="btn btn-border-color"><img width="20" src={chatIcon} alt="Chat icon" /> ({item?.commentData?.length})</button>
                                                                <button type="button" onClick={() => history.push(`/signin`)} className="btn btn-border-color"><img width="15" src={starIcon} alt="Stat icon" /> ({item?.ratingata?.length})</button>
                                                            </>
                                                        )}

                                                    </div>
                                                    <div className="itineraries-btn-right">
                                                        <OverlayTrigger
                                                            placement="top"
                                                            trigger="click"
                                                            rootClose
                                                            overlay={
                                                                <Popover>
                                                                    <Popover.Body className="share-popover-body-sty">
                                                                        <FacebookShareButton url={`${process.env.REACT_APP_BASE_PATH}/public-itinarary-details/?itineraryId=${item._id}`}>
                                                                            <FacebookIcon size={24} round={true} />
                                                                        </FacebookShareButton>
                                                                        <WhatsappShareButton url={`${process.env.REACT_APP_BASE_PATH}/public-itinarary-details/?itineraryId=${item._id}`}>
                                                                            <WhatsappIcon size={24} round={true} />
                                                                        </WhatsappShareButton>
                                                                        <TwitterShareButton url={`${process.env.REACT_APP_BASE_PATH}/public-itinarary-details/?itineraryId=${item._id}`}>
                                                                            <TwitterIcon size={24} round={true} />
                                                                        </TwitterShareButton>
                                                                        <LinkedinShareButton url={`${process.env.REACT_APP_BASE_PATH}/public-itinarary-details/?itineraryId=${item._id}`}>
                                                                            <LinkedinIcon size={24} round={true} />
                                                                        </LinkedinShareButton>
                                                                    </Popover.Body>
                                                                </Popover>
                                                            }
                                                        >
                                                            <button type="button" className="btn btn-border-color itinerary-btn-merge">
                                                                <span className="tooltip bs-tooltip-bottom" role="tooltip">
                                                                    <span className="tooltip-arrow"></span>
                                                                    <span className="tooltip-inner">
                                                                        Share
                                                                    </span>
                                                                </span>
                                                                <i className="bi bi-share"></i>
                                                            </button>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger
                                                            placement="bottom"
                                                            overlay={
                                                                <Tooltip>
                                                                    View Details
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <button type="button" onClick={(e) => handleItinerariesDetails(item, e)} className="btn btn-border-color"><i className="bi bi-eye"></i></button>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger
                                                            placement="bottom"
                                                            overlay={
                                                                <Tooltip>
                                                                    Request Quote
                                                                </Tooltip>
                                                            }
                                                        >
                                                            {authed?.isAuth === true ?
                                                                <button type="button" onClick={(e) => handleItineraryQuote(item, e)} className="btn btn-border-color"><i className="bi bi-chat-quote"></i></button>
                                                                : <button type="button" onClick={() => history.push(`/signin`)} className="btn btn-border-color"><i className="bi bi-chat-quote"></i></button>}
                                                        </OverlayTrigger>

                                                        {props.showExisting === true ? <OverlayTrigger
                                                            trigger="click"
                                                            placement="top"
                                                            rootClose
                                                            overlay={
                                                                <Popover>
                                                                    <Popover.Body className="delete-popover-body-sty">
                                                                        <h6>Are you sure?</h6>
                                                                        <p>Customize this itinerary</p>
                                                                        <div className="delete-btn-groups mt-2">
                                                                            <button onClick={(e) => handleItinerariesEdit(item, e)} type="button" className="btn btn-primary-color">Yes</button>
                                                                            <button onClick={() => document.body.click()} type="button" className="btn btn-danger ms-2">No</button>
                                                                        </div>
                                                                    </Popover.Body>
                                                                </Popover>
                                                            }
                                                        >
                                                            <button type="button" className="btn btn-primary-color itinerary-btn-edit">
                                                                <span className="tooltip bs-tooltip-bottom" role="tooltip">
                                                                    <span className="tooltip-arrow"></span>
                                                                    <span className="tooltip-inner">
                                                                        Customize itinerary
                                                                    </span>
                                                                </span>
                                                                <i className="bi bi-pencil"></i>
                                                            </button>
                                                        </OverlayTrigger> : null}


                                                        {authed?.isAuth === true ?
                                                            <OverlayTrigger
                                                                trigger="click"
                                                                placement="top"
                                                                rootClose
                                                                onExit={handleMergeItineraryClose}
                                                                overlay={
                                                                    <Popover className="merge-itinerary-bx">
                                                                        <Popover.Header as="div">
                                                                            <div className="mi-header-searchbar">
                                                                                <input type="text" onChange={handleSearchItinerary} value={searchItineraryQuery} className="form-control" placeholder="Search itineraries to merge..." />
                                                                                {searchItineraryQuery === "" ? null : <button type="button" className="btn btn-clear" onClick={handleClearSearch}><i className="bi bi-x"></i></button>}
                                                                            </div>
                                                                        </Popover.Header>
                                                                        <Popover.Body>
                                                                            <div className="merge-itinerary-body">
                                                                                {filterItineraryData?.length > 0 && filterItineraryData?.map((iteml, dl) => {
                                                                                    return (
                                                                                        <>
                                                                                            <div className="form-check">
                                                                                                <input className="form-check-input" onChange={(e) => handleSelectItinerary(e, iteml)} value={iteml.tour_name} ref={(element) => { itineraryRef.current[dl] = element }} type="radio" name="idl" id={`idl_${dl + 1}`} />
                                                                                                <label className="form-check-label" htmlFor={`idl_${dl + 1}`}>{iteml.tour_name}</label>
                                                                                            </div>
                                                                                        </>
                                                                                    )
                                                                                })}
                                                                                {filterItineraryData?.length === 0 ?
                                                                                    <div className="text-center ">
                                                                                        <p><small>You do not have any draft itineraries to merge. Click to <Link to="/admin/create-itinerary">add new</Link></small></p>
                                                                                    </div> : null}
                                                                            </div>
                                                                        </Popover.Body>
                                                                        <div className="popover-footer">
                                                                            <button type="button" onClick={() => handleMergeItinerary()} disabled={itemItineraryId.defaultItineraryId === "" || isMergeLoading === true} className="btn btn-primary-color">{isMergeLoading ? "Merging..." : "Merge"}</button>
                                                                            <button type="button" onClick={() => { handleMergeItineraryClose(); document.body.click(); }} className="btn btn-danger ms-2">Close</button>
                                                                        </div>
                                                                    </Popover>
                                                                }>
                                                                <button type="button" className="btn btn-border-color itinerary-btn-merge" onClick={() => handleGetItinerayId(item._id)}>
                                                                    <span className="tooltip bs-tooltip-bottom" role="tooltip">
                                                                        <span className="tooltip-arrow"></span>
                                                                        <span className="tooltip-inner">
                                                                            Merge itinerary
                                                                        </span>
                                                                    </span>
                                                                    <i className="bi bi-plus-lg"></i>
                                                                </button>
                                                            </OverlayTrigger>

                                                            : <OverlayTrigger
                                                                placement="bottom"
                                                                overlay={<Tooltip>Merge itinerary</Tooltip>}
                                                            >
                                                                <button type="button" onClick={() => history.push(`/signin`)} className="btn btn-border-color"><i className="bi bi-plus-lg"></i></button>
                                                            </OverlayTrigger>
                                                        }

                                                    </div>
                                                </div>
                                                <Collapse in={isOpenCollapse === i}>
                                                    <div className="itinerary-comment-box">
                                                        <form onSubmit={commentFromik.handleSubmit} className="d-flex comnt-iti-txtarea">
                                                            <div className="d-col-input-field">
                                                                <TextareaAutosize maxLength={160}
                                                                    onChange={commentFromik.handleChange}
                                                                    onBlur={commentFromik.handleBlur}
                                                                    value={commentFromik.values.comment}
                                                                    name="comment" className="form-control" placeholder="Write a Comment..." />
                                                                {/* <span className="txt-limit-area">{commentFromik.values.comment.length}/160 Characters</span> */}
                                                            </div>
                                                            <button disabled={!(commentFromik.isValid && commentFromik.dirty)} type="submit" className="btn btn-primary-color btn-border-color"><i className="bi bi-send-fill"></i></button>
                                                        </form>
                                                    </div>
                                                </Collapse>
                                            </div>
                                        </div>
                                        : ""}
                                </>
                            )
                        })
                    }
                    <Pagination
                        className="pagination-style text-end mt-4"
                        showTitle={false}
                        showTotal={(total, range) => `Showing ${range[0]} - ${range[1]} itineraries of ${total} itineraries`}
                        onChange={publicPaginationChange}
                        total={cityWiseItinerary?.length}
                        current={currentPublicPaginate}
                        pageSize={sizePublicPaginate}
                        showSizeChanger={false}
                        onShowSizeChange={perPageChangePublic}
                        hideOnSinglePage={true}
                    />
                </div>
            </div>
        </>
    )
});

export default PublicItinerary