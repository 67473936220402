import "./PublicItineraryDetails.scss";
import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import BlankAvatar from '../../../assets/images/blank-avatar.png';
import noPictureAvailable from '../../../assets/images/no-picture-available.jpg';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { publicDetailServiceApi } from '../../../services/DataService';
import { imageUrlProfile, googleApiKey, googlePhotoUrl, imageUrlOthers } from '../../../services/CommonUrl';
import { LoadingSpinnerOne } from "../../../loader/Loader";
import ModalMap from '../../Admin/manage-itinerary/ModalMap';
import moment from 'moment';
import { useAuth } from "../../../contexts/AuthProvider";

const ptiItemSliderOptions = {
    loop: false,
    autoplay: false,
    margin: 10,
    responsiveClass: true,
    mouseDrag: false,
    touchDrag: true,
    navText: ['<i class="bi bi-chevron-left"></i>', '<i class="bi bi-chevron-right"></i>'],
    responsive: {
        0: {
            items: 1,
            nav: false,
            dots: true
        },
        480: {
            items: 2,
            nav: false,
            dots: true
        },
        768: {
            items: 3,
            nav: false,
            dots: true
        },
        1024: {
            items: 3,
            nav: true,
            dots: false,
            margin: 16
        }
    }
}

const PublicItineraryDetails = () => {
    const { currencyData } = useAuth();
    const photoUrl = `${googlePhotoUrl}`;
    const apikey = `&key=${googleApiKey}`;
    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [itineraryDetailsData, setItineraryDetailsData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [showMapModal, setShowMapModal] = useState(false);
    const [daysitemItineraryDaysDetails, setdaysitemItineraryDaysDetails] = useState([]);

    const publicItinerayDetails = async () => {
        setIsLoading(true);
        const Obj = {
            itineraryId: searchParams.get("itineraryId"),
            newCurrency: currencyData?.currency
        }
        await publicDetailServiceApi(Obj, async (res) => {
            // console.log("Details response: ", res.data.data.data);
            if (res.status === 404) {
                console.log('Something went wrong')
            }
            if (res.status === 200) {
                // console.log(res.data.data.message);
                if (res.data.data.data === null) return
                setItineraryDetailsData(res.data.data.data);
                setIsLoading(false);
            }
        });
    }
    const handleMapModalClose = () => setShowMapModal(false);
    const getItineraryDaysDetails = (data, event) => {
        event.preventDefault();
        setdaysitemItineraryDaysDetails(data);
        setShowMapModal(true);
    }

    const daysNumber = (i, d) => {
        let s = 0;
        for (let j = 0; j < i; j++) {
            s += Number(itineraryDetailsData?.destinationCityDetails[j]?.itineraryDatys?.length)
        }
        return s + d
    }

    useEffect(() => {
        publicItinerayDetails();
    }, []);

    return (
        <>
            <section className="inner-title-block inner-mrt-80">
                <div className="container-lg text-center">
                    <h1>Overview</h1>
                </div>
            </section>

            <section className="accomadation-details-wrapper padd-top-70 padd-bottom-70">
                <div className="container-lg">
                    {isLoading ? <LoadingSpinnerOne /> : null}
                    {itineraryDetailsData?.estimated_Cost ? <p className="txt-cost"> Estimated cost: {Math.floor(itineraryDetailsData?.currencyConvert?.new_amount) + " " + itineraryDetailsData?.currencyConvert?.new_currency}</p> : null}
                    {itineraryDetailsData && itineraryDetailsData?.destinationCityDetails?.length > 0 ? itineraryDetailsData?.destinationCityDetails?.map((destinationitem, i) => {
                        return (
                            <>
                                <div className="mt-3">
                                    <h4 className="d-nav-tabs-title mb-3">Destination City  -  <b>{destinationitem?.destination_city?.city}</b></h4>
                                    {destinationitem?.itineraryDatys?.length ? <OwlCarousel {...ptiItemSliderOptions} className="owl-carousel owl-theme customItinaray-owl-theme">
                                        {destinationitem?.itineraryDatys?.map((daysitem, d) => {
                                            return (
                                                <div className="item" key={daysitem._id}>
                                                    <div className="civ-item-bx">
                                                        <div className="civ-day-date d-flex justify-content-between">
                                                            <div>
                                                                <span className="civ-day-txt">Day {i > 0 ? daysNumber(i, daysitem.day) : daysitem.day}</span>
                                                                <span>{moment(daysitem.date).format("DD MMMM yyyy")}</span>
                                                            </div>
                                                            {daysitem?.itineraryDaysDetails?.length === 0 ? "" : <button type="button" className="btn btn-secondary-color" onClick={(event) => getItineraryDaysDetails(daysitem?.itineraryDaysDetails, event)}><i className="bi bi-geo-alt"></i></button>}
                                                        </div>
                                                        <div className="civ-list-row">
                                                            {daysitem?.itineraryDaysDetails?.sort((a, b) => a.time > b.time ? 1 : -1).map((item, d) => {
                                                                return (
                                                                    <>
                                                                        {item.days_type === "Hotel" ?
                                                                            <div className="civ-list-item">
                                                                                <div className="civ-item-top-flex">
                                                                                    <div className="civ-item-img">
                                                                                        {item.hotelDetails.response_data.hotelImages === "" ? <img src={`${noPictureAvailable}`} alt="" /> : <img src={`${item.hotelDetails.response_data.hotelImages[0].url}`} alt="" />}
                                                                                    </div>
                                                                                    <div className="civ-item-info">
                                                                                        <div className="civ-item-icon-check">
                                                                                            <span><img style={{ maxWidth: "14px" }} src={require("../../../assets/images/Stays-icon-2.png")} alt="icon" /> Stays</span>
                                                                                        </div>
                                                                                        <h5>{item.hotelDetails.response_data.name}</h5>
                                                                                        {item.amount === undefined || item.amount === null ? "" : <p className="civ-item-price">{item.currencyConvert ? Math.floor(item.currencyConvert.new_amount) : Math.floor(item.amount)} {item.currencyConvert ? item.currencyConvert.new_currency : item.currency}</p>}
                                                                                        <p className="civ-time-count">{item.time}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        : ""}
                                                                        {item.days_type === "Food" ?
                                                                            <div className="civ-list-item">
                                                                                <div className="civ-item-top-flex">
                                                                                    <div className="civ-item-img">
                                                                                        {item.icon === "" ? <img src={`${noPictureAvailable}`} alt="" /> : <img src={`${photoUrl}maxwidth=400&photoreference=${item.icon}${apikey}`} alt="" />}
                                                                                    </div>
                                                                                    <div className="civ-item-info">
                                                                                        <div className="civ-item-icon-check">
                                                                                            <span><img style={{ maxWidth: "10px" }} src={require("../../../assets/images/Food-icon-2.png")} alt="icon" /> Food</span>
                                                                                        </div>
                                                                                        <h5>{item.name}</h5>
                                                                                        {item.amount === undefined || item.amount === null ? "" : <p className="civ-item-price">{item.currencyConvert ? Math.floor(item.currencyConvert.new_amount) : Math.floor(item.amount)} {item.currencyConvert ? item.currencyConvert.new_currency : item.currency}</p>}
                                                                                        <p className="civ-time-count">{item.time}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            : ""}
                                                                        {item.days_type === "Activity" ?
                                                                            <div className="civ-list-item">
                                                                                <div className="civ-item-top-flex">
                                                                                    <div className="civ-item-img">
                                                                                        {item.icon === "" ? <img src={`${noPictureAvailable}`} alt="" /> : <img src={`${photoUrl}maxwidth=400&photoreference=${item.icon}${apikey}`} alt="" />}
                                                                                    </div>
                                                                                    <div className="civ-item-info">
                                                                                        <div className="civ-item-icon-check">
                                                                                            <span><img style={{ maxWidth: "11px" }} src={require("../../../assets/images/Activity-icon-2.png")} alt="icon" /> Activity</span>
                                                                                        </div>
                                                                                        <h5>{item.name}</h5>
                                                                                        {item.amount === undefined || item.amount === null ? "" : <p className="civ-item-price">{item.currencyConvert ? Math.floor(item.currencyConvert.new_amount) : Math.floor(item.amount)} {item.currencyConvert ? item.currencyConvert.new_currency : item.currency}</p>}
                                                                                        <p className="civ-time-count">{item.time}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            : ""}
                                                                        {item.days_type === "Others" ?
                                                                            <div className="civ-list-item">
                                                                                <div className="civ-item-top-flex">
                                                                                    <div className="civ-item-img">
                                                                                        {item.icon === "" ? <img src={`${noPictureAvailable}`} alt="" /> : <img src={`${imageUrlOthers + item.icon}`} alt="" />}
                                                                                    </div>
                                                                                    <div className="civ-item-info">
                                                                                        <div className="civ-item-icon-check">
                                                                                            <span><img style={{ maxWidth: "9px" }} src={require("../../../assets/images/others-icon.png")} alt="icon" /> Others</span>
                                                                                        </div>
                                                                                        <h5>{item.name}</h5>
                                                                                        {item.amount === undefined || item.amount === null ? "" : <p className="civ-item-price">{item.currencyConvert ? Math.floor(item.currencyConvert.new_amount) : Math.floor(item.amount)} {item.currencyConvert ? item.currencyConvert.new_currency : item.currency}</p>}
                                                                                        <p className="civ-time-count">{item.time}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            : ""}
                                                                    </>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </OwlCarousel> : ""}
                                </div>
                            </>
                        )
                    }) : ""}
                    {itineraryDetailsData?.commentData?.length > 0 ?
                        <>
                            <hr className="mt-3 mb-3" />
                            <div className="dt-comments-block">
                                <h4 className="d-nav-tabs-title">Comments</h4>
                                {itineraryDetailsData?.commentData?.length === 0 ? <p className="mt-2">No comments found.</p> : ""}
                                {itineraryDetailsData?.commentData?.map((commentItem, i) => {
                                    return (
                                        <>
                                            <div key={i} className="dt-comment-item">
                                                <div className="dt-comment-item-img">
                                                    {commentItem?.userId?.profileImage === undefined || commentItem?.userId?.profileImage === "" || commentItem?.userId?.profileImage === null ? <img src={`${BlankAvatar}`} alt="" /> : commentItem?.userId?.loginType === "social" ? <img src={commentItem?.userId.profileImage} /> : <img src={imageUrlProfile + commentItem?.userId.profileImage} />}
                                                </div>
                                                <div className="testimonial-content">
                                                    <h5>{commentItem?.userId?.name}</h5>
                                                    <p>{commentItem?.comment}</p>
                                                    <p className="review-text">Posted on {moment(commentItem?.createdAt).format("DD MMM YY")}</p>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                                )}
                            </div>
                        </> : ""}
                </div>
            </section>
            <ModalMap open={showMapModal} days={daysitemItineraryDaysDetails} onClose={handleMapModalClose} />
        </>
    );
}

export default PublicItineraryDetails;
