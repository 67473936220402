import React, {useState, useRef, useEffect} from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {getUserPublicPrivateListApi, addExistingTripApi, removeItineraryApi} from '../../../services/AdminService';
import {imageUrlOthers, googleApiKey, googlePhotoUrl} from '../../../services/CommonUrl';
import noPictureAvailable from '../../../assets/images/no-picture-available.jpg';
import {LoadingSpinnerTwo} from "../../../loader/Loader";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { useAuth } from '../../../contexts/AuthProvider';

const ItinerariesList = () => {
    const {authed, currencyData} = useAuth();
    const photoUrl = `${googlePhotoUrl}`;
    const apikey = `&key=${googleApiKey}`;
    const history = useHistory();
    const location = useLocation();
    const [itinerariesListData, setItinerariesListData] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const getItineraryListData = async ()=>{
        setIsLoading(true);
        const Obj ={
            userId: authed?.user?._id,
            newCurrency: currencyData?.currency
        }
        await getUserPublicPrivateListApi(Obj, async (res) => {
            // console.log("res updateItineraryPublishApi ====", res);
            if (res.status === 404) {
                console.log('Something went wrong');
            }
            if (res.status === 200) {
                // console.log(res.data.data.message);
                const result = res.data.data.data.sort((a,b) => new Date(a) < new Date(b) ? 1 : -1);
                setItinerariesListData(result);
                setIsLoading(false);
            }
        });
    }

    const handlePreview = (e, item, i)=>{
        history.push(`/admin/itinerary-item-preview/?userId=${item.userId}&&itineraryId=${item.id}`)
    }
    const handleEdit = async (data)=>{
        const obj = {
            userId: authed?.user?._id,
            itineraryId: data._id,
            currency: currencyData?.currency,
        }
        // console.log(obj)
        await addExistingTripApi(obj, async (res) => {
            if (res.status === 404) {
                console.log('Something went wrong');
            }
            if (res.status === 200) {
                // console.log(res.data.data.message);
                history.push({
                    pathname:'/admin/create-itinerary/',
                    search:(`userId=${authed?.user?._id}&&itineraryId=${res.data.data.data._id}`)
                });
            }
        });
    }
    const handleDelete = async (e, data)=>{
        const obj = {
            itineraryId: data._id,
        }
        await removeItineraryApi(obj, async (res) => {
            if (res.status === 404) {
                console.log('Something went wrong')
            }
            if (res.status === 200) {
                //toast.success(res.data.data.message, options);
                document.body.click();
                getItineraryListData();
            }
        });
    }
    // const handleClose = () => {
    //     setAnchorEl(null);
    // };
    useEffect(() => {
        getItineraryListData();
    }, []);
    return ( 
        <>
       
        <div className="">
            <h1 className="d-col-title mb-3 mt-3">List Itineraries</h1>
            {isLoading ? <LoadingSpinnerTwo/> : itinerariesListData.length === 0 ? <p className="text-center">No existing itinerary.</p> : null}
            {itinerariesListData && itinerariesListData.length > 0? itinerariesListData?.map((item, i) =>{
                const imgArrl= [];
                return (
                <div className="listitenaries-item mt-3">
                    
                    {/* <div className="listitenaries-bottom-bx mt-4">
                        <h5>Darjeeling & Gangtok - Himalayan Beauty</h5>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.. <span className="readmore">Read More</span></p>
                    </div> */}
                    <div className="listitenaries-img-scroller">
                        <ul className="listitenaries-img-ul">
                            {item.destinationCityDetails.map((distitem, a) =>{
                                return <>
                                {distitem.itineraryDatys.map((daysitem, b) =>{
                                    return <>
                                        {daysitem.itineraryDaysDetails.map((dtitem, c) =>{
                                            imgArrl.push("");
                                            return<>
                                            {dtitem.days_type === "Hotel" ?
                                                <>
                                                {dtitem.hotelDetails.response_data.hotelImages.map((hotelImg, h) =>{
                                                    return <>
                                                    {h === 0 ?
                                                        <li>
                                                            <div className="listitenaries-img-bx">
                                                                <img src={`${hotelImg.url}`} alt=""/>
                                                            </div>
                                                        </li>
                                                    : null}
                                                    </>
                                                })}</>
                                            : ""}
                                            {dtitem.days_type === "Activity" ?
                                                <li>
                                                    <div className="listitenaries-img-bx">
                                                        {dtitem.icon === "" ? <img src={`${noPictureAvailable}`} alt=""/>: <img src={`${photoUrl}maxwidth=400&photoreference=${dtitem.icon}${apikey}`} alt=""/>}  
                                                    </div>
                                                </li>
                                            : ""}
                                            {dtitem.days_type === "Food" ?
                                                <li>
                                                    <div className="listitenaries-img-bx">
                                                        {dtitem.icon === "" ? <img src={`${noPictureAvailable}`} alt=""/>: <img src={`${photoUrl}maxwidth=400&photoreference=${dtitem.icon}${apikey}`} alt=""/>}  
                                                    </div>
                                                </li>
                                            : ""}
                                            {dtitem.days_type === "Others" ?
                                                <li>
                                                    <div className="listitenaries-img-bx">
                                                        {dtitem.icon === "" ? <img src={`${noPictureAvailable}`} alt=""/>: <img src={`${imageUrlOthers+dtitem.icon}`} alt=""/>}  
                                                    </div>
                                                </li>
                                            : ""}
                                            </>
                                        })}
                                    </>
                                })}
                            </>
                            })}
                            {imgArrl.length > 7 ? 
                                <li className="lit-img-count-item">
                                    <div className="lit-img-count-item-in">
                                        <span>+{Number(imgArrl.length) - 8}</span>
                                    </div>
                                </li>
                            : ""}
                            {/* <li>
                                <div className="listitenaries-img-bx">
                                    <img src={ require("../../assets/images/list-itenaries-img-2.png") } alt="Itenaries" />
                                </div>
                            </li> */}
                        </ul>
                    </div>
                    <div className="listitenaries-top-bx">
                        <div className="listitenaries-item-title">
                            <span className="listitenaries-cust">{item.type_of_itinerary}</span>
                            <h4>{item.tour_name}</h4>
                            <p>{item.destinationCityDetails.reduce((accumulator, current) => accumulator + current.itineraryDatys.length, 0)} days <span>|</span>  {item.travellers_type} <span>|</span>&nbsp;
                            {item.destinationCityDetails.map((desitem, d) =>{return <>{desitem.destination_city.country}, {desitem.destination_city.city}<span>&nbsp;|&nbsp;</span></>})}
                            {item.no_adult_travellers} adults {item.no_children_travellers.no_children === 0 ? null : "and "+ item.no_children_travellers.no_children +"children"} <span>|</span> Estimated cost: {Math.floor(item.currencyConvert.new_amount)} {item.currencyConvert.new_currency}</p>
                        </div>
                        <div className="listitenaries-item-btns">
                            <button onClick={(e)=>handlePreview(e, item, i)} type="button" className="btn"><i className="bi bi-eye"></i></button>
                            <OverlayTrigger
                            trigger="click"
                            placement="top"
                            rootClose
                            overlay={
                                <Popover>
                                    <Popover.Body className="delete-popover-body-sty">
                                        <h6>Are you sure?</h6>
                                        <p>Customize this itinerary</p>
                                        <div className="delete-btn-groups mt-2">
                                            <button onClick={() => handleEdit(item, i)} type="button" className="btn btn-primary-color">Yes</button>
                                            <button onClick={()=> document.body.click()} type="button" className="btn btn-danger ms-2">No</button>
                                        </div>
                                    </Popover.Body>
                                </Popover>
                            }
                            >
                                <button type="button" className="btn edit-icon"></button>
                            </OverlayTrigger>
                            <OverlayTrigger
                            trigger="click"
                            placement="top"
                            rootClose
                            overlay={
                                <Popover>
                                    <Popover.Body className="delete-popover-body-sty">
                                        <h6>Are you sure?</h6>
                                        <p>This itinerary will be deleted permanently</p>
                                        <div className="delete-btn-groups mt-2">
                                            <button onClick={(e) => handleDelete(e, item, i)} type="button" className="btn btn-primary-color">Yes</button>
                                            <button onClick={()=> document.body.click()} type="button" className="btn btn-danger ms-2">No</button>
                                        </div>
                                    </Popover.Body>
                                </Popover>
                            }
                            >
                            <button type="button" className="btn delete-icon"></button>
                            </OverlayTrigger>
                        </div>
                    </div>
                </div>
                )}): null}
            
        </div>
                    
        </>
    );
}
 
export default ItinerariesList;