import axios from "axios"; 
import { baseURL } from "./CommonUrl";

export const HotelSearchService = async(data, cb) => {
    const fullURL = baseURL + "hotelSearchFromAPI"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}
export const StaySearchLatLngService = async(data, cb) => {
    const fullURL = baseURL + "hotelSearchFromAPILatLng"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}
export const HotelDetailService = async(data, cb) => {
    const fullURL = baseURL + "GetHotelContent"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}
export const HotelFilterService = async(data, cb) => {
    const fullURL = baseURL + "hotle_filter"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}
export const HotelRoomRateService = async(data, cb) => {
    const fullURL = baseURL + "get_room_rates"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}
export const HotelRoomRateRulesService = async(data, cb) => {
    const fullURL = baseURL + "get_room_rates_rules"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}

export const UserBookHotelPayment = async(data, cb) => {
    const fullURL = baseURL + "user_book_hotel_payment"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}

export const getStaysDataList = async(data, cb) => {
    const fullURL = baseURL + "get_stays_data_list"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}

export const deleteStaysDataApi = async(data, cb) => {
    const fullURL = baseURL + "delete_stays_data"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}
export const getBookedHotelDataApi = async(data, cb) => {
    const fullURL = baseURL + "hotel_data"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}

export const cancelBookingApi = async(data, cb) => {
    const fullURL = baseURL + "cancelBooking"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}
export const saveUserHotelBook = async(data, cb) => {
    const fullURL = baseURL + "save_user_hotel_book"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}
