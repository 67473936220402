import './StayCheckout.scss';
import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Spinner from "react-bootstrap/Spinner";
import noPictureAvailable from '../../../../assets/images/no-picture-available.jpg';
import PaypalLogo from '../../../../assets/images/paypal.png';
import StripeLogo from '../../../../assets/images/card.png';
import { HotelRoomRateRulesService, HotelDetailService, UserBookHotelPayment} from '../../../../services/StayService';
import {getManageTravelService } from '../../../../services/AdminService';
import { LoadingSpinnerOne } from '../../../../loader/Loader';
import AddItineraryModal from "../../shared/common/AddItineraryModal";
import SessionExpiredModal from "../../shared/common/SessionExpiredModal";
import NotAvailableModal from '../../shared/common/NotAvailableModal';
import TimePicker from 'react-bootstrap-time-picker';
import { timeFromInt } from 'time-number';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import moment from 'moment';
import Form from 'react-bootstrap/Form';
import {toast} from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from '../../../../contexts/AuthProvider';
toast.configure();

const StayCheckout = (props) => {
    const { authed } =useAuth();
    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [isLoading, setIsLoading] = useState(false);
    const [hotelCheckoutData, setHotelCheckoutData] = useState();
    const [getHotelDetailsData, setHotelDetailsData] = useState();
    const [occupancyData, setOccupancyData] = useState();
    const [getTravellerList, setGetTravellerList] = useState();
    const [validated, setValidated] = useState(false);
    const [showAdultTraveller, setShowAdultTraveller] = useState(false);
    const handleAdultTravellerClose = () => {setShowAdultTraveller(false)}
    const handleAdultTravellerShow = () => {setShowAdultTraveller(true)}
    const [showChildTraveller, setShowChildTraveller] = useState(false);
    const handleChildTravellerClose = () => {setShowChildTraveller(false)}
    const handleChildTravellerShow = () => {setShowChildTraveller(true)}
    const [timeHdn, setTimeHdn] = useState({time: ""});
    const [showItineraryList, setShowItineraryList] = useState(false);
    const [showExpiredModal, setShowExpiredModal] = useState(false);
    const handleExpiredModalClose = () => setShowExpiredModal(false);
    const [showPaymentMethodModal, setPaymentMethodModal] = useState(false);
    const [showNotAvailableModal, setShowNotAvailableModal] = useState(false);
    const handleNotAvailableModalClose = () => setShowNotAvailableModal(false);
    const [isDataLoading, setIsDataLoading] = useState(false);

    const initialFormData = {
        email: "",
        phone: "",
        bookingNote: "",
        bookingTime: "",
        termsAndConditions: false,
    };
    const [userData, setUserData] = useState(initialFormData);
    const [getNightsCount, setNightsCount] = useState("");
    const userSearchDataLocal = async () => {
            const getEpData = authed?.user;
            await setUserData(getEpData);
            await setOccupancyData(JSON.parse(searchParams.get("occupancy")));
            const startDate = moment(searchParams.get("checkInDate"));
            const endDate = moment(searchParams.get("checkOutDate"));
            setNightsCount(endDate.diff(startDate, 'days'));
    }
    const getTravellerListData = async ()=>{
        await getManageTravelService({userId: authed?.user?._id}, async (res) => {
            if (res.status === 404) {
                console.log("Error 404");
              return;
            }
            if (res.status === 200) {
            //   console.log("API Get traveller list response: ", res.data.data.data);
              await setGetTravellerList(res.data.data.data)
            }
        });
    }
   
    const getHotelRoomRateRules = async () => {
        setIsLoading(true);
        const Obj = {
            sessionId: searchParams.get("sessionId"),
            tokenId: searchParams.get("tokenId"),
            hotelId: searchParams.get("hotelId"),
            rateBasisId: searchParams.get("rateBasisId"),
            productId: searchParams.get("productId")
        }
        await HotelRoomRateRulesService(Obj, async (res) => {
            // console.log("HotelRoomRateRulesService====", res.data.data);
            if (res.status === 404) {
                console.log('Something went wrong')
            }
            if (res.status === 200) {
                await setHotelCheckoutData(res.data.data);
                setIsLoading(false);
                if(res.data.data.response_data.status.error === "session expired or invalid sessionId" || 
                res.data.data.response_data.status.error === "Invalid sessionId or session expired."){
                    setShowExpiredModal(true);
                }
                if(res.data.data.response_data.status.error === "Selected Room Not Available."){
                    setShowNotAvailableModal(true);
                }
            }
        });
    }

    const getHotelDetails = async () => {
        //setIsLoading(true);
        const Obj = {
            hotelId: searchParams.get("hotelId"),
            productId: searchParams.get("productId"),
            sessionId: searchParams.get("sessionId"),
            tokenId: searchParams.get("tokenId")
        }
        await HotelDetailService(Obj, async (res) => {
            // console.log("Hotel deatils for check out: ", res.data.data);
            if (res.status === 404) {
                console.log('Something went wrong')
            }
            if (res.status === 200) {
                //setIsLoading(false);
                await setHotelDetailsData(res.data.data);
            }
        });
    }

    // For Add travellers functions
    const [getArrPax, setArrPax] = useState([]);
    const bookingArrDetails = () => {
        let Arr = [];
        const getTravelTotalData = JSON.parse(searchParams.get("occupancy"));
        for( let a= 0; a < getTravelTotalData.length; a++ ){
            Arr.push({"room_no": a+1, adult: {title: [], firstName: [], lastName: []}, child: {title: [], firstName: [], lastName: []}});
            for( let c= 0; c < getTravelTotalData[a].adult; c++ ){
                Arr[a].adult.title.push('');
                Arr[a].adult.firstName.push('');
                Arr[a].adult.lastName.push('');
            }
            for( let d= 0; d < getTravelTotalData[a].child; d++ ){
                Arr[a].child.title.push('');
                Arr[a].child.firstName.push('');
                Arr[a].child.lastName.push('');
            }
            setArrPax(Arr);
        }
    }
    const [getAdultValue, setGetAdultValue] = useState({});
    const [getChildValue, setGetChildValue] = useState({});
    
    const handleTitleChange = async (e, i, j) => {
        e.preventDefault();
        getArrPax[i].adult.title[j] = e.target.value;
        setGetAdultValue({...getAdultValue, [e.target.name]: e.target.value});
    }
    const handleFirstNameChange = (e, i, j) => {
        e.preventDefault();
        getArrPax[i].adult.firstName[j] = e.target.value;
        setGetAdultValue({...getAdultValue, [e.target.name]: e.target.value});
    }
    const handleLastNameChange = (e, i, j) => {
        e.preventDefault();
        getArrPax[i].adult.lastName[j] = e.target.value;
        setGetAdultValue({...getAdultValue, [e.target.name]: e.target.value});
    }

    const handleChildTitleChange = async (e, i, k) => {
        e.preventDefault();
        getArrPax[i].child.title[k] = e.target.value;
        setGetChildValue({...getChildValue, [e.target.name]: e.target.value});
    }
    const handleChildFirstNameChange = (e, i, k) => {
        e.preventDefault();
        getArrPax[i].child.firstName[k] = e.target.value;
        setGetChildValue({...getChildValue, [e.target.name]: e.target.value});
    }
    const handleChildLastNameChange = (e, i, k) => {
        e.preventDefault();
        getArrPax[i].child.lastName[k] = e.target.value;
        setGetChildValue({...getChildValue, [e.target.name]: e.target.value});
    }

    const [getIndexRoom, setIndexRoom] = useState();
    const [getIndexRoomC, setIndexRoomC] = useState();
    const [getIndexAdult, setIndexAdult] = useState();
    const [getIndexChild, setIndexChild] = useState();
    const changeSelectAdultItem = (e, i, itemadulttraveller) => {
        getArrPax[getIndexRoom].adult.title[getIndexAdult] = itemadulttraveller.title;
        getArrPax[getIndexRoom].adult.firstName[getIndexAdult] = itemadulttraveller.first_name;
        getArrPax[getIndexRoom].adult.lastName[getIndexAdult] = itemadulttraveller.last_name;
        setShowAdultTraveller(false);
       
    };
    const changeSelectChildItem = (e, x, itemchildtraveller) => {
        getArrPax[getIndexRoomC].child.title[getIndexChild] = itemchildtraveller.title;
        getArrPax[getIndexRoomC].child.firstName[getIndexChild] = itemchildtraveller.first_name;
        getArrPax[getIndexRoomC].child.lastName[getIndexChild] = itemchildtraveller.last_name;
        setShowChildTraveller(false);
    };

    const handleAddAdultTraveller =(e, i, j)=>{
        setIndexRoom(i);
        setIndexAdult(j)
        setShowAdultTraveller(true);
    }
    const handleDeleteAdultTraveller =(e, i, j)=>{
        getArrPax[i].adult.title[j] = "";
        getArrPax[i].adult.firstName[j] = "";
        getArrPax[i].adult.lastName[j] = "";
        setArrPax([...getArrPax]);
    }

    const handleAddChildTraveller =(e, i, k)=>{
        setIndexRoomC(i);
        setIndexChild(k)
        setShowChildTraveller(true);
    }
    const handleDeleteChildTraveller =(e, i, k)=>{
        // console.log("delete", i, k)
        getArrPax[i].child.title[k] = "";
        getArrPax[i].child.firstName[k] = "";
        getArrPax[i].child.lastName[k] = "";
        setArrPax([...getArrPax]);
    }
   // End For Add travellers functions
    //Booking form function
    const buttonState = {button: 1};
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (buttonState.button === 1) {
            // console.log("Button 1 clicked!");
            const form = e.currentTarget;
            if (form.checkValidity() === false) {
                e.stopPropagation();
                // console.log("Form submit book now invalid!");
            }
            setValidated(true);
            if (form.checkValidity() === true) {
                // console.log("Form submit book now valid!")
                setPaymentMethodModal(true)
                // history.push({
                //     pathname:'/preview-booking/',
                //     search:(`sessionId=${searchParams.get("sessionId")}&&tokenId=${searchParams.get("tokenId")}&&hotelId=${searchParams.get("hotelId")}&&productId=${searchParams.get("productId")}&&rateBasisId=${searchParams.get("rateBasisId")}&&checkInDate=${searchParams.get("checkInDate")}&&checkOutDate=${searchParams.get("checkOutDate")}&&paxDetails=${JSON.stringify(getArrPax)}&&occupancy=${searchParams.get("occupancy")}`),
                // });
                // console.log("userData",userData)
                // console.log("getArrPax",getArrPax)
            }
        }
        if (buttonState.button === 2) {
            // console.log("Button 2 clicked!");
            const form = e.currentTarget;
            if (form.checkValidity() === false) {
                e.stopPropagation();
                // console.log("Form submit save invalid!")
            }
            setValidated(true);
            if (form.checkValidity() === true) {
                getHotelRoomRateRules();
                if(hotelCheckoutData?.response_data?.status?.error === "Invalid sessionId or session expired." ||
                hotelCheckoutData?.response_data?.status?.error === "session expired or invalid sessionId"){
                    setShowItineraryList(false);
                }else if(hotelCheckoutData?.response_data?.status?.error === "Selected Room Not Available."){
                    setShowItineraryList(false);
                }else{
                    setShowItineraryList(true);
                }
                // console.log("Form Booking save submit valid!");
            }
        }
    };
   
    const handleItineraryListClose = () => setShowItineraryList(false);

    const handleCheckPayment = async (e) =>{
        setIsDataLoading(true);
        const hotelCheckObj = {
            sessionId: searchParams.get("sessionId"),
            tokenId: searchParams.get("tokenId"),
            productId: searchParams.get("productId"),
            rateBasisId: searchParams.get("rateBasisId"),
            userId: authed?.user?._id,
            clientRef: "TDB85454",
            customerEmail: userData.email,
            customerPhone: userData.phone,
            bookingNote: userData.bookingNote,
            paxDetails: getArrPax,
            hotelDetails: getHotelDetailsData,
            checkIn: searchParams.get("checkInDate"),
            checkOut: searchParams.get("checkOutDate"),
            city_name: searchParams.get("cityName"),
            country_name: searchParams.get("countryName"),
            payment_type: e.target.value
        }
        await UserBookHotelPayment(hotelCheckObj, async (res) => {
            // console.log("UserBookHotelPayment====", res);
            if (res.status === 404) {
                console.log('Something went wrong')
            }
            if (!res.data.data.success) {
                toast.warning(res.data.data.message)
            }
            if (res.status === 200) {
                setPaymentMethodModal(false);
                setIsDataLoading(false);
                let url = res.data.data.url
                if (url) window.open(url);
            }
        });
    }
    const handlePaymentMethodClose = () => {
        setPaymentMethodModal(false);
        setIsDataLoading(false);
    }
    
    useEffect(() => {
        bookingArrDetails();
        getTravellerListData();
        getHotelRoomRateRules();
        getHotelDetails();
        userSearchDataLocal();
    }, []);

    return (
        <>
        <section className="inner-mrt-80">
            <div className="container-lg text-center">
                {/* <h1>Hotel Checkout</h1> */}
            </div>
        </section>
        {isLoading ? <LoadingSpinnerOne /> : null}
        <section className="hotel-checkout-wrapper padd-top-70 padd-bottom-70">
            <div className="container-lg">
                <h1 className="d-col-title mb-3">Review Your Booking</h1>
                <div className="row">
                    <div className="col-lg-8 order-2 order-lg-1 mt-3 mt-lg-0">
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <label className="chkt-title">Contact Details</label>
                            <div className="row">
                                <div className="col-md-6 cmn-input-field mt-2">
                                    <Form.Group>
                                        <Form.Control type="text" value={userData ? userData.email : ""} onChange={(e) => {setUserData({...userData, email: e.target.value})}} name="email" placeholder="Email"/>
                                        {/* <Form.Control.Feedback type="invalid">
                                            Please enter email.
                                        </Form.Control.Feedback> */}
                                    </Form.Group>
                                </div>
                                <div className="col-md-6 cmn-input-field mt-2">
                                    <Form.Group>
                                        <PhoneInput
                                            country={'us'}
                                            value={userData ? userData.phone : ""}
                                            onChange={(value) => {setUserData({...userData, phone: value})}}
                                            placeholder="Enter Phone Number"
                                            containerClass="custom-ep-input"
                                            />
                                    </Form.Group>
                                </div>
                            </div>
                        
                            {occupancyData?.map((occupancyItem, i) => {
                                return(
                                    <>
                                    <div key={i} className="room-deatils-fields mt-3">
                                        <div className="room-deatils-header">
                                            <label className="chkt-title">Room {i+1}</label>
                                            {/* <button onClick={handleTravellerShow} type="button" className="btn btn-primary-color border-radius-10">Add travellers</button> */}
                                        </div>
                                        {[...Array(Number(occupancyItem?.adult))].map((arrayadult, j) => {
                                            return ( 
                                                <div className="pax-form-row mt-2" key={j}>
                                                    <div className="pax-form-2 padding-lf-5 cmn-input-field mt-2">
                                                        <Form.Group controlId={`validationtitle${i}${j}`} >
                                                            <Form.Control as="select" name={`title${i}${j}`} value={getArrPax[i]?.adult?.title[j]} onChange={(e) => handleTitleChange(e, i, j)} required>
                                                                <option value="">Title</option>
                                                                <option value="Mr">Mr</option>
                                                                <option value="Mrs">Mrs</option>
                                                                <option value="Ms">Ms</option>
                                                            </Form.Control>
                                                            {/* <Form.Control.Feedback type="invalid">
                                                                Please select title.
                                                            </Form.Control.Feedback> */}
                                                        </Form.Group>
                                                    </div>
                                                    <div className="pax-form-4 padding-lf-5 cmn-input-field mt-2">
                                                        <Form.Group controlId={`validationfirstname${i}${j}`}>
                                                            <Form.Control name={`firstname${i}${j}`} value={getArrPax[i]?.adult?.firstName[j]} onChange={(e) => handleFirstNameChange(e, i, j)} type="text" placeholder="First Name" required/>
                                                            {/* <Form.Control.Feedback type="invalid">
                                                                Please enter first name.
                                                            </Form.Control.Feedback> */}
                                                        </Form.Group>
                                                    </div>
                                                    <div className="pax-form-4 padding-lf-5 cmn-input-field mt-2">
                                                        <Form.Group controlId={`validationlastname${i}${j}`}>
                                                            <Form.Control name={`lastname${i}${j}`} value={getArrPax[i]?.adult?.lastName[j]} onChange={(e) => handleLastNameChange(e, i, j)} type="text" placeholder="Last Name" required/>
                                                            {/* <Form.Control.Feedback type="invalid">
                                                                Please enter last name.
                                                            </Form.Control.Feedback> */}
                                                        </Form.Group>
                                                    </div>
                                                    <div className="pax-form-btns padding-lf-5 mt-2">
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip>
                                                                Add from saved contacts
                                                                </Tooltip>
                                                            }
                                                            >
                                                            <button type="button" className="btn btn-primary-color" onClick={(e)=>{handleAddAdultTraveller(e, i, j)}}><i className="bi bi-plus-lg"></i></button>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip>
                                                                Delete (clear field)
                                                                </Tooltip>
                                                            }
                                                            >
                                                            <button type="button" className="btn bg-danger" onClick={(e)=>{handleDeleteAdultTraveller(e, i, j)}}><i className="bi bi-dash-lg"></i></button>
                                                        </OverlayTrigger>
                                                    </div>
                                                </div>
                                                ) 
                                            }
                                        )}
                                        {[...Array(Number(occupancyItem?.child))].map((arraychild, k) => {
                                            return ( 
                                                <div className="pax-form-row mt-2" key={k}>
                                                    <div className="pax-form-2 padding-lf-5 cmn-input-field mt-2">
                                                        <Form.Group controlId={`validationchildtitle${i}${k}`} >
                                                            <Form.Control as="select" name={`childtitle${i}${k}`} value={getArrPax[i]?.child?.title[k]} onChange={(e) => handleChildTitleChange(e, i, k)} required>
                                                                <option value="">Title</option>
                                                                <option value="Master">Master</option>
                                                                <option value="Ms">Ms</option>
                                                            </Form.Control>
                                                            {/* <Form.Control.Feedback type="invalid">
                                                                Please select title.
                                                            </Form.Control.Feedback> */}
                                                        </Form.Group>
                                                    </div>
                                                    <div className="pax-form-4 padding-lf-5 cmn-input-field mt-2">
                                                        <Form.Group controlId={`validationchildfirstname${i}${k}`}>
                                                            <Form.Control name={`childfirstname${i}${k}`} value={getArrPax[i]?.child?.firstName[k]} onChange={(e) => handleChildFirstNameChange(e, i, k)} type="text" placeholder="First Name" required/>
                                                            {/* <Form.Control.Feedback type="invalid">
                                                                Please enter first name.
                                                            </Form.Control.Feedback> */}
                                                        </Form.Group>
                                                    </div>
                                                    <div className="pax-form-4 padding-lf-5 cmn-input-field mt-2">
                                                        <Form.Group controlId={`validationchildlastname${i}${k}`}>
                                                            <Form.Control name={`childlastname${i}${k}`} value={getArrPax[i]?.child?.lastName[k]} onChange={(e) => handleChildLastNameChange(e, i, k)} type="text" placeholder="Last Name" required/>
                                                            {/* <Form.Control.Feedback type="invalid">
                                                                Please enter last name.
                                                            </Form.Control.Feedback> */}
                                                        </Form.Group>
                                                    </div>
                                                    <div className="pax-form-btns padding-lf-5 mt-2">
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip>
                                                                Add from saved contacts
                                                                </Tooltip>
                                                            }
                                                            >
                                                            <button type="button" className="btn btn-primary-color" onClick={(e)=>{handleAddChildTraveller(e, i, k)}}><i className="bi bi-plus-lg"></i></button>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip>
                                                                Delete (clear field)
                                                                </Tooltip>
                                                            }
                                                            >
                                                            <button type="button" className="btn bg-danger" onClick={(e)=>{handleDeleteChildTraveller(e, i, k)}}><i className="bi bi-dash-lg"></i></button>
                                                        </OverlayTrigger>
                                                    </div>
                                                </div>
                                                ) 
                                            }
                                        )}
                                    </div>
                                    </>
                                )}  
                            )}
                        
                            <label className="chkt-title mt-3">Special Request</label>
                            <div className="cmn-input-field mt-2">
                                <Form.Group controlId="bookingNote">
                                    <Form.Control as="textarea" rows={3} value={userData.bookingNote} name="bookingNote" onChange={(e) => {setUserData({...userData, [e.target.name]: e.target.value})}} placeholder="Note" />
                                    {/* <Form.Control.Feedback type="invalid">
                                        Please enter notes.
                                    </Form.Control.Feedback> */}
                                </Form.Group>
                            </div>
                            <label className="chkt-title mt-3">Time</label>
                            <div className="cmn-input-field mt-2">
                                <Form.Group controlId={`validationTime`} >
                                    <TimePicker format={24} onChange={(time) => {
                                        setUserData({...userData, bookingTime: timeFromInt(time)});
                                        timeHdn.time = timeFromInt(time);
                                    }} value={userData.bookingTime} step={15} required/>
                                    <Form.Control type="text" name="time" onChange={(e) => setTimeHdn({...timeHdn, time: e.target.value})} value={timeHdn.time} hidden/>
                                    {/* <Form.Control.Feedback type="invalid">
                                        Please select time.
                                    </Form.Control.Feedback> */}
                                </Form.Group>
                            </div>
                            <div className="mt-2">
                                <Form.Group className="mb-2">
                                    <Form.Check
                                    required
                                    label="I confirm that I have read, understood and agree with the booking engine terms & conditions provided."
                                    feedback="You must agree before submitting."
                                    feedbackType="invalid"
                                    name="termsAndConditions"
                                    onChange={(e) => {setUserData({...userData, [e.target.name]: e.target.checked})}}
                                    />
                                </Form.Group>
                            </div>
                            <div className="d-flex justify-content-end mt-3">
                                <button onClick={() => (buttonState.button = 1)} type="submit" className="btn btn-primary-color btn-chkout-book-now">Book Now</button>
                                <button onClick={() => (buttonState.button = 2)} type="submit" className="btn btn-primary-color btn-chkout-book-now ms-3">Add to Itinerary</button>
                            </div>
                        </Form>
                    </div>
                    <div className="col-lg-4 order-1 order-lg-2">
                        <div className="chk-hotel-right-pane">
                            <div className="hotel-round">
                                <div className="fare-box-title">
                                    <h4>{getHotelDetailsData?.response_data?.name}</h4>
                                </div>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="room-check-in">
                                        <span>Check-in</span>
                                        <h5 className="date-build">{moment(searchParams.get("checkInDate")).format("DD-MM-YYYY")}</h5>
                                        <span className="time-build">From 12:00</span>
                                    </div>
                                    <div className="room-check-out text-right">
                                        <span>Check-out</span>
                                        <h5 className="date-build">{moment(searchParams.get("checkOutDate")).format("DD-MM-YYYY")}</h5>
                                        <span className="time-build">Until 12:00</span>
                                    </div>
                                </div>
                            </div>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Your Booking Details</Accordion.Header>
                                    <Accordion.Body>
                                        <div className="row">
                                            <div className="col-12 chk-base-text-item d-flex align-items-center justify-content-between">
                                                <p className="base-hotel-text text-strong">Total length of stay:</p>
                                                <p className="sub-text text-manual">{getNightsCount? getNightsCount: ""} Night</p>
                                            </div>
                                            <div className="col-12 chk-base-text-item d-flex align-items-center justify-content-between">
                                                <p className="base-hotel-text text-strong">You selected:</p>
                                                <p className="sub-text text-manual">{hotelCheckoutData?.response_data?.roomRates?.perBookingRates[0]?.roomType}</p>
                                            </div>
                                            <div className="col-12 chk-base-text-item d-flex align-items-center justify-content-between">
                                                <p className="base-hotel-text text-strong">Guests:</p>
                                                <p className="sub-text text-manual">{occupancyData?.reduce((accumulator, current) => accumulator + current.adult, 0)} Adults, {occupancyData?.reduce((accumulator, current) => accumulator + current.child, 0)} Childs</p>
                                            </div>
                                            <div className="col-12 chk-base-text-item d-flex align-items-center justify-content-between">
                                                <p className="base-hotel-text text-strong">Base Fare: (for all guests)</p>
                                                <p className="sub-text text-manual">{hotelCheckoutData?.response_data?.roomRates?.perBookingRates[0]?.currency} {hotelCheckoutData?.response_data?.roomRates?.perBookingRates[0]?.netPrice}</p>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Your Price Summary</Accordion.Header>
                                    <Accordion.Body>
                                        <div className="row">
                                            <div className="col-12 chk-base-text-item d-flex align-items-center justify-content-between">
                                                <p className="base-hotel-text text-strong">Hotel Charges</p>
                                                <p className="sub-text text-manual">{hotelCheckoutData?.response_data?.roomRates?.perBookingRates[0]?.currency} {hotelCheckoutData?.response_data?.roomRates?.perBookingRates[0]?.netPrice}</p>
                                            </div>
                                            <div className="col-12 chk-base-text-item d-flex align-items-center justify-content-between">
                                                <p className="base-hotel-text text-strong">Services Amount</p>
                                                <p className="sub-text text-manual">INR 00</p>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                <Accordion.Header>Cancellation Policy</Accordion.Header>
                                <Accordion.Body>
                                    <div className="row">
                                        <div className="col-12 chk-base-text-item">
                                            <p className="text-manual">{hotelCheckoutData?.response_data?.roomRates?.perBookingRates[0]?.cancellationPolicy}</p>
                                        </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            </Accordion>
                            <div className="chk-sub-total d-flex align-items-center justify-content-between">
                                <p className="sub-total"> Sub Total:</p>
                                <p className="sub-total"> {hotelCheckoutData?.response_data?.roomRates?.perBookingRates[0]?.currency} <span className="total_fare">{hotelCheckoutData?.response_data?.roomRates?.perBookingRates[0]?.netPrice}</span></p>
                            </div>
                        </div>
                        <div className="chk-hotel-right-pane chk-hotel-list mt-3">
                            <div className="chk-hotel-item">
                                <div className="chk-hotel-img-block">
                                    {getHotelDetailsData?.response_data?.hotelImages ? (
                                        <img src={getHotelDetailsData?.response_data?.hotelImages[0]?.url} alt="" />
                                        ) : (
                                        <img src={noPictureAvailable} alt="" />
                                    )}
                                </div>
                                <div className="chk-hotel-info-block">
                                    <h5>{getHotelDetailsData?.response_data?.name}</h5>
                                    <div className="staring-box"><span><i className="bi bi-star-fill" aria-hidden="true"></i></span> {getHotelDetailsData?.response_data?.hotelRating}</div>
                                    <p>{getHotelDetailsData?.response_data?.address}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <Modal
            show={showAdultTraveller}
            onHide={handleAdultTravellerClose}
            keyboard={false}
            size="md"
            centered
            className="EnquirecustomModal"
        >
            <Modal.Header closeButton>
                <Modal.Title>Travellers list</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="mt-table-wrap tl-table-wrap table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Gender</th>
                            <th scope="col" style={{textAlign: "left"}}>DOB</th>
                            <th scope="col" className="text-center">Action</th>
                            {/* <th scope="col">Passport No.</th>
                            <th scope="col" className="tl-text-left">Social Id No.</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {getTravellerList?.length > 0 ? getTravellerList.map((itemadulttraveller, i) => {
                            return (
                                <tr key={itemadulttraveller._id}>
                                    <td>{i+1}</td>
                                    <td>{itemadulttraveller.title} {itemadulttraveller.first_name} {itemadulttraveller.last_name}</td>
                                    <td>{itemadulttraveller.gender}</td>
                                    <td>{moment(itemadulttraveller.dob).format("DD-MM-YYYY")}</td>
                                    <td className="text-center at-action-btns"><button type="button" className="btn btn-primary-color" onClick={(e)=>{changeSelectAdultItem(e, i, itemadulttraveller)}}>Select</button></td>
                                    {/* <td>{itemtraveller.passport_no}</td>
                                    <td>{itemtraveller.socialId_no}</td> */}
                                </tr>
                            )
                        }) : <tr><td className="text-center" colSpan="6">No results found.</td></tr>}
                    </tbody>
                </table>
            </div>
            </Modal.Body>
        </Modal>

        <Modal
            show={showChildTraveller}
            onHide={handleChildTravellerClose}
            keyboard={false}
            size="md"
            centered
            className="EnquirecustomModal"
        >
            <Modal.Header closeButton>
                <Modal.Title>Travellers list</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="mt-table-wrap tl-table-wrap table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Gender</th>
                            <th scope="col" style={{textAlign: "left"}}>DOB</th>
                            <th scope="col" className="text-center">Action</th>
                            {/* <th scope="col">Passport No.</th>
                            <th scope="col" className="tl-text-left">Social Id No.</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {getTravellerList?.length > 0 ? getTravellerList.map((itemchildtraveller, x) => {
                            return (
                                <tr key={itemchildtraveller._id}>
                                    <td>{x+1}</td>
                                    <td>{itemchildtraveller.title} {itemchildtraveller.first_name} {itemchildtraveller.last_name}</td>
                                    <td>{itemchildtraveller.gender}</td>
                                    <td>{moment(itemchildtraveller.dob).format("DD-MM-YYYY")}</td>
                                    <td className="text-center at-action-btns"><button type="button" className="btn btn-primary-color" onClick={(e)=>{changeSelectChildItem(e, x, itemchildtraveller)}}>Select</button></td>
                                    {/* <td>{itemtraveller.passport_no}</td>
                                    <td>{itemtraveller.socialId_no}</td> */}
                                </tr>
                            )
                        }) : <tr><td className="text-center" colSpan="6">No results found.</td></tr>}
                    </tbody>
                </table>
            </div>
            </Modal.Body>
        </Modal>
        <AddItineraryModal open={showItineraryList} onClose={handleItineraryListClose} paxDetails={getArrPax} roomData={hotelCheckoutData?.response_data?.roomRates?.perBookingRates[0]} hotelData={getHotelDetailsData} />
        <SessionExpiredModal open={showExpiredModal} onClose={handleExpiredModalClose}/>
        <NotAvailableModal open={showNotAvailableModal} onClose={handleNotAvailableModalClose}/>
        
        <Modal
            show={showPaymentMethodModal}
            onHide={handlePaymentMethodClose}
            keyboard={false}
            size="sm"
            centered
            className="EnquirecustomModal"
        >
            <Modal.Header closeButton>
                <Modal.Title style={{fontSize:"15px"}}>Select payment method</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isDataLoading ?
                    <div className="stay-added-loading">
                    <Spinner animation="border" variant="primary" />
                    </div>
                 : null}
                <div className="d-col-radio-field">
                    <div className="form-check me-4 d-flex align-items-center">
                        <input onChange={handleCheckPayment} value="paypal" className="form-check-input" name="checkpayment" type="radio" id="Paypal"/>
                        <label className="form-check-label" htmlFor="Paypal">
                            <img width="100" src={PaypalLogo}/>
                        </label>
                    </div>
                    <div className="form-check me-0 d-flex align-items-center">
                        <input onChange={handleCheckPayment} value="stripe" className="form-check-input" name="checkpayment" type="radio" id="Stripe"/>
                        <label className="form-check-label" htmlFor="Stripe">
                            <img width="40" src={StripeLogo}/>
                        </label>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
        </>
    );
}

export default StayCheckout;
