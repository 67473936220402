import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import hourGlassIcon from '../../../../assets/images/hourglass.png';

const NotAvailableModal = (props) => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {

  }, [props.open]);

  return (
    <>
      <Modal
        show={props.open}
        onHide={props.onClose}
        keyboard={false}
        size="md"
        backdrop="static"
        centered
        className="session-expired-content"
      >
        <Modal.Body>
          <img src={hourGlassIcon}/>
          <div>
            <h3 className="mb-1">We are Sorry.</h3>
            <p className="mb-2">Selected Room Not Available.</p>
            <p>Please back to details and select availability room.</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Link to={"/stay-details/?"+`sessionId=${searchParams.get("sessionId")}&&hotelId=${searchParams.get("hotelId")}&&productId=${searchParams.get("productId")}&&rateBasisId=${searchParams.get("rateBasisId")}&&tokenId=${searchParams.get("tokenId")}&&cityName=${searchParams.get("cityName")}&&countryName=${searchParams.get("countryName")}&&checkInDate=${searchParams.get("checkInDate")}&&checkOutDate=${searchParams.get("checkOutDate")}&&occupancy=${searchParams.get("occupancy")}`} className="btn btn-primary-color btn-border-color">Back</Link>
          <Link to="/" className="btn btn-primary-color btn-border-color">Home</Link>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NotAvailableModal;
