import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  createItinerariesApi,
  editItinerariesApi,
  getItinerariesApi,
  getFoodRestaurentAllApi,
  addFoodRestaurentApi,
  addActivityApi,
  addOthersApi,
  deleteItineraryDaysDataApi,
  deleteDestinationCityApi,
  deleteDestinationCityDaysApi
} from "../../../services/AdminService";
import {
  googleApiKey,
  googlePhotoUrl,
  googlePlaceUrl,
  imageUrlOthers
} from "../../../services/CommonUrl";
// import NumericInput from 'react-numeric-input';
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import Modal from "react-bootstrap/Modal";
import noPictureAvailable from "../../../assets/images/no-picture-available.jpg";
import soloIcon from "../../../assets/images/solo-icon.png";
import coupleIcon from "../../../assets/images/couple-icon.png";
import familyIcon from "../../../assets/images/family-icon.png";
import groupIcon from "../../../assets/images/group-icon.png";
import stayIcon60 from "../../../assets/images/stay-icon-60.png";
import foodIcon60 from "../../../assets/images/food-icon-60.png";
import activityIcon60 from "../../../assets/images/activity-icon-60.png";
import othersIcon60 from "../../../assets/images/others-icon-60.png";
import { LoadingSpinnerTwo } from "../../../loader/Loader";
import Autocomplete from "react-google-autocomplete";
import TimePicker from "react-bootstrap-time-picker";
import { timeFromInt } from "time-number";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import Swal from "sweetalert2";
import { toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../../../contexts/AuthProvider";
toast.configure();
const options = {
  position: "top-center",
  hideProgressBar: true,
  transition: Slide,
  theme: "colored",
  closeButton: false,
  autoClose: 2000,
  showDuration: 300,
  hideDuration: 1000,
};
const CreateItinerary = () => {
  const placeUrl = `${googlePlaceUrl}`;
  const photoUrl = `${googlePhotoUrl}`;
  const apikey = `&key=${googleApiKey}`;
  const history = useHistory();
  const location = useLocation();
  const searchPrams = new URLSearchParams(location.search);
  const [userItineraryData, setUserItineraryData] = useState({
    userId: "",
    itineraryId: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showFoodModal, setShowFoodModal] = useState(false);
  const [showActivityModal, setShowActivityModal] = useState(false);
  const [getFoodData, setFoodData] = useState([]);
  const [getActivityData, setActivityData] = useState([]);
  const { authed, currencyData } = useAuth();
  const [validated, setValidated] = useState(false);
  const [checkDate, setcheckDate] = useState(null);
  const [childNoCount, setChildNoCount] = useState(0);
  const [address, setAddress] = useState("");
  const [stayCheckinDate, setStayCheckinDate] = useState(null);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [color, setColor] = useState("");
  const [flag, setFlag] = useState(false);
  const [showOtherModal, setShowOtherModal] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  const numArray = [
    {
      day: 1,
      date: checkDate ? moment(checkDate).format("DD MMMM yyyy") : null,
    },
  ];
  const multiDesArr = [
    {
      itineraryDatys: numArray,
      tour_start_date: "",
      tour_no_of_days: 1,
      destination_city: {
        iso_code: "",
        country: "",
        city: "",
        destination_address: "",
      },
      from_city: { iso_code: "", country: "", city: "", from_address: "" },
    },
  ];
  const initialGetItData = Object.freeze({
    type_of_itinerary: "",
    tour_name: "",
    type_of_tour: "",
    destinationCityDetails: multiDesArr,
    travellers_type: "",
    no_adult_travellers: 1,
    no_infant_travellers: 0,
    no_children_travellers: {
      no_children: 0,
      children_age: [],
    },
  });
  const [itinariesDataGet, setItinariesDataGet] = useState(initialGetItData);

  const handleChange = (e) => {
    setItinariesDataGet({
      ...itinariesDataGet,
      [e.target.name]: e.target.value,
    });
    setColor(true);
  };
  // const handleSelectGoingTo = async (value, y) =>{
  //     const results = await geocodeByAddress(value);
  //     // const ll = await getLatLng(results[0]);
  //     // console.log("results", results)
  //     console.log("results", value, y)
  //     let city, state, country;
  //     for (let i = 0; i < results[0].address_components.length; i++) {
  //       for (let j = 0; j < results[0].address_components[i].types.length; j++) {
  //         switch (results[0].address_components[i].types[j]) {
  //           case "locality":
  //             city = results[0].address_components[i].long_name;
  //             break;
  //           case "country":
  //             country = results[0].address_components[i].long_name;
  //             break;
  //         }
  //       }
  //     }
  //     // console.log("ll", ll)
  //     setAddress(`${city +", "+ country}`);
  //     // setCordinates(ll);
  //     // setStaySearchData({...staySearchData, city_name: city, country_name: country});
  //     itinariesDataGet.destinationCityDetails[y].destination_city.country = country;
  //     itinariesDataGet.destinationCityDetails[y].destination_city.city = city;
  //     console.log('itinariesDataGet', itinariesDataGet)
  // }
  const handleSelectGoingFrom = async (place, y) => {
    setColor(true);
    setFlag(true);
    const results = place;
    //console.log("results", place, y)
    let city, country, isoCode;
    for (let i = 0; i < results.address_components.length; i++) {
      for (let j = 0; j < results.address_components[i].types.length; j++) {
        switch (results.address_components[i].types[j]) {
          case "locality":
            city = results.address_components[i].long_name;
            break;
          case "country":
            country = results.address_components[i].long_name;
            isoCode = results.address_components[i].short_name;
            break;
        }
      }
    }
    // console.log("city + country from results", city, country, isoCode)
    itinariesDataGet.destinationCityDetails[y].from_city.country = country;
    itinariesDataGet.destinationCityDetails[y].from_city.city = city;
    itinariesDataGet.destinationCityDetails[y].from_city.iso_code = isoCode;
    itinariesDataGet.destinationCityDetails[y].from_city.from_address = `${city + ", " + country
      }`;
    setFlag(false);
    //setItinariesDataGet({...itinariesDataGet});
    //console.log(itinariesDataGet)
  };
  const handleSelectGoingTo = async (place, y) => {
    setColor(true);
    setFlag(true);
    const results = place;
    //console.log("results", place, y)
    let city, country, isoCode;
    for (let i = 0; i < results.address_components.length; i++) {
      for (let j = 0; j < results.address_components[i].types.length; j++) {
        switch (results.address_components[i].types[j]) {
          case "locality":
            city = results.address_components[i].long_name;
            break;
          case "country":
            country = results.address_components[i].long_name;
            isoCode = results.address_components[i].short_name;
            break;
        }
      }
    }
    // console.log("city + country to results", city, country, isoCode)
    if (itinariesDataGet.destinationCityDetails[y].from_city.from_address === `${city + ", " + country}`) {
      toast.warning("Can't select the same city", options);
      itinariesDataGet.destinationCityDetails[y].destination_city.country = "";
      itinariesDataGet.destinationCityDetails[y].destination_city.city = "";
      itinariesDataGet.destinationCityDetails[y].destination_city.iso_code = "";
    } else {
      itinariesDataGet.destinationCityDetails[y].destination_city.country =
        country;
      itinariesDataGet.destinationCityDetails[y].destination_city.city = city;
      itinariesDataGet.destinationCityDetails[y].destination_city.iso_code =
        isoCode;
      itinariesDataGet.destinationCityDetails[
        y
      ].destination_city.destination_address = `${city + ", " + country}`;
    }
    if (y != itinariesDataGet.destinationCityDetails.length - 1) {
      itinariesDataGet.destinationCityDetails[y + 1].from_city.country =
        country;
      itinariesDataGet.destinationCityDetails[y + 1].from_city.city = city;
      itinariesDataGet.destinationCityDetails[y + 1].from_city.iso_code =
        isoCode;
      itinariesDataGet.destinationCityDetails[
        y + 1
      ].from_city.from_address = `${city + ", " + country}`;
    }
    setFlag(false);

    //setItinariesDataGet({...itinariesDataGet});
  };

  const handleChangeGoingFrom = (e, y) => {
    if (e.target.value.length === 0) {
      setColor(true);
    }
    itinariesDataGet.destinationCityDetails[y].from_city.from_address =
      e.target.value;
    setItinariesDataGet({ ...itinariesDataGet });
  };
  const handleChangeGoingTo = (e, y) => {
    if (e.target.value.length === 0) {
      setColor(true);
    }
    itinariesDataGet.destinationCityDetails[
      y
    ].destination_city.destination_address = e.target.value;
    setItinariesDataGet({ ...itinariesDataGet });
  };

  const onValueChange = (event) => {
    itinariesDataGet.destinationCityDetails.forEach((d) => {
      d.from_city.from_address = "";
      d.destination_city.destination_address = "";
      d.tour_start_date = null;
    });

    setItinariesDataGet({
      ...itinariesDataGet,
      type_of_tour: event.target.value,
    });
    setColor(true);
  };

  const totalTraveller = Number(
    itinariesDataGet.no_adult_travellers +
    itinariesDataGet.no_children_travellers.no_children +
    itinariesDataGet.no_infant_travellers
  );

  const adultsdecrementCount = () => {
    if (itinariesDataGet.no_adult_travellers > 1) {
      itinariesDataGet.no_adult_travellers =
        itinariesDataGet.no_adult_travellers - 1;
      travellersTypeCount();
    }
    setColor(true);
  };
  const adultsincrementCount = () => {
    if (totalTraveller < 9) {
      itinariesDataGet.no_adult_travellers =
        itinariesDataGet.no_adult_travellers + 1;
      travellersTypeCount();
    }
    setColor(true);
  };

  const childdecrementCount = () => {
    if (itinariesDataGet.no_children_travellers.no_children > 0) {
      setChildNoCount(itinariesDataGet.no_children_travellers.no_children - 1);
      itinariesDataGet.no_children_travellers.no_children =
        itinariesDataGet.no_children_travellers.no_children - 1;
      itinariesDataGet.no_children_travellers.children_age.pop("");
      travellersTypeCount();
    }
    setColor(true);
  };
  const childincrementCount = () => {
    if (totalTraveller < 9) {
      setChildNoCount(itinariesDataGet.no_children_travellers.no_children + 1);
      itinariesDataGet.no_children_travellers.no_children =
        itinariesDataGet.no_children_travellers.no_children + 1;
      itinariesDataGet.no_children_travellers.children_age.push("");
      travellersTypeCount();
    }
    setColor(true);
  };

  const infantsdecrementCount = () => {
    if (itinariesDataGet.no_infant_travellers > 0) {
      itinariesDataGet.no_infant_travellers =
        itinariesDataGet.no_infant_travellers - 1;
      travellersTypeCount();
    }
    setColor(true);
  };
  const infantsincrementCount = () => {
    if (totalTraveller < 9) {
      itinariesDataGet.no_infant_travellers =
        itinariesDataGet.no_infant_travellers + 1;
      travellersTypeCount();
    }
    setColor(true);
  };

  const onChangeChildAge = (e, i) => {
    setColor(true);
    itinariesDataGet.no_children_travellers.children_age[i] = e.target.value;
    setItinariesDataGet({ ...itinariesDataGet });
  };

  const travellersTypeCount = () => {
    let totalTravellerCnt = Number(
      itinariesDataGet.no_adult_travellers +
      itinariesDataGet.no_children_travellers.no_children +
      itinariesDataGet.no_infant_travellers
    );
    if (totalTravellerCnt === 1) {
      setItinariesDataGet({ ...itinariesDataGet, travellers_type: "Solo" });
    }
    if (totalTravellerCnt === 2) {
      setItinariesDataGet({ ...itinariesDataGet, travellers_type: "Couple" });
    }
    if (totalTravellerCnt > 2) {
      setItinariesDataGet({ ...itinariesDataGet, travellers_type: "Family" });
    }
    if (totalTravellerCnt > 4) {
      setItinariesDataGet({ ...itinariesDataGet, travellers_type: "Group" });
    }
  };

  const handleDateChange = (date, d) => {
    setColor(true);
    setcheckDate(date);
    // console.log(d);
    itinariesDataGet.destinationCityDetails[d].tour_start_date =
      moment(date).format("DD MMMM yyyy");
    itinariesDataGet.destinationCityDetails[d].itineraryDatys[0].date =
      moment(date).format("DD MMMM yyyy");
    for (
      let l = 0;
      l < itinariesDataGet.destinationCityDetails[d].itineraryDatys.length;
      l++
    ) {
      itinariesDataGet.destinationCityDetails[d].itineraryDatys[l].date =
        moment(itinariesDataGet.destinationCityDetails[d].tour_start_date).add(
          l,
          "days"
        )
    }
    // if(d != itinariesDataGet.destinationCityDetails.length -1 ){
    //     for(let i = d+1; i < itinariesDataGet.destinationCityDetails.length; i++){
    //         itinariesDataGet.destinationCityDetails[i].tour_start_date = moment(itinariesDataGet.destinationCityDetails[i-1].tour_start_date).add(1, "days");
    //     }
    // }
    setItinariesDataGet({ ...itinariesDataGet });
    // console.log("inside", itinariesDataGet.destinationCityDetails)
  };

  const handleRemove = (e, daysitem, destinationitem, i, idx) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      text: "This day will be deleted permanently",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (itinariesDataGet.destinationCityDetails[i].tour_no_of_days > 0) {
          setColor(true);
          let itiArrRmv =
            itinariesDataGet.destinationCityDetails[i].itineraryDatys;
          let index = itiArrRmv.indexOf(idx);
          itiArrRmv.splice(index, 1);
          itinariesDataGet.destinationCityDetails[i].tour_no_of_days = Number(
            itinariesDataGet.destinationCityDetails[i].itineraryDatys.length
          );
          setItinariesDataGet({ ...itinariesDataGet });
          if (daysitem._id) {
            let obj = {
              destinationCityId: daysitem.destinationCityId,
              itineraryId: daysitem.itineraryId,
              daysId: daysitem._id,
            };
            await deleteDestinationCityDaysApi(obj, async (res) => {
              // console.log("res deleteDestinationCityDaysApi", res);
              if (res.status === 404) {
                console.log("Something went wrong");
              }
              if (res.status === 200) {
                // console.log("Day item Deleted successfully.");
                getDraftData();
              }
            });
          }
        }
      }
    });
  };

  const handleAddItem = async (e, destinationitem, i) => {
    e.preventDefault();
    itinariesDataGet.destinationCityDetails[i].itineraryDatys.push({
      day:
        Number(
          itinariesDataGet.destinationCityDetails[i].itineraryDatys[
            itinariesDataGet.destinationCityDetails[i].itineraryDatys.length - 1
          ].day
        ) + 1,
      // day: Number(itinariesDataGet.destinationCityDetails[i].itineraryDatys.length)+1,
      date: moment(
        itinariesDataGet.destinationCityDetails[i].itineraryDatys[
          itinariesDataGet.destinationCityDetails[i].itineraryDatys.length - 1
        ].date
      )
        .add(1, "days")
        .format("DD MMMM YYYY"),
      // date: moment(itinariesDataGet.destinationCityDetails[i].tour_start_date).add(Number(itinariesDataGet.destinationCityDetails[i].itineraryDatys.length), 'days').format('DD MMMM YYYY')
    });
    itinariesDataGet.destinationCityDetails[i].tour_no_of_days = Number(
      itinariesDataGet.destinationCityDetails[i].itineraryDatys.length
    );
    setItinariesDataGet({ ...itinariesDataGet });
    setColor(true);
  };

  const state = { button: 1 };
  const handleCreateItinaries = async (e) => {
    e.preventDefault();
    if (state.button === 1) {
      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.stopPropagation();
        // console.log("Button 1 clicked! invalid");
      }
      setValidated(true);
      if (form.checkValidity() === true) {
        // console.log("Button 1 clicked! valid");
        // console.log(itinariesDataGet)
        // return
        if (itinariesDataGet.type_of_tour === "One Way") {
          setIsDataLoading(true);
          let Obj = {
            userId: authed?.user?._id,
            travellers_type: itinariesDataGet.travellers_type,
            tour_name: itinariesDataGet.tour_name,
            type_of_tour: itinariesDataGet.type_of_tour,
            currency: currencyData?.currency,
            destinationCityDetails: itinariesDataGet.destinationCityDetails,
            no_adult_travellers: itinariesDataGet.no_adult_travellers,
            no_infant_travellers: itinariesDataGet.no_infant_travellers,
            no_children_travellers: {
              no_children: itinariesDataGet.no_children_travellers.no_children,
              children_age:
                itinariesDataGet.no_children_travellers.children_age,
            },
          };
          // console.log(Obj)
          // return
          await createItinerariesApi(Obj, async (res) => {
            // console.log("res Itineraries one way Api ====", res);
            //console.log(Obj)
            if (res.status === 404) {
              console.log("Something went wrong");
            }
            if (res.status === 200) {
              setIsDataLoading(false);
              //toast.success("Created itineraries successfully!", options);
              history.push({
                pathname: "/admin/create-itinerary/",
                search: `userId=${res.data.data.data.userId}&&itineraryId=${res.data.data.data._id}`,
                state: {
                  userId: res.data.data.data.userId,
                  itineraryId: res.data.data.data._id,
                },
              });
              userItineraryData.userId = res.data.data.data.userId;
              userItineraryData.itineraryId = res.data.data.data._id;
              getDraftData();
            }
          });
        }
        if (itinariesDataGet.type_of_tour === "Round Trip") {
          setIsDataLoading(true);
          let Obj = {
            userId: authed?.user?._id,
            travellers_type: itinariesDataGet.travellers_type,
            tour_name: itinariesDataGet.tour_name,
            type_of_tour: itinariesDataGet.type_of_tour,
            currency: currencyData?.currency,
            destinationCityDetails: itinariesDataGet.destinationCityDetails,
            no_adult_travellers: itinariesDataGet.no_adult_travellers,
            no_infant_travellers: itinariesDataGet.no_infant_travellers,
            no_children_travellers: {
              no_children: itinariesDataGet.no_children_travellers.no_children,
              children_age:
                itinariesDataGet.no_children_travellers.children_age,
            },
          };
          // console.log(Obj)
          // return
          await createItinerariesApi(Obj, async (res) => {
            // console.log("res Itineraries Round Trip Api ====", res);
            if (res.status === 404) {
              console.log("Something went wrong");
            }
            if (res.status === 200) {
              //toast.success("Created itineraries successfully!", options)
              setIsDataLoading(false);
              history.push({
                pathname: "/admin/create-itinerary/",
                search: `userId=${res.data.data.data.userId}&&itineraryId=${res.data.data.data._id}`,
                state: {
                  userId: res.data.data.data.userId,
                  itineraryId: res.data.data.data._id,
                },
              });
              userItineraryData.userId = res.data.data.data.userId;
              userItineraryData.itineraryId = res.data.data.data._id;
              getDraftData();
            }
          });
        }
        if (itinariesDataGet.type_of_tour === "Multi City") {
          setIsDataLoading(true);
          let Obj = {
            userId: authed?.user?._id,
            travellers_type: itinariesDataGet.travellers_type,
            tour_name: itinariesDataGet.tour_name,
            type_of_tour: itinariesDataGet.type_of_tour,
            currency: currencyData?.currency,
            destinationCityDetails: itinariesDataGet.destinationCityDetails,
            no_adult_travellers: itinariesDataGet.no_adult_travellers,
            no_infant_travellers: itinariesDataGet.no_infant_travellers,
            no_children_travellers: {
              no_children: itinariesDataGet.no_children_travellers.no_children,
              children_age:
                itinariesDataGet.no_children_travellers.children_age,
            },
          };
          // console.log('Obj Multi city==============', Obj)
          // return
          await createItinerariesApi(Obj, async (res) => {
            // console.log("res Itineraries Multi city Api ====", res);
            if (res.status === 404) {
              console.log("Something went wrong");
            }
            if (res.status === 200) {
              setIsDataLoading(false);
              //toast.success("Created itineraries successfully!", options)
              history.push({
                pathname: "/admin/create-itinerary/",
                search: `userId=${res.data.data.data.userId}&&itineraryId=${res.data.data.data._id}`,
                state: {
                  userId: res.data.data.data.userId,
                  itineraryId: res.data.data.data._id,
                },
              });
              userItineraryData.userId = res.data.data.data.userId;
              userItineraryData.itineraryId = res.data.data.data._id;
              getDraftData();
            }
          });
        }
        setColor(false);
      }
    }
    if (state.button === 2) {
      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.stopPropagation();
        // console.log("Button 2 clicked! invalid");
      }
      setValidated(true);
      if (form.checkValidity() === true) {
        // console.log("Button 2 clicked! valid");
        if (itinariesDataGet.type_of_tour === "One Way") {
          setIsDataLoading(true);
          let Obj = {
            userId: searchPrams.get("userId"),
            itineraryId: searchPrams.get("itineraryId"),
            travellers_type: itinariesDataGet.travellers_type,
            tour_name: itinariesDataGet.tour_name,
            type_of_tour: itinariesDataGet.type_of_tour,
            currency: currencyData?.currency,
            destinationCityDetails: itinariesDataGet.destinationCityDetails,
            no_adult_travellers: itinariesDataGet.no_adult_travellers,
            no_infant_travellers: itinariesDataGet.no_infant_travellers,
            no_children_travellers: {
              no_children: itinariesDataGet.no_children_travellers.no_children,
              children_age:
                itinariesDataGet.no_children_travellers.children_age,
            },
          };
          await editItinerariesApi(Obj, async (res) => {
            // console.log("res DraftItinerariesApi ====", res);
            if (res.status === 404) {
              console.log("Something went wrong");
            }
            if (res.status === 200) {
              setIsDataLoading(false);
              //toast.success("Data save successfully!", options);
              getDraftData();
            }
          });
        }
        if (itinariesDataGet.type_of_tour === "Round Trip") {
          setIsDataLoading(true);
          let Obj = {
            userId: searchPrams.get("userId"),
            itineraryId: searchPrams.get("itineraryId"),
            travellers_type: itinariesDataGet.travellers_type,
            tour_name: itinariesDataGet.tour_name,
            type_of_tour: itinariesDataGet.type_of_tour,
            currency: currencyData ? currencyData?.currency : "AUD",
            destinationCityDetails: itinariesDataGet.destinationCityDetails,
            no_adult_travellers: itinariesDataGet.no_adult_travellers,
            no_infant_travellers: itinariesDataGet.no_infant_travellers,
            no_children_travellers: {
              no_children: itinariesDataGet.no_children_travellers.no_children,
              children_age:
                itinariesDataGet.no_children_travellers.children_age,
            },
          };
          await editItinerariesApi(Obj, async (res) => {
            // console.log("res DraftItinerariesApi ====", res);
            if (res.status === 404) {
              console.log("Something went wrong");
            }
            if (res.status === 200) {
              //toast(res.data.data.message)
              setIsDataLoading(false);
              //toast.success("Data save successfully!", options);
              getDraftData();
            }
          });
        }
        if (itinariesDataGet.type_of_tour === "Multi City") {
          setIsDataLoading(true);
          let Obj = {
            userId: searchPrams.get("userId"),
            itineraryId: searchPrams.get("itineraryId"),
            travellers_type: itinariesDataGet.travellers_type,
            tour_name: itinariesDataGet.tour_name,
            type_of_tour: itinariesDataGet.type_of_tour,
            currency: currencyData?.currency,
            destinationCityDetails: itinariesDataGet.destinationCityDetails,
            no_adult_travellers: itinariesDataGet.no_adult_travellers,
            no_infant_travellers: itinariesDataGet.no_infant_travellers,
            no_children_travellers: {
              no_children: itinariesDataGet.no_children_travellers.no_children,
              children_age:
                itinariesDataGet.no_children_travellers.children_age,
            },
          };
          // for(let i = 0; i < itinariesDataGet.destinationCityDetails.length; i++){
          //     for(let d = 0; d < itinariesDataGet.destinationCityDetails[i].itineraryDatys.length; d++){
          //         itinariesDataGet.destinationCityDetails[i].itineraryDatys[d].itineraryDaysDetails.sort((a, b) => a.updatedAt > b.updatedAt ? 1 : -1);
          //     }
          // }
          // console.log("Multi City Obj ====", Obj);
          // return
          await editItinerariesApi(Obj, async (res) => {
            // console.log("res DraftItinerariesApi ====", res);
            if (res.status === 404) {
              console.log("Something went wrong");
            }
            if (res.status === 200) {
              //toast(res.data.data.message)
              setIsDataLoading(false);
              //toast.success("Data save successfully!", options);
              getDraftData();
            }
          });
        }
        setColor(false);
      }
    }
  };
  const getDraftData = async () => {
    let Obj = {
      userId:
        searchPrams.get("userId") != null
          ? searchPrams.get("userId")
          : userItineraryData.userId,
      itineraryId:
        searchPrams.get("itineraryId") != null
          ? searchPrams.get("itineraryId")
          : userItineraryData.itineraryId,
    };
    await getItinerariesApi(Obj, async (res) => {
      // console.log("res getItinerariesApi ====", res);
      if (res.status === 404) {
        console.log("Something went wrong");
      }
      if (res.status === 200) {
        toast.success(res.data.data.message, options);
        if (res.data.data.data === null) return;
        setItinariesDataGet(res.data.data.data);
        if (res.data.data.data.tour_name === "") {
          setColor(true);
        }
      }
    });
  };
  const handleItineraryDaysDataDelete = async (e, data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "This activity will be deleted permanently",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(false);
        let obj = {
          _id: data._id,
          days_type: data.days_type,
        };
        setIsLoading(true);
        await deleteItineraryDaysDataApi(obj, async (res) => {
          // console.log("res deleteItineraryDaysDataApi ====", res);
          if (res.status === 404) {
            console.log("Something went wrong");
          }
          if (res.status === 200) {
            // console.log("Item Delete successfully.");
            getDraftData();
            setIsLoading(false);
          }
        });
      }
    });
  };
  // Start Stays Functions
  const getDayItem = (e, idx, data) => {
    e.preventDefault();
    setStayCheckinDate(moment(data.date).format("YYYY-MM-DD"));
  };
  const handleStays = (
    e,
    i,
    x,
    destinationitem,
    daystabitem,
    stayscheckoutdate
  ) => {
    //return
    window.open(`/stays-results/?cityName=${destinationitem.destination_city.city}&&countryName=${destinationitem.destination_city.country
      }&&checkInDate=${moment(
        stayCheckinDate ? stayCheckinDate : destinationitem.tour_start_date
      ).format("YYYY-MM-DD")}&&checkOutDate=${moment(
        stayscheckoutdate.date
      ).format("YYYY-MM-DD")}&&occupancy=${JSON.stringify([
        {
          room_no: 1,
          adult: itinariesDataGet.no_adult_travellers,
          child: itinariesDataGet.no_children_travellers.no_children,
          child_age: itinariesDataGet.no_children_travellers.children_age,
        },
      ])}`, "_blank");
    // history.push({
    //   pathname: "/stays-results/",
    //   search: `cityName=${destinationitem.destination_city.city}&&countryName=${destinationitem.destination_city.country
    //     }&&checkInDate=${moment(
    //       stayCheckinDate ? stayCheckinDate : destinationitem.tour_start_date
    //     ).format("YYYY-MM-DD")}&&checkOutDate=${moment(
    //       stayscheckoutdate.date
    //     ).format("YYYY-MM-DD")}&&occupancy=${JSON.stringify([
    //       {
    //         room_no: 1,
    //         adult: itinariesDataGet.no_adult_travellers,
    //         child: itinariesDataGet.no_children_travellers.no_children,
    //         child_age: itinariesDataGet.no_children_travellers.children_age,
    //       },
    //     ])}`,
    // });
  };
  // End Stays Functions
  // Start Food Functions
  const [foodResObjData, setFoodResObjData] = useState({});
  const handleFoodModalShow = async (e, i, x, data) => {
    setFoodResObjData(data);
    setShowFoodModal(true);
    setIsLoading(false);
    const type = "&query=restaurants";
    const restaurantSearchUrl =
      placeUrl +
      type +
      "+" +
      itinariesDataGet.destinationCityDetails[i].destination_city.city +
      "+" +
      itinariesDataGet.destinationCityDetails[i].destination_city.country +
      apikey;
    let obj = {
      url: restaurantSearchUrl,
    };
    setIsLoading(true);
    await getFoodRestaurentAllApi(obj, async (res) => {
      // console.log("res getFoodRestaurentAllApi ====", res);
      if (res.status === 404) {
        console.log("Something went wrong");
      }
      if (res.status === 200) {
        setFoodData(res.data.data.data.results);
        setIsLoading(false);
      }
    });
  };
  const foodFormik = useFormik({
    initialValues: {
      foodType: "",
      foodDetails: "",
      amount: "",
      time: "",
    },
    validationSchema: Yup.object({
      foodDetails: Yup.string().required("Please select restaurant."),
      foodType: Yup.string().required("Please select food type."),
      //amount: Yup.string().required("Please enter amount."),
      time: Yup.string().required("Please enter time."),
    }),
    onSubmit: async (values) => {
      setIsLoadingSubmit(true);
      const obj = {
        userId: foodResObjData.userId,
        itineraryId: foodResObjData.itineraryId,
        daysId: foodResObjData._id,
        address: JSON.parse(values.foodDetails).formatted_address,
        lat: JSON.parse(values.foodDetails).geometry.location.lat,
        lng: JSON.parse(values.foodDetails).geometry.location.lng,
        name: JSON.parse(values.foodDetails).name,
        rating: JSON.parse(values.foodDetails).rating,
        types: values.foodType,
        icon:
          JSON.parse(values.foodDetails).photos === undefined
            ? ""
            : JSON.parse(values.foodDetails).photos[0].photo_reference,
        currency: currencyData?.currency,
        amount: values.amount,
        time: values.time,
      };
      // console.log("getFoodDtlsData obj", obj)
      // return
      await addFoodRestaurentApi(obj, async (res) => {
        // console.log("res AddFoodRestaurentApi ====", res);
        if (res.status === 404) {
          console.log("Something went wrong");
        }
        if (res.status === 200) {
          getDraftData();
          setShowFoodModal(false);
          setIsLoadingSubmit(false);
          foodFormik.resetForm();
        }
      });
    }
  });

  const handleFoodModalClose = () => {
    setShowFoodModal(false);
    setIsLoading(false);
    setIsLoadingSubmit(false);
    setFoodData([]);
    foodFormik.resetForm();
  };
  // End Food Functions
  // Start Activity Functions
  const [activityResObjData, setActivityResObjData] = useState({});
  const [activitylng, setactivitylng] = useState("");
  const handleActivityModalShow = async (e, i, x, data) => {
    setActivityResObjData(data);
    setShowActivityModal(true);
    setactivitylng(i);
    setActivityData([]);
    activityTypeFromik.submitForm();
  };

  const activityTypeFromik = useFormik({
    initialValues: {
      activityType: "tourist_attraction",
    },
    validationSchema: Yup.object({
      activityType: Yup.string().required("Please select activity type."),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      let obj = {};
      if (values.activityType === "museum") {
        obj.url =
          placeUrl +
          `&query=${values.activityType}` +
          "+" +
          itinariesDataGet.destinationCityDetails[activitylng].destination_city
            .city +
          "+" +
          itinariesDataGet.destinationCityDetails[activitylng].destination_city
            .country +
          apikey;
      }
      if (values.activityType === "aquarium") {
        obj.url =
          placeUrl +
          `&query=${values.activityType}` +
          "+" +
          itinariesDataGet.destinationCityDetails[activitylng].destination_city
            .city +
          "+" +
          itinariesDataGet.destinationCityDetails[activitylng].destination_city
            .country +
          apikey;
      }
      if (values.activityType === "park") {
        obj.url =
          placeUrl +
          `&query=${values.activityType}` +
          "+" +
          itinariesDataGet.destinationCityDetails[activitylng].destination_city
            .city +
          "+" +
          itinariesDataGet.destinationCityDetails[activitylng].destination_city
            .country +
          apikey;
      }
      if (values.activityType === "amusement_park") {
        obj.url =
          placeUrl +
          `&query=${values.activityType}` +
          "+" +
          itinariesDataGet.destinationCityDetails[activitylng].destination_city
            .city +
          "+" +
          itinariesDataGet.destinationCityDetails[activitylng].destination_city
            .country +
          apikey;
      }
      if (values.activityType === "tourist_attraction") {
        obj.url =
          placeUrl +
          `&query=${values.activityType}` +
          "+" +
          itinariesDataGet.destinationCityDetails[activitylng].destination_city
            .city +
          "+" +
          itinariesDataGet.destinationCityDetails[activitylng].destination_city
            .country +
          apikey;
      }
      if (values.activityType === "cemetery") {
        obj.url =
          placeUrl +
          `&query=${values.activityType}` +
          "+" +
          itinariesDataGet.destinationCityDetails[activitylng].destination_city
            .city +
          "+" +
          itinariesDataGet.destinationCityDetails[activitylng].destination_city
            .country +
          apikey;
      }
      if (values.activityType === "church") {
        obj.url =
          placeUrl +
          `&query=${values.activityType}` +
          "+" +
          itinariesDataGet.destinationCityDetails[activitylng].destination_city
            .city +
          "+" +
          itinariesDataGet.destinationCityDetails[activitylng].destination_city
            .country +
          apikey;
      }
      if (values.activityType === "hindu_temple") {
        obj.url =
          placeUrl +
          `&query=${values.activityType}` +
          "+" +
          itinariesDataGet.destinationCityDetails[activitylng].destination_city
            .city +
          "+" +
          itinariesDataGet.destinationCityDetails[activitylng].destination_city
            .country +
          apikey;
      }
      if (values.activityType === "mosque") {
        obj.url =
          placeUrl +
          `&query=${values.activityType}` +
          "+" +
          itinariesDataGet.destinationCityDetails[activitylng].destination_city
            .city +
          "+" +
          itinariesDataGet.destinationCityDetails[activitylng].destination_city
            .country +
          apikey;
      }
      if (values.activityType === "movie_theater") {
        obj.url =
          placeUrl +
          `&query=${values.activityType}` +
          "+" +
          itinariesDataGet.destinationCityDetails[activitylng].destination_city
            .city +
          "+" +
          itinariesDataGet.destinationCityDetails[activitylng].destination_city
            .country +
          apikey;
      }
      if (values.activityType === "night_club") {
        obj.url =
          placeUrl +
          `&query=${values.activityType}` +
          "+" +
          itinariesDataGet.destinationCityDetails[activitylng].destination_city
            .city +
          "+" +
          itinariesDataGet.destinationCityDetails[activitylng].destination_city
            .country +
          apikey;
      }
      if (values.activityType === "bar") {
        obj.url =
          placeUrl +
          `&query=${values.activityType}` +
          "+" +
          itinariesDataGet.destinationCityDetails[activitylng].destination_city
            .city +
          "+" +
          itinariesDataGet.destinationCityDetails[activitylng].destination_city
            .country +
          apikey;
      }
      if (values.activityType === "taxi_stand") {
        obj.url =
          placeUrl +
          `&query=${values.activityType}` +
          "+" +
          itinariesDataGet.destinationCityDetails[activitylng].destination_city
            .city +
          "+" +
          itinariesDataGet.destinationCityDetails[activitylng].destination_city
            .country +
          apikey;
      }
      if (values.activityType === "bus_station") {
        obj.url =
          placeUrl +
          `&query=${values.activityType}` +
          "+" +
          itinariesDataGet.destinationCityDetails[activitylng].destination_city
            .city +
          "+" +
          itinariesDataGet.destinationCityDetails[activitylng].destination_city
            .country +
          apikey;
      }
      if (values.activityType === "zoo") {
        obj.url =
          placeUrl +
          `&query=${values.activityType}` +
          "+" +
          itinariesDataGet.destinationCityDetails[activitylng].destination_city
            .city +
          "+" +
          itinariesDataGet.destinationCityDetails[activitylng].destination_city
            .country +
          apikey;
      }
      if (values.activityType === "library") {
        obj.url =
          placeUrl +
          `&query=${values.activityType}` +
          "+" +
          itinariesDataGet.destinationCityDetails[activitylng].destination_city
            .city +
          "+" +
          itinariesDataGet.destinationCityDetails[activitylng].destination_city
            .country +
          apikey;
      }
      await getFoodRestaurentAllApi(obj, async (res) => {
        // console.log("res getActitvityTypeListApi ====", res);
        if (res.status === 404) {
          console.log("Something went wrong");
        }
        if (res.status === 200) {
          setActivityData(res.data.data.data.results);
          setIsLoading(false);
          //activityTypeFromik.resetForm();
        }
      });
    },
  });

  const activityFormik = useFormik({
    initialValues: {
      activityDetails: "",
      amount: "",
      time: "",
    },
    validationSchema: Yup.object({
      activityDetails: Yup.string().required("Please select activity."),
      //amount: Yup.string().required("Please enter amount."),
      time: Yup.string().required("Please enter time."),
    }),
    onSubmit: async (values) => {
      setIsLoadingSubmit(true);
      const obj = {
        userId: activityResObjData.userId,
        itineraryId: activityResObjData.itineraryId,
        daysId: activityResObjData._id,
        address: JSON.parse(values.activityDetails).formatted_address,
        lat: JSON.parse(values.activityDetails).geometry.location.lat,
        lng: JSON.parse(values.activityDetails).geometry.location.lng,
        name: JSON.parse(values.activityDetails).name,
        rating: JSON.parse(values.activityDetails).rating,
        types: activityTypeFromik.values.activityType,
        icon:
          JSON.parse(values.activityDetails).photos === undefined
            ? ""
            : JSON.parse(values.activityDetails).photos[0].photo_reference,
        currency: currencyData?.currency,
        amount: values.amount,
        time: values.time,
      };
      // console.log(obj);
      // return
      await addActivityApi(obj, async (res) => {
        // console.log("res addActivityApi ====", res);
        if (res.status === 404) {
          console.log("Something went wrong");
        }
        if (res.status === 200) {
          getDraftData();
          setShowActivityModal(false);
          setIsLoadingSubmit(false);
          activityFormik.resetForm();
        }
      });
    }
  });

  const handleActivityModalClose = () => {
    setShowActivityModal(false);
    setIsLoading(false);
    setActivityData([]);
    setIsLoadingSubmit(false);
    activityTypeFromik.resetForm();
    activityFormik.resetForm();
  };
  // End Activity Functions

  const addMultiDesFields = (data, leng) => {
    setColor(true);
    itinariesDataGet.destinationCityDetails.push({
      itineraryDatys: [
        {
          day: 1,
          date:
            data.tour_start_date === null
              ? null
              : moment(data.tour_start_date).add(1, "days"),
        },
      ],
      tour_start_date:
        data.tour_start_date === null
          ? null
          : moment(data.tour_start_date).add(1, "days"),
      tour_no_of_days: 1,
      destination_city: {
        iso_code: "",
        country: "",
        city: "",
        destination_address: "",
      },
      from_city: {
        iso_code: data.destination_city.iso_code,
        country: data.destination_city.country,
        city: data.destination_city.city,
        from_address:
          data.destination_city.destination_address === ""
            ? ""
            : `${data.destination_city.city +
            ", " +
            data.destination_city.country
            }`,
      },
    });
    setItinariesDataGet({ ...itinariesDataGet });
  };

  const removeMultiDesFields = (i, data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "This destination will be deleted permanently",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setColor(true);
        itinariesDataGet.destinationCityDetails.splice(i, 1);
        setItinariesDataGet({ ...itinariesDataGet });
        if (data._id) {
          let obj = {
            destinationCityId: data._id,
            itineraryId: data.itineraryId,
          };
          await deleteDestinationCityApi(obj, async (res) => {
            if (res.status === 404) {
              console.log("Something went wrong");
            }
            if (res.status === 200) {
              // console.log("Data Delete successfully.");
              getDraftData();
            }
          });
        }
      }
    });
  };

  const dayCount = (i) => {
    let s = 0;
    for (let j = 0; j <= i; j++) {
      s += Number(itinariesDataGet?.destinationCityDetails[j]?.tour_no_of_days);
    }
    return s
  }
  const dayNumber = (i, d) => {
    let s = 0;
    for (let j = 0; j < i; j++) {
      s += Number(itinariesDataGet.destinationCityDetails[j].itineraryDatys.length);
    }
    return s + d
  }

  const handleItineraryStaysEdit = () => {
    history.push(`/admin/stays/`);
  };
  // Start of Other functions
  const [othersData, setOthersData] = useState({});
  const [othersLatLng, setOthersLatLng] = useState({ lat: "", lng: "" });
  const [otherAvatar, setOtherAvatar] = useState("");
  const [otherAvatarImg, setOtherAvatarImg] = useState("");
  const handleOtherModalShow = (e, i, x, data) => {
    setShowOtherModal(true);
    setOthersData(data);
  };
  const otherFromik = useFormik({
    initialValues: {
      name: "",
      address: "",
      description: "",
      amount: "",
      time: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter title."),
      address: Yup.string().required("Please enter a location."),
      //description: Yup.string().required("Please enter description."),
      time: Yup.string().required("Please enter time."),
    }),
    onSubmit: async (values) => {
      // console.log(values);
      let formData = new FormData();
      formData.append("icon", otherAvatarImg);
      formData.append("name", values.name);
      formData.append("address", values.address);
      formData.append("lat", othersLatLng.lat);
      formData.append("lng", othersLatLng.lng);
      formData.append("description", values.description);
      formData.append("amount", values.amount);
      formData.append("currency", currencyData?.currency);
      formData.append("time", values.time);
      formData.append("userId", othersData.userId);
      formData.append("itineraryId", othersData.itineraryId);
      formData.append("daysId", othersData._id);
      setIsLoadingSubmit(true);
      await addOthersApi(formData, async (res) => {
        // console.log("res addOthersApi ====", res);
        if (res.status === 404) {
          console.log('Something went wrong')
        }
        if (res.status === 200) {
          setIsLoading(false);
          getDraftData();
          setOtherAvatar("");
          setOtherAvatarImg("")
          setShowOtherModal(false);
          setIsLoadingSubmit(false);
          otherFromik.resetForm();
        }
      });
    },
  });

  const selectAvatarImg = async (event) => {
    if (event["target"].files.length > 0) {
      const imgfile = event["target"].files[0];
      setOtherAvatarImg(imgfile);
      const reader = new FileReader();
      reader.readAsDataURL(imgfile);
      reader.onload = (event) => {
        setOtherAvatar(reader.result);
      };
    }
  };
  const handleOtherModalClose = () => {
    setOtherAvatar("");
    setOtherAvatarImg("")
    setShowOtherModal(false);
    otherFromik.resetForm();
    setIsLoadingSubmit(false);
  };
  // end of Other functions

  useEffect(() => {
    getDraftData();
    travellersTypeCount();
  }, []);

  const checkNullItinerary = itinariesDataGet.destinationCityDetails.some((element) => { return element.tour_start_date === null })

  return (
    <>
      <div className="content-body create-itinarary-wrap">
        <h1 className="d-col-title mb-3">Create Itinerary</h1>
        <Form noValidate validated={validated} onSubmit={handleCreateItinaries}>
          <div className="d-span-row">
            <div className="d-col-8">
              <div className="d-col-input-field">
                <Form.Group controlId="TourName">
                  <Form.Control
                    onChange={handleChange}
                    name="tour_name"
                    value={itinariesDataGet.tour_name}
                    placeholder="Tour Name"
                    required
                  />
                  {/* <Form.Control.Feedback type="invalid">
                                    Please enter tour name.
                                </Form.Control.Feedback> */}
                </Form.Group>
              </div>
              <div className="mt-3 d-col-radio-field">
                {itinariesDataGet._id ? (
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      defaultChecked={true}
                      type="radio"
                    />
                    <label className="form-check-label">
                      {itinariesDataGet.type_of_tour}
                    </label>
                  </div>
                ) : (
                  <>
                    <Form.Group
                      className="d-col-radio-field"
                      controlId="typeOfTourControl"
                    >
                      <Form.Check
                        type="radio"
                        label="One Way"
                        onChange={onValueChange}
                        name="type_of_tour"
                        defaultChecked={
                          itinariesDataGet.type_of_tour === "One Way"
                        }
                        value="One Way"
                        id="OneWay"
                        required
                      />
                      <Form.Check
                        type="radio"
                        label="Round Trip"
                        onChange={onValueChange}
                        name="type_of_tour"
                        defaultChecked={
                          itinariesDataGet.type_of_tour === "Round Trip"
                        }
                        value="Round Trip"
                        id="RoundTrip"
                        required
                      />
                      <Form.Check
                        type="radio"
                        label="Multi City"
                        onChange={onValueChange}
                        name="type_of_tour"
                        defaultChecked={
                          itinariesDataGet.type_of_tour === "Multi City"
                        }
                        value="Multi City"
                        id="MultiCity"
                        required
                      />
                      {/* <Form.Control.Feedback type="invalid">Please select tour type.</Form.Control.Feedback> */}
                    </Form.Group>
                  </>
                )}
              </div>
              <div className="row">
                {itinariesDataGet.type_of_tour === "One Way" ||
                  itinariesDataGet.type_of_tour === "Round Trip" ? (
                  <>
                    <div className="col-md-12 mt-3">
                      <div className="multi-city-row">
                        <div className="multi-city-item position-relative">
                          <div className="d-col-input-field">
                            <label className="form-label">Going From</label>
                            <Form.Group controlId="validationGoingFrom">
                              <Autocomplete
                                apiKey={`${googleApiKey}`}
                                options={{ types: ["(cities)"] }}
                                onPlaceSelected={(place) => {
                                  handleSelectGoingFrom(place, 0);
                                }}
                                value={
                                  itinariesDataGet.destinationCityDetails[0]
                                    .from_city.from_address
                                }
                                onChange={(e) => {
                                  handleChangeGoingFrom(e, 0);
                                }}
                                className="form-control"
                                placeholder="Enter a Location"
                                required
                              />
                              {/* <Form.Control.Feedback type="invalid">
                                    Please enter going from.
                                </Form.Control.Feedback> */}
                            </Form.Group>
                          </div>
                        </div>
                        <div className="multi-city-item">
                          <div className="d-col-input-field">
                            <label className="form-label">Going To</label>
                            <Form.Group controlId="validationGoingTo">
                              <Autocomplete
                                apiKey={`${googleApiKey}`}
                                options={{ types: ["(cities)"] }}
                                onPlaceSelected={(place) => {
                                  handleSelectGoingTo(place, 0);
                                }}
                                value={
                                  itinariesDataGet.destinationCityDetails[0]
                                    .destination_city.destination_address
                                }
                                onChange={(e) => {
                                  handleChangeGoingTo(e, 0);
                                }}
                                className="form-control"
                                placeholder="Enter a Location"
                                required
                              />
                              {/* <Form.Control.Feedback type="invalid">
                                    Please enter going to.
                                </Form.Control.Feedback> */}
                            </Form.Group>
                          </div>
                        </div>
                        <div className="multi-city-item">
                          <div className="d-col-input-field">
                            <label className="form-label">Depart</label>
                            <Form.Group controlId="validationStartDate" className="sd-date-field">
                              <label className="sd-date-icon" htmlFor="owrt_0"></label>
                              <DatePicker
                                value={
                                  itinariesDataGet?.destinationCityDetails[0]
                                    ?.tour_start_date
                                    ? moment(
                                      itinariesDataGet
                                        ?.destinationCityDetails[0]
                                        ?.tour_start_date
                                    ).format("DD-MM-YYYY")
                                    : null
                                }
                                onChange={(date) => handleDateChange(date, 0)}
                                name="tour_start_date"
                                dateFormat="dd MMMM yyyy"
                                autoComplete="off"
                                customInput={
                                  <input
                                    className="form-control"
                                    type="text"
                                    readOnly
                                  />
                                }
                                placeholderText="Enter Date"
                                minDate={new Date()}
                                id="owrt_0"
                                required
                              />
                              {/* <Form.Control.Feedback type="invalid">Please select start date.</Form.Control.Feedback> */}
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
                {itinariesDataGet.type_of_tour === "Multi City" ? (
                  <div className="col-md-12 mt-3">
                    {itinariesDataGet.destinationCityDetails.map(
                      (element, i) => {
                        return (
                          <>
                            <div className="multi-city-row rs-multi-city mb-3">
                              <div className="multi-city-count">{i + 1}</div>
                              <div className="multi-city-item">
                                <div className="d-col-input-field">
                                  <label className="form-label">
                                    Going From
                                  </label>
                                  <Form.Group
                                    controlId={`validationGoingFrom${i}`}
                                  >
                                    <Autocomplete
                                      apiKey={`${googleApiKey}`}
                                      options={{ types: ["(cities)"] }}
                                      onPlaceSelected={(place) => {
                                        handleSelectGoingFrom(place, i);
                                      }}
                                      value={
                                        itinariesDataGet.destinationCityDetails[
                                          i
                                        ].from_city.from_address
                                      }
                                      onChange={(e) => {
                                        handleChangeGoingFrom(e, i);
                                      }}
                                      className="form-control"
                                      placeholder="Enter a Location"
                                      required
                                    />
                                  </Form.Group>
                                </div>
                              </div>
                              <div className="multi-city-item">
                                <div className="d-col-input-field">
                                  <label className="form-label">Going To</label>
                                  <Form.Group
                                    controlId={`validationGoingTo${i}`}
                                  >
                                    <Autocomplete
                                      apiKey={`${googleApiKey}`}
                                      options={{ types: ["(cities)"] }}
                                      onPlaceSelected={(place) => {
                                        handleSelectGoingTo(place, i);
                                      }}
                                      value={
                                        itinariesDataGet.destinationCityDetails[
                                          i
                                        ].destination_city.destination_address
                                      }
                                      onChange={(e) => {
                                        handleChangeGoingTo(e, i);
                                      }}
                                      className="form-control"
                                      placeholder="Enter a Location"
                                      required
                                    />
                                  </Form.Group>
                                </div>
                              </div>
                              <div className="multi-city-item">
                                <div className="d-col-input-field">
                                  <label className="form-label">Depart</label>
                                  <Form.Group controlId="validationStartDate" className="sd-date-field">
                                    <label className="sd-date-icon" htmlFor={`mc_${i + 1}`}></label>
                                    <DatePicker
                                      value={
                                        itinariesDataGet.destinationCityDetails[
                                          i
                                        ].tour_start_date
                                          ? moment(
                                            itinariesDataGet
                                              .destinationCityDetails[i]
                                              .tour_start_date
                                          ).format("DD-MM-YYYY")
                                          : null
                                      }
                                      onChange={(date) =>
                                        handleDateChange(date, i)
                                      }
                                      name="tour_start_date"
                                      dateFormat="dd MMMM yyyy"
                                      autoComplete="off"
                                      customInput={
                                        <input
                                          className="form-control"
                                          type="text"
                                          readOnly
                                        />
                                      }
                                      placeholderText="Enter Date"
                                      minDate={i > 0 ? new Date(itinariesDataGet?.destinationCityDetails[i - 1]?.tour_start_date).setDate(new Date(itinariesDataGet?.destinationCityDetails[i - 1]?.tour_start_date).getDate() + 1) : new Date()}
                                      required
                                      id={`mc_${i + 1}`}
                                    />
                                  </Form.Group>
                                </div>
                              </div>
                              <div className="multi-city-item-delete">
                                {i ? (
                                  <button
                                    type="button"
                                    className="btn close-btn btn-secondary-color"
                                    onClick={() =>
                                      removeMultiDesFields(i, element)
                                    }
                                  >
                                    <i className="bi bi-x-circle"></i>
                                  </button>
                                ) : null}
                              </div>
                            </div>
                          </>
                        );
                      }
                    )}
                    <div className="d-flex justify-content-end">
                      <button
                        type="button"
                        className="btn btn-primary-color"
                        style={{ fontSize: "14px" }}
                        onClick={() =>
                          addMultiDesFields(
                            itinariesDataGet.destinationCityDetails[
                            itinariesDataGet.destinationCityDetails.length - 1
                            ],
                            itinariesDataGet.destinationCityDetails.length - 1
                          )
                        }
                      >
                        <i className="bi bi-plus-lg"></i> Add Destination
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="d-col-4">
              {/* <div className="d-col-input-field">
                            <label className="form-label mb-0">Add number of travellers</label>
                        </div> */}
              <div className="">
                <div className="d-col-number-group d-flex">
                  <div className="number-form-label">
                    <p>Travellers</p>
                  </div>
                  <div className="d-col-type-bx">
                    {itinariesDataGet.travellers_type === "Solo" ? (
                      <img src={soloIcon} />
                    ) : (
                      ""
                    )}
                    {itinariesDataGet.travellers_type === "Couple" ? (
                      <img src={coupleIcon} />
                    ) : (
                      ""
                    )}
                    {itinariesDataGet.travellers_type === "Family" ? (
                      <img src={familyIcon} />
                    ) : (
                      ""
                    )}
                    {itinariesDataGet.travellers_type === "Group" ? (
                      <img src={groupIcon} />
                    ) : (
                      ""
                    )}
                    {/* <select value={itinariesDataGet.travellers_type} className="form-select" onChange={(e) => onChangeTravellersType(e)}>
                                    <option value="">Select</option>
                                    <option value="Solo">Solo</option>
                                    <option value="Couple">Couple</option>
                                    <option value="Family">Family</option> 
                                    <option value="Group">Group</option> 
                                </select> */}
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <div className="d-col-number-group">
                  <div className="number-form-label">
                    <p>
                      Adults <small>14 years & above</small>
                    </p>
                    <p>
                      <small>on the day of travel</small>
                    </p>
                  </div>
                  <div className="d-col-number-field custom-add-rm-btn">
                    <button
                      type="button"
                      disabled={itinariesDataGet.no_adult_travellers < 2}
                      onClick={adultsdecrementCount}
                    >
                      <i className="bi bi-dash"></i>
                    </button>
                    <input
                      type="number"
                      name="adults"
                      value={itinariesDataGet.no_adult_travellers}
                      readOnly
                    />
                    <button type="button" onClick={adultsincrementCount}>
                      <i className="bi bi-plus"></i>
                    </button>
                  </div>
                  {/* <div className="d-col-number-field">
                                    <NumericInput onChange={(value)=>onChangeAdultsValue(value)} name="adults" mobile={true} value={itinariesDataGet.no_adult_travellers} min={1} type="number" strict={true}/>
                                </div> */}
                </div>
              </div>
              <div className="mt-3">
                <div className="d-col-number-group">
                  <div className="number-form-label">
                    <p>
                      Children <small>2 to 13 years</small>
                    </p>
                    <p>
                      <small>on the day of travel</small>
                    </p>
                  </div>
                  <div className="d-col-number-field custom-add-rm-btn">
                    <button
                      type="button"
                      disabled={
                        itinariesDataGet.no_children_travellers.no_children < 1
                      }
                      onClick={childdecrementCount}
                    >
                      <i className="bi bi-dash"></i>
                    </button>
                    <input
                      type="number"
                      name="no_children"
                      value={
                        itinariesDataGet.no_children_travellers.no_children
                      }
                      readOnly
                    />
                    <button type="button" onClick={childincrementCount}>
                      <i className="bi bi-plus"></i>
                    </button>
                  </div>
                </div>
              </div>
              {[
                ...Array(
                  Number(itinariesDataGet.no_children_travellers.no_children)
                ),
              ].map((childitem, i) => {
                return (
                  <div key={i} className="mt-3">
                    <div className="d-col-number-group">
                      <div className="number-form-label">
                        <p>
                          Age of child {i + 1} <small> Under 12 yrs</small>
                        </p>
                        <p>
                          <small>on the day of travel</small>
                        </p>
                      </div>
                      <div className="d-col-number-select">
                        <Form.Group controlId={`validationChildAge${i}`}>
                          <Form.Control
                            as="select"
                            name={`childAge${i}`}
                            value={
                              itinariesDataGet.no_children_travellers
                                .children_age[i]
                            }
                            onChange={(e) => onChangeChildAge(e, i)}
                            required
                          >
                            <option value="">Select</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                          </Form.Control>
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                );
              })}
              <div className="mt-3">
                <div className="d-col-number-group">
                  <div className="number-form-label">
                    <p>
                      Infant <small>0 to 2 years</small>
                    </p>
                    <p>
                      <small>on the day of travel</small>
                    </p>
                  </div>
                  <div className="d-col-number-field custom-add-rm-btn">
                    <button
                      type="button"
                      disabled={itinariesDataGet.no_infant_travellers < 1}
                      onClick={infantsdecrementCount}
                    >
                      <i className="bi bi-dash"></i>
                    </button>
                    <input
                      type="number"
                      name="infants"
                      value={itinariesDataGet.no_infant_travellers}
                      readOnly
                    />
                    <button type="button" onClick={infantsincrementCount}>
                      <i className="bi bi-plus"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Tab.Container defaultActiveKey="desItem0">
            {itinariesDataGet.type_of_tour === "Multi City" ? (
              <Nav variant="pills" className="mt-3 nav-multicity-tabs">
                {itinariesDataGet.destinationCityDetails.map(
                  (destinationitem, i) => {
                    return (
                      <Nav.Item key={i}>
                        {destinationitem.destination_city.city ? (
                          <Nav.Link eventKey={`desItem${i}`}>
                            {destinationitem.destination_city.city}
                          </Nav.Link>
                        ) : (
                          ""
                        )}
                      </Nav.Item>
                    );
                  }
                )}
              </Nav>
            ) : (
              ""
            )}
            <Tab.Content>
              {itinariesDataGet.destinationCityDetails.map(
                (destinationitem, i) => {
                  return (
                    <Tab.Pane eventKey={`desItem${i}`}>
                      <div className="mt-3">
                        {itinariesDataGet.type_of_tour === "One Way" &&
                          destinationitem.destination_city.city ? (
                          <h4 className="d-nav-tabs-title">
                            Destination City -{" "}
                            <b>{destinationitem.destination_city.city}</b>
                          </h4>
                        ) : (
                          ""
                        )}
                        {itinariesDataGet.type_of_tour === "Round Trip" &&
                          destinationitem.destination_city.city ? (
                          <h4 className="d-nav-tabs-title">
                            Destination City -{" "}
                            <b>{destinationitem.destination_city.city}</b>
                          </h4>
                        ) : (
                          ""
                        )}
                        {itinariesDataGet.type_of_tour === "Multi City" &&
                          destinationitem.destination_city.city ? (
                          <h4 className="d-nav-tabs-title">
                            Destination City -{" "}
                            <b>{destinationitem.destination_city.city}</b>
                          </h4>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="row">
                        {/* <div className="col-md-4 mt-4">
                                        <div className="d-col-input-field">
                                            <label className="form-label">Start Date</label>
                                            <DatePicker 
                                            //selected={checkDate} 
                                            onChange={(date)=> handleDateChange(date, i)}
                                            value={destinationitem.tour_start_date? moment(destinationitem.tour_start_date).format("DD MMMM yyyy"):  null}
                                            name="tour_start_date"
                                            dateFormat="dd MMMM yyyy"
                                            customInput={<input className="form-control d-dateIcon" type="text" readOnly/>}
                                            placeholderText="Enter Date"
                                            minDate={new Date()}
                                            />
                                        </div>
                                    </div> */}
                        <div className="col-md-4 mt-3 d-flex align-items-end">
                          {destinationitem.tour_start_date ? (
                            <div className="d-col-number-days d-col-input-field">
                              <label className="form-label">Days</label>
                              <div className="d-col-number-field custom-daysAdd-Rm-btn">
                                <input
                                  type="number"
                                  name="tour_no_of_days"
                                  value={i > 0 ? dayCount(i) : destinationitem.tour_no_of_days}
                                  readOnly
                                />
                                <div className="daysAdd-minus-btns">
                                  <button
                                    type="button"
                                    onClick={(e) =>
                                      handleAddItem(e, destinationitem, i)
                                    }
                                  >
                                    <i className="bi bi-plus"></i>
                                  </button>
                                  <button
                                    type="button"
                                    disabled={
                                      destinationitem?.itineraryDatys?.length <
                                      2
                                    }
                                    onClick={(e) =>
                                      handleRemove(
                                        e,
                                        destinationitem?.itineraryDatys?.[
                                        destinationitem?.itineraryDatys
                                          ?.length - 1
                                        ],
                                        destinationitem,
                                        i,
                                        destinationitem?.itineraryDatys
                                          ?.length - 1
                                      )
                                    }
                                  >
                                    <i className="bi bi-dash"></i>
                                  </button>
                                </div>
                                {/* <NumericInput onChange={(value) => onChangeDaysValue(value, i)} mobile={false} type="number" value={destinationitem.tour_no_of_days} min={1} strict={true} /> */}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        {/*<div className="col-md-8 mt-3 btn-crt-itinaries">
                                        {itinariesDataGet._id ? <button type="button" className={color ? "btn btn-red-color border-radius-10 btn-height-58" : "btn btn-primary-color border-radius-10 btn-height-58"} onClick={handleDraftSave}><i className="bi bi-plus-circle"></i>&nbsp;&nbsp;Save</button>
                                        : <button type="submit" className="btn btn-primary-color border-radius-10 btn-height-58">Create</button>}  
                                    </div>*/}
                      </div>
                      {destinationitem.tour_start_date || destinationitem._id ?
                        <Tab.Container defaultActiveKey="days1">
                          <div className="mt-3">
                            <Nav variant="pills" className="d-nav-tabs days-count-nav">
                              {destinationitem.itineraryDatys.map((daysitem, idx) => {
                                return (
                                  <Nav.Item key={idx}>
                                    <Nav.Link eventKey={`days${daysitem.day}`} onClick={(e) => getDayItem(e, idx, daysitem)}>
                                      <i className="bi bi-x-circle" onClick={(e) => handleRemove(e, daysitem, destinationitem, i, idx)}></i>
                                      Day {i > 0 ? dayNumber(i, daysitem.day) : daysitem.day}
                                      <span>{daysitem.date ? moment(daysitem.date).format("DD MMMM yyyy") : null}</span>
                                    </Nav.Link>
                                  </Nav.Item>
                                )
                              })}
                              <Nav.Item onClick={(e) => handleAddItem(e, destinationitem, i)}>
                                <span className="btn day-item-add btn-primary-color"><i className="bi bi-plus-lg"></i></span>
                              </Nav.Item>
                            </Nav>
                          </div>
                          {itinariesDataGet._id ? <Tab.Content>
                            {destinationitem?.itineraryDatys?.map((daystabitem, x) => {
                              let arrItineraryDay = !daystabitem?.itineraryDaysDetails ? [] : [...daystabitem?.itineraryDaysDetails];
                              return (
                                <Tab.Pane eventKey={`days${daystabitem.day}`}>
                                  {color === true ?
                                    <Nav variant="pills" className="d-nav2-tabs mb-2">
                                      <Nav.Item>
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={<Tooltip>Please save first then you can add details.</Tooltip>}
                                        >
                                          <Nav.Link disabled={true}>
                                            <div className="add-day-t">
                                              <i className="icon-Transpott"></i>
                                              <span>Transport</span>
                                            </div>
                                          </Nav.Link>
                                        </OverlayTrigger>
                                      </Nav.Item>
                                      <Nav.Item>
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={<Tooltip>Please save first then you can add details.</Tooltip>}
                                        >
                                          <Nav.Link disabled={true}>
                                            <div className="add-day-t">
                                              <i className="icon-Stays"></i>
                                              <span>Stays</span>
                                            </div>
                                          </Nav.Link>
                                        </OverlayTrigger>
                                      </Nav.Item>
                                      <Nav.Item>
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={<Tooltip>Please save first then you can add details.</Tooltip>}
                                        >
                                          <Nav.Link disabled={true}>
                                            <div className="add-day-t">
                                              <i className="icon-Activity"></i>
                                              <span>Activity</span>
                                            </div>
                                          </Nav.Link>
                                        </OverlayTrigger>
                                      </Nav.Item>
                                      <Nav.Item>
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={<Tooltip>Please save first then you can add details.</Tooltip>}
                                        >
                                          <Nav.Link disabled={true}>
                                            <div className="add-day-t">
                                              <i className="icon-Tours"></i>
                                              <span>Tours</span>
                                            </div>
                                          </Nav.Link>
                                        </OverlayTrigger>
                                      </Nav.Item>
                                      <Nav.Item>
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={<Tooltip>Please save first then you can add details.</Tooltip>}
                                        >
                                          <Nav.Link disabled={true}>
                                            <div className="add-day-t">
                                              <i className="icon-Food"></i>
                                              <span>Food</span>
                                            </div>
                                          </Nav.Link>
                                        </OverlayTrigger>
                                      </Nav.Item>
                                      <Nav.Item>
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={<Tooltip>Please save first then you can add details.</Tooltip>}
                                        >
                                          <Nav.Link disabled={true}>
                                            <div className="add-day-t">
                                              <i className="icon-Others"></i>
                                              <span>Other</span>
                                            </div>
                                          </Nav.Link>
                                        </OverlayTrigger>
                                      </Nav.Item>
                                    </Nav> :
                                    <Nav variant="pills" className="d-nav2-tabs mb-2">
                                      <Nav.Item>
                                        <Nav.Link className="coming-soon-style" disabled={true}>
                                          <span className="badge btn-warning">Coming soon</span>
                                          <div className="add-day-t">
                                            <i className="icon-Transpott"></i>
                                            <span>Transport</span>
                                          </div>
                                        </Nav.Link>
                                      </Nav.Item>
                                      <Nav.Item>
                                        <Nav.Link onClick={(e) => handleStays(e, i, x, destinationitem, daystabitem, destinationitem?.itineraryDatys?.[destinationitem?.itineraryDatys?.length - 1])}>
                                          <div className="add-day-t">
                                            <i className="icon-Stays"></i>
                                            <span>Stays</span>
                                          </div>
                                        </Nav.Link>
                                      </Nav.Item>
                                      <Nav.Item>
                                        <Nav.Link onClick={(e) => handleActivityModalShow(e, i, x, daystabitem)}>
                                          <div className="add-day-t">
                                            <i className="icon-Activity"></i>
                                            <span>Activity</span>
                                          </div>
                                        </Nav.Link>
                                      </Nav.Item>
                                      <Nav.Item>
                                        <Nav.Link className="coming-soon-style" disabled={true}>
                                          <span className="badge btn-warning">Coming soon</span>
                                          <div className="add-day-t">
                                            <i className="icon-Tours"></i>
                                            <span>Tours</span>
                                          </div>
                                        </Nav.Link>
                                      </Nav.Item>
                                      <Nav.Item>
                                        <Nav.Link onClick={(e) => handleFoodModalShow(e, i, x, daystabitem)}>
                                          <div className="add-day-t">
                                            <i className="icon-Food"></i>
                                            <span>Food</span>
                                          </div>
                                        </Nav.Link>
                                      </Nav.Item>
                                      <Nav.Item>
                                        <Nav.Link onClick={(e) => handleOtherModalShow(e, i, x, daystabitem)}>
                                          <div className="add-day-t">
                                            <i className="icon-Others"></i>
                                            <span>Other</span>
                                          </div>
                                        </Nav.Link>
                                      </Nav.Item>
                                    </Nav>}
                                  <div className="mt-4">
                                    {/* ?.sort((a, b) => a.time > b.time ? 1 : -1) */}
                                    {arrItineraryDay?.sort((a, b) => a.time > b.time ? 1 : -1).map((item, d) => {
                                      return (
                                        <>
                                          {/* {item.isItineraryStaysDelete === false ? <>*/}

                                          {item.days_type === "Hotel" ? <>
                                            <div className="time-list-item">
                                              <div className="time-text">
                                                <span>{item.time}</span>
                                              </div>
                                              <div className="time-description">
                                                <div className="add-destination-item">
                                                  <div className="a-d-left-bx">
                                                    <div className="a-d-icon">
                                                      {item.hotelDetails.response_data.hotelImages === "" ? <img src={`${stayIcon60}`} alt="" /> : <img src={`${item.hotelDetails.response_data.hotelImages[0].url}`} alt="" />}
                                                    </div>
                                                    <div className="a-d-item-info">
                                                      <h5>{item.hotelDetails.response_data.name} {item.isPayment === false ? <span className="custom-badge-style btn-warning">Hotel Booking is pending</span> : <span className="custom-badge-style btn-success">Hotel is booked.</span>}</h5>
                                                      <p>{item.roomRates.perBookingRates[0].roomType} </p>
                                                      {/* | {item.paxDetails[0].adult.firstName.length} Adult, {item.paxDetails[0].child.firstName.length} Child */}
                                                      <p><span className="a-d-type">{item.days_type}</span></p>
                                                    </div>
                                                  </div>
                                                  <div className="a-d-item-actions">
                                                    <button type="button" onClick={(e) => handleItineraryStaysEdit(e, item)} className="btn edit-icon"></button>
                                                    <button type="button" onClick={(e) => handleItineraryDaysDataDelete(e, item)} className="btn delete-icon"></button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </> : ""}
                                          {/* </> : ""} */}
                                          {item.days_type === "Food" ?
                                            <div className="time-list-item">
                                              <div className="time-text">
                                                <span>{item.time}</span>
                                              </div>
                                              <div className="time-description">
                                                <div className="add-destination-item">
                                                  <div className="a-d-left-bx">
                                                    <div className="a-d-icon">
                                                      {item.icon === "" ? <img src={`${foodIcon60}`} alt="" /> : <img src={`${photoUrl}maxwidth=400&photoreference=${item.icon}${apikey}`} alt="" />}
                                                    </div>
                                                    <div className="a-d-item-info">
                                                      <h5>{item.name}</h5>
                                                      <p>{item.address}</p>
                                                      <p><span className="a-d-type">{item.days_type}</span></p>
                                                    </div>
                                                  </div>
                                                  <div className="a-d-item-actions">
                                                    {/* <button type="button" className="btn edit-icon"></button> */}
                                                    <button type="button" onClick={(e) => handleItineraryDaysDataDelete(e, item)} className="btn delete-icon"></button>

                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            : ""}
                                          {item.days_type === "Activity" ?
                                            <div className="time-list-item">
                                              <div className="time-text">
                                                <span>{item.time}</span>
                                              </div>
                                              <div className="time-description">
                                                <div className="add-destination-item">
                                                  <div className="a-d-left-bx">
                                                    <div className="a-d-icon">
                                                      {item.icon === "" ? <img src={`${activityIcon60}`} alt="" /> : <img src={`${photoUrl}maxwidth=400&photoreference=${item.icon}${apikey}`} alt="" />}
                                                    </div>
                                                    <div className="a-d-item-info">
                                                      <h5>{item.name}</h5>
                                                      <p>{item.address}</p>
                                                      <p><span className="a-d-type">{item.days_type}</span></p>
                                                    </div>
                                                  </div>
                                                  <div className="a-d-item-actions">
                                                    {/* <button type="button" className="btn edit-icon"></button> */}
                                                    <button type="button" onClick={(e) => handleItineraryDaysDataDelete(e, item)} className="btn delete-icon"></button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            : ""}
                                          {item.days_type === "Others" ?
                                            <div className="time-list-item">
                                              <div className="time-text">
                                                <span>{item.time}</span>
                                              </div>
                                              <div className="time-description">
                                                <div className="add-destination-item">
                                                  <div className="a-d-left-bx">
                                                    <div className="a-d-icon">
                                                      {item.icon === "" ? <img src={`${othersIcon60}`} alt="" /> : <img src={`${imageUrlOthers + item.icon}`} alt="" />}
                                                    </div>
                                                    <div className="a-d-item-info">
                                                      <h5>{item.name}</h5>
                                                      <p>{item.address}</p>
                                                      <p><span className="a-d-type">{item.days_type}</span></p>
                                                    </div>
                                                  </div>
                                                  <div className="a-d-item-actions">
                                                    {/* <button type="button" className="btn edit-icon"></button> */}
                                                    <button type="button" onClick={(e) => handleItineraryDaysDataDelete(e, item)} className="btn delete-icon"></button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            : ""}
                                        </>
                                      )
                                    })}
                                  </div>
                                </Tab.Pane>
                              )
                            })}
                          </Tab.Content> : ""}
                        </Tab.Container>
                        : ""}
                    </Tab.Pane>
                  )
                })}
            </Tab.Content>
          </Tab.Container>
          <div className="form-btn-groups d-flex justify-content-end ci-buttons-bar">
            {itinariesDataGet._id ? <>
              <button type="submit" disabled={isDataLoading === true} onClick={() => (state.button = 2)} className={color ? "btn btn-red-color border-radius-10 btn-height-58" : "btn btn-primary-color border-radius-10 btn-height-58"}>{isDataLoading ? <><Spinner animation="border" size="sm" />&nbsp;&nbsp;Saving...</> : <><i className="bi bi-plus-circle"></i>&nbsp;&nbsp;Save</>}</button>
              <button type="button" disabled={color === true || checkNullItinerary === true} onClick={() => history.push(`/admin/itinerary-preview/?userId=${searchPrams.get("userId")}&&itineraryId=${searchPrams.get("itineraryId")}`)} className="btn btn-primary-color ms-2">Preview</button>
            </> : <button type="submit" disabled={isDataLoading === true} onClick={() => (state.button = 1)} className="btn btn-primary-color border-radius-10 btn-height-58">{isDataLoading ? <><Spinner animation="border" size="sm" />&nbsp;&nbsp;Loading...</> : <><i className="bi bi-plus-circle"></i>&nbsp;&nbsp;Create</>}</button>}
          </div>
        </Form>

      </div>

      {/* Add Food Modal */}
      <Modal
        show={showFoodModal}
        onHide={handleFoodModalClose}
        keyboard={false}
        size="lg"
        className="add-travellers-modal"
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Nearby Restaurants</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            {isLoading ? <LoadingSpinnerTwo /> : getFoodData.length === 0 ? <p className="text-center">No data found.</p> : null}
            {getFoodData && getFoodData.length > 0 ? getFoodData.map((item, i) => (
              <div className="col-lg-6 mb-3" key={i}>
                <div className="srws-item-lg">
                  <input type="radio" className="srws-item-radio" onChange={foodFormik.handleChange} onBlur={foodFormik.handleBlur} name="foodDetails" value={JSON.stringify(item)} id={`srws${i}`} />
                  <div className="srws-item">
                    <div className="srws-item-img">
                      {item.photos === undefined || item.photos === null || item.photos === "" ? <img src={`${noPictureAvailable}`} alt="" /> : <img src={`${photoUrl}maxwidth=400&photoreference=${item.photos[0].photo_reference}${apikey}`} alt="" />}
                    </div>
                    <div className="srws-item-info">
                      <div className="srws-item-head">
                        <h5>{item.name}</h5>
                        <span className="srws-item-rating"><i className="bi bi-star-fill" aria-hidden="true"></i> {item.rating} </span>
                      </div>
                      <p>{item.formatted_address}</p>
                    </div>
                  </div>
                </div>
              </div>
            )) : ""}
          </div>
        </Modal.Body>
        <Modal.Footer className="pt-1 pb-1 ci-iti-modal-footer">
          <div className="food-footer-lt m-0 mt-2 mb-2">
            <div className="d-flex food-radio-style">
              <div className="form-check">
                <input className="form-check-input" type="radio" onChange={foodFormik.handleChange} onBlur={foodFormik.handleBlur} name="foodType" value="Breakfast" id="Breakfast" />
                <label className="form-check-label" htmlFor="Breakfast">
                  Breakfast
                </label>
              </div>
              <div className="form-check">
                <input className="form-check-input" type="radio" onChange={foodFormik.handleChange} onBlur={foodFormik.handleBlur} name="foodType" value="Lunch" id="Lunch" />
                <label className="form-check-label" htmlFor="Lunch">
                  Lunch
                </label>
              </div>
              <div className="form-check">
                <input className="form-check-input" type="radio" onChange={foodFormik.handleChange} onBlur={foodFormik.handleBlur} name="foodType" value="Dinner" id="Dinner" />
                <label className="form-check-label" htmlFor="Dinner">
                  Dinner
                </label>
              </div>
            </div>
            <div className="input-group cost-item-field mt-2">
              <span className="input-group-text">Cost</span>
              <span className="input-group-text">{currencyData ? currencyData?.currency : null}</span>
              <input type="number" min="1" className="form-control" onChange={foodFormik.handleChange} onBlur={foodFormik.handleBlur} name="amount" value={foodFormik.values.amount} />
            </div>
            <div className="input-group cost-item-field mt-2">
              <span className="input-group-text">Time</span>
              <TimePicker onChange={(value) => foodFormik.setFieldValue("time", timeFromInt(value))}
                onBlur={foodFormik.handleBlur("time")}
                value={foodFormik.values.time}
                format={24}
                step={15}
              />
            </div>
          </div>

          <div className="food-footer-rt m-0 mb-2">
            <ul className="errors-ul">
              {foodFormik.touched.foodDetails && foodFormik.errors.foodDetails ? (<li style={{ color: "red" }}>{foodFormik.errors.foodDetails}</li>) : null}
              {foodFormik.touched.foodType && foodFormik.errors.foodType ? (<li style={{ color: "red" }}>{foodFormik.errors.foodType}</li>) : null}
              {foodFormik.touched.time && foodFormik.errors.time ? (<li style={{ color: "red" }}>{foodFormik.errors.time}</li>) : null}
            </ul>
            <div className="text-end mt-2">
              <button type="button" onClick={handleFoodModalClose} className="btn btn-secondary-color btn-border-color" >Close</button>
              <button type="button" disabled={isLoadingSubmit === true} onClick={(e) => foodFormik.handleSubmit()} className="btn btn-primary-color btn-border-color ms-3">{isLoadingSubmit ? "Submitting..." : "Submit"}</button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      {/* Add Activity Modal */}
      <Modal
        show={showActivityModal}
        onHide={handleActivityModalClose}
        keyboard={false}
        size="lg"
        className="add-travellers-modal"
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Nearby Activity Places</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={activityTypeFromik.handleSubmit}>
            <div className="add-activity-search-box">
              <div className="d-col-input-field">
                <select className="form-select" name="activityType" value={activityTypeFromik.values.activityType}
                  onChange={(e) => {
                    activityTypeFromik.setFieldValue("activityType", e.target.value);
                    activityTypeFromik.submitForm();
                  }}
                  onBlur={activityTypeFromik.handleBlur("activityType")} >
                  <option value="">Select activity type</option>
                  <option value="tourist_attraction">Tourist attraction</option>
                  <option value="park">Park</option>
                  <option value="amusement_park">Amusement park</option>
                  <option value="museum">Museum</option>
                  <option value="aquarium">Aquarium</option>
                  <option value="zoo">Zoo</option>
                  <option value="library">Library</option>
                  <option value="cemetery">Cemetery</option>
                  <option value="church">Church</option>
                  <option value="mosque">Mosque</option>
                  <option value="hindu_temple">Hindu temple</option>
                  <option value="movie_theater">Movie theater</option>
                  <option value="night_club">Night club</option>
                  <option value="bar">Bar</option>
                  <option value="taxi_stand">Taxi stand</option>
                  <option value="bus_station">Bus station</option>
                </select>
                {activityTypeFromik.touched.activityType && activityTypeFromik.errors.activityType ? <p className="errors-msg">{activityTypeFromik.errors.activityType}</p> : null}
              </div>
              {/* <button type="submit" className="btn btn-primary-color border-radius-10 btn-height-58"><i class="bi bi-search"></i></button> */}
            </div>
          </form>
          <div className="row">
            {isLoading ? <LoadingSpinnerTwo /> : getActivityData.length === 0 ? <p className="text-center">No data found.</p> : null}
            {getActivityData && getActivityData.length > 0 ? getActivityData.map((item, i) => (
              <div className="col-lg-6 mt-3" key={i}>
                <div className="srws-item-lg">
                  <input type="radio" className="srws-item-radio" onChange={activityFormik.handleChange} onBlur={activityFormik.handleBlur} name="activityDetails" value={JSON.stringify(item)} id={`srws${i}`} />
                  <div className="srws-item">
                    <div className="srws-item-img">
                      {item.photos === undefined || item.photos === null || item.photos === "" ? <img src={`${noPictureAvailable}`} alt="" /> : <img src={`${photoUrl}maxwidth=400&photoreference=${item.photos[0].photo_reference}${apikey}`} alt="" />}
                    </div>
                    <div className="srws-item-info">
                      <div className="srws-item-head">
                        <h5>{item.name}</h5>
                        <span className="srws-item-rating"><i className="bi bi-star-fill" aria-hidden="true"></i> {item.rating} </span>
                      </div>
                      <p>{item.formatted_address}</p>
                    </div>
                  </div>
                </div>
              </div>
            )) : ""}
          </div>
        </Modal.Body>
        <Modal.Footer className="pt-1 pb-1 ci-iti-modal-footer">
          <div className="food-footer-lt m-0 mt-2 mb-2">
            <div className="input-group cost-item-field">
              <span className="input-group-text">Cost</span>
              <span className="input-group-text">{currencyData ? currencyData?.currency : null}</span>
              <input type="number" min="1" className="form-control" onChange={activityFormik.handleChange} onBlur={activityFormik.handleBlur} name="amount" value={activityFormik.values.amount} />
            </div>
            <div className="input-group cost-item-field mt-2">
              <span className="input-group-text">Time</span>
              <TimePicker onChange={(value) => activityFormik.setFieldValue("time", timeFromInt(value))}
                onBlur={activityFormik.handleBlur("time")}
                value={activityFormik.values.time}
                format={24}
                step={15}
              />
            </div>
          </div>
          <div className="food-footer-rt m-0 mb-2">
            <ul className="errors-ul">
              {activityFormik.touched.activityDetails && activityFormik.errors.activityDetails ? (<li style={{ color: "red" }}>{activityFormik.errors.activityDetails}</li>) : null}
              {activityFormik.touched.time && activityFormik.errors.time ? (<li style={{ color: "red" }}>{activityFormik.errors.time}</li>) : null}
            </ul>
            <div className="text-end mt-2">
              <button type="button" onClick={handleActivityModalClose} className="btn btn-secondary-color btn-border-color" >Close</button>
              <button type="button" disabled={isLoadingSubmit === true} onClick={(e) => activityFormik.handleSubmit()} className="btn btn-primary-color btn-border-color ms-3">{isLoadingSubmit ? "Submitting..." : "Submit"}</button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      {/* Add Other Modal */}
      <Modal
        show={showOtherModal}
        onHide={handleOtherModalClose}
        keyboard={false}
        size="md"
        className="add-travellers-modal"
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Other</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={otherFromik.handleSubmit}>
            <div className="row">
              <div className="col-md-12 mb-3">
                <div className="ci-other-img-field d-flex align-items-center">
                  <div className="ci-other-img-block">
                    {otherAvatar ? (
                      <img src={otherAvatar} alt="" />
                    ) : (
                      <img src={noPictureAvailable} alt="" />
                    )}
                  </div>
                  <div className="">
                    <label className="form-label d-block mb-1">
                      Upload new avatar
                    </label>
                    <div className="ci-other-upload-btn">
                      Choose file...
                      <input
                        onChange={selectAvatarImg}
                        onClick={(event) => {
                          event.target.value = null;
                        }}
                        accept="image/x-png,image/gif,image/jpeg"
                        type="file"
                        name="file"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 mb-3">
                <div className="d-col-input-field">
                  <input
                    className="form-control"
                    onChange={otherFromik.handleChange}
                    onBlur={otherFromik.handleBlur}
                    name="name"
                    type="text"
                    value={otherFromik.values.name}
                    placeholder="Enter title"
                  />
                  {otherFromik.touched.name && otherFromik.errors.name ? (
                    <p className="errors-msg">
                      {otherFromik.errors.name}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="col-md-12 mb-3">
                <div className="d-col-input-field">
                  <Autocomplete
                    apiKey={`${googleApiKey}`}
                    options={{ types: ['geocode', 'establishment'] }}
                    onPlaceSelected={(place) => {
                      otherFromik.setFieldValue(
                        "address",
                        place.formatted_address
                      );
                      setOthersLatLng({
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng()
                      });
                    }}
                    value={otherFromik.values.address}
                    name="address"
                    onChange={otherFromik.handleChange}
                    onBlur={otherFromik.handleBlur}
                    className="form-control"
                  />
                  {otherFromik.touched.address && otherFromik.errors.address ? (
                    <p className="errors-msg">
                      {otherFromik.errors.address}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="col-md-12 mb-3">
                <div className="d-col-input-field">
                  <textarea
                    className="form-control"
                    onChange={otherFromik.handleChange}
                    onBlur={otherFromik.handleBlur}
                    name="description"
                    value={otherFromik.values.description}
                    placeholder="Enter description"
                    rows={2}
                  ></textarea>
                  {/* {otherFromik.touched.description &&
                    otherFromik.errors.description ? (
                    <p className="errors-msg">
                      {otherFromik.errors.description}
                    </p>
                  ) : null} */}
                </div>
              </div>
              <div className="col-md-12 mb-3">
                <div className="input-group d-col-input-field">
                  <span className="input-group-text">Cost</span>
                  <span className="input-group-text">
                    {currencyData ? currencyData?.currency : null}
                  </span>
                  <input
                    className="form-control"
                    type="number"
                    min="1"
                    onChange={otherFromik.handleChange}
                    onBlur={otherFromik.handleBlur}
                    name="amount"
                    value={otherFromik.values.amount}
                  />
                </div>
                {otherFromik.touched.amount && otherFromik.errors.amount ? (
                  <p className="errors-msg">
                    {otherFromik.errors.amount}
                  </p>
                ) : null}
              </div>
              <div className="col-md-12">
                <div className="input-group d-col-input-field">
                  <span className="input-group-text">Time</span>
                  <TimePicker
                    onChange={(value) =>
                      otherFromik.setFieldValue("time", timeFromInt(value))
                    }
                    onBlur={otherFromik.handleBlur("time")}
                    value={otherFromik.values.time}
                    format={24}
                    step={15}
                  />
                </div>
                {otherFromik.touched.time && otherFromik.errors.time ? (
                  <p className="errors-msg">
                    {otherFromik.errors.time}
                  </p>
                ) : null}
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="pt-2 pb-2">
          <button
            type="button"
            onClick={handleOtherModalClose}
            className="btn btn-secondary-color btn-border-color me-0"
          >
            Close
          </button>
          <button
            type="button"
            onClick={(e) => otherFromik.handleSubmit()}
            className="btn btn-primary-color btn-border-color ms-3"
            disabled={isLoadingSubmit === true}
          >
            {isLoadingSubmit ? "Submitting..." : "Submit"}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateItinerary;
