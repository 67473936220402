import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./Error.scss";

const Error = () => {
  const history = useHistory();

  useEffect(()=>{
    // document.querySelector('header').remove();
    // document.querySelector('footer').remove();
    // document.querySelector('nav').remove();
    // const element = document.getElementById("sidebar");
    // element.remove();
  },[])
  return (
    <div className="error-page-container feed">
      <h1>404</h1>
      <h5>Page not found</h5>
      <p>The page you are searching for does not exist.</p>
      <button className="btn btn-primary-color" onClick={() => history.push("/")}>Back to Home</button>
    </div>
  );
};

export default Error;

