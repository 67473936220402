import './MyCabs.scss';
import React from 'react';

const MyCabs = () => {

    return ( 
        <>
        <div className="content-body">
            <h1 className="d-col-title mb-3">My Cabs</h1>
            <p>Coming soon</p>
        </div>
        </>
    );
}
 
export default MyCabs;