import './BackToTop.scss';
import React, { useState, useEffect } from "react";

export const BackToTop = () => {
    const [showTopBtn, setShowTopBtn] = useState(false);
    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 100) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });
    }, []);

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return showTopBtn && (
        <button type="button"
            className="backToTopBtn bi bi-arrow-up"
            onClick={goToTop}
        />
    )
}