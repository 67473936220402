import "./Signup.scss";
import React, {useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
// import signupBg from '../../assets/images/signup-bg.png';
// import PhoneInput from 'react-phone-input-2';
// import 'react-phone-input-2/lib/style.css';
import {LoginSocialGoogle, LoginSocialFacebook} from 'reactjs-social-login';
import {SignupService, SocialLoginService} from '../../../services/AuthService';
import Alert from 'react-bootstrap/Alert';
import {LoadingSpinnerOne} from "../../../loader/Loader";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAuth } from "../../../contexts/AuthProvider";

const Signup = () => {
    const {setAuthed} = useAuth();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);
    const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
    const [showMessage, setShowMessage] = useState("");
    const [visibleAlert, setVisibleAlert] = useState(false);
    const [visibleSignupBox, setVisibleSignupBox] = useState(true);
    const [visibleSignupMessage, setVisibleSignupMessage] = useState(false);
    const [userName, setUserName] = useState("");
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };
    const toggleConfirmPassword = () => {
        setConfirmPasswordShown(!confirmPasswordShown);
    };
    //const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            phone: "",
            password: "",
            confirmpassword: "",
            termsAndConditions: false,
        },
        validationSchema: Yup.object({
            name: Yup.string()
              .max(20, 'Name should not exceed 20 characters.')
              .required('Please enter name.'),
            email: Yup.string()
              .email('Invalid email address')
              .required('Please enter email address.'),
            //phone: Yup.string()
              //.matches(phoneRegExp, 'Phone number is not valid')
             // .required('Please enter phone number.'),
            password: Yup.string()
              .required('Please enter password.')
              .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                "Must contain 8 characters, one uppercase, one lowercase, one number and one special case character."
              ),
            confirmpassword: Yup.string()
                .required('Please enter confirm password.')
                .when("password", {
                    is: val => (val && val.length > 0 ? true : false),
                    then: Yup.string().oneOf(
                        [Yup.ref("password")],
                        "Both passwords need to be the same."
                    )
            }),
            termsAndConditions: Yup.bool().oneOf([true], 'You need to accept the terms and conditions.'),
        }),
        onSubmit: values => {  
            //console.log(values)
            delete values.confirmpassword;
            setUserName(values.name);
            SignupService(values, async (res) => {
                //console.log("sign up response: ", res);
                if (res.status === 404){
                  console.log(res.data.response.data.errors.msg);
                  return
                } 
                if(res.status === 200){
                  if(res.data.status === 201){
                    //toast.success(res.data.data.message);
                    setVisibleSignupBox(false);
                    setVisibleSignupMessage(true);
                    //localStorage.setItem("register_gw", JSON.stringify(obj));
                    formik.resetForm();
                    //history.push('/signin');
                  }else{
                    //toast.error(res.data.data.error.message);
                    setShowMessage(res.data.data.error.message);
                    setVisibleAlert(true);
                    setTimeout(() => {
                        setVisibleAlert(false);
                    }, 5000);
                  }
                }
            });
        }
    });

    return ( 
        <>
        {isLoading ? <LoadingSpinnerOne /> : null}
        <section className="signup-wrapper inner-mrt-80 padd-top-70 padd-bottom-70">
            <div className="container-lg">
                <div className="signin-row">
                    <div className="signin-col-block signin-img-block">
                        <h3>Welcome Back</h3>
                        <p>To keep connected with us please login with your personal info</p>
                        <Link to="/signin" className="btn btn-secondary-color frm-mrt-20">Sign In</Link>
                    </div>
                   {visibleSignupBox && <div className="signin-col-block signin-form-block">
                        {visibleAlert && <Alert variant="danger" onClose={() => setVisibleAlert(false)} dismissible>
                            <p>{showMessage}</p>
                        </Alert>}
                        <div className="signin-title-block">
                            <h1>Sign Up</h1>
                            <p>Signup with valid Email and Details</p>
                        </div>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="frm-mrt-20">
                                <div className="form-input-fields">
                                    <i className="frm-icon f-user-icon"></i>
                                    <div className="has-float-label">
                                        <input onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.name} name="name" className="form-control" type="text" id="Name" placeholder="Name" autoComplete="off"/>
                                        <label htmlFor="Name">Name</label>
                                    </div>
                                </div>
                                {formik.touched.name && formik.errors.name ? <p className="errors-msg">{formik.errors.name}</p> : null}
                            </div>
                            <div className="frm-mrt-20">
                                <div className="form-input-fields">
                                    <i className="frm-icon f-mail-icon"></i>
                                    <div className="has-float-label">
                                        <input onChange={formik.handleChange} onBlur={formik.handleBlur} name="email" value={formik.values.email} className="form-control" type="email" id="EmailAddress" placeholder="Email Address" autoComplete="off"/>
                                        <label htmlFor="EmailAddress">Email Address</label>
                                    </div>
                                </div>
                                {formik.touched.email && formik.errors.email ? <p className="errors-msg">{formik.errors.email}</p> : null}
                            </div>
                            {/* <div className="frm-mrt-20">
                                <div className="form-input-fields">
                                    <i className="frm-icon f-call-icon"></i>
                                    <div className="has-float-label">
                                        <label htmlFor="PhoneNumber">Phone Number</label>
                                        <PhoneInput
                                            country={'us'}
                                            onChange={e => formik.setFieldValue("phone", e)}
                                            onBlur={formik.handleBlur("phone")} 
                                            name="phone" 
                                            value={formik.values.phone}  
                                            id="PhoneNumber" 
                                            placeholder="Phone Number"
                                            containerClass="custom-phone-input"
                                        />
                                    </div>
                                </div>
                                {formik.touched.phone && formik.errors.phone ? <p className="errors-msg">{formik.errors.phone}</p> : null}
                            </div> */}
                            <div className="frm-mrt-20">
                                <div className="form-input-fields">
                                    <i className="frm-icon f-lock-icon"></i>
                                    <div className="has-float-label">
                                        <input onChange={formik.handleChange} onBlur={formik.handleBlur} name="password" value={formik.values.password} type={passwordShown ? "text" : "password"} className="form-control" id="Password" placeholder="Password" autoComplete="off"/>
                                        <label htmlFor="Password">Password</label>
                                    </div>
                                    <button type="button" onClick={togglePassword} className={passwordShown ? "bi eye-btn bi-eye" : "bi eye-btn bi-eye-slash"}></button>
                                </div>
                                {formik.touched.password && formik.errors.password ? <p className="errors-msg">{formik.errors.password}</p> : null}
                            </div>
                            <div className="frm-mrt-20">
                                <div className="form-input-fields">
                                    <i className="frm-icon f-lock-icon"></i>
                                    <div className="has-float-label">
                                    <input onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.confirmpassword} name="confirmpassword" type={confirmPasswordShown ? "text" : "password"} className="form-control" id="ConfirmPassword" placeholder="Confirm Password" autoComplete="off"/>
                                        <label htmlFor="ConfirmPassword">Confirm Password</label>
                                    </div>
                                    <button type="button" onClick={toggleConfirmPassword} className={confirmPasswordShown ? "bi eye-btn bi-eye" : "bi eye-btn bi-eye-slash"}></button>
                                </div>
                                {formik.touched.confirmpassword && formik.errors.confirmpassword ? <p className="errors-msg">{formik.errors.confirmpassword}</p> : null}
                            </div>
                            <div className="frm-mrt-20">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="termsAndConditions" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.termsAndConditions}/>
                                    <label className="form-check-label">
                                        I agree to <Link target="_blank" to="/terms-conditions">Terms & Conditions.</Link>
                                    </label>
                                </div>
                                {formik.touched.termsAndConditions && formik.errors.termsAndConditions ? <p className="errors-msg">{formik.errors.termsAndConditions}</p> : null}
                            </div>
                            <div className="signin-frm-btns frm-btn-mrt-30 frm-btn-mb-50">
                                <button type="submit" className="btn btn-border-color btn-primary-color">Sign Up</button>
                            </div>
                            <div className="or-divider"></div>
                            <div className="s-social-links">
                                <LoginSocialGoogle
                                    client_id={process.env.REACT_APP_GOOGLE_CLIENTID}
                                    scope="openid profile email"
                                    discoveryDocs="claims_supported"
                                    access_type="offline"
                                    onResolve={({ provider, data }) => {
                                        //console.log(provider, data);
                                        setIsLoading(true);
                                        let obj ={
                                            name: data.name,
                                            email: data.email,
                                            phone: "",
                                            fbid: "",
                                            googleid: data.sub,
                                            profileImage: data.picture
                                        }
                                        SocialLoginService(obj, async (res) => {
                                            if(res.status === 200){
                                                if(res.data.data.code === 400){
                                                    setShowMessage(res.data.data.message);
                                                    setVisibleAlert(true);
                                                    setIsLoading(false);
                                                }
                                                if(res.data.data.success === true){
                                                    let loginobj = res.data.data.data;
                                                    let loginToken = res.data.data.token;
                                                    localStorage.setItem("login_gw", JSON.stringify(loginobj));
                                                    localStorage.setItem("token_gw", JSON.stringify(loginToken));
                                                    setAuthed({isAuth: true, token: loginToken, user: loginobj});
                                                    setIsLoading(false);
                                                    setShowMessage("Login Successfully.");
                                                    history.push('/');
                                                }else{
                                                    setShowMessage(res.data.data.errors.msg);
                                                    setVisibleAlert(true);
                                                    setIsLoading(false);
                                                    setTimeout(() => {
                                                        setVisibleAlert(false);
                                                    }, 5000);
                                                }
                                                setShowMessage(res.data.data.errors.msg);
                                                setVisibleAlert(true);
                                                setIsLoading(false);
                                                setTimeout(() => {
                                                    setVisibleAlert(false);
                                                }, 5000);
                                            }
                                        });
                                    }}
                                    onReject={err => {
                                        console.log(err);
                                    }}
                                    >
                                    <button type="button" className="btn google-plus"></button>
                                </LoginSocialGoogle>
                                
                                <LoginSocialFacebook
                                    appId={process.env.REACT_APP_FACEBOOK_APPID}
                                    autoLoad={true}
                                    fields="id,name,email,picture"
                                    onResolve={(response) => {
                                        setIsLoading(true);
                                        //console.log(response);
                                        let obj ={
                                            name: response.data.name,
                                            email: response.data.email,
                                            phone: "",
                                            fbid: response.data.id,
                                            googleid: "",
                                            profileImage: response.data.picture.data.url
                                        }
                                        SocialLoginService(obj, async (res) => {
                                            if(res.status === 200){
                                                if(res.data.data.code === 400){
                                                    setShowMessage(res.data.data.message);
                                                    setVisibleAlert(true);
                                                    setIsLoading(false);
                                                }
                                                if(res.data.data.success === true){
                                                    let loginobj = res.data.data.data;
                                                    let loginToken = res.data.data.token;
                                                    localStorage.setItem("login_gw", JSON.stringify(loginobj));
                                                    localStorage.setItem("token_gw", JSON.stringify(loginToken));
                                                    setAuthed({isAuth: true, token: loginToken, user: loginobj});
                                                    setIsLoading(false);
                                                    setShowMessage("Login Successfully.");
                                                    history.push('/');
                                                }else{
                                                    setShowMessage(res.data.data.errors.msg);
                                                    setVisibleAlert(true);
                                                    setIsLoading(false);
                                                    setTimeout(() => {
                                                        setVisibleAlert(false);
                                                    }, 5000);
                                                }
                                                setShowMessage(res.data.data.errors.msg);
                                                setVisibleAlert(true);
                                                setIsLoading(false);
                                                setTimeout(() => {
                                                    setVisibleAlert(false);
                                                }, 5000);
                                            }
                                        });
                                    }}
                                    onReject={err => {
                                        console.log(err);
                                    }}
                                    >
                                    <button type="button" className="btn facebook"></button>
                                </LoginSocialFacebook>
                            </div>
                        </form>
                    </div>} 
                    {visibleSignupMessage && <div className="signin-col-block signin-form-block">
                        <div className="signup-message-block">
                            <i className="bi bi-envelope-open"></i>
                            <p>Hello {userName}, <br/> Please check your email and verify your account to proceed.</p>
                        </div>
                    </div>}
                </div>
            </div>
        </section>
        </>
    );
}
 
export default Signup;