import React, {useState, useEffect} from 'react';
import moment from 'moment';
import { Page, Text, View, Image, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import logo from '../../../../assets/images/logo.png';
import locationIcon from '../../../../assets/images/pin-icon.png';
import callIcon from '../../../../assets/images/f-call-icon.png';
import emailIcon from '../../../../assets/images/f-mail-icon.png';
import websiteIcon from '../../../../assets/images/www-icon.png';

const styles = StyleSheet.create({
    page: {
        paddingLeft:40,
        paddingRight: 40,
        paddingTop:140,
        paddingBottom: 40
    },
    section: {
        flexGrow: 1
    },
    // footer: {
    //     position: "absolute",
    //     fontSize: 8,
    //     bottom: 10,
    //     left: 0,
    //     right: 0,
    //     paddingTop: 10,
    //     borderTop:"1px solid #ced4da",
    //     textAlign: "center",
    //     color: "#555"
    // },
    header:{
        position: "absolute",
        fontSize: 8,
        top: 0,
        left: 0,
        right: 0,
        textAlign: "center",
        color: "#555",
        display: "flex",
        flexDirection:'column',
        justifyContent:"center",
        alignItems:"center",
        paddingTop: 5,
    },
    headerlogo:{
        width: 50,
        height: 50,
        objectFit: "contain",
        marginTop:10
    },
    icon:{
        objectFit: "center",
        width: 12
    },
    ttext9:{
        fontSize: 9,
        color: "#777",
        marginLeft: 5
    },
    topinforow:{
        backgroundColor: "#E2F1FA",
        padding: 8,
        marginTop: 8,
        display: "flex",
        flexDirection:'row',
        justifyContent:"center",
        alignItems: "center",
        width: "100%"
    },
    topinfocol:{
        display: "flex",
        flexDirection:'row',
        alignItems: "center",
        marginLeft: 8,
        marginRight: 8,
    },
    headertitle:{
        fontSize: 12,
        color: "#333",
    },
    box1:{
        display: "flex",
        flexDirection:'row',
        justifyContent:"space-between",
        width: "100%"
    },
    alignmiddlecenter:{
        alignItems: "center",
    },
    alignbottomend:{
        alignItems: "flex-end",
    },
    itemrow:{
        display: "flex",
        flexDirection:'row',
    },
    width70:{
        width: "70%"
    },
    width60:{
        width: "60%"
    },
    width50:{
        width: "50%"
    },
    width40:{
        width: "40%"
    },
    width30:{
        width: "30%"
    },
    width20:{
        width: "20%"
    },
    text10:{
        fontSize: 10,
        color: "#333",
        marginTop: 4,
        marginBottom: 4
    },
    textright:{
       textAlign: "right"
    },
    textleft:{
        textAlign: "left"
     },
    fontbold:{
        fontWeight: "bold"
    },
    text12:{
        fontSize: 12,
        color: "#000",
    },
    text16:{
        fontSize: 18,
        color: "#000",
        textTransform: "uppercase",
    },
    margintop5:{
        marginTop: 10
    },
    margintop30:{
        marginTop: 30
    },
    marginbottom10:{
        marginBottom: 10
    },
    margintopbottom30:{
        marginTop: 40,
        marginBottom: 40,
    },
    descriptiotable:{
        border:"1px solid #ced4da",
        borderRadius: "3px"
    },
    tableflex:{
        display: "flex",
        flexDirection:'row',
        padding: "7px 14px",
        justifyContent:"space-between",
    },
    tabletd:{
        backgroundColor: "#ffffff",
    },
    tableth:{
        backgroundColor: "#E2F1FA",
    },
    tabletotal:{
        backgroundColor: "rgba(10, 102, 194, 0.2)",
    }
    
});

const PaymentInvoice = (props) => {
    const [bookedDetails, setBookedDetails] = useState();

    useEffect(() => {
        setBookedDetails(props.bookedData);
    }, [props]);

    return ( 
        <>
        <Document author="" title="Invoice">
            <Page size="LETTER" style={styles.page} wrap>
                <View style={styles.header} wrap={false} fixed>
                    <Image style={styles.headerlogo} source={{uri: logo}}/>
                    <View style={styles.topinforow}>
                        <View style={styles.topinfocol}>
                            <Image style={[styles.icon, styles.icon1]} source={{uri: locationIcon}}/> 
                            <Text style={styles.ttext9}>75 Peninsula Drive</Text>
                        </View>
                        <View style={styles.topinfocol}>
                            <Image style={[styles.icon, styles.icon2]} source={{uri: emailIcon}}/>
                            <Text style={styles.ttext9}>info@gladiatours.com</Text>
                        </View>
                        <View style={styles.topinfocol}>
                            <Image style={[styles.icon, styles.icon3]} source={{uri: callIcon}}/> 
                            <Text style={styles.ttext9}>123456789</Text>
                        </View>
                        <View style={styles.topinfocol}>
                            <Image style={[styles.icon, styles.icon4]} source={{uri: websiteIcon}}/>
                            <Text style={styles.ttext9}>www.gladiatours.com</Text>
                        </View>
                    </View>
                </View>
                <View>
                    <Text style={[styles.headertitle, styles.marginbottom10]}>Paid By</Text>
                    <View style={[styles.box1, styles.alignmiddlecenter]}>
                        <View style={styles.width60}>
                            <Text style={[styles.text10]}>{bookedDetails?.customerName ? bookedDetails?.customerName : "N/A"}</Text>
                            <Text style={[styles.text10]}>{bookedDetails?.customerEmail ? bookedDetails?.customerEmail : "N/A"}</Text>
                            <Text style={[styles.text10]}>{bookedDetails?.customerPhone ? "+" + bookedDetails?.customerPhone: "N/A"}</Text>
                        </View>
                        <View style={styles.width40}>
                            <Text style={[styles.text16, styles.textright]}>Receipt</Text>
                        </View>
                    </View>
                </View>
                <View style={styles.margintop30}>
                    <Text style={[styles.headertitle, styles.marginbottom10]}>Booking Details</Text>
                    <View style={[styles.box1, styles.alignbottomend]}>
                        <View style={styles.width60}>
                            <View style={styles.itemrow}>
                                <Text style={[styles.itemcol, styles.width30, styles.text10]}>Check-in</Text>
                                <Text style={[styles.itemcol, styles.width60, styles.text10, styles.fontbold]}>{bookedDetails?.roomBookDetails?.checkIn ? moment(bookedDetails?.roomBookDetails?.checkIn).format("dddd, MMMM Do YYYY"): "N/A"}</Text>
                            </View>
                            <View style={styles.itemrow}>
                                <Text style={[styles.itemcol, styles.width30, styles.text10]}>Check-out</Text>
                                <Text style={[styles.itemcol, styles.width60, styles.text10, styles.fontbold]}>{bookedDetails?.roomBookDetails?.checkOut ? moment(bookedDetails?.roomBookDetails?.checkOut).format("dddd, MMMM Do YYYY"): "N/A"}</Text>
                            </View>
                            <View style={styles.itemrow}>
                                <Text style={[styles.itemcol, styles.width30, styles.text10]}>Hotel Name</Text>
                                <Text style={[styles.itemcol, styles.width60, styles.text10]}>{bookedDetails?.roomBookDetails?.hotelName ? bookedDetails?.roomBookDetails?.hotelName : "N/A"}</Text>
                            </View>
                            <View style={styles.itemrow}>
                                <Text style={[styles.itemcol, styles.width30, styles.text10]}>Hotel Address</Text>
                                <Text style={[styles.itemcol, styles.width60, styles.text10]}>{bookedDetails?.roomBookDetails?.address ? bookedDetails?.roomBookDetails?.address : "N/A"}</Text>
                            </View>
                        </View>
                        <View style={styles.width40}>
                            <View style={styles.itemrow}>
                                <Text style={[styles.itemcol, styles.width50, styles.text10, styles.textright]}>Booking Reference #</Text>
                                <Text style={[styles.itemcol, styles.width50, styles.text10, styles.textright]}>{bookedDetails?.referenceNum ? bookedDetails?.referenceNum : "N/A"}</Text>
                            </View>
                            <View style={styles.itemrow}>
                                <Text style={[styles.itemcol, styles.width50, styles.text10, styles.textright]}>Booking Confirmation #</Text>
                                <Text style={[styles.itemcol, styles.width50, styles.text10, styles.textright]}>{bookedDetails?.supplierConfirmationNum ? bookedDetails?.supplierConfirmationNum : "N/A"}</Text>
                            </View>
                            <View style={styles.itemrow}>
                                <Text style={[styles.itemcol, styles.width50, styles.text10, styles.textright]}>Booking Date #</Text>
                                <Text style={[styles.itemcol, styles.width50, styles.text10, styles.textright]}>{bookedDetails?.roomBookDetails?.bookingDateTime ? moment(bookedDetails?.roomBookDetails?.bookingDateTime).format("MMMM Do YYYY") : "N/A"}</Text>
                            </View>
                        </View>
                    </View>
                </View>
                <View style={[styles.margintopbottom30, styles.descriptiotable]}>
                    <View style={[styles.tableth, styles.tableflex]}>
                        <Text style={[styles.text10, styles.width50, styles.fontbold]}>Description</Text>
                        <Text style={[styles.text10, styles.width20, styles.fontbold]}>Guest</Text>
                        <Text style={[styles.text10, styles.width30, styles.fontbold, styles.textright]}>Amount</Text>
                    </View>
                    {bookedDetails?.roomBookDetails?.rooms?.map((item, i)=>{
                        return <>
                            <View style={[styles.tabletd, styles.tableflex]}>
                                <Text style={[styles.text10, styles.width50]}>{item?.description}</Text>
                                <Text style={[styles.text10, styles.width20, styles.fontbold]}>{item?.paxDetails?.name?.length}</Text>
                                <Text style={[styles.text10, styles.width30, styles.textright]}>{bookedDetails?.roomBookDetails?.NetPrice ? bookedDetails?.roomBookDetails?.NetPrice +" "+ bookedDetails?.roomBookDetails?.currency : "N/A"}</Text>
                            </View>
                        </>
                    })}
                    <View style={[styles.tabletotal, styles.tableflex]}>
                        <Text style={[styles.text10, styles.width70, styles.textright]}>Total</Text>
                        <Text style={[styles.text10, styles.width30, styles.textright]}>{bookedDetails?.roomBookDetails?.NetPrice ? bookedDetails?.roomBookDetails?.NetPrice +" "+ bookedDetails?.roomBookDetails?.currency : "N/A"}</Text>
                    </View>
                </View>
                <View>
                    <Text style={[styles.text12, styles.fontbold]}>Notes</Text>
                    <Text style={[styles.text10, styles.margintop5]}>Thank you for staying with us. We look forward to your next visit.</Text>
                </View>
            </Page>
        </Document>
        </>
    );
}
 
export default PaymentInvoice;