import './PaymentSuccessful.scss';
import React, {useState, useEffect} from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import {PDFDownloadLink } from '@react-pdf/renderer';
import {getBookedHotelDataApi} from '../../../../services/StayService';
import {LoadingSpinnerOne} from "../../../../loader/Loader";
import InvoiceDocument from '../payment-invoice/PaymentInvoice';
import {toast} from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
toast.configure();

const PaymentSuccessful = () => {
    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [bookedDetails, setBookedDetails] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [bookingCancelModal, setBookingCancelModal] = useState(false);

    const hotelBookedData = async () => {
        setIsLoading(true);
        const Obj = {
            supplierConfirmationNum: searchParams.get("supplierConfirmationNum"),
            referenceNum: searchParams.get("referenceNum")
        }
        await getBookedHotelDataApi(Obj, async (res) => {
            // console.log("booked hotel response: ", res.data.data);
            if (res.status === 404) {
                console.log('Something went wrong')
            }
            if (res.status === 200) {
                // console.log(res.data.data.message);
                // console.log(res.data.data.data);
                await setBookedDetails(res.data.data.data.data);
                setIsLoading(false);
            }
        });
    }

    const handleCancelConfirm =()=> {
        history.push({
            pathname:'/payment-cancelled/',
            search:(`supplierConfirmationNum=${searchParams.get("supplierConfirmationNum")}&&referenceNum=${searchParams.get("referenceNum")}`),
        });
    }

    useEffect(() => {
        hotelBookedData();
    }, []);

    return ( 
        <>
        <section className="inner-mrt-80 padd-top-70 padd-bottom-70">
            {isLoading ? <LoadingSpinnerOne /> : null}
            <div className="container-lg">
                <div className="cmn-card-info-block w-100">
                    <div className="cmn-card-info-content payment-successful text-center">
                        <i className="bi bi-check-circle-fill"></i>
                        <h3>Your hotel booking has been Successfully completed.</h3>
                        <p>Thank you for your payment. An automated payment receipt will be sent to your registerd email.</p>
                    </div>
                    <div className="mt-4 ps-item-row">
                        <div className="ps-item-col">
                            <i className="fa fa-bed"></i>
                            <div className="ps-item-info">
                                <span>Booking reference</span>
                                <p>{bookedDetails?.referenceNum ? bookedDetails?.referenceNum : "N/A"}</p>
                            </div>
                        </div>
                        <div className="ps-item-col">
                            <i className="fa fa-envelope"></i>
                            <div className="ps-item-info">
                                <span>Confirmation sent to</span>
                                <p>{bookedDetails?.customerEmail ? bookedDetails?.customerEmail : "N/A"}</p>
                            </div>
                        </div>
                        <div className="ps-item-col">
                            <i className="bi bi-bank2"></i>
                            <div className="ps-item-info">
                                <span>Payment</span>
                                <p>{bookedDetails?.roomBookDetails?.NetPrice ? bookedDetails?.roomBookDetails?.NetPrice +" "+ bookedDetails?.roomBookDetails?.currency : "N/A"}</p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4 ps-block">
                        <h4 className="ps-block-title">Booking details</h4>
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Phone</th>
                                        <th scope="col">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{bookedDetails?.customerName ? bookedDetails?.customerName : "N/A"}</td>
                                        <td>{bookedDetails?.customerEmail ? bookedDetails?.customerEmail : "N/A"}</td>
                                        <td>{bookedDetails?.customerPhone ? "+"+bookedDetails?.customerPhone: "N/A"}</td>
                                        <td>{bookedDetails?.status ? <span className="badge bg-success text-white">{bookedDetails?.status}</span> : "N/A"}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mt-3 ps-block">
                            <h4 className="ps-block-title">Hotel details</h4>
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <th scope="col">Name</th>
                                            <td>{bookedDetails?.roomBookDetails?.hotelName ? bookedDetails?.roomBookDetails?.hotelName : "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th scope="col">Address</th>
                                            <td>{bookedDetails?.roomBookDetails?.address ? bookedDetails?.roomBookDetails?.address: "N/A"}</td>
                                        </tr>
                                        {/* <tr>
                                            <th scope="col">Email</th>
                                            <td>{bookedDetails?.roomBookDetails?.email ? bookedDetails?.roomBookDetails?.email : "N/A"}</td>
                                        </tr> */}
                                        <tr>
                                            <th scope="col">Phone</th>
                                            <td dangerouslySetInnerHTML={{__html: bookedDetails?.roomBookDetails?.phone ? bookedDetails?.roomBookDetails?.phone : "N/A"}}>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="col" className="text-nowrap">Booking date</th>
                                            <td>{bookedDetails?.roomBookDetails?.bookingDateTime ? moment(bookedDetails?.roomBookDetails?.bookingDateTime).format("dddd, MMMM Do YYYY") : "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th scope="col">Check-in</th>
                                            <td>{bookedDetails?.roomBookDetails?.checkIn ? moment(bookedDetails?.roomBookDetails?.checkIn).format("dddd, MMMM Do YYYY"): "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th scope="col">Check-out</th>
                                            <td>{bookedDetails?.roomBookDetails?.checkOut ? moment(bookedDetails?.roomBookDetails?.checkOut).format("dddd, MMMM Do YYYY"): "N/A"}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-md-6 mt-3 ps-block">
                            <h4 className="ps-block-title">Room details</h4>
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <tbody>
                                        {bookedDetails?.roomBookDetails?.rooms?.map((item, i)=>{
                                            return<>
                                            {/* <tr>
                                                <th scope="col">Room type</th>
                                                <td>{item.boardType? item.boardType : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <td dangerouslySetInnerHTML={{__html: item.name ? item.name : "N/A"}}></td>
                                            </tr> */}
                                            <tr className="bg-light">
                                                <th scope="col">Room No.</th>
                                                <td>{i+1}</td>
                                            </tr>
                                            <tr>
                                                <th scope="col">Description</th>
                                                <td dangerouslySetInnerHTML={{__html: item.description ? item.description : "N/A"}}></td>
                                            </tr>
                                            <tr>
                                                <th scope="col">Guest</th>
                                                <td>
                                                    {item?.paxDetails?.name?.length}
                                                    {/* <ul>
                                                        {item?.paxDetails?.name?.map((guestName, g)=>{
                                                            return<><li key={g}>{guestName}</li></>
                                                        })}
                                                    </ul> */}
                                                </td>
                                            </tr>
                                            </>
                                        })}
                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <hr style={{backgroundColor: "#e2f1fa"}}/>
                    <div className="text-end">
                        <button type="button" onClick={()=> setBookingCancelModal(true)} className="btn btn-border-color btn-secondary-color">Cancel Booking</button>
                        {/* <Link to="/" className="btn btn-primary-color"><i className="bi bi-arrow-left-short"></i> Home</Link> */}
                        <PDFDownloadLink  className="btn btn-primary-color ms-3" document={<InvoiceDocument bookedData={bookedDetails} />} fileName={"Invoice" + new Date().getTime() + ".pdf"}>
                        {({ blob, url, loading, error }) => ('Download invoice')}
                        </PDFDownloadLink>
                    </div>
                </div>
            </div>
        </section>
        <Modal
            show={bookingCancelModal}
            keyboard={false}
            size="sm"
            className="add-travellers-modal"
            centered
        >
            <Modal.Body>
                <div className="text-center">
                    <h6>Are you sure?</h6>
                    <p className="mt-1">You want to cancel this booking.</p>
                </div>
                <div className="justify-content-center d-flex mt-3">
                    <button type="button" onClick={handleCancelConfirm} className="btn btn-primary-color btn-border-color me-2 text-size-14">Yes</button>
                    <button type="button" onClick={()=> setBookingCancelModal(false)} className="btn text-size-14 btn-secondary-color btn-border-color">No</button>
                </div>
            </Modal.Body>
        </Modal>
        </>
    );
}
 
export default PaymentSuccessful;