import './ManageTravelers.scss';
import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import DatePicker from 'react-datepicker';
import BlankImg from '../../../assets/images/no-picture-available.jpg';
import {LoadingSpinnerTwo} from "../../../loader/Loader";
import { 
addManageTravelService, 
getManageTravelService, 
editTravellerService,
deleteTravellerService,
activeDeactiveTravelService } from '../../../services/AdminService';
import { imageUrlTravellers } from '../../../services/CommonUrl';
import { useAuth } from '../../../contexts/AuthProvider';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

const ManageTravelers = () => {
    const {authed} = useAuth();
    const [showAddTraveller, setShowAddTraveller] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingTraveler, setIsLoadingTraveler] = useState(false);
    
    const addTravellerClose = () => {
        setShowAddTraveller(false);
        setCurrentPFImg(null);
        setCurrentPBImg(null);
        setCurrentSFImg(null);
        setCurrentSBImg(null);
        setIsLoading(false);
        formik.resetForm(); 
    }
    const addTravellerShow = () =>{
        setShowAddTraveller(true);
        formik.resetForm();  
    } 
    const [showEditTraveller, setShowEditTraveller] = useState(false);
    const editTravellerClose = () =>{
        setShowEditTraveller(false);
        setCurrentEPFImg(null);
        setCurrentEPBImg(null);
        setCurrentESFImg(null);
        setCurrentESBImg(null);
        setIsLoading(false);
        editTravelFormik.resetForm();  
    } 
    const [showViewTraveller, setShowViewTraveller] = useState(false);
    const viewTravellerClose = () => setShowViewTraveller(false);
    const [showDeleteTraveller, setShowDeleteTraveller] = useState(false);
    const deleteTravellerClose = () => setShowDeleteTraveller(false);

    // For Add Image
    const [getPassportFrontImage, setPassportFrontImage] = useState("");
    const [getPassportBackImage, setPassportBackImage] = useState("");
    const [getSocialIdFrontImage, setSocialIdFrontImage] = useState("");
    const [getSocialIdBackImage, setSocialIdBackImage] = useState("");

    const [getCurrentPFImg, setCurrentPFImg] = useState("");
    const [getCurrentPBImg, setCurrentPBImg] = useState("");
    const [getCurrentSFImg, setCurrentSFImg] = useState("");
    const [getCurrentSBImg, setCurrentSBImg] = useState("");
    // For Edit Image
    const [getEPFrontImage, setEPFrontImage] = useState("");
    const [getEPBackImage, setEPBackImage] = useState("");
    const [getESFrontImage, setESFrontImage] = useState("");
    const [getESBackImage, setESBackImage] = useState("");

    const [getCurrentEPFImg, setCurrentEPFImg] = useState("");
    const [getCurrentEPBImg, setCurrentEPBImg] = useState("");
    const [getCurrentESFImg, setCurrentESFImg] = useState("");
    const [getCurrentESBImg, setCurrentESBImg] = useState("");

    const [getManageTravellers, setGetManageTravellers] = useState();
 
    const [getDeleteData, setGetDeleteData] = useState();
    const [getViewData, setGetViewData] = useState();

    const formik = useFormik({
        initialValues: {
            title: "", 
            first_name: "", 
            last_name: "", 
            gender: "", 
            dob: "", 
            passport_no: "",  
            socialId_no: "",
        },
        validationSchema: Yup.object({
            title: Yup.string()
            .required('Please select title.'),
            first_name: Yup.string()
            .required('Please enter first name.'),
            last_name: Yup.string()
            .required('Please enter last name.'),
            gender: Yup.string()
            .required('Please select gender.'),
            dob: Yup.date()
            .nullable()
            .transform((curr, orig) => orig === '' ? null : curr)
            .required('Please enter date of birth.'),
            // passport_no: Yup.string()
            // .required('Please enter passport No.'),
            // socialId_no: Yup.string()
            // .required('Please enter social No.'),
        }),
        onSubmit: values => {  
            //console.log('Add value:', values);
            setIsLoading(true);
            let formData = new FormData();
            formData.append("userId", authed?.user?._id);
            formData.append("title", values.title);
            formData.append("first_name", values.first_name);
            formData.append("last_name", values.last_name);
            formData.append("gender", values.gender);
            formData.append("dob", moment(new Date(values.dob)).format("DD MMMM YYYY"));
            formData.append("passport_no", values.passport_no);
            formData.append("socialId_no", values.socialId_no);
            formData.append("passport_front_image", getPassportFrontImage);
            formData.append("passport_back_image", getPassportBackImage);
            formData.append("socialId_front_image", getSocialIdFrontImage);
            formData.append("socialId_back_image", getSocialIdBackImage);
            addManageTravelService(formData, async (res) => {
                if (res.status === 404) {
                    console.log("Error 404");
                    return;
                }
                if (res.status === 200) {
                    //console.log("API 1 response: ", res.data.data);
                    await getTravellerList();
                    await setShowAddTraveller(false);
                    await setCurrentPFImg("");
                    await setCurrentPBImg("");
                    await setCurrentSFImg("");
                    await setCurrentSBImg("");
                    await setPassportFrontImage("");
                    await setPassportBackImage("");
                    await setSocialIdFrontImage("");
                    await setSocialIdBackImage("");
                    setIsLoading(false);
                    formik.resetForm();  
                }
            });
        }
    });

    const selectPassportFrontImage = async (event) => {
        if (event["target"].files.length > 0) {
          const PassportFrontImagefile = event["target"].files[0];
          setPassportFrontImage(PassportFrontImagefile);
          const reader = new FileReader();
          reader.readAsDataURL(PassportFrontImagefile);
          reader.onload = (event) => {
            setCurrentPFImg(reader.result);
          };
        }
    };
    const selectPassportBackImage = async (event) => {
        if (event["target"].files.length > 0) {
          const PassportBackImagefile = event["target"].files[0];
          setPassportBackImage(PassportBackImagefile);
          const reader = new FileReader();
          reader.readAsDataURL(PassportBackImagefile);
          reader.onload = (event) => {
            setCurrentPBImg(reader.result);
          };
        }
    };
    const selectSocialIdFrontImage = async (event) => {
        if (event["target"].files.length > 0) {
          const SocialFrontImgfile = event["target"].files[0];
          setSocialIdFrontImage(SocialFrontImgfile);
          const reader = new FileReader();
          reader.readAsDataURL(SocialFrontImgfile);
          reader.onload = (event) => {
            setCurrentSFImg(reader.result);
          };
        }
    };
    const selectSocialIdBackImage = async (event) => {
        if (event["target"].files.length > 0) {
          const SocialBackImgfile = event["target"].files[0];
          setSocialIdBackImage(SocialBackImgfile);
          const reader = new FileReader();
          reader.readAsDataURL(SocialBackImgfile);
          reader.onload = (event) => {
            setCurrentSBImg(reader.result);
          };
        }
    };
    
    const getTravellerList = async ()=>{
        setIsLoadingTraveler(true);
        await getManageTravelService({userId: authed?.user?._id}, async (res) => {
            if (res.status === 404) {
                console.log("Error 404");
              return;
            }
            if (res.status === 200) {
            //   console.log("API Get traveller response: ", res.data.data);
              await setGetManageTravellers(res.data.data.data)
              setIsLoadingTraveler(false);
            }
        });
    }
    const editFormData = Object.freeze({
        userId: "", 
        title: "", 
        first_name: "", 
        last_name: "", 
        gender: "", 
        dob: "", 
        passport_no: "", 
        passport_front_image: "", 
        passport_back_image: "", 
        socialId_no: "",
        socialId_front_image: "", 
        socialId_back_image: ""
    });
    const [userEditData, setUserEditData] = useState(editFormData);
    const initialData = {
        userId: userEditData._id,
        title: userEditData.title, 
        first_name: userEditData.first_name, 
        last_name: userEditData.last_name, 
        gender: userEditData.gender, 
        dob: userEditData.dob, 
        passport_no: userEditData.passport_no, 
        socialId_no: userEditData.socialId_no,

    }
    const editTravelFormik = useFormik({
        initialValues: initialData,
        enableReinitialize: true,
        validationSchema: Yup.object({
            title: Yup.string()
            .required('Please select title.'),
            first_name: Yup.string()
            .required('Please enter first name.'),
            last_name: Yup.string()
            .required('Please enter last name.'),
            gender: Yup.string()
            .required('Please select gender.'),
            dob: Yup.date()
            .nullable()
            .transform((curr, orig) => orig === '' ? null : curr)
            .required('Please enter date of birth.'),
            // passport_no: Yup.string()
            // .required('Please enter passport No.'),
            // socialId_no: Yup.string()
            // .required('Please enter social No.'),
        }),
        onSubmit: values => {  
            //console.log('Edit value:', values);
            setIsLoading(true);
            let formData = new FormData();
            formData.append("_id", values.userId);
            formData.append("title", values.title);
            formData.append("first_name", values.first_name);
            formData.append("last_name", values.last_name);
            formData.append("gender", values.gender);
            formData.append("dob", values.dob);
            formData.append("passport_no", values.passport_no);
            formData.append("socialId_no", values.socialId_no);
            formData.append("passport_front_image", getEPFrontImage);
            formData.append("passport_back_image", getEPBackImage);
            formData.append("socialId_front_image", getESFrontImage);
            formData.append("socialId_back_image", getESBackImage);
            
            editTravellerService(formData, async (res) => {
                if (res.status === 404) {
                    console.log("Error 404");
                    return;
                }
                if (res.status === 200) {
                    // console.log("API editTravellerService response: ", res.data);
                    await getTravellerList();
                    await setCurrentEPFImg("");
                    await setCurrentEPBImg("");
                    await setCurrentESFImg("");
                    await setCurrentESBImg("");
                    await setIsLoading(false);
                    await setShowEditTraveller(false);
                }
            });
        }
    });
  
    const editPassportFrontImage = async (event) => {
        if (event["target"].files.length > 0) {
          const PassportFrontImagefile = event["target"].files[0];
          setEPFrontImage(PassportFrontImagefile);
          const reader = new FileReader();
          reader.readAsDataURL(PassportFrontImagefile);
          reader.onload = (event) => {
            setCurrentEPFImg(reader.result);
          };
        }
    };
    const editPassportBackImage = async (event) => {
        if (event["target"].files.length > 0) {
          const PassportBackImagefile = event["target"].files[0];
          setEPBackImage(PassportBackImagefile);
          const reader = new FileReader();
          reader.readAsDataURL(PassportBackImagefile);
          reader.onload = (event) => {
            setCurrentEPBImg(reader.result);
          };
        }
    };
    const editSocialIdFrontImage = async (event) => {
        if (event["target"].files.length > 0) {
          const SocialFrontImgfile = event["target"].files[0];
          setESFrontImage(SocialFrontImgfile);
          const reader = new FileReader();
          reader.readAsDataURL(SocialFrontImgfile);
          reader.onload = (event) => {
            setCurrentESFImg(reader.result);
          };
        }
    };
    const editSocialIdBackImage = async (event) => {
        if (event["target"].files.length > 0) {
          const SocialBackImgfile = event["target"].files[0];
          setESBackImage(SocialBackImgfile);
          const reader = new FileReader();
          reader.readAsDataURL(SocialBackImgfile);
          reader.onload = (event) => {
            setCurrentESBImg(reader.result);
          };
        }
    };

    const editTravelItem = async (e, data, i)=> {
        //console.log("EditTravellersItem", data)
        await setShowEditTraveller(true);
        await setUserEditData(data);
        await setEPFrontImage(data.passport_front_image);
        await setEPBackImage(data.passport_back_image);
        await setESFrontImage(data.socialId_front_image);
        await setESBackImage(data.socialId_back_image);
        // console.log(data.passport_front_image)
    }
    const deleteTravelItem = async (e, data, i)=> {
        //console.log("deleteTravelItem", data)
        await setShowDeleteTraveller(true);
        await setGetDeleteData(data);
    }
    const deleteTravellerSubmit = async ()=>{
        let obj = {
            _id: getDeleteData._id
        };
        await deleteTravellerService(obj, async (res) => {
            if (res.status === 404) {
                console.log("Error 404");
              return;
            }
            if (res.status === 200) {
            //   console.log("API Get traveller response: ", res.data.data);
              await getTravellerList();
            }
        });
        setShowDeleteTraveller(false);
    }

    const viewTravelItem = async (e, data, i)=> {
        //console.log("viewTravelItem", data)
        await setShowViewTraveller(true);
        await setGetViewData(data);
    }

    const changeTravellerStatus = async (e, data, i) => {
        let isStatus;
        if(data.isActive === true){
            isStatus = false
        }
        if(data.isActive === false){
            isStatus = true
        }
        let obj = {
            _id: data._id,
            isActive: isStatus
        };
        await activeDeactiveTravelService(obj, async (res) => {
            if (res.status === 404) {
                console.log("Error 404");
                return;
            }
            if (res.status === 200) {
                // console.log("Status updated successfully. ");
                await getTravellerList();
            }
        });
    };

    const closebtnstate = {button: 1};
    const handleCloseImg =(e) =>{
        // console.log(closebtnstate.button)
        if(closebtnstate.button === 1){
            setCurrentPFImg("");
            setPassportFrontImage("");
        }
        if(closebtnstate.button === 2){
            setCurrentPBImg("");
            setPassportBackImage("");
        }
        if(closebtnstate.button === 3){
            setCurrentSFImg("");
            setSocialIdFrontImage("");
        }
        if(closebtnstate.button === 4){
            setCurrentSBImg("");
            setSocialIdBackImage("");
        }
    }

    const closebtneditstate = {button: 1};
    const handleCloseEditImg =(e) =>{
        // console.log(closebtneditstate.button)
        if(closebtneditstate.button === 1){
            setEPFrontImage("");
            setCurrentEPFImg("");
            setUserEditData({...userEditData, passport_front_image: ""});
        }
        if(closebtneditstate.button === 2){
            setEPBackImage("");
            setCurrentEPBImg("");
            setUserEditData({...userEditData, passport_back_image: ""});
        }
        if(closebtneditstate.button === 3){
            setESFrontImage("");
            setCurrentESFImg("");
            setUserEditData({...userEditData, socialId_front_image: ""});
        }
        if(closebtneditstate.button === 4){
            setESBackImage("");
            setCurrentESBImg("");
            setUserEditData({...userEditData, socialId_back_image: ""});
        }
    }

    useEffect(()=>{
        getTravellerList();
    },[]);

    return ( 
        <>
        <div className="content-body">
            <div className="mt-header-block mb-3">
                <h1 className="d-col-title">Manage Travelers</h1>
                <button type="button" className="btn btn-primary-color" onClick={addTravellerShow}><i className="bi bi-plus-lg"></i> Add</button>
            </div>
            <div className="mt-table-wrap table-responsive">
                <table className="table align-middle">
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Gender</th>
                            <th scope="col">DOB</th>
                            <th scope="col">Passport No.</th>
                            <th scope="col">Social Id No.</th>
                            <th scope="col" className="text-right">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    {isLoadingTraveler ? <tr><td className="text-center" colSpan="6"><LoadingSpinnerTwo/></td></tr> : getManageTravellers?.length === 0 ? <tr><td className="text-center" colSpan="6">No results found.</td></tr> : null}
                    {!getManageTravellers ? null : getManageTravellers.map((travellersItem, i) => {
                        return(
                            <>
                            <tr key={travellersItem._id}>
                                <td>{travellersItem.first_name} {travellersItem.last_name}</td>
                                <td>{travellersItem.gender}</td>
                                <td>{moment(travellersItem.dob).format("DD-MM-YYYY")}</td>
                                <td>
                                    <b>{travellersItem.passport_no ? travellersItem.passport_no : "N/A"}</b>
                                    <div className="passport-img-row">
                                        {travellersItem.passport_front_image ? <span><img src={ imageUrlTravellers + travellersItem.passport_front_image } alt=""/></span> : ""}
                                        {travellersItem.passport_back_image ? <span><img src={ imageUrlTravellers + travellersItem.passport_back_image } alt=""/></span> : ""}
                                    </div>
                                </td>
                                <td>
                                    <b>{travellersItem.socialId_no ? travellersItem.socialId_no : "N/A"}</b>
                                    <div className="passport-img-row">
                                        {travellersItem.socialId_front_image ? <span><img src={ imageUrlTravellers + travellersItem.socialId_front_image } alt=""/></span> : ""}
                                        {travellersItem.socialId_back_image ? <span><img src={ imageUrlTravellers + travellersItem.socialId_back_image } alt=""/></span> : ""}
                                    </div>
                                </td>
                                <td className="mt-btn-groups">
                                    <OverlayTrigger
                                        placement="bottom"
                                        trigger="focus"
                                        overlay={
                                            <Popover>
                                                <Popover.Body className="btn-mt-actions">
                                                    {travellersItem.isActive === true ? 
                                                    <button className="btn" onClick={(e) => changeTravellerStatus(e, travellersItem, i)} type="button"><i className="bi bi-toggle-off"></i> Deactivate</button> 
                                                    : <button className="btn" onClick={(e) => changeTravellerStatus(e, travellersItem, i)} type="button"><i className="bi bi-toggle-on"></i> Active</button>}
                                                    <button onClick={(e) => editTravelItem(e, travellersItem, i)} type="button" className="btn"><i className="bi bi-pencil"></i> Edit</button>
                                                    <button onClick={(e) => viewTravelItem(e, travellersItem, i)} type="button" className="btn"><i className="bi bi-eye"></i> View</button>
                                                    <button onClick={(e) => deleteTravelItem(e, travellersItem, i)} type="button" className="btn"><i className="bi bi-trash"></i> Delete</button>
                                                </Popover.Body>
                                            </Popover>
                                        }
                                        >
                                        <button className="btn"><i className="bi bi-three-dots-vertical"></i></button>
                                    </OverlayTrigger>
                                </td>
                            </tr>
                            </>
                        )}  
                    )}  
                    </tbody>
                </table>
            </div>
        </div>
        
    <Modal
        show={showAddTraveller}
        onHide={addTravellerClose}
        keyboard={false}
        size="lg"
        className="add-travellers-modal"
      >
        <form onSubmit={formik.handleSubmit}>
            <Modal.Header closeButton>
                <Modal.Title>Add Traveller</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="mb-3 col-lg-2 d-col-input-field">
                        <label className="form-label">Title</label>
                        <select className="form-select" onChange={formik.handleChange} onBlur={formik.handleBlur} name="title">
                            <option defaultValue>Title</option>
                            <option value="Mr">Mr</option>
                            <option value="Mrs">Mrs</option>
                            <option value="Ms">Ms</option>
                        </select>
                        {formik.touched.title && formik.errors.title ? <p className="errors-msg">{formik.errors.title}</p> : null}
                    </div>
                    <div className="mb-3 col-lg-5 d-col-input-field">
                        <label className="form-label">First Name</label>
                        <input onChange={formik.handleChange} onBlur={formik.handleBlur} name="first_name" type="text" className="form-control" placeholder="First Name"/>
                        {formik.touched.first_name && formik.errors.first_name ? <p className="errors-msg">{formik.errors.first_name}</p> : null}
                    </div>
                    <div className="mb-3 col-lg-5 d-col-input-field">
                        <label className="form-label">Last Name</label>
                        <input onChange={formik.handleChange} onBlur={formik.handleBlur} name="last_name" type="text" className="form-control" placeholder="Last Name"/>
                        {formik.touched.last_name && formik.errors.last_name ? <p className="errors-msg">{formik.errors.last_name}</p> : null}
                    </div>
                    <div className="mb-3 col-lg-6 d-col-input-field">
                        <label className="form-label">Gender</label>
                        <select className="form-select" onChange={formik.handleChange} onBlur={formik.handleBlur} name="gender">
                            <option defaultValue>Select</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Prefer-not-to-say">Prefer not to say</option>
                        </select>
                        {formik.touched.gender && formik.errors.gender ? <p className="errors-msg">{formik.errors.gender}</p> : null}
                    </div>
                    <div className="mb-3 col-lg-6 d-col-input-field">
                        <label className="form-label">Date of Birth</label>
                        <DatePicker 
                            selected={formik.values.dob}
                            // value={value}
                            onChange={value => formik.setFieldValue("dob", value)}
                            onBlur={formik.handleBlur('dob')} 
                            name="dob"
                            dateFormat="dd-MM-yyyy"
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            customInput={<input className="form-control d-dateIcon" type="text" autocomplete="off"/>}
                            placeholderText="Select Date"
                            maxDate={new Date()}
                            />
                        {formik.touched.dob && formik.errors.dob ? <p className="errors-msg">{formik.errors.dob}</p> : null}
                    </div>
                    <div className="mb-3 col-lg-6 d-flex flex-column justify-content-between">
                        <div className="d-col-input-field">
                            <label className="form-label">Passport</label>
                            <input onChange={formik.handleChange} onBlur={formik.handleBlur} name="passport_no" type="text" className="form-control" placeholder="Passport No."/>
                            {/* {formik.touched.passport_no && formik.errors.passport_no ? <p className="errors-msg">{formik.errors.passport_no}</p> : null} */}
                        </div>
                        <div className="row">
                            <div className="mt-3 col-sm-6">
                                <div className="upload-image-btn">
                                    <i className="bi bi-cloud-arrow-up"></i> Passport front image
                                    <input onChange={selectPassportFrontImage} onClick={(event)=> {event.target.value = null}} accept="image/x-png,image/gif,image/jpeg" type="file" name="file"/>
                                </div>
                                {getCurrentPFImg ? 
                                <div className="ps-img-box">
                                    <button type="button" className="ps-close-btn btn" onClick={(event)=> handleCloseImg(event, closebtnstate.button = 1)}><i className="bi bi-x"></i></button>
                                    <img className="ps-img-upload-fluid" src={ getCurrentPFImg } alt=""/> 
                                </div>
                                : 
                                <div className="ps-img-box">
                                    <img className="ps-img-upload-fluid" src={ BlankImg } alt=""/>
                                </div>}
                            </div>
                            <div className="mt-3 col-sm-6">
                                <div className="upload-image-btn">
                                    <i className="bi bi-cloud-arrow-up"></i> Passport back image
                                    <input onChange={selectPassportBackImage} onClick={(event)=> {event.target.value = null}} accept="image/x-png,image/gif,image/jpeg" type="file" name="file"/>
                                </div>
                                {getCurrentPBImg ? 
                                <div className="ps-img-box">
                                    <button type="button" onClick={(event)=> handleCloseImg(event, closebtnstate.button = 2)} className="ps-close-btn btn"><i className="bi bi-x"></i></button>
                                    <img className="ps-img-upload-fluid" src={ getCurrentPBImg } alt=""/>
                                </div> 
                                : 
                                <div className="ps-img-box">
                                    <img className="ps-img-upload-fluid" src={ BlankImg } alt=""/>
                                </div>}
                            </div>
                        </div>
                    </div>
                    <div className="mb-3 col-lg-6 d-flex flex-column justify-content-between">
                        <div className="d-col-input-field">
                            <label className="form-label">Social</label>
                            <input onChange={formik.handleChange} onBlur={formik.handleBlur} name="socialId_no" type="text" className="form-control" placeholder="Social Id No."/>
                            {/* {formik.touched.socialId_no && formik.errors.socialId_no ? <p className="errors-msg">{formik.errors.socialId_no}</p> : null} */}
                        </div>
                        <div className="row">
                            <div className="mt-3 col-sm-6">
                                <div className="upload-image-btn">
                                    <i className="bi bi-cloud-arrow-up"></i> Social id front image
                                    <input onChange={selectSocialIdFrontImage} onClick={(event)=> {event.target.value = null}} accept="image/x-png,image/gif,image/jpeg" type="file" name="file"/>
                                </div>
                                {getCurrentSFImg ? 
                                <div className="ps-img-box">
                                    <button type="button" onClick={(event)=> handleCloseImg(event, closebtnstate.button = 3)} className="ps-close-btn btn"><i className="bi bi-x"></i></button>
                                    <img className="ps-img-upload-fluid" src={ getCurrentSFImg } alt=""/> 
                                </div>
                                : 
                                <div className="ps-img-box">
                                    <img className="ps-img-upload-fluid" src={ BlankImg } alt=""/>
                                </div>}
                            </div>
                            <div className="mt-3 col-sm-6">
                                <div className="upload-image-btn">
                                    <i className="bi bi-cloud-arrow-up"></i> Social id back image
                                    <input onChange={selectSocialIdBackImage} onClick={(event)=> {event.target.value = null}} accept="image/x-png,image/gif,image/jpeg" type="file" name="file"/>
                                </div>
                                {getCurrentSBImg ? 
                                <div className="ps-img-box">
                                    <button type="button" onClick={(event)=> handleCloseImg(event, closebtnstate.button = 4)} className="ps-close-btn btn"><i className="bi bi-x"></i></button>
                                    <img className="ps-img-upload-fluid" src={ getCurrentSBImg } alt=""/> 
                                </div>
                                : 
                                <div className="ps-img-box">
                                    <img className="ps-img-upload-fluid" src={ BlankImg } alt=""/>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-secondary-color btn-border-color" onClick={addTravellerClose}>
                    Close
                </button>
                <button type="submit" disabled={isLoading === true} className="btn btn-primary-color btn-border-color">{isLoading ? "Submitting..." : "Submit"}</button>
            </Modal.Footer>
        </form>
    </Modal>

    <Modal
      show={showEditTraveller}
      onHide={editTravellerClose}
      keyboard={false}
      size="lg"
      className="add-travellers-modal"
    >
    <form onSubmit={editTravelFormik.handleSubmit}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Traveller</Modal.Title>
      </Modal.Header>
      <Modal.Body>
            <div className="row">
                <div className="mb-3 col-lg-2 d-col-input-field">
                    <label className="form-label">Title</label>
                    <select className="form-select" onChange={editTravelFormik.handleChange} onBlur={editTravelFormik.handleBlur} name="title" value={editTravelFormik.values.title}>
                        <option defaultValue>Title</option>
                        <option value="Mr">Mr</option>
                        <option value="Mrs">Mrs</option>
                        <option value="Ms">Ms</option>
                    </select>
                    {editTravelFormik.touched.title && editTravelFormik.errors.title ? <p className="errors-msg">{editTravelFormik.errors.title}</p> : null}
                </div>
                <div className="mb-3 col-lg-5 d-col-input-field">
                    <label className="form-label">First Name</label>
                    <input onChange={editTravelFormik.handleChange} onBlur={editTravelFormik.handleBlur} name="first_name" value={editTravelFormik.values.first_name} type="text" className="form-control" placeholder="First Name"/>
                    {editTravelFormik.touched.first_name && editTravelFormik.errors.first_name ? <p className="errors-msg">{editTravelFormik.errors.first_name}</p> : null}
                </div>
                <div className="mb-3 col-lg-5 d-col-input-field">
                    <label className="form-label">Last Name</label>
                    <input onChange={editTravelFormik.handleChange} onBlur={editTravelFormik.handleBlur} name="last_name" value={editTravelFormik.values.last_name} type="text" className="form-control" placeholder="Last Name"/>
                    {editTravelFormik.touched.last_name && editTravelFormik.errors.last_name ? <p className="errors-msg">{editTravelFormik.errors.last_name}</p> : null}
                </div>
                <div className="mb-3 col-lg-6 d-col-input-field">
                    <label className="form-label">Gender</label>
                    <select className="form-select" onChange={editTravelFormik.handleChange} onBlur={editTravelFormik.handleBlur} name="gender" value={editTravelFormik.values.gender}>
                        <option defaultValue>Select</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Prefer-not-to-say">Prefer not to say</option>
                    </select>
                    {editTravelFormik.touched.gender && editTravelFormik.errors.gender ? <p className="errors-msg">{editTravelFormik.errors.gender}</p> : null}
                </div>

                <div className="mb-3 col-lg-6 d-col-input-field">
                    <label className="form-label">Date of Birth</label>
                    <DatePicker 
                        //selected={editTravelFormik.values.dob}
                        value={editTravelFormik.values.dob? moment(editTravelFormik.values.dob).format("DD-MM-YYYY"): null}
                        onChange={value => editTravelFormik.setFieldValue("dob", value)}
                        onBlur={editTravelFormik.handleBlur('dob')} 
                        name="dob"
                        dateFormat="dd-MM-yyyy"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        customInput={<input className="form-control d-dateIcon" type="text" autocomplete="off"/>}
                        placeholderText="Select Date"
                        maxDate={new Date()}
                        />
                    {editTravelFormik.touched.dob && editTravelFormik.errors.dob ? <p className="errors-msg">{editTravelFormik.errors.dob}</p> : null}
                </div>
                <div className="mb-3 col-lg-6">
                    <div className="d-col-input-field">
                        <label className="form-label">Passport</label>
                        <input onChange={editTravelFormik.handleChange} onBlur={editTravelFormik.handleBlur} name="passport_no" value={editTravelFormik.values.passport_no} type="text" className="form-control" placeholder="Passport No."/>
                        {/* {editTravelFormik.touched.passport_no && editTravelFormik.errors.passport_no ? <p className="errors-msg">{editTravelFormik.errors.passport_no}</p> : null} */}
                    </div>
                    <div className="row">
                        <div className="mt-3 col-sm-6">
                            <div className="upload-image-btn">
                                <i className="bi bi-cloud-arrow-up"></i> Passport front image
                                <input onChange={editPassportFrontImage} onClick={(event)=> {event.target.value = null}} accept="image/x-png,image/gif,image/jpeg" type="file" name="file"/>
                            </div>
                                {userEditData.passport_front_image && !getCurrentEPFImg ? 
                                <div className="ps-img-box">
                                    <button type="button" onClick={(event)=> handleCloseEditImg(event, closebtneditstate.button = 1)} className="ps-close-btn btn"><i className="bi bi-x"></i></button>
                                    <img className="ps-img-upload-fluid" src={ imageUrlTravellers + userEditData.passport_front_image } alt=""/> 
                                </div>
                                : getCurrentEPFImg ? 
                                <div className="ps-img-box">
                                    <button type="button" onClick={(event)=> handleCloseEditImg(event, closebtneditstate.button = 1)} className="ps-close-btn btn"><i className="bi bi-x"></i></button>
                                    <img className="ps-img-upload-fluid" src={ getCurrentEPFImg } alt=""/> 
                                </div>
                                : 
                                <div className="ps-img-box">
                                    <img className="ps-img-upload-fluid" src={ BlankImg } alt=""/>
                                </div>}
                        </div>
                        <div className="mt-3 col-sm-6">
                            <div className="upload-image-btn">
                                <i className="bi bi-cloud-arrow-up"></i> Passport back image
                                <input onChange={editPassportBackImage} onClick={(event)=> {event.target.value = null}} accept="image/x-png,image/gif,image/jpeg" type="file" name="file"/>
                            </div>
                                {userEditData.passport_back_image && !getCurrentEPBImg ? 
                                <div className="ps-img-box">
                                    <button type="button" onClick={(event)=> handleCloseEditImg(event, closebtneditstate.button = 2)} className="ps-close-btn btn"><i className="bi bi-x"></i></button>
                                    <img className="ps-img-upload-fluid" src={ imageUrlTravellers + userEditData.passport_back_image } alt=""/> 
                                </div>
                                : getCurrentEPBImg ? 
                                <div className="ps-img-box">
                                    <button type="button" onClick={(event)=> handleCloseEditImg(event, closebtneditstate.button = 2)} className="ps-close-btn btn"><i className="bi bi-x"></i></button>
                                    <img className="ps-img-upload-fluid" src={ getCurrentEPBImg } alt=""/> 
                                </div>
                                : 
                                <div className="ps-img-box">
                                    <img className="ps-img-upload-fluid" src={ BlankImg } alt=""/>
                                </div>}
                        </div>
                    </div>
                </div>
                <div className="mb-3 col-lg-6">
                    <div className="d-col-input-field">
                        <label className="form-label">Social</label>
                        <input onChange={editTravelFormik.handleChange} onBlur={editTravelFormik.handleBlur} name="socialId_no" value={editTravelFormik.values.socialId_no} type="text" className="form-control" placeholder="Social Id No."/>
                        {/* {editTravelFormik.touched.socialId_no && editTravelFormik.errors.socialId_no ? <p className="errors-msg">{editTravelFormik.errors.socialId_no}</p> : null} */}
                    </div>
                    <div className="row">
                        <div className="mt-3 col-sm-6">
                            <div className="upload-image-btn">
                                <i className="bi bi-cloud-arrow-up"></i> Social id front image
                                <input onChange={editSocialIdFrontImage} onClick={(event)=> {event.target.value = null}} accept="image/x-png,image/gif,image/jpeg" type="file" name="file"/>
                            </div>
                                {userEditData.socialId_front_image && !getCurrentESFImg ? 
                                <div className="ps-img-box">
                                    <button type="button" onClick={(event)=> handleCloseEditImg(event, closebtneditstate.button = 3)} className="ps-close-btn btn"><i className="bi bi-x"></i></button>
                                    <img className="ps-img-upload-fluid" src={ imageUrlTravellers + userEditData.socialId_front_image } alt=""/> 
                                </div>
                                : getCurrentESFImg ? 
                                <div className="ps-img-box">
                                    <button type="button" onClick={(event)=> handleCloseEditImg(event, closebtneditstate.button = 3)} className="ps-close-btn btn"><i className="bi bi-x"></i></button>
                                    <img className="ps-img-upload-fluid" src={ getCurrentESFImg } alt=""/> 
                                </div>
                                : 
                                <div className="ps-img-box">
                                    <img className="ps-img-upload-fluid" src={ BlankImg } alt=""/>
                                </div>}
                        </div>
                        <div className="mt-3 col-sm-6">
                            <div className="upload-image-btn">
                                <i className="bi bi-cloud-arrow-up"></i> Social id back image
                                <input onChange={editSocialIdBackImage} onClick={(event)=> {event.target.value = null}} accept="image/x-png,image/gif,image/jpeg" type="file" name="file"/>
                            </div>
                                {userEditData.socialId_back_image && !getCurrentESBImg ? 
                                <div className="ps-img-box">
                                    <button type="button" onClick={(event)=> handleCloseEditImg(event, closebtneditstate.button = 4)} className="ps-close-btn btn"><i className="bi bi-x"></i></button>
                                    <img className="ps-img-upload-fluid" src={ imageUrlTravellers + userEditData.socialId_back_image } alt=""/> 
                                </div>
                                : getCurrentESBImg ? 
                                <div className="ps-img-box">
                                    <button type="button" onClick={(event)=> handleCloseEditImg(event, closebtneditstate.button = 4)} className="ps-close-btn btn"><i className="bi bi-x"></i></button>
                                    <img className="ps-img-upload-fluid" src={ getCurrentESBImg } alt=""/> 
                                </div>
                                : 
                                <div className="ps-img-box">
                                    <img className="ps-img-upload-fluid" src={ BlankImg } alt=""/>
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
        </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-secondary-color btn-border-color" onClick={editTravellerClose}>Close</button>
        <button type="submit" disabled={isLoading === true} className="btn btn-primary-color btn-border-color">{isLoading ? "Submitting..." : "Submit"}</button>
      </Modal.Footer>
    </form>
    </Modal>

    <Modal
    show={showDeleteTraveller}
    onHide={deleteTravellerClose}
    keyboard={false}
    size="md"
    className="add-travellers-modal delete-travellers-modal"
    centered
    >
    <Modal.Body>
        <p>Are you sure you want to delete?</p>
        <div className="mt-3 justify-content-center d-flex">       
            <button type="button" className="btn btn-secondary-color btn-border-color" onClick={deleteTravellerClose}>
                Cancel
            </button>
            <button onClick={deleteTravellerSubmit} type="submit" className="btn btn-primary-color btn-border-color ms-3">Delete</button>
        </div>
    </Modal.Body>
    </Modal>

    <Modal
    show={showViewTraveller}
    onHide={viewTravellerClose}
    keyboard={false}
    size="md"
    className="add-travellers-modal view-travellers-modal"
    centered
    >
    <Modal.Header closeButton>
        <Modal.Title>View Traveller</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <div className="row mb-2">
            <div className="col-md-4">
                <p>Name :</p>
            </div>
            <div className="col-md-8">
                <p>{getViewData?.title} {getViewData?.first_name} {getViewData?.last_name}</p>
            </div>
        </div>
        <div className="row mb-2">
            <div className="col-md-4">
                <p>Gender :</p>
            </div>
            <div className="col-md-8">
                <p>{getViewData?.gender}</p>
            </div>
        </div>
        <div className="row mb-2">
            <div className="col-md-4">
                <p>Date of Birth :</p>
            </div>
            <div className="col-md-8">
                <p>{moment(getViewData?.dob).format("DD-MM-YYYY")}</p>
            </div>
        </div>
        <div className="row mb-2">
            <div className="col-md-4">
                <p>Passport No :</p>
            </div>
            <div className="col-md-8">
                <p>{getViewData?.passport_no ? getViewData?.passport_no : "N/A"}</p>
            </div>
        </div>
        
        <div className="row">
            <div className="col-md-4">
                <p>Social Id No :</p>
            </div>
            <div className="col-md-8">
                <p>{getViewData?.socialId_no ? getViewData?.socialId_no : "N/A"}</p>
            </div>
        </div>
    </Modal.Body>
    </Modal>
        </>
    );
}
 
export default ManageTravelers;