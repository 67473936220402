import './MyStays.scss';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {getStaysDataList, deleteStaysDataApi} from '../../../services/StayService';
import noPictureAvailable from '../../../assets/images/no-picture-available.jpg';
import {LoadingSpinnerTwo} from "../../../loader/Loader";
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Popover from 'react-bootstrap/Popover';
import moment from 'moment';
import { useAuth } from '../../../contexts/AuthProvider';

const Mystays = () => {
    const {authed} = useAuth();
    const history = useHistory();
    const [stayList, setStayList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    // For pagination
    const [size, setSize] = useState(8);
    const [current, setCurrent] = useState(1);
    const PerPageChange = (value) => {
        setSize(value);
        const newPerPage = Math.ceil(stayList?.length / value);
        if (current > newPerPage) {
            setCurrent(newPerPage);
        }
    }
    const PaginationChange = (page, pageSize) => {
        setCurrent(page);
        setSize(pageSize)
    }

    const getStayDataList = async () => {
        setIsLoading(true);
        const obj = {
            userId: authed?.user?._id,
            offset_val: "",
            limit_val: ""
        }
        await getStaysDataList(obj, async (res) => {
            console.log("res stays list: ", res.data.data);
            if (res.status === 404) {
                console.log('Something went wrong')
                setIsLoading(false);
            }
            if (res.status === 200) {
                const result = res.data.data.data.data.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1)
                await setStayList(result);
                setIsLoading(false);
            }
        });
    }

    const handleDeleteItem = async (e, data) => {
        await deleteStaysDataApi({stays_id: data._id}, async (res) => {
            // console.log("res stays item delete: ", res.data.data);
            if (res.status === 404) {
                console.log('Something went wrong')
            }
            if (res.status === 200) {
                // console.log('Delete Successfully.');
                document.body.click();
                getStayDataList();
            }
        });
    }
    const handleBookNow = async (e, data) =>{
        history.push({
            pathname:'/stays-results/',
            search:(`cityName=${data.city_name}&&countryName=${data.country_name}&&checkInDate=${moment(data.checkIn).format("YYYY-MM-DD")}&&checkOutDate=${moment(data.checkOut).format("YYYY-MM-DD")}&&occupancy=${JSON.stringify(data.occupancy)}`),
        });
    }

    useEffect(()=>{
        getStayDataList();
    },[]);


    return ( 
        <>
        
        <div className="content-body">
            <h1 className="d-col-title mb-4">My Stays</h1>
            {isLoading ? <LoadingSpinnerTwo/> : stayList?.length === 0 ? <p className="text-center">No stays found.</p> :
            <div className="hotel-stays-row">
                {stayList?.slice((current - 1) * size, current * size).map((item, i) => {
                    return (
                        <div key={i} className="hotel-list-item">
                            <div className="hotel-img-block">
                                {item.icon ? <img src={item.icon} alt="" /> : <img src={noPictureAvailable} alt="" />}
                            </div>
                            <div className="hotel-content-block">
                                <div className="hotel-details">
                                    <div className="hotel-name">
                                        <h4>{item?.name} </h4>
                                    </div>
                                    <div className="hotels-staring">
                                        <ul>
                                            <li className="locations-box"><span className="locations-icon"><i className="bi bi-geo-alt-fill"></i></span>&nbsp;{item?.hotelDetails?.response_data?.address}</li>
                                        </ul>
                                    </div>
                                    <div className="room-type">
                                        <ul>
                                            <li className="hotel-type-text">
                                                <i className="fa fa-bed" aria-hidden="true"></i>&nbsp;&nbsp;{item?.roomRates?.perBookingRates[0]?.roomType}                                 
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="hotel-fasilite">
                                        <ul>
                                            {item?.roomRates?.perBookingRates[0]?.facilities?.map((facilitiesArray, i) => {
                                                return (<><li key={i}>{facilitiesArray}</li></>)
                                            })}
                                            {item?.roomRates?.perBookingRates[0]?.facilities?.length > 3 ?
                                            <li>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                <Tooltip>
                                                    <div className="tooltip-scroll-y"> 
                                                        {item?.roomRates?.perBookingRates[0]?.facilities?.filter((item, index) => index > 2).map((facilitiesitem, i) => {
                                                            return(<><div key={i} className="p-item">{facilitiesitem}</div></>)
                                                        })}
                                                    </div>
                                                </Tooltip>
                                                }
                                            >
                                                <span style={{cursor: "pointer"}}>+{item?.roomRates?.perBookingRates[0]?.facilities?.length - 3}</span>
                                            </OverlayTrigger>
                                            </li> : ""}
                                        </ul>
                                    </div>
                                    {/*<div className="">
                                        <p>{item?.roomRates?.perBookingRates[0]?.description}</p>
                                            </div>*/}
                                </div>
                            </div>
                            <div className="hotel-price-block">
                                <div className="date-pos">
                                    <p>Booking + save date:&nbsp;<span>{moment(item?.createdAt).format("DD-MM-YYYY")}</span></p>
                                    <p>Checkin:&nbsp;<span>{moment(item?.checkIn).format("DD-MM-YYYY")}</span></p>
                                    <p>Checkout:&nbsp;<span>{moment(item?.checkOut).format("DD-MM-YYYY")}</span></p>
                                    <p className='py-1'>{item?.roomRates?.perBookingRates[0]?.cancellationPolicy}</p>
                                </div>
                                <div className="price-pos">
                                    <div className="offer-price">{item?.roomRates?.perBookingRates[0]?.currency}&nbsp;{item?.roomRates?.perBookingRates[0]?.netPrice}</div>
                                    {item?.isPayment === true ?
                                    <p className="night mb-0">
                                        Payment status:&nbsp;&nbsp;<span className="badge bg-success">Paid</span>
                                    </p> : ""}
                                    {/* <button type="button" className="btn btn-primary-color">Choose Room</button>*/}
                                </div>
                                {item?.isPayment === false ? 
                                <div className="hotel-rght-btn-groups">
                                    <button onClick={(e)=>handleBookNow(e, item)} type="button" className="btn btn-primary-color">Book Now</button>
                                    <OverlayTrigger
                                        trigger="click"
                                        placement="top"
                                        rootClose
                                        overlay={
                                            <Popover>
                                                <Popover.Body className="delete-popover-body-sty">
                                                    <h6>Are you sure?</h6>
                                                    <p>This hotel will be deleted permanently</p>
                                                    <div className="delete-btn-groups mt-2">
                                                        <button onClick={(e)=>handleDeleteItem(e, item)} type="button" className="btn btn-primary-color">Yes</button>
                                                        <button onClick={()=> document.body.click()} type="button" className="btn btn-danger ms-2">No</button>
                                                    </div>
                                                </Popover.Body>
                                            </Popover>
                                        }
                                        >
                                       <button type="button" className="btn btn-primary-color ms-2"><i className="bi bi-trash3"></i></button>
                                    </OverlayTrigger>
                                </div> : ""}
                            </div>
                                    
                        </div>
                    )
                })}
            </div>}
            <Pagination
                className="pagination-style text-end"
                showTitle={false}
                showTotal={(total, range) => `Showing ${range[0]} - ${range[1]} of ${total}`}
                onChange={PaginationChange}
                total={stayList?.length}
                current={current}
                pageSize={size}
                showSizeChanger={false}
                onShowSizeChange={PerPageChange}
                hideOnSinglePage={true}
            />
        </div>
                   
        </>
    );
}
 
export default Mystays;