import './StayDetails.scss';
import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import {HotelDetailService, HotelRoomRateService} from "../../../../services/StayService";
import mapViewImg from "../../../../assets/images/map-view.svg";
import noPictureAvailable from "../../../../assets/images/no-picture-available.jpg";
import stayMarker from "../../../../assets/images/stay-marker.svg";
import { LoadingSpinnerOne } from "../../../../loader/Loader";
import moment from "moment";
import { scroller } from "react-scroll";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import AddItineraryModal from "../../shared/common/AddItineraryModal";
import SessionExpiredModal from "../../shared/common/SessionExpiredModal";
import { useAuth } from '../../../../contexts/AuthProvider';

const StayDetails = (props) => {
  const {authed} = useAuth();
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  //console.log("searchPrams", searchParams.get("hotelId"))
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [hotelDetailsData, sethotelDetailsData] = useState();
  const [hotelImagesData, setHotelImagesData] = useState();
  const [hotelReviewsData, setHotelReviewsData] = useState({});
  const [getRoomRatesSessionId, setGetRoomRatesSessionId] = useState();
  const [hotelFacilitiesData, setHotelFacilitiessData] = useState([]);
  const [hotelRoomRatesData, setHotelRoomRatesData] = useState({});
  const [activeMarker, setActiveMarker] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showRooms, setShowRooms] = useState(false);
  const [roomsImagesData, setRoomsImagesData] = useState();
  const [showItineraryList, setShowItineraryList] = useState(false);
  const [perRoomItemData, setPerRoomItemData] = useState();
  const handleRoomClose = () => setShowRooms(false);
  const handleRoomShow = async (roomRatesItem, i) => {
    setShowRooms(true);
    let RoomsDataArr = roomRatesItem;
    await setRoomsImagesData(RoomsDataArr);
  };

  const handleItineraryListShow = async (e, data) => {
    setPerRoomItemData(data);
    setShowItineraryList(true);
  };

  const handleItineraryListClose = () => setShowItineraryList(false);
  const [showExpiredModal, setShowExpiredModal] = useState(false);
  const handleExpiredModalClose = () => setShowExpiredModal(false);
  const [showMapModal, setShowMapModal] = useState(false);
  const handleMapModalClose = () => setShowMapModal(false);
  const handleMapModalShow = () => setShowMapModal(true);
  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  useEffect(() => {
    hotelSearchSubmit();
    getRoomRatesData();
  }, []);

  const hotelSearchSubmit = async () => {
    setIsLoading(true);
    let Obj = {
      hotelId: searchParams.get("hotelId"),
      productId: searchParams.get("productId"),
      sessionId: searchParams.get("sessionId"),
      tokenId: searchParams.get("tokenId"),
    };
    await HotelDetailService(Obj, async (res) => {
      // console.log("Hotel search deatils response: ", res.data.data);
      if (res.status === 404) {
        console.log("Something went wrong");
      }
      if (res.status === 200) {
        await sethotelDetailsData(res.data.data);
        await setHotelImagesData(res.data.data.response_data.hotelImages);
        await setHotelReviewsData(res.data.data.response_data.hotel_review);
        await setHotelFacilitiessData(res.data.data.response_data.facilities);
        setIsLoading(false);
      }
    });
  };
  const getRoomRatesData = async () => {
    setIsLoading(true);
    let Obj = {
      hotelId: searchParams.get("hotelId"),
      productId: searchParams.get("productId"),
      sessionId: searchParams.get("sessionId"),
      tokenId: searchParams.get("tokenId"),
    };
    await HotelRoomRateService(Obj, async (res) => {
      if (res.status === 404) {
        console.log("Something went wrong");
      }
      if (res.status === 200) {
        // console.log("HotelRoomRateService", res.data.data.response_data);
        setIsLoading(false);
        await setHotelRoomRatesData(res.data.data.response_data);
        await setGetRoomRatesSessionId(res.data.data);
        if(res.data.data.response_data.status.error === "Invalid sessionId or session expired." ||
        res.data.data.response_data.status.error === "session expired or invalid sessionId"){
          setShowExpiredModal(true);
        }
        // if(res.data.data.response_data.status.error === "Invalid sessionId or session expired."){
        //   setShowExpiredModal(true);
        // }
      }
    });
  };
  const scrollToSection = () => {
    scroller.scrollTo("dt-room-list-bx", {
      duration: 100,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };
  const gotoHotelCheckout = async (event, data) => {
    event.preventDefault();
    // console.log("room rates book data=========", data);
    history.push({
      pathname: "/stay-checkout/",
      search: `sessionId=${getRoomRatesSessionId.response_data.sessionId}&&hotelId=${getRoomRatesSessionId.response_data.hotelId}&&productId=${data.productId}&&rateBasisId=${data.rateBasisId}&&tokenId=${getRoomRatesSessionId.response_data.tokenId}&&checkInDate=${searchParams.get("checkInDate")}&&checkOutDate=${searchParams.get("checkOutDate")}&&cityName=${searchParams.get("cityName")}&&countryName=${searchParams.get("countryName")}&&occupancy=${searchParams.get("occupancy")}`,
    });
  };
 
  // const [minutes, setMinutes] = useState(20);
  // const [seconds, setSeconds] = useState(0);

  // useEffect(() => {
  //   if(getRoomRatesSessionId){
  //     let sessionInterval = setInterval(() => {
  //       if (seconds > 0) {
  //         setSeconds(seconds - 1)
  //       } 
  //       if (seconds === 0) {
  //         if (minutes === 0) {
  //           clearInterval(sessionInterval)
  //           setShowExpiredModal(true);
  //         } else {
  //           setMinutes(minutes - 1)
  //           setSeconds(59)
  //         }
  //       }
  //     }, 1000)
  //     return () => {
  //       clearInterval(sessionInterval);
  //     }
  //   }

   
  // })

  return (
    <>
      <section className="inner-mrt-80">
        <div className="container-lg text-center">
          {/* <h1>Hotel search Details</h1> */}
        </div>
      </section>

      <section className="accomadation-details-wrapper padd-top-70 padd-bottom-70">
        <div className="container-lg">
          {isLoading ? <LoadingSpinnerOne /> : null}
          {hotelDetailsData ? (
            <>
              <div className="row">
                <div className="col-lg-9">
                  {hotelImagesData ? (
                    <>
                      {!!isOpen && (
                        <Lightbox
                          mainSrc={hotelImagesData[photoIndex]?.url}
                          nextSrc={
                            hotelImagesData[
                              (photoIndex + 1) % hotelImagesData.length
                            ]
                          }
                          prevSrc={
                            hotelImagesData[
                              (photoIndex + hotelImagesData.length - 1) %
                                hotelImagesData.length
                            ]
                          }
                          onCloseRequest={() => setIsOpen(false)}
                          onMovePrevRequest={() =>
                            setPhotoIndex(
                              (photoIndex + hotelImagesData.length - 1) %
                                hotelImagesData.length
                            )
                          }
                          onMoveNextRequest={() =>
                            setPhotoIndex(
                              (photoIndex + 1) % hotelImagesData.length
                            )
                          }
                        />
                      )}
                      <div className="dt-hotel-img-row">
                        <div className="dt-hotel-img-col width-55">
                          <div className="dt-hotel-img-block height-310">
                            <img
                              src={hotelImagesData[0]?.url}
                              alt={hotelImagesData[0]?.caption}
                            />
                          </div>
                        </div>
                        <div className="dt-hotel-img-col width-45">
                          <div className="dt-hotel-img-block height-150">
                            <img
                              src={hotelImagesData[1]?.url}
                              alt={hotelImagesData[1]?.caption}
                            />
                          </div>
                          <div className="dt-hotel-img-row">
                            <div className="dt-hotel-img-col width-50">
                              <div className="dt-hotel-img-block height-150">
                                <img
                                  src={hotelImagesData[2]?.url}
                                  alt={hotelImagesData[2]?.caption}
                                />
                              </div>
                            </div>
                            <div className="dt-hotel-img-col width-50">
                              <div className="dt-hotel-img-block dt-hotel-img-last height-150">
                                <img
                                  src={hotelImagesData[3]?.url}
                                  alt={hotelImagesData[3]?.caption}
                                />
                                <span onClick={() => setIsOpen([photoIndex])}>
                                  See all {hotelImagesData?.length} photos
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <div className="dt-hotel-content-block mt-3">
                    <div className="hotel-details">
                      <div className="hotel-name">
                        <h4>{hotelDetailsData?.response_data?.name}</h4>
                      </div>
                      <div className="locations-box">
                        <i className="bi bi-geo-alt-fill"></i>&nbsp;{" "}
                        {hotelDetailsData?.response_data?.address}
                      </div>
                      <div className="staring-box">
                        <span>
                          <i className="bi bi-star-fill" aria-hidden="true"></i>
                        </span>{" "}
                        {hotelDetailsData?.response_data?.hotelRating}
                      </div>
                    </div>
                    <div className="price-pos">
                      {/* <div className="no-price"><strike>USD&nbsp;80.00</strike></div>
                                        <div className="offer-price">USD&nbsp;60.00</div> */}
                      <button
                        type="button"
                        className="btn btn-primary-color"
                        onClick={scrollToSection}
                      >
                        Choose Room
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 mt-4 mt-lg-0">
                  {/* <div className="time-counter-box"><i className="bi bi-clock"></i> Session Expires in {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}</div> */}
                  <div className="map-div">
                    <div className="top-map-view-block">
                      <img src={mapViewImg} className="top-img" />
                      <div className="map-text" onClick={handleMapModalShow}>
                        <i className="bi bi-geo-alt-fill"></i>
                        <h5>SEE A MAP VIEW</h5>
                      </div>
                    </div>
                  </div>
                  {/* <div className="landmark-sec mt-3">
                                <h6 className="head"><b>Nearby Landmarks</b></h6>
                                <div className="landmark-div">
                                    <p className="main-head"><b>Transport</b></p>
                                    <ul>
                                        <li>
                                            <div className="row">
                                                <div className="col-8">
                                                    Greyhound Bus Lines
                                                    </div>
                                                <div className="col-4 text-right pl-0">
                                                    0.21km
                                                    </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="landmark-div">
                                    <p className="main-head"><b>Restaurant</b></p>
                                    <ul>
                                        <li>
                                            <div className="row">
                                                <div className="col-8">
                                                    Shoney's
                                                    </div>
                                                <div className="col-4 text-right pl-0">
                                                    0.22km
                                                    </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="row">
                                                <div className="col-8">
                                                    SUBWAY
                                                    </div>
                                                <div className="col-4 text-right pl-0">
                                                    0.23km
                                                    </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="row">
                                                <div className="col-8">
                                                    Mexi Wing II
                                                    </div>
                                                <div className="col-4 text-right pl-0">
                                                    0.15km
                                                    </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="landmark-div">
                                    <p className="main-head"><b>Restaurant</b></p>
                                </div>
                            </div> */}
                  {hotelReviewsData ? (
                    <div className="mt-4 dt-review-block">
                      <div className="review-content">
                        <div className="row">
                          <div className="col-md-12 dt-review-right-panel">
                            <div className="review-left-sec">
                              {hotelReviewsData.rating}
                              <span> /&nbsp;5</span>
                            </div>
                            <div className="dt-review-right-bx">
                              <h6>Excellent</h6>
                              <p>{hotelReviewsData.num_reviews} reviews</p>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="progress-sec">
                              <div className="progress-div">
                                <div className="progress">
                                  <div
                                    className="progress-bar"
                                    style={{
                                      width:
                                        `${Math.ceil(
                                          (hotelReviewsData.rate_cleanliness /
                                            5) *
                                            100
                                        )}` + "%",
                                    }}
                                  ></div>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <p className="rate-text">Cleanliness</p>
                                  <p className="rate-num">
                                    {hotelReviewsData.rate_cleanliness}
                                  </p>
                                </div>
                              </div>
                              <div className="progress-div">
                                <div className="progress">
                                  <div
                                    className="progress-bar"
                                    style={{
                                      width:
                                        `${Math.ceil(
                                          (hotelReviewsData.rate_location / 5) *
                                            100
                                        )}` + "%",
                                    }}
                                  ></div>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <p className="rate-text">Location</p>
                                  <p className="rate-num">
                                    {hotelReviewsData.rate_location}
                                  </p>
                                </div>
                              </div>
                              <div className="progress-div">
                                <div className="progress">
                                  <div
                                    className="progress-bar"
                                    style={{
                                      width:
                                        `${Math.ceil(
                                          (hotelReviewsData.rate_service / 5) *
                                            100
                                        )}` + "%",
                                    }}
                                  ></div>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <p className="rate-text">Service</p>
                                  <p className="rate-num">
                                    {hotelReviewsData.rate_service}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {hotelFacilitiesData ? (
                <div className="mt-4 dt-facilities-block">
                  <div className="dt-header-title">
                    <h4>What they offer</h4>
                  </div>
                  <ul>
                    {hotelFacilitiesData?.map((facilitiesArray, i) => {
                      return (
                        <>
                          <li key={i}>
                            <div className="dt-facilities-items">
                              <p dangerouslySetInnerHTML={{__html: facilitiesArray}}></p>
                            </div>
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </div>
              ) : (
                ""
              )}

              <div className="mt-4 dt-room-list-bx">
                <div className="dt-header-title">
                  <h4>Select your room</h4>
                </div>
                {hotelRoomRatesData && hotelRoomRatesData?.roomRates?.perBookingRates?.length > 0 ? (
                  <>
                    {hotelRoomRatesData?.roomRates?.perBookingRates?.map((roomRatesItem, i) => {
                        return (
                          <>
                            <div
                              key={i}
                              className="dt-room-div dt-room-info-box"
                            >
                              <div className="dt-room-title">
                                <h6>{roomRatesItem.roomType}</h6>
                                <span
                                  onClick={(e) => {
                                    handleRoomShow(roomRatesItem, i);
                                  }}
                                  className="dt-room-view"
                                >
                                  View Room
                                </span>
                              </div>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="dt-room-head d-none d-md-block">
                                    <div className="row">
                                      <div className="col-md-4">
                                        <p>Benefits</p>
                                      </div>
                                      <div className="col-md-2">
                                        <p>Sleeps</p>
                                      </div>
                                      <div className="col-md-3">
                                        <p>Price</p>
                                      </div>
                                      <div className="col-md-3 text-end">
                                        <p>Most Booked</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="dt-room-content">
                                    <div className="row">
                                      <div className="col-md-4 dt-room-features">
                                        <p>Your price includes:</p>
                                        <ul>
                                          {roomRatesItem?.facilities?.map(
                                            (roomFacilitiesItem, i) => {
                                              return (
                                                <>
                                                  <li key={i}>
                                                    {roomFacilitiesItem}
                                                  </li>
                                                </>
                                              );
                                            }
                                          )}
                                        </ul>
                                      </div>
                                      <div className="col-md-2">
                                        <div className="dt-icons-include">
                                          {roomRatesItem?.maxOccupancyPerRoom ? (
                                            <>
                                              {[
                                                ...Array(
                                                  roomRatesItem?.maxOccupancyPerRoom
                                                ),
                                              ].map(
                                                (
                                                  maxOccupancyPerRoomItem,
                                                  i
                                                ) => {
                                                  return (
                                                    <>
                                                      <i key={i} className="fa fa-male"></i>
                                                    </>
                                                  );
                                                }
                                              )}
                                            </>
                                          ) : (
                                            ""
                                          )}

                                          {/* <i className="fa fa-male"></i><i className="fa fa-male"></i>{roomRatesItem?.maxOccupancyPerRoom} */}
                                        </div>
                                      </div>
                                      <div className="col-md-3">
                                        <div className="dt-room-price-sec">
                                          <p className="offer-price">
                                            {roomRatesItem?.currency}{" "}
                                            {roomRatesItem?.netPrice}
                                          </p>
                                          {/*<small>Price per night as low as</small>*/}
                                        </div>
                                      </div>
                                      <div className="col-md-3 dt-room-btns">
                                        {authed?.isAuth === true ? (
                                          <>
                                            <button
                                              onClick={(e) =>
                                                gotoHotelCheckout(
                                                  e,
                                                  roomRatesItem
                                                )
                                              }
                                              type="button"
                                              className="btn btn-primary-color"
                                            >
                                              Book Now
                                            </button>
                                            <button
                                              onClick={(e) =>
                                                handleItineraryListShow(
                                                  e,
                                                  roomRatesItem
                                                )
                                              }
                                              type="button"
                                              className="btn btn-primary-color mt-2"
                                            >
                                              Add to Itinerary
                                            </button>
                                          </>
                                        ) : (
                                          <>
                                            <Link
                                              to="/signin"
                                              className="btn btn-primary-color"
                                            >
                                              Book Now
                                            </Link>
                                            <Link
                                              to="/signin"
                                              className="btn btn-primary-color mt-2"
                                            >
                                              Add to Itinerary
                                            </Link>
                                          </>
                                        )}
                                        <p className="cancellation">
                                          Cancellation Policy&nbsp;&nbsp;
                                          <OverlayTrigger
                                            placement="top"
                                            overlay={
                                              <Tooltip>
                                                {
                                                  roomRatesItem?.cancellationPolicy
                                                }
                                              </Tooltip>
                                            }
                                          >
                                            <i className="fa fa-info-circle"></i>
                                          </OverlayTrigger>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      }
                    )}
                  </>
                ) : (
                  "Room is not available."
                )}
              </div>

              <div className="mt-4">
                <div className="dt-header-title">
                  <h4>About us</h4>
                </div>
                <div
                  className="dt-about-content"
                  dangerouslySetInnerHTML={{
                    __html:
                      hotelDetailsData?.response_data?.description?.content,
                  }}
                ></div>
              </div>

              {hotelReviewsData ?
                  <>
                  <div className="mt-4 dt-review-block">
                    <div className="dt-header-title">
                      <h4>Reviews</h4>
                    </div>  
                
                    <div className="review-content">
                      <div className="row">
                        <div className="col-md-3 col-lg-1 pr-0">
                          <div className="review-left-sec">
                            {hotelReviewsData.rating}
                            <span> /&nbsp;5</span>
                          </div>
                          <h6></h6>
                        </div>

                        <div className="col-md-9 col-lg-6">
                          <div className="progress-sec">
                            <div className="row">
                              <div className="col-sm-6">
                                <div className="progress-div">
                                  <div className="progress">
                                    <div
                                      className="progress-bar"
                                      style={{
                                        width:
                                          `${Math.ceil(
                                            (hotelReviewsData.rate_cleanliness /
                                              5) *
                                              100
                                          )}` + "%",
                                      }}
                                    ></div>
                                  </div>
                                  <div className="row">
                                    <div className="col-9 text-left">
                                      <p className="rate-text">Cleanliness</p>
                                    </div>
                                    <div className="col-3 text-right">
                                      <p className="rate-num">
                                        {hotelReviewsData.rate_cleanliness}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="progress-div">
                                  <div className="progress">
                                    <div
                                      className="progress-bar"
                                      style={{
                                        width:
                                          `${Math.ceil(
                                            (hotelReviewsData.rate_sleep / 5) *
                                              100
                                          )}` + "%",
                                      }}
                                    ></div>
                                  </div>
                                  <div className="row">
                                    <div className="col-9 text-left">
                                      <p className="rate-text">Sleep</p>
                                    </div>
                                    <div className="col-3 text-right">
                                      <p className="rate-num">
                                        {hotelReviewsData.rate_sleep}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="progress-div">
                                  <div className="progress">
                                    <div
                                      className="progress-bar"
                                      style={{
                                        width:
                                          `${Math.ceil(
                                            (hotelReviewsData.rate_location /
                                              5) *
                                              100
                                          )}` + "%",
                                      }}
                                    ></div>
                                  </div>
                                  <div className="row">
                                    <div className="col-9 text-left">
                                      <p className="rate-text">Location</p>
                                    </div>
                                    <div className="col-3 text-right">
                                      <p className="rate-num">
                                        {hotelReviewsData.rate_location}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="progress-div">
                                  <div className="progress">
                                    <div
                                      className="progress-bar"
                                      style={{
                                        width:
                                          `${Math.ceil(
                                            (hotelReviewsData.rate_room / 5) *
                                              100
                                          )}` + "%",
                                      }}
                                    ></div>
                                  </div>
                                  <div className="row">
                                    <div className="col-9 text-left">
                                      <p className="rate-text">
                                        Room Comfort & Quality
                                      </p>
                                    </div>
                                    <div className="col-3 text-right">
                                      <p className="rate-num">
                                        {hotelReviewsData.rate_room}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="progress-div">
                                  <div className="progress">
                                    <div
                                      className="progress-bar"
                                      style={{
                                        width:
                                          `${Math.ceil(
                                            (hotelReviewsData.rate_service /
                                              5) *
                                              100
                                          )}` + "%",
                                      }}
                                    ></div>
                                  </div>
                                  <div className="row">
                                    <div className="col-9 text-left">
                                      <p className="rate-text">Service</p>
                                    </div>
                                    <div className="col-3 text-right">
                                      <p className="rate-num">
                                        {hotelReviewsData.rate_service}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="progress-div">
                                  <div className="progress">
                                    <div
                                      className="progress-bar"
                                      style={{
                                        width:
                                          `${Math.ceil(
                                            (hotelReviewsData.rate_value / 5) *
                                              100
                                          )}` + "%",
                                      }}
                                    ></div>
                                  </div>
                                  <div className="row">
                                    <div className="col-9 text-left">
                                      <p className="rate-text">
                                        Value for money
                                      </p>
                                    </div>
                                    <div className="col-3 text-right">
                                      <p className="rate-num">
                                        {hotelReviewsData.rate_value}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="rating-brdr-left col-md-12 col-lg-5">
                          <div className="row">
                            <div className="col-sm-6">
                              <h5>Rating</h5>
                              <ul>
                                <li>
                                  <i className="fa fa-hand-o-right"></i>
                                  &nbsp;&nbsp;9+ Exceptional (
                                  {hotelReviewsData.review_rating_count_1})
                                </li>
                                <li>
                                  <i className="fa fa-hand-o-right"></i>
                                  &nbsp;&nbsp;8-9 Excellent (
                                  {hotelReviewsData.review_rating_count_2})
                                </li>
                                <li>
                                  <i className="fa fa-hand-o-right"></i>
                                  &nbsp;&nbsp;7-8 Very Good (
                                  {hotelReviewsData.review_rating_count_3})
                                </li>
                                <li>
                                  <i className="fa fa-hand-o-right"></i>
                                  &nbsp;&nbsp;6-7 Good (
                                  {hotelReviewsData.review_rating_count_4})
                                </li>
                                <li>
                                  <i className="fa fa-hand-o-right"></i>
                                  &nbsp;&nbsp;&lt;6 Below Expectation (
                                  {hotelReviewsData.review_rating_count_5})
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="dt-testimonial-block">
                      {hotelReviewsData?.reviews?.map((reviewsItem, i) => {
                        return (
                          <>
                            <div key={i} className="dt-testimonial-item">
                              <div className="row">
                                <div className="col-md-3">
                                  <div className="dt-border-right">
                                    <h4>
                                      <i
                                        className="bi bi-star-fill"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      {reviewsItem.rating}
                                    </h4>
                                    <p>
                                      <i
                                        className="fa fa-user"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      {reviewsItem.user.username}
                                    </p>
                                    <p>
                                      <i className="fa fa-bed"></i>{" "}
                                      {reviewsItem.trip_type}
                                    </p>
                                    <p>
                                      <i
                                        className="fa fa-calendar"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      Stayed in {reviewsItem.travel_date}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-md-9">
                                  <div className="testimonial-content">
                                    <h5>{reviewsItem.title}</h5>
                                    <p>{reviewsItem.text}</p>
                                    <p className="review-text">
                                      Review by{" "}
                                      {moment(
                                        reviewsItem.published_date
                                      ).format("MMM YY")}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                  </>
                : ""}
            </>
          ) : (
            ""
          )}
        </div>
      </section>

      <Modal
        show={showRooms}
        onHide={handleRoomClose}
        keyboard={false}
        size="lg"
        centered
        className="EnquirecustomModal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="roomModalTitle">
            {roomsImagesData?.roomType}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {roomsImagesData?.roomImages?.length > 0 ? (
            <>
              <Carousel
                className="roomcustom-Carousel"
                showArrows={true}
                showThumbs={true}
                axis="horizontal"
              >
                {roomsImagesData?.roomImages?.map((roomItem, r) => (
                  <img key={r} src={roomItem} />
                ))}
              </Carousel>
            </>
          ) : (
            <>
              <p className="text-center">Room image is not available.</p>
            </>
          )}
        </Modal.Body>
      </Modal>
      <Modal
        show={showMapModal}
        onHide={handleMapModalClose}
        keyboard={false}
        size="lg"
        centered
        className="MapViewModal"
      >
        {/* <Modal.Header closeButton>
                <Modal.Title>Map View</Modal.Title>
            </Modal.Header> */}
        <Modal.Body>
          <div className="MapView-block">
            <GoogleMap
              onLoad={(map) => {
                const bounds = new window.google.maps.LatLngBounds({
                  lat: parseFloat(hotelDetailsData?.response_data?.latitude),
                  lng: parseFloat(hotelDetailsData?.response_data?.longitude),
                });
                map.fitBounds(bounds);
              }}
              onClick={() => setActiveMarker(null)}
              mapContainerStyle={{ width: "100%", height: "100%" }}
            >
              <Marker
                position={{
                  lat: parseFloat(hotelDetailsData?.response_data?.latitude),
                  lng: parseFloat(hotelDetailsData?.response_data?.longitude),
                }}
                icon={stayMarker}
                onMouseOver={() =>
                  handleActiveMarker(hotelDetailsData?.response_data?.hotelId)
                }
                onMouseOut={() => setActiveMarker(null)}
              >
                {activeMarker === hotelDetailsData?.response_data?.hotelId ? (
                  <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                    <div className="llp-item">
                      <div className="civ-item-img">
                        {hotelDetailsData?.response_data?.hotelImages === "" ? (
                          <img src={`${noPictureAvailable}`} alt="" />
                        ) : (
                          <img
                            src={`${hotelDetailsData?.response_data?.hotelImages[0]?.url}`}
                            alt=""
                          />
                        )}
                      </div>
                      <div className="civ-item-info">
                        <h5>{hotelDetailsData?.response_data?.name}</h5>
                        <p>{hotelDetailsData?.response_data?.address}</p>
                      </div>
                    </div>
                  </InfoWindow>
                ) : null}
              </Marker>
            </GoogleMap>
          </div>
        </Modal.Body>
      </Modal>
      <AddItineraryModal open={showItineraryList} onClose={handleItineraryListClose} roomData={perRoomItemData} hotelData={hotelDetailsData} />
      <SessionExpiredModal open={showExpiredModal} onClose={handleExpiredModalClose}/>
    </>
  );
};

export default StayDetails;
