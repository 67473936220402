import "./EmailVerifiedSuccessful.scss";
import React from 'react';
import { Link } from 'react-router-dom';

const EmailVerifiedSuccessful = () => {

    return (
        <>
            <section className="div-center-wrap">
                <div className="container-lg">
                    <div className="cmn-card-info-block text-center">
                        <div className="cmn-card-info-content email-verified-successful">
                            <i className="bi bi-envelope-check-fill"></i>
                            <h3>Your email address has been verified.</h3>
                            <p>Thank you for your support. We have successfully verified your email address. You can now sign in with your new account.</p>
                            <Link to="/signin" className="btn btn-primary-color mt-4">Continue to Signin</Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default EmailVerifiedSuccessful;