import React, {useState, useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {getDraftAllApi, removeItineraryApi} from '../../../services/AdminService';
import {LoadingSpinnerTwo} from "../../../loader/Loader";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import moment from 'moment';
import { useAuth } from '../../../contexts/AuthProvider';

const ItineraryDraftList = () => {
    const {authed} = useAuth();
    const history = useHistory();
    const [getAllDraftData, setAllDraftData] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const getDraftAllData = async ()=>{
        setIsLoading(true);
        await getDraftAllApi({userId: authed?.user?._id}, async (res) => {
            // console.log("res AllDraftData ====", res);
            if (res.status === 404) {
                console.log('Something went wrong')
            }
            if (res.status === 200) {
                // toast(res.data.data.message);
                await setAllDraftData(res.data.data.data);
                setIsLoading(false);
            }
        });
    }
    const editDraftItem = async (e, data, i)=> {
        //console.log("EditTravellersItem", data)
        history.push({
            pathname:'/admin/create-itinerary/',
            search:(`userId=${data.userId}&&itineraryId=${data._id}`),
            state: { 
                userId: data.userId,  
                _id: data._id,
            }
        });
        //localStorage.setItem("itineraryId", JSON.stringify(data));
    }
    const deleteDraftItem = async (e, data)=>{
        let obj = {
            itineraryId: data._id,
        }
        await removeItineraryApi(obj, async (res) => {
            if (res.status === 404) {
                console.log('Something went wrong')
            }
            if (res.status === 200) {
                //toast.success(res.data.data.message, options);
                document.body.click();
                getDraftAllData();
            }
        });
    }
    useEffect(() => {
        getDraftAllData();
    }, []);

    

    return ( 
        <>
      
        <div className="content-body">
            <h1 className="d-col-title mb-3">Draft Itinerary</h1>
            <div className="mt-table-wrap table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Tour Name</th>
                            <th scope="col">Tour Type</th>
                            <th scope="col">Updated Date</th>
                            <th scope="col" className="text-right">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading ? <tr><td className="text-center" colSpan="6"><LoadingSpinnerTwo/></td></tr> : getAllDraftData?.length === 0 ? <tr><td className="text-center" colSpan="6">No results found.</td></tr> : null}
                        {!getAllDraftData ? null : getAllDraftData.map((draftitem, i) => {
                            return(
                                <>
                                <tr key={draftitem._id}>
                                    <td>{draftitem.tour_name}</td>
                                    <td>{draftitem.type_of_tour}</td>
                                    <td>{moment(draftitem.updatedAt).format("DD-MM-YYYY")}</td>
                                    <td>
                                        <div className="d-flex justify-content-end draft-btn-right">
                                            <button onClick={(e) => editDraftItem(e, draftitem, i)} type="button" className="btn edit-icon"></button>
                                            <OverlayTrigger
                                                trigger="click"
                                                placement="top"
                                                rootClose
                                                overlay={
                                                    <Popover>
                                                        <Popover.Body className="delete-popover-body-sty">
                                                            <h6>Are you sure?</h6>
                                                            <p>This itinerary will be deleted permanently</p>
                                                            <div className="delete-btn-groups mt-2">
                                                                <button onClick={(e) => deleteDraftItem(e, draftitem, i)} type="button" className="btn btn-primary-color">Yes</button>
                                                                <button onClick={()=> document.body.click()} type="button" className="btn btn-danger ms-2">No</button>
                                                            </div>
                                                        </Popover.Body>
                                                    </Popover>
                                                }
                                                >
                                                <button type="button" className="btn delete-icon"></button>
                                            </OverlayTrigger>
                                            
                                        </div>
                                    </td>
                                </tr>
                                </>
                            )}  
                        )}  
                    </tbody>
                </table>
            </div>
        </div>
                   
        </>
    );
}
 
export default ItineraryDraftList;