import React from "react";
import "./Loader.scss";

export const LoadingSpinnerOne = () => {
  return (
    <div className="spinner-container">
      <div className="loading-spinner-2">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
}

export const LoadingSpinnerTwo = () => {
  return (
    <div className="loading-spinner-2-box text-center">
      <div className="loading-spinner-2">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
}