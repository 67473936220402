import "./ResetPassword.scss";
import React, {useState} from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {ResetPasswordService} from '../../../services/AuthService';
import Alert from 'react-bootstrap/Alert';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {toast} from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
toast.configure();

const ResetPassword = () => {
    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [passwordShown, setPasswordShown] = useState(false);
    const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
    const [showMessage, setShowMessage] = useState("");
    const [visibleAlertError, setVisibleAlertError] = useState(false);
    const [visibleAlertSuccess, setVisibleAlertSuccess] = useState(false);

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };
    const toggleConfirmPassword = () => {
        setConfirmPasswordShown(!confirmPasswordShown);
    };
    const formik = useFormik({
        initialValues: {
            newpassword: "",
            confirmpassword: ""
        },
        validationSchema: Yup.object({
            newpassword: Yup.string()
              .required('Please enter password.')
              .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                "Must contain 8 characters, one uppercase, one lowercase, one number and one special case character."
              ),
            confirmpassword: Yup.string()
                .required('Please enter confirm password.')
                .when("newpassword", {
                    is: val => (val && val.length > 0 ? true : false),
                    then: Yup.string().oneOf(
                        [Yup.ref("newpassword")],
                        "Both password need to be the same."
                    )
            })
        }),
        onSubmit: values => {  
            // console.log(values)
            const obj = {
                token: searchParams.get("token"), 
                password: values.newpassword
            }
            ResetPasswordService(obj, async (res) => {
                // console.log("API reset password response: ", res);
                if(res.data.data.success){
                    // toast.success(res.data.data.message)
                    setShowMessage(res.data.data.message);
                    setVisibleAlertSuccess(true);
                    setTimeout(() => {
                        setVisibleAlertSuccess(false);
                        history.push('/signin');
                    }, 2000);
                    formik.resetForm();
                }else{
                  //toast.error(res.data.data.message)
                  setShowMessage(res.data.data.message);
                  setVisibleAlertError(true);
                  setTimeout(() => {
                    setVisibleAlertError(false);
                }, 5000);
                }
            });
        }
    });
    return ( 
        <>
        <section className="signup-wrapper inner-mrt-80 padd-top-70 padd-bottom-70">
            <div className="container-lg">
                <div className="signin-row">
                    <div className="signin-col-block signin-form-block">
                        {visibleAlertSuccess && <Alert variant="success" onClose={() => setVisibleAlertSuccess(false)} dismissible>
                            <p>{showMessage}</p>
                        </Alert>}
                        {visibleAlertError && <Alert variant="danger" onClose={() => setVisibleAlertError(false)} dismissible>
                            <p>{showMessage}</p>
                        </Alert>}
                        <div className="signin-title-block">
                            <h1>Reset Password?</h1>
                            <p>Enter a new password for your account.</p>
                        </div>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="frm-mrt-20">
                                <div className="form-input-fields">
                                    <i className="frm-icon f-lock-icon"></i>
                                    <div className="has-float-label">
                                        <input onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.newpassword} name="newpassword" type={passwordShown ? "text" : "password"} className="form-control" id="Password" placeholder="Password"/>
                                        <label htmlFor="Password">Password</label>
                                    </div>
                                    <button onClick={togglePassword} className={passwordShown ? "bi eye-btn bi-eye" : "bi eye-btn bi-eye-slash"}></button>
                                </div>
                                {formik.touched.newpassword && formik.errors.newpassword ? <p className="errors-msg">{formik.errors.newpassword}</p> : null}
                            </div>
                            <div className="frm-mrt-20">
                                <div className="form-input-fields frm-mrt-20">
                                    <i className="frm-icon f-lock-icon"></i>
                                    <div className="has-float-label">
                                    <input onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.confirmpassword} name="confirmpassword" type={confirmPasswordShown ? "text" : "password"} className="form-control" id="ConfirmPassword" placeholder="Confirm Password"/>
                                        <label htmlFor="ConfirmPassword">Confirm New Password</label>
                                    </div>
                                    <button onClick={toggleConfirmPassword} className={confirmPasswordShown ? "bi eye-btn bi-eye" : "bi eye-btn bi-eye-slash"}></button>
                                </div>
                                {formik.touched.confirmpassword && formik.errors.confirmpassword ? <p className="errors-msg">{formik.errors.confirmpassword}</p> : null}
                            </div>
                            <div className="signin-frm-btns">
                                <button type="submit" className="btn btn-border-color btn-primary-color">Submit</button>
                            </div>
                        </form>
                    </div>
                    <div className="signin-col-block signin-img-block">
                        <h3>Hello, Friend!</h3>
                        <p>Enter your personal details and start journey with us</p>
                        <Link to="/signin" className="btn btn-secondary-color frm-mrt-20">Sign in</Link>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}
 
export default ResetPassword;