import "./Signin.scss";
import React, {useState, useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {LoadingSpinnerOne} from "../../../loader/Loader";
import Alert from 'react-bootstrap/Alert';
import {SigninService, SocialLoginService} from '../../../services/AuthService';
import {LoginSocialGoogle, LoginSocialFacebook} from 'reactjs-social-login';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAuth } from "../../../contexts/AuthProvider";

const Signin = () => {
    const {setAuthed} = useAuth();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);
    const [showMessage, setShowMessage] = useState("");
    const [visibleAlert, setVisibleAlert] = useState(false);
    const [visibleAlertSuccess, setVisibleAlertSuccess] = useState(false);
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };
    const formik = useFormik({
        initialValues: {
            email: "",
            password: ""
        },
        validationSchema: Yup.object({
            email: Yup.string()
              .email('Invalid email address.')
              .required('Please enter email address.'),
            password: Yup.string()
              .required('Please enter password'),
            //   .matches(
            //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            //     "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
            //   ),
        }),
        onSubmit: values => {  
            //console.log('Login value:', values);
            setIsLoading(true);
            SigninService(values, async (res) => {
                //console.log("signin response: ", res);
                // if (res.status === 404){
                //     const errMsg = res.data.response.data.errors.msg
                //     toast(errMsg)
                //     return
                //   } 
                if(res.status === 200){
                    if(res.data.data.code === 400){
                        //toast.error(res.data.data.message);
                        setShowMessage(res.data.data.message);
                        setVisibleAlert(true);
                        setIsLoading(false);
                    }
                   
                    if(res.data.data.success === true){
                        let loginobj = res.data.data.data;
                        let loginToken = res.data.data.token;
                        localStorage.setItem("login_gw", JSON.stringify(loginobj));
                        localStorage.setItem("token_gw", JSON.stringify(loginToken));
                        setAuthed({isAuth: true, token: loginToken, user: loginobj});
                        setIsLoading(false);
                        setShowMessage("Login Successfully.");
                        setVisibleAlertSuccess(true);
                        setTimeout(() => {
                            setVisibleAlertSuccess(false);
                        }, 5000);
                        //toast.success("Login Successfully!");
                        history.push('/');
                    }else{
                        setShowMessage(res.data.data.errors.msg);
                        setVisibleAlert(true);
                        setIsLoading(false);
                    }
                    setShowMessage(res.data.data.errors.msg);
                    setVisibleAlert(true);
                    setIsLoading(false);
                    setTimeout(() => {
                        setVisibleAlert(false);
                    }, 5000);
                }
            });
            // formik.resetForm();  
        }
    });
 
    useEffect(() => {
       
    }, []);


    return ( 
        <>
        {isLoading ? <LoadingSpinnerOne /> : null}
        <section className="signin-wrapper inner-mrt-80 padd-top-70 padd-bottom-70">
            <div className="container-lg">
                <div className="signin-row">
                    <div className="signin-col-block signin-form-block">
                        {visibleAlertSuccess && <Alert variant="success" onClose={() => setVisibleAlertSuccess(false)} dismissible>
                            <p>{showMessage}</p>
                        </Alert>}
                        {visibleAlert && <Alert variant="danger" onClose={() => setVisibleAlert(false)} dismissible>
                            <p>{showMessage}</p>
                        </Alert>}
                        <div className="signin-title-block">
                            <h1>Sign In</h1>
                            <p>Signin to continue</p>
                        </div>
                        
                        <form onSubmit={formik.handleSubmit}>
                            <div className="frm-mrt-20">
                                <div className="form-input-fields frm-mrt-20">
                                    <i className="frm-icon f-user-icon"></i>
                                    <div className="has-float-label">
                                        <input onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email} name="email" className="form-control" type="email" id="EmailId" placeholder="Email ID" autoComplete="off"/>
                                        <label htmlFor="EmailId">Email ID</label>
                                    </div>
                                </div>
                                {formik.touched.email && formik.errors.email ? <p className="errors-msg">{formik.errors.email}</p> : null}
                            </div>
                            <div className="frm-mrt-20">
                                <div className="form-input-fields frm-mrt-20">
                                    <i className="frm-icon f-lock-icon"></i>
                                    <div className="has-float-label">
                                        <input onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.password} name="password" type={passwordShown ? "text" : "password"} className="form-control" id="Password" placeholder="Password" autoComplete="off"/>
                                        <label htmlFor="Password">Password</label>
                                    </div>
                                    <button type="button" onClick={togglePassword} className={passwordShown ? "bi eye-btn bi-eye" : "bi eye-btn bi-eye-slash"}></button>
                                </div>
                                {formik.touched.password && formik.errors.password ? <p className="errors-msg">{formik.errors.password}</p> : null}
                            </div>
                            <div className="signin-frm-btns frm-btn-mrt-30">
                                <button type="submit" className="btn btn-border-color btn-primary-color">Sign In</button>
                            </div>
                            <div className="forgot-txt text-center">
                                <Link to="/forgot-password">Forgot password?</Link>
                            </div>
                            <div className="or-divider"></div>
                            <div className="s-social-links">
                                <LoginSocialGoogle
                                    client_id={process.env.REACT_APP_GOOGLE_CLIENTID}
                                    scope="openid profile email"
                                    discoveryDocs="claims_supported"
                                    access_type="offline"
                                    onResolve={({ provider, data }) => {
                                        console.log(provider, data);
                                        setIsLoading(true);
                                        let obj ={
                                            name: data.name,
                                            email: data.email,
                                            phone: "",
                                            fbid: "",
                                            googleid: data.sub,
                                            profileImage: data.picture
                                        }
                                        SocialLoginService(obj, async (res) => {
                                            console.log(res,"res")
                                            if(res.status === 200){
                                                if(res.data.data.code === 400){
                                                    setShowMessage(res.data.data.message);
                                                    setVisibleAlert(true);
                                                    setIsLoading(false);
                                                }
                                                if(res.data.data.statuscode === 422){
                                                    setShowMessage(res.data.data.message);
                                                    setVisibleAlert(true);
                                                    setIsLoading(false);
                                                }
                                                if(res.data.data.success === true){
                                                    let loginobj = res.data.data.data;
                                                    let loginToken = res.data.data.token;
                                                    localStorage.setItem("login_gw", JSON.stringify(loginobj));
                                                    localStorage.setItem("token_gw", JSON.stringify(loginToken));
                                                    setAuthed({isAuth: true, token: loginToken, user: loginobj});
                                                    setIsLoading(false);
                                                    setShowMessage("Login Successfully.");
                                                    setVisibleAlertSuccess(true);
                                                    setTimeout(() => {
                                                        setVisibleAlertSuccess(false);
                                                    }, 5000);
                                                    history.push('/');
                                                }else{
                                                    setShowMessage(res.data.data.errors.msg);
                                                    setVisibleAlert(true);
                                                    
                                                }
                                                setShowMessage(res.data.data.errors.msg);
                                                setVisibleAlert(true);
                                                setIsLoading(false);
                                                setTimeout(() => {
                                                    setVisibleAlert(false);
                                                }, 5000);
                                            }
                                        });
                                    }}
                                    onReject={err => {
                                        console.log(err);
                                    }}
                                    >
                                    <button type="button" className="btn google-plus"></button>
                                </LoginSocialGoogle>
                                
                                <LoginSocialFacebook
                                    appId={process.env.REACT_APP_FACEBOOK_APPID}
                                    autoLoad={true}
                                    fields="id,name,email,picture"
                                    onResolve={(response) => {
                                        console.log(response);
                                        setIsLoading(true);
                                        let obj ={
                                            name: response.data.name,
                                            email: response.data.email,
                                            phone: "",
                                            fbid: response.data.id,
                                            googleid: "",
                                            profileImage: response.data.picture.data.url
                                        }
                                        SocialLoginService(obj, async (res) => {
                                            if(res.status === 200){
                                                if(res.data.data.code === 400){
                                                    setShowMessage(res.data.data.message);
                                                    setVisibleAlert(true);
                                                    setIsLoading(false);
                                                }
                                                if(res.data.data.success === true){
                                                    let loginobj = res.data.data.data;
                                                    let loginToken = res.data.data.token;
                                                    localStorage.setItem("login_gw", JSON.stringify(loginobj));
                                                    localStorage.setItem("token_gw", JSON.stringify(loginToken));
                                                    setAuthed({isAuth: true, token: loginToken, user: loginobj});
                                                    setIsLoading(false);
                                                    setShowMessage("Login Successfully.");
                                                    setVisibleAlertSuccess(true);
                                                    setTimeout(() => {
                                                        setVisibleAlertSuccess(false);
                                                    }, 5000);
                                                    history.push('/');
                                                }else{
                                                    setShowMessage(res.data.data.errors.msg);
                                                    setVisibleAlert(true);
                                                    setIsLoading(false);
                                                }
                                                setShowMessage(res.data.data.errors.msg);
                                                setVisibleAlert(true);
                                                setTimeout(() => {
                                                    setVisibleAlert(false);
                                                }, 5000);
                                                setIsLoading(false);
                                            }
                                        });
                                    }}
                                    onReject={err => {
                                        console.log(err);
                                    }}
                                    >
                                    <button type="button" className="btn facebook"></button>
                                </LoginSocialFacebook>
                            </div>
                            
                        </form>
                    </div>
                    <div className="signin-col-block signin-img-block">
                        <h3>Hello, Friend</h3>
                        <p>Sign up and spread the love for travel!</p>
                        <Link to="/signup" className="btn btn-secondary-color frm-mrt-20">Sign Up</Link>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}
 
export default Signin;