import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Dropdown from 'react-bootstrap/Dropdown';
import WhiteArrowIcon from '../../../../assets/images/white-arrow.png';
// import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
// import moment from 'moment';
// import { useFormik } from 'formik';
// import * as Yup from 'yup';

const FlightSearchForm = () => {
    const [checkInDate, setcheckInDate] = useState(null);
    const [checkOutDate, setcheckOutDate] = useState(null);
   
    useEffect(()=>{
        
    },[]);

    return ( 
        <>
        <div className="form-row">
            <div className="form-col">
                <div className="cmn-form-input-fields">
                    <input className="form-control location-icon" type="text" placeholder="Departing from"/>
                </div>
            </div>
            <div className="form-col">
                <div className="cmn-form-input-fields">
                    <input className="form-control location-icon" type="text" placeholder="Going to"/>
                </div>
            </div>
            <div className="form-col">
                <div className="cmn-form-input-fields">
                    <DatePicker 
                    selectsStart
                    selected={checkInDate} 
                    //onChange={handleCheckinDateChange} 
                    dateFormat="dd MMMM yyyy"
                    autoComplete="off"
                    customInput={<input className="form-control calendar-icon" type="text" id="CheckIn"/>}
                    placeholderText="Departing date"
                    minDate={new Date()}
                    />
                </div>
            </div>
            <div className="form-col">
                <div className="cmn-form-input-fields">
                    <DatePicker 
                    selectsEnd
                    selected={checkOutDate} 
                    // onChange={handleCheckOutDateChange} 
                    dateFormat="dd MMMM yyyy"
                    autoComplete="off"
                    customInput={ <input className="form-control calendar-icon" type="text" id="CheckOut"/>}
                    placeholderText="Returning date"
                    //minDate={new Date(staySearchData.checkin)}
                    />
                </div>
            </div>
            <div className="form-col">
                <Dropdown className="custom-travellers-drp">
                    <Dropdown.Toggle variant="trasparent">
                        <div className="cmn-form-input-fields">
                            <input className="form-control user-icon" type="text" placeholder="Enter travellers" readOnly/>
                        </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <form>
                            <div className="flighttravellers-frm-rw mb-2">
                                <label className="flighttravellers-frm-label">Adults <span>(12 yrs+)</span></label>
                                <select className="form-select">
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                </select>
                            </div>
                            <div className="flighttravellers-frm-rw mb-2">
                                <label className="flighttravellers-frm-label">Children <span>(2-11 yrs)</span></label>
                                <select className="form-select">
                                    <option value="0">0</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                </select>
                            </div>
                            <div className="flighttravellers-frm-rw">
                                <label className="flighttravellers-frm-label">Infant <span>(0-2 years years)</span></label>
                                <select className="form-select">
                                    <option value="0">0</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                </select>
                            </div>
                            <div className="d-flex justify-content-end mt-3">
                                <Dropdown.Item as="button" className="btn done-btn btn-primary-color">Done</Dropdown.Item>
                            </div>
                        </form>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <div className="form-col">
                <select className="form-select flightclasstype-select">
                    <option value="Y">Economy Class</option>
                    <option value="S">Premium Economy</option>
                    <option value="C">Business Class</option>
                    <option value="F">First Class</option>
                </select>
            </div>
            <div className="form-col">
            </div>
            <div className="form-col">
                <div className="form-btns justify-content-end">
                    <button type="submit" className="btn btn-primary-color">Search <img src={WhiteArrowIcon} alt=""/></button>
                </div>
            </div>
        </div>
        </>
    );
};
 
export default FlightSearchForm;