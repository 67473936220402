import React, {useState} from "react";
import Modal from 'react-bootstrap/Modal';
import { GoogleMap, InfoWindow, Marker, Polyline } from "@react-google-maps/api";
import {googleApiKey, googlePhotoUrl, imageUrlOthers} from '../../../services/CommonUrl';
import noPictureAvailable from '../../../assets/images/no-picture-available.jpg';
import stayMarker from '../../../assets/images/stay-marker.svg';
import activityMarker from '../../../assets/images/activity-marker.svg';
import foodMarker from '../../../assets/images/food-marker.svg';
import othersMarker from '../../../assets/images/others-marker.svg';

const mapContainerStyle = {
    width: "100%",
    height: "500px",
};

function ModalMap(props) {
    const photoUrl = `${googlePhotoUrl}`;
    const apikey = `&key=${googleApiKey}`;
    const [activeMarker, setActiveMarker] = useState(null);
    const [waypoints, setwaypoints] = React.useState([]);
    const [itineraryDayDetails, setItineraryDayDetails] = React.useState([]);

    React.useEffect(() => {
        // console.log("Running", props)
        setItineraryDayDetails(props.days);
        let wayPointArr = [];
        for(let i = 0; i < props.days.length; i++) {
            wayPointArr.push({lat: props.days[i].location.coordinates[0], lng: props.days[i].location.coordinates[1]})
        }
        setwaypoints(wayPointArr);
    }, [props.open])
    
    // New Code 24/03/2023
    
    const handleActiveMarker = (marker) => {
        if (marker === activeMarker) {
        return;
        }
        setActiveMarker(marker);
    };

    const handleOnLoad = (map) => {
        const bounds = new window.google.maps.LatLngBounds();
        props?.days?.forEach(({ location }) => bounds.extend({ lat: location.coordinates[0], lng: location.coordinates[1]}));
        map.fitBounds(bounds);
    };

    return (
        <Modal
            show={props.open}
            onHide={props.onClose}
            keyboard={false}
            size="lg"
            centered
        >
            <Modal.Body>
            {itineraryDayDetails && <GoogleMap
                onLoad={handleOnLoad}
                onClick={() => setActiveMarker(null)}
                mapContainerStyle={mapContainerStyle}
                >
                <Polyline
                geodesic={true}
                path={waypoints}
                options={{
                    strokeColor: "#004aad",
                    strokeOpacity: 0.75,
                    strokeWeight: 2
                }} />
                {itineraryDayDetails.sort((a, b) => b.time - a.time).map((item, index) =>{
                    return(
                    <Marker
                    key={item._id}
                    position={{ lat: item.location.coordinates[0], lng: item.location.coordinates[1]}}
                    //label= {`${index+1}`}
                    animation= {window.google.maps.Animation.DROP}
                    label= {{
                        text: `${index+1}`,
                        color: "#fff",
                        fontSize: "10px",
                        //fontWeight: "bold",
                        className: 'marker-label-position'
                      }}
                    icon={item.days_type === "Hotel" ? stayMarker : "" || item.days_type === "Food" ? foodMarker : "" || item.days_type === "Activity" ? activityMarker: "" || item.days_type === "Others" ? othersMarker: ""}
                    onClick={() => handleActiveMarker(item._id)}
                    onMouseOver={() => handleActiveMarker(item._id)}
                    onMouseOut={() => setActiveMarker(null)}
                    >
                    {activeMarker === item._id ? (
                        <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                            <div>
                                {item.days_type === "Hotel" ?
                                    <div className="llp-item">
                                        <div className="civ-item-img">
                                            {item.hotelDetails.response_data.hotelImages === "" ? <img src={`${noPictureAvailable}`} alt=""/>: <img src={`${item.hotelDetails.response_data.hotelImages[0].url}`} alt=""/>}
                                        </div>
                                        <div className="civ-item-info">
                                            <div className="civ-item-icon-check">
                                                <span><img width={14} src={ require("../../../assets/images/Stays-icon-2.png") } alt="icon" /> Stays</span>
                                            </div>
                                            <h5>{item.hotelDetails.response_data.name}</h5>
                                            <p>{item.hotelDetails.response_data.address}</p>
                                        </div>
                                    </div>
                                : ""}
                                {item.days_type === "Food" ?
                                    <div className="llp-item">
                                        <div className="civ-item-img">
                                            {item.icon === "" ? <img src={`${noPictureAvailable}`} alt=""/>: <img src={`${photoUrl}maxwidth=400&photoreference=${item.icon}${apikey}`} alt=""/>}  
                                        </div>
                                        <div className="civ-item-info">
                                            <div className="civ-item-icon-check">
                                                <span><img width={10} src={ require("../../../assets/images/Food-icon-2.png") } alt="icon" /> Food</span>
                                            </div>
                                            <h5>{item.name}</h5>
                                            <p>{item.address}</p>
                                        </div>
                                    </div>
                                : ""}
                                {item.days_type === "Activity" ?
                                    <div className="llp-item">
                                        <div className="civ-item-img">
                                            {item.icon === "" ? <img src={`${noPictureAvailable}`} alt=""/>: <img src={`${photoUrl}maxwidth=400&photoreference=${item.icon}${apikey}`} alt=""/>}  
                                        </div>
                                        <div className="civ-item-info">
                                            <div className="civ-item-icon-check">
                                                <span><img width={10} src={ require("../../../assets/images/Activity-icon-2.png") } alt="icon" /> Activity</span>
                                            </div>
                                            <h5>{item.name}</h5>
                                            <p>{item.address}</p>
                                        </div>
                                    </div>
                                : ""}
                                {item.days_type === "Others" ?
                                    <div className="llp-item">
                                        <div className="civ-item-img">
                                            {item.icon === "" ? <img src={`${noPictureAvailable}`} alt=""/>: <img src={`${imageUrlOthers+item.icon}`} alt=""/>}  
                                        </div>
                                        <div className="civ-item-info">
                                            <div className="civ-item-icon-check">
                                                <span><img width={9} src={ require("../../../assets/images/others-icon.png") } alt="icon" /> Others</span>
                                            </div>
                                            <h5>{item.name}</h5>
                                            <p>{item.address}</p>
                                        </div>
                                    </div>
                                : ""}
                            </div>
                        </InfoWindow>
                    ) : null}
                    </Marker>
                    )
                })}
               
                </GoogleMap>}
                
            </Modal.Body>
        </Modal>
    )
}

export default React.memo(ModalMap);