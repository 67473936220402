import "./Header.scss";
import React, {useState, useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import siteLogo from '../../../../assets/images/logo.png'; 
import BlankAvatar from '../../../../assets/images/blank-avatar.png';
import {imageUrlProfile, currencyBaseURL} from '../../../../services/CommonUrl';
import EnquiryForm from './../common/EnquiryForm';
import { useAuth } from "../../../../contexts/AuthProvider";
import axios from "axios";

const Header = () => {
    const { authed, setAuthed } = useAuth();
    const history = useHistory();
    const [isScrollClassHeader, setIsScrollClassHeader] = useState(false);
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const [showEnquiryModal, setShowEnquiryModal] = useState(false);
    
    const scrollHeader =() => {
        const handleScroll = () => {
            setIsScrollClassHeader(window.scrollY > 80);
        }
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll);
    }
 
    const logoutModalClose = () => setShowLogoutModal(false)

    const onClickLogout = ()=>{
        // console.log('logout')
        setAuthed({ isAuth: false, token: "", user: {} });
        localStorage.clear();
        setShowLogoutModal(false);
        //window.location.reload();
        history.push("/signin");
    }

    useEffect(() => {
        scrollHeader();
        axios.get(currencyBaseURL).then((response) => {
            localStorage.setItem("currency_gw", JSON.stringify({currency: response.data.currency, country: response.data.country}));
        });
    },[]);

    return ( 
        <>
         <header className={isScrollClassHeader ? "header-wrapper fixed-navbar" : "header-wrapper"}>
            <nav className="navbar navbar-expand-lg navbar-dark custom-nav" aria-label="navbar">
                <div className="container-lg">
                    <Link className="logo" to="/"><img src={siteLogo} alt="Gladia Tour" className="img-responsive"/></Link>
                    <div className="navbar-right-box">
                    {authed?.isAuth === true ? (
                        <>
                        <Dropdown className="headerDropdown-profile">
                            <Dropdown.Toggle variant="transparent">
                            {authed?.user?.profileImage === undefined || authed?.user?.profileImage === null || authed?.user?.profileImage === "" ? 
                                <img src={`${ BlankAvatar }`} alt="" />
                                 : <> {authed?.user?.loginType === "social" ? <img src={authed?.user?.profileImage} alt={authed?.user?.name} /> : <img src={imageUrlProfile + authed?.user?.profileImage} alt={authed?.user?.name}/>}</>}
                            <span className="profileUserName">{authed?.user?.name}</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={()=>history.push("/admin/dashboard")} className="btn btn-primary-color">Dashboard</Dropdown.Item>
                                <Dropdown.Item onClick={()=> history.push("/admin/manage-profile")} className="btn btn-primary-color">Manage Profile</Dropdown.Item>
                                <Dropdown.Item onClick={() => setShowLogoutModal(true)} className="btn btn-primary-color">Logout</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        </>
                    ) : (
                        <div className="login-links">
                            {/* <Link to="/signup" className="btn btn-primary-color"><span>Register</span> <i className="bi bi-person"></i></Link>
                            <Link to="/signin" className="btn btn-primary-color"><span>Sign in</span> <i className="bi bi-box-arrow-in-left"></i></Link> */}
                            <Link to="/signin" className="btn"><span>Sign in</span><i className="h-icon h-signin-icon"></i></Link>
                            <Link to="/help-support" className="btn"><span>Support</span><i className="h-icon h-support-icon"></i></Link>
                            {/* <Link onClick={showSigninModal} className="btn btn-primary-color"><span>Sign in</span> <i className="bi bi-box-arrow-in-left"></i></Link> */}
                            {/* <span className="divided-line">|</span> */}
                        </div>
                    )}
                        <button className="btn btn-enquire" onClick={() => setShowEnquiryModal(true)}><span>Enquire</span><i className="h-icon h-enquire-icon"></i></button>
                        <Dropdown className="headerDropdown-moretravel">
                            <Dropdown.Toggle variant="transparent">
                                <span>More travel</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item as={Link} to={'/stays-results'}><i className="fa fa-building"></i> Stays</Dropdown.Item>
                                <Dropdown.Item><i className="fa fa-plane"></i> Flights</Dropdown.Item>
                                {/* <Dropdown.Item><i className="fa fa-car"></i> Cabs</Dropdown.Item> */}
                                <Dropdown.Item><i className="fa fa-briefcase"></i> Tours</Dropdown.Item>
                                <Dropdown.Item><i className="fa fa-binoculars"></i>Activities</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        
                    </div>
                </div>
            </nav>      
        </header>
        <Modal
            show={showLogoutModal}
            onHide={logoutModalClose}
            keyboard={false}
            size="sm"
            className="add-travellers-modal delete-travellers-modal"
            centered
            >
            <Modal.Body>
                <p>Are you sure you want to logout?</p>
                <div className="mt-3 justify-content-center d-flex">       
                    <button type="button" className="btn btn-secondary-color btn-border-color" onClick={logoutModalClose}>
                        No
                    </button>
                    <button onClick={onClickLogout} type="submit" className="btn btn-primary-color btn-border-color ms-3">Yes</button>
                </div>
            </Modal.Body>
        </Modal>
        <EnquiryForm open={showEnquiryModal} close={() => setShowEnquiryModal(false)} />
        </>
    );
}
 
export default Header;