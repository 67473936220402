
import React from 'react';
import { Switch, Route, BrowserRouter as Router, Redirect } from "react-router-dom";
import './App.scss';
import { BackToTop } from './back-to-top/BackToTop';
import ScrollToTop from './ScrollToTop';
import Home from './views/Main/home/Home';
import Signin from './views/Main/signin/Signin';
import Signup from './views/Main/signup/Signup';
import ForgotPassword from './views/Main/forgot-password/ForgotPassword';
import ResetPassword from './views/Main/reset-password/ResetPassword';
import Faq from './views/Main/faq/Faq';
import About from './views/Main/about/About';
import TermsConditions from './views/Main/terms-condition/TermsConditions';
import PrivacyPolicy from './views/Main/privacy-policy/PrivacyPolicy';
import HelpSupport from './views/Main/help-support/HelpSupport';
import Contact from './views/Main/contact/Contact';
import PublicItineraryDetails from './views/Main/itinerary-details/PublicItineraryDetails';
import EmailVerifiedSuccessful from './views/Main/email-verified-successful/EmailVerifiedSuccessful';
import StayList from './views/Main/stays/stay-list/StayList';
import PreviewBooking from './views/Main/stays/preview-booking/PreviewBooking';
import CreateItinerary from './views/Admin/manage-itinerary/CreateItinerary';
import Dashboard from './views/Admin/dashboard/Dashboard';
import ChangePassword from './views/Admin/change-password/ChangePassword';
import MyFlights from './views/Admin/my-flights/MyFlights';
import MyCabs from './views/Admin/my-cabs/MyCabs';
import MyStays from './views/Admin/my-stays/MyStays';
import ManageTravelers from './views/Admin/manage-travelers/ManageTravelers';
import RequestQuote from './views/Admin/manage-itinerary/RequestQuote';
import ItineraryPreview from './views/Admin/manage-itinerary/ItineraryPreview';
import ItineraryItemPreview from './views/Admin/manage-itinerary/ItineraryItemPreview';
import ItinerariesList from './views/Admin/manage-itinerary/ItinerariesList';
import ItineraryDraftList from './views/Admin/manage-itinerary/ItineraryDraftList';
import ManageProfile from './views/Admin/manage-profile/ManageProfile';
import StayDetails from './views/Main/stays/stay-details/StayDetails';
import StayCheckout from './views/Main/stays/stay-checkout/StayCheckout';
import PaymentSuccessful from './views/Main/stays/payment-successful/PaymentSuccessful';
import PaymentCancelled from './views/Main/stays/payment-cancelled/PaymentCancelled';

import FlightResults from './views/Main/flights/flight-results/FlightResults';
import FlightBookingSummary from './views/Main/flights/flight-booking-summary/FlightBookingSummary';

import Error from './views/Error/Error';
import MainContainer from './containers/main-container/MainContainer';
import AdminContainer from './containers/admin-container/AdminContainer';
import { RequiresAuth } from './views/RequiresAuth';


const App = () => {

    return (
        <>
            <Router basename={`${process.env.REACT_APP_FILE_PATH}`}>
                <ScrollToTop>
                    <Switch>
                        <Route exact path="/email-verified/" component={EmailVerifiedSuccessful} />
                        <Route exact path="/404"><Error /></Route>
                        <Route exact path='/:path?'>
                            <MainContainer>
                                <Switch>
                                    <Route exact path="/" component={Home} />
                                    <Route exact path="/signin" component={Signin} />
                                    <Route exact path="/signup" component={Signup} />
                                    <Route exact path="/forgot-password" component={ForgotPassword} />
                                    <Route exact path="/reset-password" component={ResetPassword} />
                                    <Route exact path="/faq" component={Faq} />
                                    <Route exact path="/about" component={About} />
                                    <Route exact path="/terms-conditions" component={TermsConditions} />
                                    <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                                    <Route exact path="/help-support" component={HelpSupport} />
                                    <Route exact path="/contact" component={Contact} />
                                    <Route exact path="/stays-results/" component={StayList} />
                                    <Route exact path="/stay-details/" component={StayDetails} />
                                    <Route exact path="/stay-checkout/" component={StayCheckout} />
                                    <Route exact path="/preview-booking/" component={PreviewBooking} />
                                    <Route exact path="/public-itinarary-details/" component={PublicItineraryDetails} />
                                    <Route exact path="/payment-successful/" component={PaymentSuccessful} />
                                    <Route exact path="/payment-cancelled/" component={PaymentCancelled} />
                                    {/* Flights */}
                                    <Route exact path="/flight-results" component={FlightResults} />
                                    <Route exact path="/flight-booking-summary" component={FlightBookingSummary} />
                                    <Redirect to="/404"/>
                                </Switch>
                            </MainContainer>
                        </Route>
                        <Route path='/admin/:path?' exact>
                            <AdminContainer>
                                <Switch>
                                    <Route exact path="/admin/dashboard">
                                        <RequiresAuth>
                                            <Dashboard />
                                        </RequiresAuth>
                                    </Route>
                                    <Route exact path="/admin/create-itinerary">
                                        <RequiresAuth>
                                            <CreateItinerary />
                                        </RequiresAuth>
                                    </Route>
                                    <Route exact path="/admin/change-password">
                                        <RequiresAuth>
                                            <ChangePassword />
                                        </RequiresAuth>
                                    </Route>
                                    <Route exact path="/admin/itineraries-list">
                                        <RequiresAuth>
                                            <ItinerariesList />
                                        </RequiresAuth>
                                    </Route>
                                    <Route exact path="/admin/flights">
                                        <RequiresAuth>
                                            <MyFlights />
                                        </RequiresAuth>
                                    </Route>
                                    <Route exact path="/admin/cabs">
                                        <RequiresAuth>
                                            <MyCabs />
                                        </RequiresAuth>
                                    </Route>
                                    <Route exact path="/admin/stays">
                                        <RequiresAuth>
                                            <MyStays />
                                        </RequiresAuth>
                                    </Route>
                                    <Route exact path="/admin/manage-travelers">
                                        <RequiresAuth>
                                            <ManageTravelers />
                                        </RequiresAuth>
                                    </Route>
                                    <Route exact path="/admin/request-quote">
                                        <RequiresAuth>
                                            <RequestQuote />
                                        </RequiresAuth>
                                    </Route>
                                    <Route exact path="/admin/itinerary-preview">
                                        <RequiresAuth>
                                            <ItineraryPreview />
                                        </RequiresAuth>
                                    </Route>
                                    <Route exact path="/admin/itinerary-item-preview">
                                        <RequiresAuth>
                                            <ItineraryItemPreview />
                                        </RequiresAuth>
                                    </Route>
                                    <Route exact path="/admin/manage-profile">
                                        <RequiresAuth>
                                            <ManageProfile />
                                        </RequiresAuth>
                                    </Route>
                                    <Route exact path="/admin/itinerary-draft-list">
                                        <RequiresAuth>
                                            <ItineraryDraftList />
                                        </RequiresAuth>
                                    </Route>
                                    <Redirect to="/404"/>
                                </Switch>
                            </AdminContainer>
                        </Route>
                    </Switch>
                </ScrollToTop>
            </Router>
            <BackToTop />
        </>
    );
}

export default App;

