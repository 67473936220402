import "./PrivateItinerary.scss";
import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from "react";
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Dropdown from 'react-bootstrap/Dropdown';
import infoIcon from '../../../../../assets/images/info-icon.png';
import WhiteArrowIcon from '../../../../../assets/images/white-arrow.png';
import noPictureAvailable from '../../../../../assets/images/no-picture-available.jpg';
import { addItineraryItemRequestApi, getAllItineraryApi } from "../../../../../services/DataService";
import { useAuth } from "../../../../../contexts/AuthProvider";
import { LoadingSpinnerTwo } from "../../../../../loader/Loader";
import { googleApiKey, googlePhotoUrl, imageUrlOthers } from "../../../../../services/CommonUrl";
import Accordion from 'react-bootstrap/Accordion';
import InputRange from 'react-input-range';
import { Country, City } from 'country-state-city';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { MultiSelect } from "react-multi-select-component";
import { getCityWiseItinerary, getCountryWiseItinerary, getDateWiseItinerary, getDaysWiseItinerary, getPriceWiseItinerary, getRatingWiseItinerary, getTravellersWiseItinerary } from "../../../../../helpers/ItineraryFilter";
import { toast } from "react-toastify";


const PrivateItinerary = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        clearPrivateFilter() {
            clearAllData();
        }
    }));
    const { authed, currencyData } = useAuth();
    const history = useHistory();
    const photoUrl = `${googlePhotoUrl}`;
    const apikey = `&key=${googleApiKey}`;
    const [isLoading, setIsLoading] = useState(false);
    const [itineraryPrivateData, setItineraryPrivateData] = useState([]);
    // For private pagination
    const [sizePrivatePaginate, setSizePrivatePaginate] = useState(10);
    const [currentPrivatePaginate, setCurrentPrivatePaginate] = useState(1);
    const perPageChangePrivate = (value) => {
        setSizePrivatePaginate(value);
        const newPerPage = Math.ceil(itineraryPrivateData?.length / value);
        if (currentPrivatePaginate > newPerPage) {
            setCurrentPrivatePaginate(newPerPage);
        }
    }
    const privatePaginationChange = (page, pageSize) => {
        setCurrentPrivatePaginate(page);
        setSizePrivatePaginate(pageSize);
    }

    const handlePrivateItemInfo = (data, e) => {
        privateItemFromik.values.userId = authed?.user?._id
        privateItemFromik.values.itineraryId = data._id
        // console.log(data)
    }
    const privateItemFromik = useFormik({
        initialValues: {
            name: "",
            email: "",
            phone: "",
            itineraryId: "",
            userId: ""
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .max(20, 'Name should not exceed 20 Characters.')
                .required('Please Enter Name.'),
            email: Yup.string()
                .email('Invalid email address.')
                .required('Please enter email address.'),
        }),
        onSubmit: values => {
            // console.log('Private value:', values);
            addItineraryItemRequestApi(values, async (res) => {
                if (res.status === 404) {
                    console.log('Something went wrong');
                }
                if (res.status === 200) {
                    toast.success(res.data.data.message);
                    privateItemFromik.resetForm();
                }
            });
        }
    });

    const changeRecentSort = async () => {
        let recentSort = itineraryPrivateData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        await setItineraryPrivateData([...recentSort]);
    }
    const changePopularitySort = async () => {
        let popularSort = itineraryPrivateData.sort((a, b) => b.total_rating - a.total_rating)
        await setItineraryPrivateData([...popularSort]);
    }

    const changePriceLh = async () => {
        let lhSort = itineraryPrivateData.sort((a, b) => a.currencyConvert.new_amount - b.currencyConvert.new_amount)
        await setItineraryPrivateData([...lhSort]);
    }

    const changePriceHl = async () => {
        let hlSort = itineraryPrivateData.sort((a, b) => b.currencyConvert.new_amount - a.currencyConvert.new_amount)
        await setItineraryPrivateData([...hlSort]);
    }
    const handleToggleDrp = (tgl) => {
        const isdrp = tgl;
        if (isdrp === false) {
            privateItemFromik.resetForm();
        }
    }
    const getPrivateItineraryData = async () => {
        setIsLoading(true);
        await getAllItineraryApi({
            type_of_itinerary: "Private",
            newCurrency: currencyData.currency
        }, async (res) => {
            // console.log("res getAllItineraryApi ====", res);
            if (res.status === 404) {
                console.log('Something went wrong');
                setIsLoading(false);
            }
            if (res.status === 200) {
                // console.log(res.data.data.message);
                if (res.data.data.data === null || res.data.data.data === undefined) {
                    setMinMaxPriceValue({ min: 0, max: 0 });
                    setFilterPriceValue({ min: 0, max: 0 });
                    setItineraryPrivateData([]);
                    setIsLoading(false);
                } else {
                    const result = res.data.data.data.sort((a, b) => new Date(a) < new Date(b) ? 1 : -1);
                    setItineraryPrivateData(result);
                    setIsLoading(false);
                    const smallAmount = Math.min(...result.map(p => p.currencyConvert.new_amount));
                    const largeAmount = Math.max(...result.map(p => p.currencyConvert.new_amount));
                    setMinMaxPriceValue({ min: Math.floor(smallAmount), max: Math.ceil(largeAmount) });
                    setFilterPriceValue({ min: Math.floor(smallAmount), max: Math.ceil(largeAmount) });
                }
            }
        });
    }

    const [travellerTypeArr, setTravellerTypeArr] = useState([]);
    const [travellerRatingArr, setTravellerRatingArr] = useState([]);
    const [countryIsoCode, setCountryIsoCode] = useState();
    const [searchCountryQuery, setSearchCountryQuery] = useState("");
    const [countryName, setCountryName] = useState("");
    const countryData = Country.getAllCountries();
    const cityData = City.getCitiesOfCountry(countryIsoCode ? countryIsoCode : "");
    const [filterCountryList, setFilterCountryList] = useState(countryData);
    const ratingRef = useRef([]);
    const travellersRef = useRef([]);
    const countryRef = useRef([]);
    const cityRef = useRef([]);
    const [filterPriceValue, setFilterPriceValue] = useState({ min: 0, max: 0 });
    const [minMaxPriceValue, setMinMaxPriceValue] = useState({ min: 0, max: 0 });
    const [filterDaysValue, setFilterDaysValue] = useState({ min: 1, max: 60 });
    const [stateDate, setStateDate] = useState([{ startDate: new Date(), endDate: new Date(), key: 'selection', status: false }]);
    const [selectedCityData, setSelectedCityData] = useState([]);
    const updatedCity = cityData.map((city) => ({
        label: city.name,
        value: city.name,
        ...city
    }));

    let priceDefault = { ...filterPriceValue };
    const handlePriceFilterComplete = (value) => {
        priceDefault = value;
        setFilterPriceValue(priceDefault);
        setCurrentPrivatePaginate(1);
    }

    let daysDefault = { ...filterDaysValue };
    const handleDaysFilterComplete = (value) => {
        daysDefault = value;
        setFilterDaysValue(daysDefault);
        setCurrentPrivatePaginate(1);
    }

    let updateDateFilter = [...stateDate];
    const handleDateFilter = (item) => {
        updateDateFilter = [{ startDate: item.selection.startDate, endDate: item.selection.endDate, key: 'selection', status: true }];
        setStateDate([{ startDate: item.selection.startDate, endDate: item.selection.endDate, key: 'selection', status: true }]);
        setCurrentPrivatePaginate(1);
    }

    let updateTravelerFilter = [...travellerTypeArr];
    const handleTravellerChange = async (event) => {
        if (event.target.checked) {
            updateTravelerFilter = [...travellerTypeArr, event.target.value]
        } else {
            updateTravelerFilter.splice(travellerTypeArr.indexOf(event.target.value), 1);
        }
        await setTravellerTypeArr(updateTravelerFilter);
        setCurrentPrivatePaginate(1);
    };

    let updateRatingFilter = [...travellerRatingArr];
    const handleTravellerRatingChange = async (event) => {
        if (event.target.checked) {
            updateRatingFilter = [...travellerRatingArr, event.target.value];
        } else {
            updateRatingFilter.splice(travellerRatingArr.indexOf(event.target.value), 1);
        }
        await setTravellerRatingArr(updateRatingFilter);
        setCurrentPrivatePaginate(1);
    };

    const handleCountryChange = async (e, data) => {
        setCountryIsoCode(data.isoCode);
        setSearchCountryQuery(data.name);
        setCountryName(data.name);
        setCurrentPrivatePaginate(1);
        for (let i = 0; i < cityRef.current.length; i++) {
            if (cityRef.current[i] && cityRef.current[i].checked) {
                cityRef.current[i].checked = false;
            }
        }
    };

    const handleSearchCountryChange = (e) => {
        setSearchCountryQuery(e.target.value);
        const results = countryData.filter(item => {
            if (e.target.value === "") return countryData;
            return item.name.toLowerCase().includes(e.target.value.toLowerCase());
        });
        setFilterCountryList(results);
        setCurrentPrivatePaginate(1);
    }

    let updateCityFilter = [...selectedCityData];
    const handleCityChange = (item) => {
        updateCityFilter = item;
        setSelectedCityData(item);
        setCurrentPrivatePaginate(1);
    }

    const handleSearchClear = () => {
        setSearchCountryQuery("");
        setCountryIsoCode("");
        setCountryName("");
        setFilterCountryList(countryData);
        setSelectedCityData([]);
        setCurrentPrivatePaginate(1);
        for (let i = 0; i < countryRef.current.length; i++) {
            if (countryRef.current[i] && countryRef.current[i].checked) {
                countryRef.current[i].checked = false;
            }
        }
    }

    const clearAllData = async () => {
        priceDefault = { min: minMaxPriceValue.min, max: minMaxPriceValue.max };
        setFilterPriceValue(priceDefault);
        daysDefault = { min: 1, max: 60 };
        setFilterDaysValue(daysDefault);
        updateTravelerFilter = [];
        setTravellerTypeArr(updateTravelerFilter);
        updateRatingFilter = [];
        setTravellerRatingArr(updateRatingFilter);
        setCountryName("");
        setSearchCountryQuery("");
        setFilterCountryList(countryData);
        updateDateFilter = [{ startDate: new Date(), endDate: new Date(), key: 'selection', status: false }];
        setStateDate(updateDateFilter);
        setCountryIsoCode("");
        setSelectedCityData([]);
        setCurrentPrivatePaginate(1);
        for (let i = 0; i < ratingRef.current.length; i++) {
            ratingRef.current[i].checked = false;
        }
        for (let i = 0; i < travellersRef.current.length; i++) {
            travellersRef.current[i].checked = false;
        }
        for (let i = 0; i < countryRef.current.length; i++) {
            if (countryRef.current[i] && countryRef.current[i].checked) {
                countryRef.current[i].checked = false;
            }
        }
        getPrivateItineraryData();
    }
    const handleClearAll = () => {
        clearAllData();
    }

    const priceWiseItinerary = getPriceWiseItinerary(itineraryPrivateData, priceDefault);
    const daysWiseItinerary = getDaysWiseItinerary(priceWiseItinerary, daysDefault);
    const ratingWiseItinerary = getRatingWiseItinerary(daysWiseItinerary, updateRatingFilter);
    const travellersWiseItinerary = getTravellersWiseItinerary(ratingWiseItinerary, updateTravelerFilter);
    const datesWiseItinerary = getDateWiseItinerary(travellersWiseItinerary, updateDateFilter);
    const countryWiseItinerary = getCountryWiseItinerary(datesWiseItinerary, countryName);
    const cityWiseItinerary = getCityWiseItinerary(countryWiseItinerary, updateCityFilter);
    console.log("Private Itinerary", cityWiseItinerary)

    useEffect(() => {
        getPrivateItineraryData();
    }, [])

    return (
        <>
            <div className="row">
                <div className="col-lg-3">
                    <div className="filters-bar">
                        <div className="filterbar-header">
                            <h5>Filter</h5>
                            <button type="button" onClick={handleClearAll} className="btn btn-border-color clear-all">Clear all</button>
                        </div>
                        <Accordion alwaysOpen>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Price</Accordion.Header>
                                <Accordion.Body>
                                    {itineraryPrivateData.length > 0 ? <> <InputRange
                                        step={1}
                                        // formatLabel={value => `${value} ${currencyData?.currency}`}
                                        formatLabel={value => null}
                                        draggableTrack={false}
                                        allowSameValues={false}
                                        minValue={minMaxPriceValue.min}
                                        maxValue={minMaxPriceValue.max}
                                        value={filterPriceValue}
                                        onChange={value => setFilterPriceValue(value)}
                                        onChangeComplete={value => handlePriceFilterComplete(value)}
                                    />
                                        <div className="pricerange-minmax">
                                            <span>{currencyData?.currency} {filterPriceValue.min} </span>
                                            <span>{currencyData?.currency} {filterPriceValue.max} </span>
                                        </div> </> : null}
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Country</Accordion.Header>
                                <Accordion.Body>
                                    <div className="filter-search-box">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Search country ..."
                                            value={searchCountryQuery}
                                            onChange={handleSearchCountryChange}
                                        />
                                        {searchCountryQuery === "" ? "" : <button onClick={handleSearchClear} type="button" className="btn btn-clear"><i className="bi bi-x"></i></button>}
                                    </div>
                                    <ul className="filter-list-ul">
                                        {filterCountryList && filterCountryList.map((country, i) => (
                                            <li className="CountryList" key={country.name}>
                                                <div className="form-check">
                                                    <input ref={(element) => { countryRef.current[i] = element }} onChange={(e) => handleCountryChange(e, country)} className="form-check-input" type="radio" name="countryFilter" value={country.name} id={`countryName${i}`} />
                                                    <label className="form-check-label" htmlFor={`countryName${i}`}>
                                                        {country.name}
                                                    </label>
                                                </div>
                                            </li>
                                        ))}
                                        {filterCountryList.length === 0 && (
                                            <li className="no-result text-center">No country found.</li>
                                        )}
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>City</Accordion.Header>
                                <Accordion.Body>
                                    <MultiSelect
                                        className="custom-multi-select"
                                        options={updatedCity}
                                        value={selectedCityData}
                                        onChange={handleCityChange}
                                        labelledBy="Select city"
                                        hasSelectAll={false}
                                        overrideStrings={{
                                            selectSomeItems: "Select city",
                                            noOptions: "City not found.",
                                        }}
                                        ClearIcon={<i className="bi bi-x"></i>}
                                        ClearSelectedIcon={<i className="bi bi-x"></i>}
                                    />
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>Days</Accordion.Header>
                                <Accordion.Body>
                                    <InputRange
                                        step={1}
                                        formatLabel={value => null}
                                        draggableTrack={false}
                                        allowSameValues={false}
                                        value={filterDaysValue}
                                        minValue={1}
                                        maxValue={60}
                                        onChange={value => setFilterDaysValue(value)}
                                        onChangeComplete={value => handleDaysFilterComplete(value)}
                                    />
                                    <div className="pricerange-minmax">
                                        <span>{filterDaysValue.min} days</span>
                                        <span>{filterDaysValue.max} days</span>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>Date</Accordion.Header>
                                <Accordion.Body className="date-accordian-bx">
                                    <DateRange
                                        editableDateInputs={true}
                                        onChange={item => handleDateFilter(item)}
                                        moveRangeOnFirstSelection={false}
                                        ranges={stateDate}
                                        rangeColors={["#004aad"]}
                                    />
                                    {/* <DatePicker
                        selected={toDateFilter}
                        onChange={date => handleDateFilter(date)}
                        inline
                        selectsStart
                        selectsEnd
                        startDate={new Date()}
          endDate={new Date()}
                        /> */}
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header>Rating</Accordion.Header>
                                <Accordion.Body>
                                    <ul className="filter-list-ul">
                                        <li>
                                            <div className="form-check">
                                                <input ref={(element) => { ratingRef.current[0] = element }} onChange={handleTravellerRatingChange} className="form-check-input" type="checkbox" value="5" id="Rating5" />
                                                <label className="form-check-label rating-filter" htmlFor="Rating5">
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="form-check">
                                                <input ref={(element) => { ratingRef.current[1] = element }} onChange={handleTravellerRatingChange} className="form-check-input" type="checkbox" value="4" id="Rating4" />
                                                <label className="form-check-label rating-filter" htmlFor="Rating4">
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star"></i>
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="form-check">
                                                <input ref={(element) => { ratingRef.current[2] = element }} onChange={handleTravellerRatingChange} className="form-check-input" type="checkbox" value="3" id="Rating3" />
                                                <label className="form-check-label rating-filter" htmlFor="Rating3">
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star"></i>
                                                    <i className="bi bi-star"></i>
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="form-check">
                                                <input ref={(element) => { ratingRef.current[3] = element }} onChange={handleTravellerRatingChange} className="form-check-input" type="checkbox" value="2" id="Rating2" />
                                                <label className="form-check-label rating-filter" htmlFor="Rating2">
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star"></i>
                                                    <i className="bi bi-star"></i>
                                                    <i className="bi bi-star"></i>
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="form-check">
                                                <input ref={(element) => { ratingRef.current[4] = element }} onChange={handleTravellerRatingChange} className="form-check-input" type="checkbox" value="1" id="Rating1" />
                                                <label className="form-check-label rating-filter" htmlFor="Rating1">
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star"></i>
                                                    <i className="bi bi-star"></i>
                                                    <i className="bi bi-star"></i>
                                                    <i className="bi bi-star"></i>
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="6">
                                <Accordion.Header>Travellers</Accordion.Header>
                                <Accordion.Body>
                                    <ul className="filter-list-ul">
                                        <li>
                                            <div className="form-check">
                                                <input ref={(element) => { travellersRef.current[0] = element }} onChange={handleTravellerChange} className="form-check-input" type="checkbox" value="Solo" id="Solo" />
                                                <label className="form-check-label" htmlFor="Solo">
                                                    Solo
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="form-check">
                                                <input ref={(element) => { travellersRef.current[1] = element }} onChange={handleTravellerChange} className="form-check-input" type="checkbox" value="Couple" id="Couple" />
                                                <label className="form-check-label" htmlFor="Couple">
                                                    Couple
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="form-check">
                                                <input ref={(element) => { travellersRef.current[2] = element }} onChange={handleTravellerChange} className="form-check-input" type="checkbox" value="Family" id="Family" />
                                                <label className="form-check-label" htmlFor="Family">
                                                    Family
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="form-check">
                                                <input ref={(element) => { travellersRef.current[3] = element }} onChange={handleTravellerChange} className="form-check-input" type="checkbox" value="Group" id="Group" />
                                                <label className="form-check-label" htmlFor="Group">
                                                    Group
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </div>
                <div className="col-lg-9 mt-4 mt-lg-0">
                    <Pagination
                        className="pagination-style paginate-item-none text-end mb-2"
                        showTitle={false}
                        showTotal={(total, range) => `(Showing ${range[0]} - ${range[1]} itineraries of ${total} itineraries)`}
                        onChange={privatePaginationChange}
                        total={itineraryPrivateData?.length}
                        current={currentPrivatePaginate}
                        pageSize={sizePrivatePaginate}
                        showSizeChanger={false}
                        onShowSizeChange={perPageChangePrivate}
                        hideOnSinglePage={false}
                    />
                    <div className="sortby-select-bx">
                        <span className="p-sortby-label">Sort By:</span>
                        <button type="button" className="p-sort-item" onClick={changeRecentSort}>Recent</button>
                        <button type="button" className="p-sort-item" onClick={changePopularitySort}>Popularity</button>
                        <button type="button" className="p-sort-item" onClick={changePriceLh}>Price - Low to High</button>
                        <button type="button" className="p-sort-item" onClick={changePriceHl}>Price - High to Low</button>
                    </div>
                    {isLoading ? <LoadingSpinnerTwo /> : cityWiseItinerary?.length === 0 ? <p className="text-center">No itinerary found.</p> :
                        <ul className="private-itineraries-ul">
                            {cityWiseItinerary?.length > 0 && cityWiseItinerary.slice((currentPrivatePaginate - 1) * sizePrivatePaginate, currentPrivatePaginate * sizePrivatePaginate).map((item, i) => {
                                return (
                                    <>
                                        <li key={i}>
                                            <p>Someone built a <span>{item.destinationCityDetails.reduce((accumulator, current) => accumulator + current.itineraryDatys.length, 0)} Days</span> itinerary {item.destinationCityDetails[0].from_city.city} to {item.destinationCityDetails[0].destination_city.city}. </p>
                                            <div className="pint-right">
                                                <div className="int-img-badge">
                                                    {item.destinationCityDetails.map((distitem, a) => {
                                                        return <>
                                                            {distitem.itineraryDatys.map((daysitem, b) => {
                                                                return <>
                                                                    {daysitem.itineraryDaysDetails.map((dtitem, c) => {
                                                                        return <>
                                                                            {dtitem.days_type === "Hotel" ?
                                                                                <>
                                                                                    {dtitem.hotelDetails.response_data.hotelImages.map((hotelImg, h) => {
                                                                                        return
                                                                                        <>
                                                                                            {h === 1 ? <span><img src={`${hotelImg.url}`} alt="" /></span> : null}
                                                                                        </>
                                                                                    })}</>
                                                                                : null}
                                                                            {dtitem.days_type === "Food" ?
                                                                                <>{dtitem.icon === "" ? <span><img src={`${noPictureAvailable}`} alt="" /></span> : <span><img src={`${photoUrl}maxwidth=400&photoreference=${dtitem.icon}${apikey}`} alt="" /></span>}</>
                                                                                : null}
                                                                            {dtitem.days_type === "Activity" ?
                                                                                <>{dtitem.icon === "" ? <span><img src={`${noPictureAvailable}`} alt="" /></span> : <span><img src={`${photoUrl}maxwidth=400&photoreference=${dtitem.icon}${apikey}`} alt="" /></span>}</>
                                                                                : null}
                                                                            {dtitem.days_type === "Others" ?
                                                                                <>{dtitem.icon === "" ? <span><img src={`${noPictureAvailable}`} alt="" /></span> : <span><img src={`${imageUrlOthers + dtitem.icon}`} alt="" /></span>}</>
                                                                                : null}
                                                                        </>
                                                                    })}
                                                                </>
                                                            })}
                                                        </>
                                                    })}
                                                </div>
                                                {authed?.isAuth === true ?
                                                    <Dropdown className="int-btn-dropdown" onToggle={(isOpen) => handleToggleDrp(isOpen)} onClick={(e) => handlePrivateItemInfo(item, e)}>
                                                        <OverlayTrigger
                                                            placement="bottom"
                                                            overlay={<Tooltip className="customTooltip">Click here to know more</Tooltip>}
                                                        >
                                                            <Dropdown.Toggle className="int-btn-info">
                                                                <img src={infoIcon} alt="Info icon" />
                                                            </Dropdown.Toggle>
                                                        </OverlayTrigger>
                                                        <Dropdown.Menu className="int-dropdown">
                                                            <form onSubmit={privateItemFromik.handleSubmit}>
                                                                <div className="int-input-field">
                                                                    <input onChange={privateItemFromik.handleChange} onBlur={privateItemFromik.handleBlur} value={privateItemFromik.values.name} name="name" className="form-control" type="text" placeholder="Your Name" />
                                                                    {privateItemFromik.touched.name && privateItemFromik.errors.name ? <span className="errors-msg">{privateItemFromik.errors.name}</span> : null}
                                                                </div>
                                                                <div className="int-input-field mrt-15">
                                                                    <input onChange={privateItemFromik.handleChange} onBlur={privateItemFromik.handleBlur} value={privateItemFromik.values.email} name="email" className="form-control" type="email" placeholder="Your Email" />
                                                                    {privateItemFromik.touched.email && privateItemFromik.errors.email ? <span className="errors-msg">{privateItemFromik.errors.email}</span> : null}
                                                                </div>
                                                                <div className="int-input-field mrt-15">
                                                                    <input onChange={privateItemFromik.handleChange} value={privateItemFromik.values.phone} name="phone" className="form-control" type="text" placeholder="Phone Number" />
                                                                </div>
                                                                <div className="int-input-field mrt-20 text-center">
                                                                    <button disabled={!(privateItemFromik.isValid && privateItemFromik.dirty)} type="submit" className="btn btn-primary-color">Submit <img src={WhiteArrowIcon} alt="" /></button>
                                                                </div>
                                                            </form>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    :
                                                    <div className="int-btn-dropdown">
                                                        <OverlayTrigger
                                                            placement="bottom"
                                                            overlay={<Tooltip className="customTooltip">Click here to know more</Tooltip>}
                                                        >
                                                            <button className="btn int-btn-info" onClick={() => history.push("/signin")}>
                                                                <img src={infoIcon} alt="Info icon" />
                                                            </button>
                                                        </OverlayTrigger>
                                                    </div>}
                                            </div>
                                        </li>
                                    </>
                                )
                            })}
                        </ul>
                    }

                    <Pagination
                        className="pagination-style text-end mt-4"
                        showTitle={false}
                        showTotal={(total, range) => `Showing ${range[0]} - ${range[1]} itineraries of ${total} itineraries`}
                        onChange={privatePaginationChange}
                        total={itineraryPrivateData?.length}
                        current={currentPrivatePaginate}
                        pageSize={sizePrivatePaginate}
                        showSizeChanger={false}
                        onShowSizeChange={perPageChangePrivate}
                        hideOnSinglePage={true}
                    />
                </div>
            </div>
        </>
    )
});

export default PrivateItinerary;