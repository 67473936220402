import './MainContainer.scss';
import React from 'react';
import Header from '../../views/Main/shared/header/Header';
import Footer from '../../views/Main/shared/footer/Footer';

const MainContainer = ({ children }) => {
  return (
    <>
    <div className="body-wrapper">
      <Header />
      <div className="body-content">
      {children}
      </div>
      <Footer />
      </div>
    </>
  );
}

export default MainContainer;

