import React, { createContext, useContext, useState } from "react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const token = JSON.parse(localStorage.getItem("token_gw"));
    const user = JSON.parse(localStorage.getItem("login_gw"));
    const currency = JSON.parse(localStorage.getItem("currency_gw"));
    const [authed, setAuthed] = useState(
        token && user
            ? { isAuth: true, token, user }
            : { isAuth: false, token: "", user: {} }
    );

    const [currencyData, setCurrencyData] = useState(currency? currency : {currency: "", country: ""});
    // console.log("loginUser", authed)
    // console.log("currency", currencyData)
    return (
        <AuthContext.Provider value={{ authed, setAuthed, currencyData, setCurrencyData }}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => useContext(AuthContext);
