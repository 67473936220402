export const getPriceWiseItinerary = (itineraries, price) => {
    if (price) {
        let storePriceData = itineraries?.filter((itinerary) => itinerary?.currencyConvert?.new_amount >= price.min && itinerary?.currencyConvert?.new_amount <= price?.max)
        return storePriceData;
    } else {
        return itineraries;
    }
}

export const getDaysWiseItinerary = (itineraries, days) => {
    if (days) {
        let storeDaysData = itineraries?.filter((itinerary) => itinerary?.total_days >= days?.min && itinerary?.total_days <= days?.max)
        return storeDaysData;
    } else {
        return itineraries;
    }
}

export const getRatingWiseItinerary = (itineraries, ratings) => {
    if (ratings.length > 0) {
        let storeRatingData = itineraries?.filter((itinerary) => ratings?.map(Number).includes(itinerary?.avg_rating));
        return storeRatingData;
    } else {
        return itineraries;
    }
}

export const getTravellersWiseItinerary = (itineraries, travellers) => {
    if (travellers.length > 0) {
        let storeTravellersData = itineraries.filter((itinerary) => travellers.includes(itinerary.travellers_type));
        return storeTravellersData;
    } else {
        return itineraries;
    }
}

export const getDateWiseItinerary = (itineraries, dates) => {
    let storeDatesData = [];
    if (dates[0].status === true) {
        itineraries.map((itinerary) => {
            let checkDateArr = itinerary.total_start_date.filter((item) => item.date >= new Date(dates[0].startDate).toISOString() && item.date <= new Date(dates[0].endDate).toISOString());
            if (checkDateArr.length > 0) {
                storeDatesData.push(itinerary);
            }
        })
        return storeDatesData;
    } else {
        return itineraries;
    }
}

export const getCountryWiseItinerary = (itineraries, countries) => {
    let storeCountryData = [];
    if (countries) {
        itineraries.map((itinerary) => {
            let checkCountryArr = itinerary.total_destination_city.filter((item) => countries.includes(item.country));
            if (checkCountryArr.length > 0) {
                storeCountryData.push(itinerary);
            }
        })
        return storeCountryData;
    } else {
        return itineraries;
    }
}

export const getCityWiseItinerary = (itineraries, cities) => {
    let citiesArr = [];
    let storeCitiesData = [];
    if (cities.length > 0) {
        for (let i = 0; i < cities.length; i++) {
            citiesArr.push(cities[i].name);
        }
        itineraries.map((itinerary) => {
            let checkCityArr = itinerary.total_destination_city.filter((item) => citiesArr.includes(item.city));
            if (checkCityArr.length > 0) {
                storeCitiesData.push(itinerary);
            }
        })
        return storeCitiesData;
    } else {
        return itineraries;
    }
}