import React, { useState, useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import sponseredLogo1 from '../../../../assets/images/spns-logo-1.png'; 
import sponseredLogo2 from '../../../../assets/images/spns-logo-2.png'; 
import sponseredLogo3 from '../../../../assets/images/spns-logo-3.png'; 
import sponseredLogo4 from '../../../../assets/images/spns-logo-4.png'; 
import sponseredLogo5 from '../../../../assets/images/spns-logo-5.png'; 

const sponseredSliderOptions = {
    loop: false,
    margin: 10,
    mouseDrag: false,
    touchDrag: true,
    responsiveClass: true,
    navText: ["",""],
    responsive: {
        0: {
            items: 3,
            nav: false,
            dots: true
        },
        768: {
            items: 4,
            nav: false,
            dots: true
        },
        1024: {
            items: 5,
            nav: true,
            dots: false,
            margin: 20
        }
    }
}

const SponsoredSlider = () => {
    
    useEffect(()=>{
        
    },[]);

    return ( 
        <>
        <OwlCarousel {...sponseredSliderOptions} className="owl-theme owl-carousel custom-owl-theme">
            <div className="item">
                <div className="sponsered-item-box">
                    <img src={sponseredLogo1} alt="sponsered logo 1"/>
                </div>
            </div>
            <div className="item">
                <div className="sponsered-item-box">
                    <img src={sponseredLogo2} alt="sponsered logo 2"/>
                </div>
            </div>
            <div className="item">
                <div className="sponsered-item-box">
                    <img src={sponseredLogo3} alt="sponsered logo 3"/>
                </div>
            </div>
            <div className="item">
                <div className="sponsered-item-box">
                    <img src={sponseredLogo4} alt="sponsered logo 4"/>
                </div>
            </div>
            <div className="item">
                <div className="sponsered-item-box">
                    <img src={sponseredLogo5} alt="sponsered logo 5"/>
                </div>
            </div>
        </OwlCarousel>
        </>
    );
};
 
export default SponsoredSlider;