import React, {useState, useEffect} from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {getItinerariesApi} from '../../../services/AdminService';
import logo from '../../../assets/images/logo.png';
import moment from 'moment';
import { Page, Text, View, Image, Document, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
import foodIcon from '../../../assets/images/food-icon-60.png';
import stayIcon from '../../../assets/images/stay-icon-60.png';
import activityIcon from '../../../assets/images/activity-icon-60.png';
import othersIcon from '../../../assets/images/others-icon-60.png';
import ItineraryItem from './ItineraryItem';
import { useAuth } from '../../../contexts/AuthProvider';


const styles = StyleSheet.create({
    page: {
        paddingLeft:15,
        paddingRight: 15,
        paddingTop:60,
        paddingBottom: 35
    },
    section: {
        flexGrow: 1
    },
    footer: {
        position: "absolute",
        fontSize: 8,
        bottom: 0,
        left: 0,
        right: 0,
        paddingTop: 10,
        paddingBottom: 10,
        borderTop:"1px solid #ced4da",
        textAlign: "center",
        color: "#555"
    },
    header:{
        position: "absolute",
        fontSize: 8,
        top: 0,
        left: 0,
        right: 0,
        borderBottom:"1px solid #ced4da",
        textAlign: "center",
        color: "#555",
        display: "flex",
        flexDirection:'row',
        justifyContent:"space-between",
        alignItems:"center",
        paddingLeft:15,
        paddingRight: 15,
        paddingTop:5,
        paddingBottom: 5
    },
    headertxt:{
        fontSize: 9,
        color: "#555",
        marginTop: 2,
        marginBottom: 2,
    },
    headerlogo:{
        width: 50,
        height: 50,
        objectFit: "contain",
    },
    itemlist:{
        backgroundColor: "#E2F1FA",
        borderRadius: "3px",
        padding: 8,
        marginTop: 10,
        display: "flex",
        flexDirection:'row',
        // flexGrow: 1
    },
    itemlogo:{
        width: 50,
        height: 50,
        borderRadius: "3px",
        backgroundColor: "#cee0f3",
        display: "flex",
        alignItems:"center",
        justifyContent: "center",
    },
    itemlogoimg:{
        width: "100%",
        height: "100%",
        objectFit: "contain",
        borderRadius: "3px",
    },
    iteminfoimg:{
        display: "inline-flex",
        flexDirection:'row',
        borderRadius: "20px",
        backgroundColor: "#E2F1FA",
    },
    iteminfo:{
        paddingLeft: 10
    },
    itemtitle:{
        fontSize: 12,
        color: "#000",
        marginTop: 5,
    },
    itembudget:{
        fontSize: 10,
        color: "#333",
        marginTop: 4,
    },
    itemtime:{
        fontSize: 10,
        color: "#555",
        marginTop: 4,
    },
    iteminfoicon:{
        width: 10,
        height: 10, 
    },
    iteminfotype:{
        fontSize: 10,
        color: "#778E9C",
        //paddingLeft:5,
    },
    daysitem:{
        borderRadius: "3px",
        backgroundColor: "#E2F1FA",
        padding:8,
        marginTop: 10,
    },
    daystxt1:{
        fontSize:12,
        color: "#000"
    },
    daystxt2:{
        fontSize:10,
        color: "#555",
        marginTop: 3
    },
    destination:{
        marginTop: 10
    },
    destinationtxt:{
        fontSize:13,
        fontWeight:"600",
        color: "#000"
    },
    costtxt:{
        fontSize:11,
        fontWeight:"600",
        color: "#000",
        marginTop: 10
    }
});


const ItineraryItemPreview = () => {
    const {authed, currencyData} = useAuth();
    const history = useHistory();
    const location = useLocation();
    const searchPrams = new URLSearchParams(location.search);
    const [itineraryData, setItineraryData] = useState();

    const getDaysPreviewData = async ()=>{
        const Obj = {
            userId: searchPrams.get("userId"),
            itineraryId: searchPrams.get("itineraryId"),
            newCurrency: currencyData?.currency
        }
        // console.log('Obj===', Obj)
        await getItinerariesApi(Obj, async (res) => {
            if (res.status === 404) {
                console.log('Something went wrong');
            }
            if (res.status === 200) {
                // console.log(res.data.data.message);
                if(res.data.data.data === null) return
                setItineraryData(res.data.data.data);
                // console.log("res getItinerariesApi ====", res);
            }
        });
    }

    const dayNumber =(i, d)=>{
        let s = 0;
        for(let j =0; j < i ; j++){
          s += Number(itineraryData?.destinationCityDetails[j]?.itineraryDatys?.length);
        }
        return s + d
    }

    useEffect(() => {
        getDaysPreviewData();
    }, []);

    const PdfDocument = () => {
        return <>
        <Document author="" title="Gladiatours">
            <Page size="LETTER" style={styles.page} wrap>
                <View style={styles.header} wrap={false} fixed>
                    <Image style={styles.headerlogo} source={{uri: logo}}/>
                    <View style={styles.headerinfo}>
                        <Text style={styles.headertxt}>Name: {authed? authed?.user?.name: "N/A"}</Text>
                        <Text style={styles.headertxt}>Email: {authed? authed?.user?.email: "N/A"}</Text>
                        <Text style={styles.headertxt}>Date: {itineraryData?.updatedAt ? moment(itineraryData?.updatedAt).format("DD-MM-YYYY"): "N/A"}</Text>
                    </View>
                </View>
                <View wrap={false}>
                    <Text style={styles.costtxt}>Estimated cost: {itineraryData?.currencyConvert ? <>{Math.floor(itineraryData?.currencyConvert?.new_amount)} {itineraryData?.currencyConvert?.new_currency}</> : "N/A"}</Text>
                </View>
                {!itineraryData ? null : itineraryData?.destinationCityDetails?.map((destinationitem, i) =>{
                    return (
                        <>
                            <View style={styles.destination} wrap={false}>
                                <Text style={styles.destinationtxt}>Destination City - {destinationitem?.destination_city?.city}</Text>
                            </View>
                            {destinationitem?.itineraryDatys?.map((daysitem, d) =>{
                                return (
                                <>
                                    <View style={styles.daysitem} wrap={false}>
                                        <Text style={styles.daystxt1}>Day {i > 0? dayNumber(i, daysitem?.day) : daysitem?.day}</Text>
                                        <Text style={styles.daystxt2}>{moment(daysitem.date).format("DD MMMM yyyy")}</Text>
                                    </View>    
                                    {daysitem?.itineraryDaysDetails?.sort((a, b) => a.time > b.time ? 1 : -1).map((item, dt) =>{ 
                                        return (
                                            <>
                                            {item.days_type === "Hotel" ?
                                                <View style={styles.itemlist} wrap={false}>
                                                    <View style={styles.itemlogo}>
                                                        <Image style={styles.itemlogoimg} source={{uri: stayIcon}}/>
                                                    </View>
                                                    <View style={styles.iteminfo}>
                                                        <View style={styles.iteminfoimg}><Text style={styles.iteminfotype}>Stays</Text></View>
                                                        <Text style={styles.itemtitle}>{item.hotelDetails.response_data.name}</Text>
                                                        {item.amount === undefined || item.amount === null ? "":<Text style={styles.itembudget}>{item.currencyConvert ? Math.floor(item.currencyConvert.new_amount) : Math.floor(item.amount)} {item.currencyConvert ? item.currencyConvert.new_currency : item.currency}</Text>}
                                                        <Text style={styles.itemtime}>{item.time}</Text>
                                                    </View>
                                                </View>
                                            : ""}
                                            {item.days_type === "Food" ?
                                                <View style={styles.itemlist} wrap={false}>
                                                    <View style={styles.itemlogo}>
                                                        <Image style={styles.itemlogoimg} source={{uri: foodIcon}}/>
                                                    </View>
                                                    <View style={styles.iteminfo}>
                                                        <View style={styles.iteminfoimg}><Text style={styles.iteminfotype}>Food</Text></View>
                                                        <Text style={styles.itemtitle}>{item.name}</Text>
                                                        {item.amount === undefined || item.amount === null ? "":<Text style={styles.itembudget}>{item.currencyConvert ? Math.floor(item.currencyConvert.new_amount) : Math.floor(item.amount)} {item.currencyConvert ? item.currencyConvert.new_currency : item.currency}</Text>}
                                                        <Text style={styles.itemtime}>{item.time}</Text>
                                                    </View>
                                                </View>
                                            : ""}
                                            {item.days_type === "Activity" ?
                                                <View style={styles.itemlist} wrap={false}>
                                                    <View style={styles.itemlogo}>
                                                        <Image style={styles.itemlogoimg} source={{uri: activityIcon}}/>
                                                    </View>
                                                    <View style={styles.iteminfo}>
                                                        <View style={styles.iteminfoimg}><Text style={styles.iteminfotype}>Activity</Text></View>
                                                        <Text style={styles.itemtitle}>{item.name}</Text>
                                                        {item.amount === undefined || item.amount === null ? "":<Text style={styles.itembudget}>{item.currencyConvert ? Math.floor(item.currencyConvert.new_amount) : Math.floor(item.amount)} {item.currencyConvert ? item.currencyConvert.new_currency : item.currency}</Text>}
                                                        <Text style={styles.itemtime}>{item.time}</Text>
                                                    </View>
                                                </View>
                                            : ""}
                                             {item.days_type === "Others" ?
                                                <View style={styles.itemlist} wrap={false}>
                                                    <View style={styles.itemlogo}>
                                                        <Image style={styles.itemlogoimg} source={{uri: othersIcon}}/>
                                                    </View>
                                                    <View style={styles.iteminfo}>
                                                        <View style={styles.iteminfoimg}><Text style={styles.iteminfotype}>Others</Text></View>
                                                        <Text style={styles.itemtitle}>{item.name}</Text>
                                                        {item.amount === undefined || item.amount === null ? "":<Text style={styles.itembudget}>{item.currencyConvert ? Math.floor(item.currencyConvert.new_amount) : Math.floor(item.amount)} {item.currencyConvert ? item.currencyConvert.new_currency : item.currency}</Text>}
                                                        <Text style={styles.itemtime}>{item.time}</Text>
                                                    </View>
                                                </View>
                                            : ""}
                                            </>
                                    )})}
                                </>
                            )})}
                        </>
                )})}
                <View style={styles.footer} fixed>
                    <Text>© copyright 2022 Gladiatours. All Rights Reserved.</Text>
                </View>
            </Page>
        </Document>
        </>
    }

    return ( 
        <>
        <div className="content-body">
            <div className="d-flex align-items-center mb-2">
                <button type="button" className="btn btn-secondary-color btn-border-color btn-width-30 me-2"  onClick={() => history.push(`/admin/itineraries-list`)}><i className="bi bi-arrow-left"></i></button> 
                <h1 className="d-col-title">Preview itinerary</h1>
            </div> 
            <ItineraryItem/>
            <div className="form-btn-groups d-flex justify-content-end mt-4">
                <button type="button" className="btn btn-secondary-color btn-border-color" onClick={() => history.push('/admin/itineraries-list')}><i className="bi bi-arrow-left"></i>&nbsp;&nbsp;Back</button>
                <PDFDownloadLink  className="btn btn-primary-color ms-3" document={<PdfDocument />} fileName={"Itinerary" + new Date().getTime() + ".pdf"}>
                {({ blob, url, loading, error }) => ('Export to PDF')}
                </PDFDownloadLink>
            </div>
        </div>
       
        </>
    );
}

export default ItineraryItemPreview;
