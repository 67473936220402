import axios from "axios"; 
import { baseURL } from "./CommonUrl";

const headerToken = () =>{
    const token = JSON.parse(localStorage.getItem("token_gw"));
    const httpOptions = {
        headers: {"Authorization" : `Bearer ${token}`} 
    }
    return httpOptions;
}

export const changePasswordService = async(data, cb) => {
    const fullURL = baseURL + "changePassword"
    axios.post(fullURL, data, headerToken())
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}
export const ForgotPasswordService = async(data, cb) => {
    const fullURL = baseURL + "forgot-password"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}
export const ResetPasswordService = async(data, cb) => {
    const fullURL = baseURL + "reset-password"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}
export const SignupService = async(data, cb) => {
    const fullURL = baseURL + "registration"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}

export const OtpVerificationService = async(data, cb) => {
    const fullURL = baseURL + "registration-ot-verified"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}

export const SigninService = async(data, cb) => {
    const fullURL = baseURL + "login"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}
export const SocialLoginService = async(data, cb) => {
    const fullURL = baseURL + "social-login"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}
export const EditProfileService = async(data, cb) => {
    const fullURL = baseURL + "edit-profile"
    axios.post(fullURL, data, headerToken())
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}