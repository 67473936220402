import axios from "axios";
import { baseURL } from "./CommonUrl";

export const QuotesRequestService = async(data, cb) => {
    const fullURL = baseURL + "add_request_for_qoute"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}
export const QuotesContentService = async(data, cb) => {
    const fullURL = baseURL + "getRequestQouteContent"
    axios.get(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}

export const addManageTravelService = async(data, cb) => {
    const fullURL = baseURL + "add_manage_travel"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}
export const getManageTravelService = async(data, cb) => {
    const fullURL = baseURL + "get_manage_travel"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}
export const editTravellerService = async(data, cb) => {
    const fullURL = baseURL + "edit_manage_travel"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}
export const deleteTravellerService = async(data, cb) => {
    const fullURL = baseURL + "delete_manage_travel"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}
export const activeDeactiveTravelService = async(data, cb) => {
    const fullURL = baseURL + "active_deactive_manage_travel"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}

export const createItinerariesApi = async(data, cb) => {
    const fullURL = baseURL + "CreateItineraryBackup"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}
export const editItinerariesApi = async(data, cb) => {
    const fullURL = baseURL + "edit_itinerary_data"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}
export const getItinerariesApi = async(data, cb) => {
    const fullURL = baseURL + "get_itinerary_data_backup"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}
export const getDraftAllApi = async(data, cb) => {
    const fullURL = baseURL + "get_all_itinerary_list_backup"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}
export const getFoodRestaurentAllApi = async(data, cb) => {
    const fullURL = baseURL + "get_food_restaurant_list"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}
export const addFoodRestaurentApi = async(data, cb) => {
    const fullURL = baseURL + "add_food_restaurant_data"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}
export const addActivityApi = async(data, cb) => {
    const fullURL = baseURL + "add_activity_data"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}
export const addOthersApi = async(data, cb) => {
    const fullURL = baseURL + "add_others"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}
export const deleteItineraryDaysDataApi = async(data, cb) => {
    const fullURL = baseURL + "delete_itinerary_days_data"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}
export const deleteDestinationCityApi = async(data, cb) => {
    const fullURL = baseURL + "itinerary_destination_city_remove"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}
export const deleteDestinationCityDaysApi = async(data, cb) => {
    const fullURL = baseURL + "itinerary_destination_city_days_remove"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}
export const getUserPublicPrivateListApi = async(data, cb) => {
    const fullURL = baseURL + "get_user_private_public_intineray_list"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}
export const updateItineraryPublishApi = async(data, cb) => {
    const fullURL = baseURL + "update_itinerary_publish_status"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}

export const removeItineraryApi = async(data, cb) => {
    const fullURL = baseURL + "remove_itinerary"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}
export const getUserItineraryCountApi = async(data, cb) => {
    const fullURL = baseURL + "get_user_public_private_stays_itinerary_count"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}
export const mergeItineraryApi = async(data, cb) => {
    const fullURL = baseURL + "marge_itinerary"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}
export const addExistingTripApi = async(data, cb) => {
    const fullURL = baseURL + "CreateItineraryBackupExistingTrip"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}

export const getUserItineraryListApi = async(data, cb) => {
    const fullURL = baseURL + "get_user_itinerary_list"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}
export const getUserItineraryCityListApi = async(data, cb) => {
    const fullURL = baseURL + "get_user_itinerary_city_list"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}