import "./Home.scss";
import React, { useState, useRef } from 'react';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import tabsIcon1 from '../../../assets/images/icon-1.png';
import tabsIcon2 from '../../../assets/images/icon-2.png';
import tabsIcon3 from '../../../assets/images/icon-3.png';
import tabsIcon4 from '../../../assets/images/icon-4.png';
import tabsIcon5 from '../../../assets/images/icon-5.png';
import FlightSearchForm from './../shared/common/FlightSearchForm';
import StaySearchForm from './../shared/common/StaySearchForm';
import PopularTravelItineraries from './../shared/common/PopularTravelItineraries';
import TopDestinationItineraries from './../shared/common/TopDestinationItineraries';
import SponsoredSlider from './../shared/common/SponsoredSlider';
import PrivateItinerary from "./components/private-itinerary/PrivateItinerary";
import PublicItinerary from "./components/public-itinerary/PublicItinerary";
import { HomeSlider } from "./components/home-slider/HomeSlider";
import { scroller } from "react-scroll";

const Home = () => {
    const [existingTripBtn, setExistingTripBtn] = useState(false);
    const [tabsKey, setTabsKey] = useState('Public');
    const clearPublicRef = useRef();
    const clearPrivateRef = useRef();

    // New 
    const handleSelectTabs = (T) => {
        setTabsKey(T);
        setExistingTripBtn(false);
        if(tabsKey == 'Public'){
            clearPublicRef.current.clearPublicFilter();
        }else{
            clearPrivateRef.current.clearPrivateFilter();
        }
    }

    const customizeTripTogggle = () => {
        setExistingTripBtn(true);
        scroller.scrollTo("customizeExistingTrip", {
          duration: 100,
          delay: 0,
          offset: -100,
          smooth: "easeInOutQuart",
        });
    };

    return (
        <>
            <section className="home-banner-wrapper">
                <HomeSlider toggleTrip={customizeTripTogggle}/>
            </section>
            {/* <button onClick={customizeTripTogggle} className="btn btn-primary-color b-btn-2">Customize existing trip</button> */}
            <section className="searchform-hold-wrap padd-bottom-70">
                <div className="container-lg">
                    <Tab.Container defaultActiveKey="Stays">
                        <div className="form-nav-tabsbar">
                            <Nav variant="pills" className="form-nav-tabs">
                                <Nav.Item>
                                    <Nav.Link eventKey="Stays" className="form-nav-link-1">
                                        <i><img src={tabsIcon1} alt="Stays" /></i>
                                        <span>Stays</span>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="Flights" className="form-nav-link-2">
                                        <i><img src={tabsIcon2} alt="Flights" /></i>
                                        <span>Flights</span>
                                    </Nav.Link>
                                </Nav.Item>
                                {/* <Nav.Item>
                                <Nav.Link eventKey="Cabs" className="form-nav-link-3">
                                    <i><img src={tabsIcon3} alt="Cabs"/></i>
                                    <span>Cabs</span>
                                </Nav.Link>
                            </Nav.Item> */}
                                <Nav.Item>
                                    <Nav.Link eventKey="Tours" className="form-nav-link-4">
                                        <i><img src={tabsIcon4} alt="Tours" /></i>
                                        <span>Tours</span>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="Activities" className="form-nav-link-5">
                                        <i><img src={tabsIcon5} alt="Activities" /></i>
                                        <span>Activities</span>
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                        <Tab.Content>
                            <Tab.Pane eventKey="Stays" className="search-h-block">
                                <StaySearchForm />
                            </Tab.Pane>
                            <Tab.Pane eventKey="Flights" className="search-h-block">
                                <FlightSearchForm />
                            </Tab.Pane>
                            {/* <Tab.Pane eventKey="Cabs">
                            Cabs
                        </Tab.Pane> */}
                            <Tab.Pane eventKey="Tours">
                                Coming soon
                            </Tab.Pane>
                            <Tab.Pane eventKey="Activities">
                                Coming soon
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </section>
            <section className="pti-hold-wrap padd-bottom-70 customizeExistingTrip">
                <div className="container-lg">
                    <Tab.Container activeKey={tabsKey} onSelect={(T) => handleSelectTabs(T)}>
                        <Nav variant="pills" className="home-nav-tabs">
                            <Nav.Item>
                                <Nav.Link eventKey="Public">Public Itineraries</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="Private">Private Itineraries</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey="Public">
                                <PublicItinerary ref={clearPublicRef} showExisting={existingTripBtn}/>
                            </Tab.Pane>
                            <Tab.Pane eventKey="Private">
                                <PrivateItinerary ref={clearPrivateRef} />
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </section>
            <div className="container-lg">
                <hr className="mt-0 mb-0" />
            </div>
            <section className="pti-hold-wrap padd-top-70 padd-bottom-70">
                <div className="container-lg">
                    <PopularTravelItineraries />
                </div>
            </section>
            <section className="tdf-hold-wrap padd-bottom-70">
                <div className="container-lg">
                    <TopDestinationItineraries />
                </div>
            </section>
            <section className="sponsered-hold-wrap padd-bottom-70">
                <div className="container-lg">
                    <SponsoredSlider />
                </div>
            </section>
            <section className="subscribe-hold-wrap padd-top-80 padd-bottom-80">
                <div className="container-lg">
                    <div className="flex-subscribe">
                        <span className="subscribe-shap-3"></span>
                        <span className="subscribe-shap subscribe-shap-1"></span>
                        <div className="subscribe-info">
                            <h3>Subscribe to get information, latest news and other interesting offers about Gladiatours</h3>
                        </div>
                        <div className="subscribe-box">
                            <div className="subscribe-input-field">
                                <i className="email-icon"></i>
                                <input type="email" className="form-control" placeholder="Your email" id="subscribeEmail" />
                            </div>
                            <button className="btn btn-primary-color" type="submit">Subscribe</button>
                        </div>
                        <span className="subscribe-shap subscribe-shap-2"></span>
                    </div>
                </div>
            </section>
        </>
    );

}

export default Home;
