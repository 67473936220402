import './ChangePassword.scss';
import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {changePasswordService} from '../../../services/AuthService';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {toast} from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
toast.configure();

const ChangePassword = () => {
    const history = useHistory();
    const [newpasswordShown, setNewPasswordShown] = useState(false);
    const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
    const [oldPasswordShown, setOldPasswordShown] = useState(false);
    const [isLoading, setIsloading] = useState(false);

    const toggleNewPassword = () => {
        setNewPasswordShown(!newpasswordShown);
    };
    const toggleConfirmPassword = () => {
        setConfirmPasswordShown(!confirmPasswordShown);
    };
    const toggleOldPassword = () => {
        setOldPasswordShown(!oldPasswordShown);
    };

    const formik = useFormik({
        initialValues: {
            oldpassword: "",
            newpassword: "",
            confirmpassword: ""
        },
        validationSchema: Yup.object({
            oldpassword: Yup.string().required("Please enter old password."),
            newpassword: Yup.string()
              .required('Please enter new password.')
              .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
              ),
            confirmpassword: Yup.string()
                .required('Please enter confirm password.')
                .when("newpassword", {
                    is: val => (val && val.length > 0 ? true : false),
                    then: Yup.string().oneOf(
                        [Yup.ref("newpassword")],
                        "Both password need to be the same."
                    )
            })
        }),
        onSubmit: async (values) => {  
            // console.log(values)
            // delete values.confirmpassword;
            setIsloading(true);
            const obj = {
                //authorization: "", 
                password: values.newpassword,
                oldpassword: values.oldpassword
            }
            // console.log(obj)
            //return
            await changePasswordService(obj, async (res) => {
                // console.log("API change password response: ", res);
                if (res.status === 404) {
                    console.log("Something went wrong");
                    return
                }
                if(res.status === 200){
                    if(res.data.data.success){
                        toast.success(res.data.data.message);
                        setIsloading(false);
                        formik.resetForm();
                        localStorage.clear();
                        history.push("/signin");
                    }else{
                        toast.error(res.data.data.errors.msg);
                    }
                }  
            });
        }
    });

    return ( 
        <>
 
        <div className="content-body">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <h1 className="d-col-title mb-3">Change Password</h1>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="mt-3 d-col-input-field rp-d-flex">
                            <label className="form-label">Old Password</label>
                            <div className="c-password-input">
                                <input onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.oldpassword} name="oldpassword" type={oldPasswordShown ? "text" : "password"} className="form-control" placeholder="Enter old password"/>
                                <button type="button" onClick={toggleOldPassword} className={oldPasswordShown ? "bi eye-btn bi-eye" : "bi eye-btn bi-eye-slash"}></button>
                            </div>
                            {formik.touched.oldpassword && formik.errors.oldpassword ? <p className="errors-msg">{formik.errors.oldpassword}</p> : null}
                        </div>
                        <div className="mt-3 d-col-input-field rp-d-flex">
                            <label className="form-label">New Password</label>
                            <div className="c-password-input">
                                <input onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.newpassword} name="newpassword" type={newpasswordShown ? "text" : "password"} className="form-control" placeholder="Enter new password"/>
                                {/* <button type="button" onClick={toggleNewPassword} className={newpasswordShown ? "bi eye-btn bi-eye" : "bi eye-btn bi-eye-slash"}></button> */}
                            </div>
                            {formik.touched.newpassword && formik.errors.newpassword ? <p className="errors-msg">{formik.errors.newpassword}</p> : null}
                        </div>
                        <div className="mt-3 d-col-input-field rp-d-flex">
                            <label className="form-label">Confirm New Password</label>
                            <div className="c-password-input">
                                <input onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.confirmpassword} name="confirmpassword" type={confirmPasswordShown ? "text" : "password"} className="form-control" placeholder="Re-enter new password"/>
                                {/* <button type="button" onClick={toggleConfirmPassword} className={confirmPasswordShown ? "bi eye-btn bi-eye" : "bi eye-btn bi-eye-slash"}></button> */}
                            </div>
                            {formik.touched.confirmpassword && formik.errors.confirmpassword ? <p className="errors-msg">{formik.errors.confirmpassword}</p> : null}
                        </div>
                        <div className="form-btn-groups d-flex justify-content-end mt-3">
                            <button type="submit" disabled={isLoading === true} className="btn btn-primary-color">{isLoading ? "Submitting..":"Submit"}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        
        </>
    );
}
 
export default ChangePassword;