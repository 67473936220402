import React, {useState} from 'react';
import "./ForgotPassword.scss";
import {Link} from 'react-router-dom';
import {ForgotPasswordService} from '../../../services/AuthService';
import Alert from 'react-bootstrap/Alert';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {toast} from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
toast.configure();

const ForgotPassword = () => {
    const [showMessage, setShowMessage] = useState("");
    const [visibleAlertError, setVisibleAlertError] = useState(false);
    const [visibleAlertSuccess, setVisibleAlertSuccess] = useState(false);
    const formik = useFormik({
        initialValues: {
            email: ""
        },
        validationSchema: Yup.object({
            email: Yup.string()
              .email('Invalid email address.')
              .required('Please enter email address.'),
        }),
        onSubmit: values => {  
            // console.log(values.email)
            ForgotPasswordService(values, async (res) => {
                //console.log("sign up response: ", res);
                if (res.status === 404){
                  const errMsg = res.data.response.data.errors.msg
                  setShowMessage(errMsg);
                  setVisibleAlertError(true);
                  setTimeout(() => {
                    setVisibleAlertError(false);
                }, 5000);
                  return
                } 
                if(res.status === 200){
                    // toast.success(res.data.data.message);
                    setShowMessage(res.data.data.message);
                    setVisibleAlertSuccess(true);
                    setTimeout(() => {
                        setVisibleAlertSuccess(false);
                    }, 5000);
                    formik.resetForm();
                    //history.push('/signin');
                }
            });
        }
    });


    return ( 
        <>
        <section className="signup-wrapper inner-mrt-80 padd-top-70 padd-bottom-70">
            <div className="container-lg">
                <div className="signin-row">
                    <div className="signin-col-block signin-form-block">
                        {visibleAlertSuccess && <Alert variant="success" onClose={() => setVisibleAlertSuccess(false)} dismissible>
                            <p>{showMessage}</p>
                        </Alert>}
                        {visibleAlertError && <Alert variant="danger" onClose={() => setVisibleAlertError(false)} dismissible>
                            <p>{showMessage}</p>
                        </Alert>}
                        <div className="signin-title-block">
                            <h1>Forgot Password?</h1>
                            <p>Request to reset your password.</p>
                        </div>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="frm-mrt-20">
                                <div className="form-input-fields">
                                    <i className="frm-icon f-mail-icon"></i>
                                    <div className="has-float-label">
                                        <input onChange={formik.handleChange} onBlur={formik.handleBlur} name="email" value={formik.values.email} className="form-control" type="email" id="EmailAddress" placeholder="Email Address"/>
                                        <label htmlFor="EmailAddress">Email Address</label>
                                    </div>
                                </div>
                                {formik.touched.email && formik.errors.email ? <p className="errors-msg">{formik.errors.email}</p> : null}
                            </div>
                            <div className="signin-frm-btns">
                                <button type="submit" className="btn btn-border-color btn-primary-color">Submit</button>
                            </div>
                        </form>
                    </div>
                    <div className="signin-col-block signin-img-block">
                        <h3>Hello, Friend</h3>
                        <p>Enter your personal details and start journey with us</p>
                        <Link to="/signin" className="btn btn-secondary-color frm-mrt-20">Sign in</Link>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}
 
export default ForgotPassword;