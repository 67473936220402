import './PreviewBooking.scss';
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import noPictureAvailable from '../../../../assets/images/no-picture-available.jpg';
import { HotelDetailService, HotelRoomRateRulesService } from '../../../../services/StayService';
import {LoadingSpinnerOne} from "../../../../loader/Loader";
import moment from 'moment';
import { useAuth } from '../../../../contexts/AuthProvider';

const PreviewBooking = (props) => {
    const {authed, currencyData} = useAuth();
    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [isLoading, setIsLoading] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [getHotelDetailsData, setHotelDetailsData] = useState();
    const [roomRateBasisData, setRoomRateBasisData] = useState();
    const [getNightsCount, setNightsCount] = useState("");
    const [userData, setUserData] = useState();
    const [getPaxDetailsData, setPaxDetailsData] = useState();
    
    const getHotelDetails = async () => {
        setIsLoading(true);
        const Obj = {
            hotelId: searchParams.get("hotelId"),
            productId: searchParams.get("productId"),
            sessionId: searchParams.get("sessionId"),
            tokenId: searchParams.get("tokenId")
        }
        await HotelDetailService(Obj, async (res) => {
            // console.log("Hotel deatils for check out: ", res.data.data);
            if (res.status === 404) {
                console.log('Something went wrong')
            }
            if (res.status === 200) {
                setIsLoading(false);
                await setHotelDetailsData(res.data.data.response_data);
            }
        });
    }
    const getHotelRoomRateData = async () => {
        setIsLoading(true);
        const Obj = {
            sessionId: searchParams.get("sessionId"),
            tokenId: searchParams.get("tokenId"),
            hotelId: searchParams.get("hotelId"),
            rateBasisId: searchParams.get("rateBasisId"),
            productId: searchParams.get("productId")
        }
        await HotelRoomRateRulesService(Obj, async (res) => {
            // console.log("HotelRoomRateRulesService====", res.data.data);
            if (res.status === 404) {
                console.log('Something went wrong')
            }
            if (res.status === 200) {
                await setRoomRateBasisData(res.data.data.response_data);
                setIsLoading(false);
            }
        });
    }
    const userSearchDataLocal = async () => {
        await setUserData(authed?.user);
        await setPaxDetailsData(JSON.parse(searchParams.get("paxDetails")));
        const startDate = moment(searchParams.get("checkInDate"));
        const endDate = moment(searchParams.get("checkOutDate"));
        setNightsCount(endDate.diff(startDate, 'days'));
    }
    
    const handlePreviewBookingEdit = ()=>{
        history.push({
            pathname:'/hotel-checkout/',
            search:(`sessionId=${searchParams.get("sessionId")}&&tokenId=${searchParams.get("tokenId")}&&hotelId=${searchParams.get("hotelId")}&&productId=${searchParams.get("productId")}&&rateBasisId=${searchParams.get("rateBasisId")}&&checkInDate=${searchParams.get("checkInDate")}&&checkOutDate=${searchParams.get("checkOutDate")}&&occupancy=${searchParams.get("occupancy")}`),
        });
    }
    

    const handleContinueBooking = async()=>{
        // const hotelCheckObj = {
        //     userId: userData._id,
        //     clientRef: userData._id,
        //     customeremail: userData.email,
        //     customerPhone: userData.phone,
        //     bookingNote: "",
        //     paxDetails: getArrPax,
        //     hotelDetails: getHotelDetailsData,
        //     checkIn: searchDateData.checkin,
        //     checkOut: searchDateData.checkout
        // }
        // //console.log('hotelCheckState==============', hotelCheckObj)
        // await UserBookHotelPayment(hotelCheckObj, async (res) => {
        //     console.log("HotelRoomRateRulesService====", res.data.data);
        //     if (res.status === 404) {
        //         console.log('Something went wrong')
        //     }
        //     if (!res.data.data.success) {
        //         toast.warning(res.data.data.message)
        //     }
        //     if (res.status === 200) {
        //         // let url = res.data.data.url
        //         // if (url){
        //         //     // return <Redirect to={url}  />
        //         //     // history.push(url);
        //         //     // window.location.replace(url)
        //         //     window.open(url)
        //         // }
        //     }
        // });

    }

    useEffect(() => {
        getHotelDetails();
        getHotelRoomRateData();
        userSearchDataLocal();
    }, []);

    return (
        <>
        <section className="inner-mrt-80">
            <div className="container-lg text-center">
                {/* <h1>Hotel Checkout</h1> */}
            </div>
        </section>
        {isLoading ? <LoadingSpinnerOne /> : null}
        <section className="hotel-checkout-wrapper padd-top-70 padd-bottom-70">
            <div className="container-lg">
                <h1 className="d-col-title mb-3">Preview Booking</h1>
                <div className="row">
                    <div className="col-md-8">
                        <div className="preview-booking-pane">
                            <h4>Hotel Info</h4>
                            <div className="preview-booking-body">
                                <div className="preview-hotel-item">
                                    <div className="preview-hotel-img-block">
                                        {getHotelDetailsData?.hotelImages ? (
                                            <img src={getHotelDetailsData?.hotelImages[0]?.url} alt="" />
                                            ) : (
                                            <img src={noPictureAvailable} alt="" />
                                        )}
                                    </div>
                                    <div className="preview-hotel-info-block">
                                        <div className="staring-box">
                                            <span><i className="bi bi-star-fill" aria-hidden="true"></i></span> {getHotelDetailsData?.hotelRating}
                                        </div>
                                        <h5>{getHotelDetailsData?.name}</h5>
                                        <p>{getHotelDetailsData?.address}</p>
                                    </div>
                                </div>
                                <div className="preview-hotelcheck-details mt-3">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="dayout-box">
                                                <span>Check In</span>
                                                <p>{moment(searchParams.get("checkInDate")).format("DD MMM, YYYY")}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="dayout-box">
                                                <span>Check Out</span>
                                                <p>{moment(searchParams.get("checkOutDate")).format("DD MMM, YYYY")}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="dayout-box">
                                                <span>Guests</span>
                                                {getPaxDetailsData && getPaxDetailsData.map((item, i)=>{
                                                    return<>
                                                        <p key={i}>Room {i+1} &nbsp;-&nbsp; {item.adult.firstName.length} Adult{item.child.firstName.length? <>,&nbsp;</>: ""}{item.child.firstName.length} Child</p>
                                                   </>
                                                })}
                                                <span>{getNightsCount? getNightsCount+1 : ""} Days  &amp; {getNightsCount? getNightsCount : ""} Nights</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="preview-booking-pane mt-3">
                            <h4>Guest Details</h4>
                            <div className="preview-booking-body">
                                <div className="preview-booking-guest">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="booking-guest-box">
                                                <span>Customer Name</span>
                                                <p>{userData? userData.name : ""}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="booking-guest-box">
                                                <span>Email</span>
                                                <p>{userData? userData.email : ""}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="preview-booking-guest-view table-responsive mt-3">
                                    <table className="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <th>Rooms</th>
                                                <th>Name</th>
                                            </tr>
                                            {getPaxDetailsData && getPaxDetailsData.map((item, i)=>{
                                                return(
                                                    <tr key={i}>
                                                        <td>Room No {i+1}</td>
                                                        <td>
                                                            Adult&nbsp;-&nbsp;
                                                            {item.adult.title.map((itemTitle)=>{return <>{itemTitle}&nbsp;</>})}
                                                            {item.adult.firstName.map((itemFirstName)=>{return <>{itemFirstName}&nbsp;</>})}
                                                            {item.adult.lastName.map((itemLastName)=>{return <>{itemLastName}&nbsp;</>})}
                                                            <br/>
                                                            Child&nbsp;-&nbsp; 
                                                            {item.child.title.map((itemTitle)=>{return <>{itemTitle}&nbsp;</>})}
                                                            {item.child.firstName.map((itemFirstName)=>{return <>{itemFirstName}&nbsp;</>})}
                                                            {item.child.lastName.map((itemLastName)=>{return <>{itemLastName}&nbsp;</>})}
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                            
                                        </tbody>
                                    </table>
                                </div>
                                <div className="d-flex justify-content-end mt-3">
                                    <button type="button" onClick={handlePreviewBookingEdit} className="btn btn-secondary-color btn-border-color">Edit</button>
                                    <button type="button" className="btn btn-primary-color ms-2">Confirm the Booking</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="preview-booking-pane">
                            <h4>Price Summary</h4>
                            <div className="preview-booking-body">
                                {roomRateBasisData && roomRateBasisData?.roomRates?.perBookingRates?.map((itemRoomRate)=>{
                                    return (
                                        <div className="preview-sub-total d-flex justify-content-between">
                                            <h6 className="my-0">Room Charges<br/><small className="text-muted">(1 room x 1 night)</small></h6>
                                            <span className="text-muted">{itemRoomRate?.currency} {itemRoomRate?.netPrice}</span>
                                        </div>
                                    )
                                })}
                                <div className="preview-total d-flex justify-content-between">
                                    <span className="sub-total">Total:</span>
                                    <span className="total_fare">{currencyData? currencyData?.currency : ""} {roomRateBasisData && roomRateBasisData?.roomRates?.perBookingRates?.reduce((accumulator, current) => accumulator + current.netPrice, 0)}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}

export default PreviewBooking;
