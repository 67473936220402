import "./Sidebar.scss";
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import BlankAvatar from '../../../../assets/images/blank-avatar.png';
import {imageUrlProfile} from '../../../../services/CommonUrl';
import { AuthContext } from "../../../../contexts/AuthProvider";

class Sidebar extends Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
  }
  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({[menuState] : false});
    } else if(Object.keys(this.state).length === 0) {
      this.setState({[menuState] : true});
    } else {
      Object.keys(this.state).forEach(i => {
        this.setState({[i]: false});
      });
      this.setState({[menuState] : true});
    }
  }
  toggleCloseCanvas() {
    document.querySelector('.sidebar-offcanvas').classList.remove('active');
    document.querySelector('.sidebar-overlay').classList.remove('active');
  }
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }
  componentDidUpdate(prevState) {
   
  }

  onRouteChanged() {
    Object.keys(this.state).forEach(i => {
      this.setState({[i]: false});
    });
    const dropdownPaths = [
      {path:'/admin/manage-itinerary', state: 'ManageItineraryMenuOpen'},
      // {path:'/admin/itenaries', state: 'ItenariesMenuOpen'},
    ];

    dropdownPaths.forEach((obj => {
      if (this.isPathActive(obj.path)) {
        this.setState({[obj.state] : true})
      }
    }));
 
  }

  render () {
    const { name, email, profileImage, loginType } = this.context.authed.user;
    return (
      <>
      
      <div onClick={()=> this.toggleCloseCanvas()} className="sidebar-overlay"></div>
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        {/* <Link to="/" className="btn btn-back"><i className="bi bi-arrow-left"></i></Link> */}
        <div className="text-end">
          <button type="button" onClick={()=> this.toggleCloseCanvas()} className="btn-close btn-close-white d-inline-block d-lg-none"><i className="bi bi-close"></i></button>
        </div>
        <ul className="nav">
          <li className="nav-item nav-profile mb-2">
            <Link className="nav-link" to="/admin/manage-profile">
              <div className="nav-profile-image">
              {profileImage === undefined || profileImage === null || profileImage === "" ?
                <img src={`${ BlankAvatar }`} alt="" /> : 
                <>{loginType === "social" ? <img src={`${ profileImage }`} alt={name} />: <img src={`${ imageUrlProfile + profileImage }`} alt={name} />}</>}
                {/* <div className="upload-profileimage-btn">
                    <i className="bi bi-images"></i>
                    <input type="file" name="file"/>
                </div> */}
              </div>
              <div className="nav-profile-text mt-2">
                <span className="text-medium">{name}</span>
                <span className="text-small">{email}</span>
              </div>
            </Link>
          </li>
          <li className={ this.isPathActive('/admin/dashboard') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link custom-link" to="/admin/dashboard">
              <i className="icon-dashboard menu-icon"></i>
              <span className="menu-title">Dashboard</span>
            </Link>
          </li>
          <li className={ this.isPathActive('/admin/manage-profile') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link custom-link" to="/admin/manage-profile">
              <i className="icon-settings menu-icon"></i>
              <span className="menu-title">Manage Profile</span>
            </Link>
          </li>
          <li className={ this.isPathActive('/admin/change-password') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link custom-link" to="/admin/change-password">
              <i className="icon-password menu-icon"></i>
              <span className="menu-title">Change Password</span>
            </Link>
          </li>
          <li className={ this.isPathActive('/admin/manage-travelers') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link custom-link" to="/admin/manage-travelers">
              <i className="icon-settings menu-icon"></i>
              <span className="menu-title">Manage Travelers</span>
            </Link>
          </li>
          <li className={ this.isPathActive('/admin/manage-itinerary') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.ManageItineraryMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('ManageItineraryMenuOpen') } data-toggle="collapse">
              <i className="icon-settings menu-icon"></i>
              <span className="menu-title">Manage Itinerary</span>
              <i className="menu-arrow bi bi-chevron-down"></i>
            </div>
            <Collapse in={ this.state.ManageItineraryMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/admin/create-itinerary') ? 'nav-link custom-link active' : 'nav-link custom-link' } to="/admin/create-itinerary">Create Itinerary</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/admin/itineraries-list') ? 'nav-link custom-link active' : 'nav-link custom-link' } to="/admin/itineraries-list">List Itinerary</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/admin/request-quote') ? 'nav-link custom-link active' : 'nav-link custom-link' } to="/admin/request-quote">Request a Quote</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/admin/itinerary-draft-list') ? 'nav-link custom-link active' : 'nav-link custom-link' } to="/admin/itinerary-draft-list">Draft Itinerary</Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={ this.isPathActive('/admin/flights') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link custom-link" to="/admin/flights">
              <i className="icon-flight menu-icon"></i>
              <span className="menu-title">My Flights</span>
            </Link>
          </li>
          <li className={ this.isPathActive('/admin/stays') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link custom-link" to="/admin/stays">
              <i className="icon-stays menu-icon"></i>
              <span className="menu-title">My Stays</span>
            </Link>
          </li>
          <li className={ this.isPathActive('/admin/cabs') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link custom-link" to="/admin/cabs">
              <i className="icon-cabs menu-icon"></i>
              <span className="menu-title">My Cabs</span>
            </Link>
          </li>
        </ul>
      </nav>
      </>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item .custom-link').forEach((el) => {
      el.addEventListener('mouseover', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
      el.addEventListener('click', function() {
        document.querySelector('.sidebar-offcanvas').classList.remove('active');
        document.querySelector('.sidebar-overlay').classList.remove('active');
      });
    });
  }

}

export default withRouter(Sidebar);
