import './StayList.scss';
import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import 'react-input-range/lib/css/index.css';
import InputRange from 'react-input-range';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { LoadingSpinnerOne } from '../../../../loader/Loader';
import noPictureAvailable from '../../../../assets/images/no-picture-available.jpg';
import mapViewImg from '../../../../assets/images/map-view.svg';
import SearchField from 'react-search-field';
import moment from 'moment';
import { HotelSearchService, HotelFilterService } from '../../../../services/StayService';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAuth } from '../../../../contexts/AuthProvider';
import {
    getBudgetWiseProducts,
    getRatingWiseProducts,
    getLocationWiseProducts,
    getFacilitiesWiseProducts,
    getPropertyWiseProducts,
    getSearchNameWiseProducts
} from '../../../../helpers/StayFilter';
import { searchCitiesApi } from '../../../../services/DataService';


const StayList = () => {
    const ratingRef = useRef([]);
    const facilitiesRef = useRef([]);
    const propertyTypeRef = useRef([]);
    const locationsRef = useRef([]);
    const history = useHistory();
    const location = useLocation();
    const dayItsearchPrams = new URLSearchParams(location.search);
    const [staysData, setStaysData] = useState([]);
    const [hotelDetailsData, sethotelDetailsData] = useState({});
    // For Travellers count
    const [roomArr, setRoomArr] = useState([{ "room_no": 1, "adult": 1, "child": 0, "child_age": [] }]);
    const [travellersResult, setTravellersResult] = useState(1);
    const [roomArrLoad, setroomArrLoad] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    // For pagination
    const [sizePagination, setSizePagination] = useState(8);
    const [currentPagination, setCurrentPagination] = useState(1);
    const [citiesData, setCitiesData] = useState([]);
    const PerPageChange = (value) => {
        setSizePagination(value);
        const newPerPage = Math.ceil(staysData?.length / value);
        if (currentPagination > newPerPage) {
            setCurrentPagination(newPerPage);
        }
    }
    const PaginationChange = (page, pageSize) => {
        setCurrentPagination(page);
        setSizePagination(pageSize);
    }
    const [isActiveSortName, setActiveSortName] = useState(false);
    const [isActiveSortDistance, setActiveSortDistance] = useState(false);
    const [isActiveSortPrice, setActiveSortPrice] = useState(false);
    const [isActiveSortRating, setActiveSortRating] = useState(false);
    const { currencyData } = useAuth();
    const [arrRating, setArrRating] = useState([]);
    const [arrFacilities, setArrFacilities] = useState();
    const [arrPropartyType, setArrPropartyType] = useState();
    const [arrLocality, setArrLocality] = useState();
    const [storePropartyType, setStorePropartyType] = useState([]);
    const [storeFacilities, setStoreFacilities] = useState([]);
    const [storeLocations, setStoreLocations] = useState([]);
    const [storeHotelName, setStoreHotelName] = useState("");
    const [priceMinMaxValue, setPriceMinMaxValue] = useState({ min: 0, max: 0 });
    const [filterPriceValue, setFilterPriceValue] = useState(priceMinMaxValue);

    // const handleStayAddressTo = async value => {
    //     const results = await geocodeByAddress(value);
    //     const ll = await getLatLng(results[0]);
    //     let city, state, country;
    //     for (let i = 0; i < results[0].address_components.length; i++) {
    //         for (let j = 0; j < results[0].address_components[i].types.length; j++) {
    //             switch (results[0].address_components[i].types[j]) {
    //                 case "locality":
    //                     city = results[0].address_components[i].long_name;
    //                     break;
    //                 case "country":
    //                     country = results[0].address_components[i].long_name;
    //                     break;
    //             }
    //         }
    //     }
    //     stayFormFormik.setFieldValue("goingTo", `${city + ", " + country}`);
    //     stayFormFormik.setFieldValue("city_name", city);
    //     stayFormFormik.setFieldValue("country_name", country);
    // }
    const handleChangeGoingTo = async (e) => {
        e.stopPropagation();
        stayFormFormik.setFieldValue("goingTo", e.target.value);
        if (citiesData.length > 0) {
            document.querySelector('.cities-search-dropdown').classList.remove('d-none');
        }
        if (e.target.value.length - 1 || e.target.value.length === 0) {
            document.querySelector('.cities-search-dropdown').classList.remove('d-none');
        }
        await searchCitiesApi(e.target.value, (res) => {
            console.log("goingTo res==", res.data.data)
            setCitiesData(res.data.data.data);
        })
    }

    const onSelectItem = (searchTerm) => {
        stayFormFormik.setFieldValue("goingTo", searchTerm.city_name + ", " + searchTerm.country_name);
        stayFormFormik.setFieldValue("city_name", searchTerm.city_name);
        stayFormFormik.setFieldValue("country_name", searchTerm.country_name);
        console.log("search ", searchTerm);
    };

    const handleRoomChange = async (e) => {
        setroomArrLoad(false);
        let arr = roomArr;
        let roomNo = e.target.value;
        let roomArrLen = roomArr.length;
        if (roomNo > roomArr.length) {
            for (let i = 0; i < roomNo - roomArrLen; i++) {
                arr.push({ "room_no": roomArr.length + 1, "adult": 1, "child": 0, "child_age": [] });
            }
            await setRoomArr(roomArr);
        } else {
            let popArrLen = - (roomNo - roomArr.length)
            for (let i = 0; i < popArrLen; i++) {
                await arr.pop();
            }
            await setRoomArr(arr);
        }
        setroomArrLoad(true);
    }

    const handleAdultChange = async (e, i) => {
        setroomArrLoad(false)
        roomArr[i].adult = Number(e.target.value);
        await setRoomArr([...roomArr]);
        setroomArrLoad(true);
    }
    const handleChildrenChange = async (e, i) => {
        setroomArrLoad(false);
        let childAgeNo = Number(e.target.value);
        let childArrAgeStore = roomArr[i].child_age.length;
        if (childAgeNo > childArrAgeStore) {
            for (let a = 0; a < childAgeNo - childArrAgeStore; a++) {
                roomArr[i].child_age.push("0");
            }
        } else {
            let popArrLenChildage = - (childAgeNo - childArrAgeStore);
            for (let a = 0; a < popArrLenChildage; a++) {
                roomArr[i].child_age.pop();
            }
        }
        roomArr[i].child = Number(e.target.value);
        await setRoomArr([...roomArr]);
        setroomArrLoad(true);
    }

    const handleChildAgeChange = async (e, y, i) => {
        roomArr[i].child_age[y] = e.target.value
        await setRoomArr([...roomArr]);
    }

    const stayFormFormik = useFormik({
        initialValues: {
            goingTo: "",
            checkin: "",
            checkout: "",
            city_name: "",
            country_name: "",
            occupancy: [],
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            goingTo: Yup.string()
                .required('Please enter going to.'),
            checkin: Yup.date()
                .nullable()
                .transform((curr, orig) => orig === '' ? null : curr)
                .required('Please enter check-in date.'),
            checkout: Yup.date()
                .nullable()
                .transform((curr, orig) => orig === '' ? null : curr)
                .required('Please enter check-out date.'),

        }),
        onSubmit: async (values) => {
            // console.log(values)
                const Obj = {
                    checkin: moment(values.checkin).format("YYYY-MM-DD"),
                    checkout: moment(values.checkout).format("YYYY-MM-DD"),
                    city_name: values.city_name,
                    country_name: values.country_name,
                    occupancy: roomArr,
                    currency: currencyData?.currency,
                }
                // console.log("Obj=========", Obj)
                // localStorage.setItem("search_data", JSON.stringify(Obj));
                if(stayFormFormik.values.goingTo.match(",") === null){
                    stayFormFormik.setFieldValue("goingTo", "");
                }else{
                    history.push({
                        pathname: '/stays-results/',
                        search: (`cityName=${values.city_name}&&countryName=${values.country_name}&&checkInDate=${moment(values.checkin).format("YYYY-MM-DD")}&&checkOutDate=${moment(values.checkout).format("YYYY-MM-DD")}&&occupancy=${JSON.stringify(roomArr)}`),
                    });
                // return
                setIsLoading(true);
                await HotelSearchService(Obj, async (res) => {
                    // console.log("Hotel search from response: ", res.data.data);
                    if (res.status === 404) {
                        console.log('Something went wrong');
                    }
                    if (res.status === 200) {
                        // history.push('/accomadation-results');
                        // if (res.data.data.response_data.itineraries) {
                        //     setStaysData(res.data.data.response_data.itineraries);
                        //     sethotelDetailsData(res.data.data);
                        //     setIsLoading(false);
                        //     let getData = [...res.data.data.response_data.itineraries]
                        //     let localityArr = [];
                        //     let propartyArr = [];
                        //     let facilitiesArr = [];
                        //     for (let i = 0; i < getData.length; i++) {
                        //         localityArr.push(getData[i].locality.trim());
                        //         propartyArr.push(getData[i].propertyType);
                        //         for (let f = 0; f < getData[i].facilities.length; f++) {
                        //             facilitiesArr.push(getData[i].facilities[f])
                        //         }
                        //     }
                        //     let uniqueLocality = localityArr.filter((element, index) => {
                        //         return localityArr.indexOf(element) === index && element !== "" && element !== undefined && element !== null;
                        //     });
                        //     let uniqueProperty = propartyArr.filter((element, index) => {
                        //         return propartyArr.indexOf(element) === index && element !== "" && element !== undefined && element !== null;
                        //     });
                        //     let uniqueFacilities = facilitiesArr.filter((element, index) => {
                        //         return facilitiesArr.indexOf(element) === index && element !== "" && element !== undefined && element !== null;
                        //     });
                        //     await setArrLocality(uniqueLocality);
                        //     await setArrPropartyType(uniqueProperty);
                        //     await setArrFacilities(uniqueFacilities);

                        //     let smallAmount = Math.min(...getData.map(p => Number(p.total)));
                        //     let largeAmount = Math.max(...getData.map(p => Number(p.total)));
                        //     setFilterPriceValue({ min: Math.floor(smallAmount), max: Math.ceil(largeAmount) });
                        //     setPriceMinMaxValue({ min: Math.floor(smallAmount), max: Math.ceil(largeAmount) });
                        // } else {
                        //     setStaysData([]);
                        //     setIsLoading(false);
                        //     setFilterPriceValue({ min: 0, max: 0 });
                        //     setPriceMinMaxValue({ min: 0, max: 0 });
                        //     setArrLocality();
                        //     setArrPropartyType();
                        //     setArrFacilities();
                        // }
                        if (!res?.data?.data?.response_data?.itineraries) {
                            setStaysData([]);
                            sethotelDetailsData({});
                            getFilterDataSet([]);

                        } else {
                            setStaysData(res?.data?.data?.response_data?.itineraries);
                            sethotelDetailsData(res.data.data);
                            getFilterDataSet(res?.data?.data?.response_data?.itineraries);
                        }
                        setIsLoading(false);
                    }
                });
            }
        }
    });


    const hotelSearchGetData = async (event) => {
        //event.preventDefault();
        const Obj = {
            checkin: dayItsearchPrams.get("checkInDate") !== null ? dayItsearchPrams.get("checkInDate") : "",
            checkout: dayItsearchPrams.get("checkInDate") === dayItsearchPrams.get("checkOutDate") ? moment(new Date(dayItsearchPrams.get("checkOutDate"))).add(1, "days").format("YYYY-MM-DD") : dayItsearchPrams.get("checkOutDate"),
            city_name: dayItsearchPrams.get("cityName") !== null ? dayItsearchPrams.get("cityName") : "",
            country_name: dayItsearchPrams.get("countryName") !== null ? dayItsearchPrams.get("countryName") : "",
            occupancy: dayItsearchPrams.get("occupancy") !== null ? JSON.parse(dayItsearchPrams.get("occupancy")) : "",
            currency: currencyData?.currency,
        }
        // localStorage.setItem("search_data", JSON.stringify(Obj));
        // console.log("hotelSearchGetDataObj=========", Obj)
        // return
        setIsLoading(true);
        await HotelSearchService(Obj, async (res) => {
            // console.log("Hotel search response: ", res.data.data);
            if (res.status === 404) {
                console.log('Something went wrong')
            }
            if (res.status === 200) {
                console.log("stay data===", res?.data?.data)
                // history.push('/accomadation-results');
                if (!res?.data?.data?.response_data?.itineraries) {
                    setStaysData([]);
                    sethotelDetailsData({});
                    getFilterDataSet([]);

                } else {
                    setStaysData(res?.data?.data?.response_data?.itineraries);
                    sethotelDetailsData(res?.data?.data);
                    getFilterDataSet(res?.data?.data?.response_data?.itineraries);
                }
                setIsLoading(false);
            }
        });
    }


    const getFilterDataSet = async (data) => {
        console.log("stay getFilterDataSet===", data);
        setIsLoading(false);
        if (data?.length > 0) {
            let localityArr = [];
            let propartyArr = [];
            let facilitiesArr = [];
            for (let i = 0; i < data?.length; i++) {
                localityArr.push(data[i]?.locality?.trim());
                propartyArr.push(data[i]?.propertyType);
                for (let f = 0; f < data[i]?.facilities?.length; f++) {
                    facilitiesArr.push(data[i]?.facilities[f]);
                }
            }
            let uniqueLocality = localityArr.filter((element, index) => {
                return localityArr.indexOf(element) === index && element !== "" && element !== undefined && element !== null;
            });
            let uniqueProperty = propartyArr.filter((element, index) => {
                return propartyArr.indexOf(element) === index && element !== "" && element !== undefined && element !== null;
            });
            let uniqueFacilities = facilitiesArr.filter((element, index) => {
                return facilitiesArr.indexOf(element) === index && element !== "" && element !== undefined && element !== null;
            });
            setArrLocality(uniqueLocality);
            setArrPropartyType(uniqueProperty);
            setArrFacilities(uniqueFacilities);

            let smallAmount = Math.min(...data.map(p => Number(p.total)));
            let largeAmount = Math.max(...data.map(p => Number(p.total)));
            setFilterPriceValue({ min: Math.floor(smallAmount), max: Math.ceil(largeAmount) });
            setPriceMinMaxValue({ min: Math.floor(smallAmount), max: Math.ceil(largeAmount) });
        } else {
            setIsLoading(false);
            setFilterPriceValue({ min: 0, max: 0 });
            setPriceMinMaxValue({ min: 0, max: 0 });
            setArrLocality();
            setArrPropartyType();
            setArrFacilities();
        }
    }


    const totalTravellersSubmit = async () => {
        //event.preventDefault();
        const adultTotal = roomArr.reduce(function (prev, cur) {
            return prev + Number(cur.adult);
        }, 0);
        const childernTotal = roomArr.reduce(function (prev, cur) {
            return prev + Number(cur.child);
        }, 0);
        await setTravellersResult(adultTotal + childernTotal);
    }

    const gotoHotelDetails = async (event, data) => {
        event.preventDefault();
        //console.log('data=========', data)
        history.push({
            pathname: '/stay-details/',
            search: (`sessionId=${hotelDetailsData.response_data.status.sessionId}&&hotelId=${data.hotelId}&&productId=${data.productId}&&tokenId=${data.tokenId}&&checkInDate=${dayItsearchPrams.get("checkInDate")}&&checkOutDate=${dayItsearchPrams.get("checkOutDate")}&&cityName=${dayItsearchPrams.get("cityName")}&&countryName=${dayItsearchPrams.get("countryName")}&&occupancy=${dayItsearchPrams.get("occupancy")}`),
        });
    }

    let updatePriceFilter = { ...filterPriceValue }
    const multiRangeSlider = async (value) => {
        updatePriceFilter = value
        await setFilterPriceValue(updatePriceFilter);
        setCurrentPagination(1);
    }
    let updatedRatingList = [...arrRating];
    const handleChangeRating = async (event) => {

        if (event.target.checked) {
            updatedRatingList = [...arrRating, event.target.value];
        } else {
            updatedRatingList.splice(arrRating.indexOf(event.target.value), 1);
        }
        setArrRating(updatedRatingList);
        setCurrentPagination(1);
        // await FilterSearch();
    }
    let updateFacilitiesList = [...storeFacilities];
    const handleFacilities = (event) => {
        if (event.target.checked) {
            updateFacilitiesList = [...storeFacilities, event.target.value];
        } else {
            updateFacilitiesList.splice(storeFacilities.indexOf(event.target.value), 1);
        }
        setStoreFacilities(updateFacilitiesList);
        setCurrentPagination(1);
    }
    let updatePropartyTypeList = [...storePropartyType];
    const handlePropertyType = (event) => {
        if (event.target.checked) {
            updatePropartyTypeList = [...storePropartyType, event.target.value];
        } else {
            updatePropartyTypeList.splice(storePropartyType.indexOf(event.target.value), 1);
        }
        setStorePropartyType(updatePropartyTypeList);
        setCurrentPagination(1);
    }
    let updateLocationsList = [...storeLocations];
    const handleLocations = (event) => {
        if (event.target.checked) {
            updateLocationsList = [...storeLocations, event.target.value];
        } else {
            updateLocationsList.splice(storeLocations.indexOf(event.target.value), 1);
        }
        setStoreLocations(updateLocationsList);
        setCurrentPagination(1);
    }

    const searchByHotelName = async (query) => {
        // console.log('query===============', query)
        setStoreHotelName(query);
        setCurrentPagination(1);
    }


    // const FilterSearch = async (e) => {
    //     setIsLoading(true);
    //     const filterObj = {
    //         // sessionId: "",
    //         // min_price: "",
    //         // max_price: "",
    //         // rating: "",
    //         // propertyType:"",
    //         // facility:"",
    //         // locality:"",
    //     };
    //     filterObj.sessionId = hotelDetailsData.response_data.status.sessionId;
    //     filterObj.min_price = updatePriceFilter.min;
    //     filterObj.max_price = updatePriceFilter.max;
    //     filterObj.rating = updatedRatingList.toString();
    //     filterObj.propertyType = updatePropartyTypeList.toString();
    //     filterObj.facility = updateFacilitiesList.toString();
    //     if (updateLocationsList.length !== 0) {
    //         filterObj.locality = updateLocationsList.toString();
    //     }
    //     console.log(filterObj)
    //     //return
    //     await HotelFilterService(filterObj, async (res) => {
    //         // console.log("Hotel filter response: ", res.data.data);
    //         if (res.status === 404) {
    //             console.log('Something went wrong')
    //         }
    //         if (res.status === 200) {
    //             await setStaysData(res.data.data.response_data.itineraries);
    //             setIsLoading(false);
    //         }
    //     });
    // }

    const SortByDistance = async () => {
        // let arrDistance = [];
        setActiveSortName(false);
        setActiveSortPrice(false);
        setActiveSortRating(false);
        setActiveSortDistance(!isActiveSortDistance);
        if (isActiveSortDistance === true) {
            const DistanceSorted = [...staysData].sort((a, b) => (a.distanceValue > b.distanceValue ? 1 : -1))
            await setStaysData(DistanceSorted);
        } else {
            const DistanceSorted = [...staysData].sort((a, b) => (a.distanceValue > b.distanceValue ? -1 : 1))
            await setStaysData(DistanceSorted);
        }
    }

    const SortByName = async () => {
        setActiveSortDistance(false);
        setActiveSortPrice(false);
        setActiveSortRating(false);
        setActiveSortName(!isActiveSortName);
        if (isActiveSortName === true) {
            const NameSorted = [...staysData].sort((a, b) => (a.hotelName > b.hotelName ? 1 : -1))
            await setStaysData(NameSorted);
        } else {
            const NameSorted = [...staysData].sort((a, b) => (a.hotelName > b.hotelName ? -1 : 1))
            await setStaysData(NameSorted);
        }

    }

    const SortByPrice = async () => {
        setActiveSortDistance(false);
        setActiveSortRating(false);
        setActiveSortName(false);
        setActiveSortPrice(!isActiveSortPrice);
        if (isActiveSortPrice === true) {
            const PriceSorted = [...staysData].sort((a, b) => (Number(a.total) > Number(b.total) ? 1 : -1))
            await setStaysData(PriceSorted);
        } else {
            const PriceSorted = [...staysData].sort((a, b) => (Number(a.total) > Number(b.total) ? -1 : 1))
            await setStaysData(PriceSorted);
        }
    }

    const SortByRating = async () => {
        setActiveSortDistance(false);
        setActiveSortPrice(false);
        setActiveSortName(false);
        setActiveSortRating(!isActiveSortRating);
        if (isActiveSortRating === true) {
            const RatingSorted = [...staysData].sort((a, b) => (a.hotelRating > b.hotelRating ? 1 : -1))
            await setStaysData(RatingSorted);
        } else {
            const RatingSorted = [...staysData].sort((a, b) => (a.hotelRating > b.hotelRating ? -1 : 1))
            await setStaysData(RatingSorted);
        }
    }

    const handleResetChange = () => {
        setCurrentPagination(1);
        setFilterPriceValue({ min: priceMinMaxValue.min, max: priceMinMaxValue.max });
        setStoreHotelName("");
        setArrRating([]);
        setStorePropartyType([]);
        setStoreFacilities([]);
        setStoreLocations([]);
        for (let r = 0; r < ratingRef.current.length; r++) {
            ratingRef.current[r].checked = false;
        }
        for (let f = 0; f < facilitiesRef.current.length; f++) {
            facilitiesRef.current[f].checked = false;
        }
        for (let p = 0; p < propertyTypeRef.current.length; p++) {
            propertyTypeRef.current[p].checked = false;
        }
        for (let l = 0; l < locationsRef.current.length; l++) {
            locationsRef.current[l].checked = false;
        }
        hotelSearchGetData();
    }

    const nameWiseProduct = getSearchNameWiseProducts(staysData, storeHotelName);
    const budgetWiseProduct = getBudgetWiseProducts(nameWiseProduct, updatePriceFilter);
    const ratingWiseProduct = getRatingWiseProducts(budgetWiseProduct, updatedRatingList);
    const locationWiseProduct = getLocationWiseProducts(ratingWiseProduct, updateLocationsList);
    const facilityWiseProduct = getFacilitiesWiseProducts(locationWiseProduct, updateFacilitiesList);
    const propertyWiseProduct = getPropertyWiseProducts(facilityWiseProduct, updatePropartyTypeList);

    console.log("propertyWiseProduct", propertyWiseProduct);
    console.log("priceMinMaxValue", priceMinMaxValue);

    useEffect(() => {
        if (dayItsearchPrams.get("occupancy")) {
            hotelSearchGetData();
            stayFormFormik.setFieldValue("goingTo", dayItsearchPrams.get("cityName") + ", " + dayItsearchPrams.get("countryName"));
            stayFormFormik.setFieldValue("city_name", dayItsearchPrams.get("cityName"));
            stayFormFormik.setFieldValue("country_name", dayItsearchPrams.get("countryName"));
            stayFormFormik.setFieldValue("checkin", dayItsearchPrams.get("checkInDate"));
            stayFormFormik.setFieldValue("checkout", dayItsearchPrams.get("checkInDate") === dayItsearchPrams.get("checkOutDate") ? moment(new Date(dayItsearchPrams.get("checkOutDate"))).add(1, "days").format("YYYY-MM-DD") : dayItsearchPrams.get("checkOutDate"));
            const travelersData = JSON.parse(dayItsearchPrams.get("occupancy"));
            const adultTotal = travelersData.reduce(function (prev, cur) {
                return prev + Number(cur.adult);
            }, 0);
            const childernTotal = travelersData.reduce(function (prev, cur) {
                return prev + Number(cur.child);
            }, 0);
            setTravellersResult(adultTotal + childernTotal);
            setRoomArr(travelersData);
        }
    }, []);

    return (
        <>
            <section className="inner-title-block stay-search-form-block inner-mrt-80">
                <div className="search-h-block container-lg">
                    <form onSubmit={stayFormFormik.handleSubmit}>
                        <div className="form-row">
                            <div className="form-col">
                                <div className="stay-form-input-fields position-relative">
                                    <div className="cities-search-box-field">
                                        <input onChange={(e) => handleChangeGoingTo(e)} onBlur={stayFormFormik.handleBlur('goingTo')} value={stayFormFormik.values.goingTo} name="goingTo" className="form-control location-icon" type="text" placeholder="Going to" autoComplete="off" />
                                        <div className='cities-search-dropdown'>
                                            {citiesData?.length > 0 ?
                                                <div className="cities-search-list">
                                                    {citiesData?.map((item, index) => {
                                                        return (
                                                            <div key={index} onClick={() => onSelectItem(item)} className="cities-search-item">
                                                                <span>{item.city_name}</span>, {item.country_name}
                                                            </div>
                                                        )
                                                    })}
                                                </div> : null}
                                        </div>
                                    </div>
                                    {/* <PlacesAutocomplete
                                        value={stayFormFormik.values.goingTo}
                                        name="goingTo"
                                        onChange={value => stayFormFormik.setFieldValue("goingTo", value)}
                                        onSelect={handleStayAddressTo}
                                        onBlur={stayFormFormik.handleBlur('goingTo')}
                                        minLength={2} // minimum length of text to search
                                        searchOptions={{ types: ['(cities)'] }}
                                    >
                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                            <>
                                                <input id="GoingTo"
                                                    {...getInputProps({
                                                        placeholder: 'Going to',
                                                        className: 'location-search-input location-icon form-control',
                                                    })}
                                                />
                                                <div className="autocomplete-dropdown-container">
                                                    {loading && <div>Loading...</div>}
                                                    {suggestions.map(suggestion => {
                                                        const className = suggestion.active
                                                            ? 'suggestion-item-active'
                                                            : 'suggestion-item';
                                                        const countrySplit = suggestion.formattedSuggestion.secondaryText.split(",");
                                                        const countrySplitArr = Array.from(countrySplit);
                                                        return (
                                                            <div key={suggestion.placeId}
                                                                {...getSuggestionItemProps(suggestion, {
                                                                    className,
                                                                })}
                                                            >
                                                                <strong>
                                                                    {suggestion.formattedSuggestion.mainText}
                                                                </strong>{", "}
                                                                <small className="countrytxt-small">
                                                                    {countrySplitArr.map((countrytxt, index) => {
                                                                        return (<span key={index}>{countrytxt}</span>);
                                                                    })}
                                                                </small>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </>
                                        )}
                                    </PlacesAutocomplete> */}
                                    {stayFormFormik.touched.goingTo && stayFormFormik.errors.goingTo ? <p className="errors-msg">{stayFormFormik.errors.goingTo}</p> : null}
                                </div>
                            </div>
                            <div className="form-col">
                                <div className="stay-form-input-fields">
                                    <DatePicker
                                        selectsStart
                                        value={stayFormFormik.values.checkin ? moment(stayFormFormik.values.checkin).format("YYYY-MM-DD") : null}
                                        onChange={value => stayFormFormik.setFieldValue("checkin", value)}
                                        onBlur={stayFormFormik.handleBlur('checkin')}
                                        name="checkin"
                                        dateFormat="dd MMMM yyyy"
                                        autoComplete="off"
                                        customInput={<input className="form-control calendar-icon" type="text" id="CheckIn" />}
                                        placeholderText="Enter check-in date"
                                        minDate={new Date()}
                                    />
                                    {stayFormFormik.touched.checkin && stayFormFormik.errors.checkin ? <p className="errors-msg">{stayFormFormik.errors.checkin}</p> : null}
                                </div>
                            </div>
                            <div className="form-col">
                                <div className="stay-form-input-fields">
                                    <DatePicker
                                        selectsEnd
                                        value={stayFormFormik.values.checkout ? moment(stayFormFormik.values.checkout).format("YYYY-MM-DD") : null}
                                        onChange={value => stayFormFormik.setFieldValue("checkout", value)}
                                        onBlur={stayFormFormik.handleBlur('checkout')}
                                        name="checkout"
                                        dateFormat="dd MMMM yyyy"
                                        autoComplete="off"
                                        customInput={<input className="form-control calendar-icon" type="text" id="CheckOut" />}
                                        placeholderText="Enter check-out date"
                                        minDate={stayFormFormik.values.checkin ? new Date(stayFormFormik.values.checkin).setDate(new Date(stayFormFormik.values.checkin).getDate() + 1) : new Date()}
                                    />
                                    {stayFormFormik.touched.checkout && stayFormFormik.errors.checkout ? <p className="errors-msg">{stayFormFormik.errors.checkout}</p> : null}
                                </div>
                            </div>
                            <div className="form-col">
                                <Dropdown className="custom-travellers-drp">
                                    <Dropdown.Toggle variant="trasparent">
                                        <div className="stay-form-input-fields">
                                            <input value={`${travellersResult ? travellersResult + ' Travellers' : ""}`} className="form-control user-icon" type="text" id="Travellers" placeholder="Enter travellers" readOnly />
                                        </div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <div>
                                            <div className="travellers-frm-rw">
                                                <span className="travellers-frm-label travellers-frm-col-50">Rooms:</span>
                                                <div className="travellers-frm-col-50">
                                                    <select className="form-select" value={roomArr.length} onChange={handleRoomChange}>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="4">4</option>
                                                    </select>
                                                </div>
                                            </div>
                                            {roomArrLoad && roomArr.length > 0 && roomArr.map((x, i) => {
                                                return (
                                                    <div key={i} className="travellers-frm-rw mt-2">
                                                        <span className="travellers-frm-label travellers-frm-col-30">Room {i + 1} :</span>
                                                        <div className="travellers-frm-col-30">
                                                            <span className="travellers-frm-in-label">Adult</span>
                                                            <select className="form-select" value={roomArr[i].adult} onChange={(e) => handleAdultChange(e, i)}>
                                                                <option value="1">1</option>
                                                                <option value="2">2</option>
                                                                <option value="3">3</option>
                                                                <option value="4">4</option>
                                                                <option value="5">5</option>
                                                                <option value="6">6</option>
                                                            </select>
                                                        </div>
                                                        <div className="travellers-frm-col-30">
                                                            <span className="travellers-frm-in-label">Children</span>
                                                            <select className="form-select" value={roomArr[i].child} onChange={(e) => handleChildrenChange(e, i)}>
                                                                <option value="0">0</option>
                                                                <option value="1">1</option>
                                                                <option value="2">2</option>
                                                            </select>
                                                        </div>
                                                        <div className="travellers-frm-col-100">
                                                            <div className="travellers-frm-rw">
                                                                {roomArrLoad && x.child_age.length > 0 && x.child_age.map((staringArray, y) => {
                                                                    return (
                                                                        <div key={y} className="travellers-frm-col-50 mt-2">
                                                                            <span className="travellers-frm-in-label">Age of Child {y + 1}</span>
                                                                            <select value={roomArr[i].child_age[y]} className="form-select" onChange={(e) => handleChildAgeChange(e, y, i)}>
                                                                                <option value="0">Select Age</option>
                                                                                <option value="1">1</option>
                                                                                <option value="2">2</option>
                                                                                <option value="3">3</option>
                                                                                <option value="4">4</option>
                                                                                <option value="5">5</option>
                                                                                <option value="6">6</option>
                                                                                <option value="7">7</option>
                                                                                <option value="8">8</option>
                                                                                <option value="9">9</option>
                                                                                <option value="10">10</option>
                                                                                <option value="11">11</option>
                                                                                <option value="12">12</option>
                                                                            </select>
                                                                        </div>
                                                                    )
                                                                }
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}

                                            <div className="d-flex justify-content-end mt-3">
                                                <Dropdown.Item type="button" onClick={totalTravellersSubmit} className="btn done-btn btn-primary-color">Done</Dropdown.Item>
                                            </div>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className="stay-form-btns">
                                <button type="submit" className="btn btn-primary-color">Search</button>
                            </div>
                        </div>
                    </form>
                    {/* <Link to="/">Back to home</Link> */}
                </div>
            </section>
            <section className="accomadation-filter-block">
                <div className="container-lg d-md-flex justify-content-between">
                    <div className="accomadation-filter-left">
                        <span className="filter-txt">Filter</span>
                        {/* <Dropdown>
                        <Dropdown.Toggle>
                            <i className="bi bi-patch-check-fill"></i> Popular
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <h5 className="accomadation-filter-drp-title">Popular filters</h5>
                            <ul className="accomadation-filter-popular">
                                <li>
                                    <div className="filter-item">
                                        <input id="flexCheckDefault" className="form-check-input" type="checkbox"/>
                                        <label className="form-check-label" htmlFor="flexCheckDefault">
                                            Dubai Area (285)
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div className="filter-item">
                                        <input id="flexCheckDefault" className="form-check-input" type="checkbox"/>
                                        <label className="form-check-label" htmlFor="flexCheckDefault">
                                            Jumeirah (46)
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div className="filter-item">
                                        <input id="flexCheckDefault" className="form-check-input" type="checkbox"/>
                                        <label className="form-check-label" htmlFor="flexCheckDefault">
                                            Al Barsha (37)
                                        </label>
                                    </div>
                                </li>
                            </ul>
                        </Dropdown.Menu>
                    </Dropdown>     */}
                        <Dropdown>
                            <Dropdown.Toggle>
                                <i className="bi bi-tag-fill"></i> Budget
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="Ad-MultiRangeS-Drop">
                                <h5 className="accomadation-filter-drp-title">Price per night ({currencyData?.currency})</h5>
                                {staysData?.length === 0 ? <p style={{ fontSize: 12 }}>No results.</p> : <>
                                    <InputRange
                                        step={1}
                                        formatLabel={value => null}
                                        draggableTrack={false}
                                        allowSameValues={false}
                                        minValue={priceMinMaxValue.min}
                                        maxValue={priceMinMaxValue.max}
                                        value={filterPriceValue}
                                        onChange={value => setFilterPriceValue(value)}
                                        onChangeComplete={value => multiRangeSlider(value)}
                                    />
                                    <div className="InputRange-content">
                                        <span>{currencyData?.currency} {priceMinMaxValue.min === 0 ? priceMinMaxValue.min : filterPriceValue.min}</span>
                                        <span>{currencyData?.currency} {priceMinMaxValue.max === 0 ? priceMinMaxValue.max : filterPriceValue.max}</span>
                                    </div></>}
                            </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown>
                            <Dropdown.Toggle>
                                <i className="bi bi-star-fill"></i> Rating
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="sort-rating-drp">
                                <h5 className="accomadation-filter-drp-title">Star Rating</h5>
                                {staysData?.length === 0 ? <p style={{ fontSize: 12 }}>No results.</p> :
                                    <ul>
                                        <li>
                                            <div className="sort-rating-item">
                                                <input onChange={handleChangeRating} ref={(element) => { ratingRef.current[0] = element }} id="rating5" className="form-check-input" type="checkbox" name="rating" value="5" />
                                                <label className="form-check-label" htmlFor="rating5">
                                                    <i className="bi bi-star-fill" aria-hidden="true"></i>5
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="sort-rating-item">
                                                <input onChange={handleChangeRating} ref={(element) => { ratingRef.current[1] = element }} id="rating4" className="form-check-input" type="checkbox" name="rating" value="4" />
                                                <label className="form-check-label" htmlFor="rating4">
                                                    <i className="bi bi-star-fill" aria-hidden="true"></i>4
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="sort-rating-item">
                                                <input onChange={handleChangeRating} ref={(element) => { ratingRef.current[2] = element }} id="rating3" className="form-check-input" type="checkbox" name="rating" value="3" />
                                                <label className="form-check-label" htmlFor="rating3">
                                                    <i className="bi bi-star-fill" aria-hidden="true"></i>3
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="sort-rating-item">
                                                <input onChange={handleChangeRating} ref={(element) => { ratingRef.current[3] = element }} id="rating2" className="form-check-input" type="checkbox" name="rating" value="2" />
                                                <label className="form-check-label" htmlFor="rating2">
                                                    <i className="bi bi-star-fill" aria-hidden="true"></i>2
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="sort-rating-item">
                                                <input onChange={handleChangeRating} ref={(element) => { ratingRef.current[4] = element }} id="rating1" className="form-check-input" type="checkbox" name="rating" value="1" />
                                                <label className="form-check-label" htmlFor="rating1">
                                                    <i className="bi bi-star-fill" aria-hidden="true"></i>1
                                                </label>
                                            </div>
                                        </li>
                                    </ul>}
                            </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown>
                            <Dropdown.Toggle>
                                <i className="bi bi-geo-fill"></i> Location
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <h5 className="accomadation-filter-drp-title">Location filters</h5>
                                <ul className="accomadation-filter-popular facilities-scrollbar">
                                    {!arrLocality ? <p style={{ fontSize: 12 }}>No results.</p> : arrLocality.map((item, l) => {
                                        return <>
                                            <li key={l}>
                                                <div className="filter-item">
                                                    <input onChange={handleLocations} value={item} ref={(element) => { locationsRef.current[l] = element }} id={`localityCheck${l}`} className="form-check-input" type="checkbox" />
                                                    <label className="form-check-label" htmlFor={`localityCheck${l}`}>
                                                        {item}
                                                    </label>
                                                </div>
                                            </li>
                                        </>
                                    })}
                                </ul>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown>
                            <Dropdown.Toggle>
                                More
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <h5 className="accomadation-filter-drp-title">Facilities</h5>
                                <ul className="accomadation-filter-popular facilities-scrollbar">
                                    {!arrFacilities ? <p style={{ fontSize: 12 }}>No results.</p> : arrFacilities.map((item, f) => {
                                        return <>
                                            <li key={f}>
                                                <div className="filter-item">
                                                    <input onChange={handleFacilities} value={item} ref={(element) => { facilitiesRef.current[f] = element }} id={`facilityType${f}`} className="form-check-input" type="checkbox" />
                                                    <label className="form-check-label" htmlFor={`facilityType${f}`}>{item}</label>
                                                </div>
                                            </li>
                                        </>
                                    })}
                                </ul>
                                <h5 className="accomadation-filter-drp-title mt-3">Property Type</h5>
                                <ul className="accomadation-filter-popular facilities-scrollbar">
                                    {!arrPropartyType ? <p style={{ fontSize: 12 }}>No results.</p> : arrPropartyType.map((item, p) => {
                                        return <>
                                            <li key={p}>
                                                <div className="filter-item">
                                                    <input onChange={handlePropertyType} value={item} ref={(element) => { propertyTypeRef.current[p] = element }} id={`propertyType${p}`} className="form-check-input" type="checkbox" />
                                                    <label className="form-check-label" htmlFor={`propertyType${p}`}>{item}</label>
                                                </div>
                                            </li>
                                        </>
                                    })}
                                </ul>
                            </Dropdown.Menu>
                        </Dropdown>
                        <button type="button" onClick={handleResetChange} className="btn btn-reset">Reset</button>
                    </div>
                    <div className="accomadation-filter-right">
                        <SearchField
                            placeholder='Search hotel name...'
                            onChange={event => searchByHotelName(event)}
                        />
                    </div>
                </div>
            </section>
            <section className="accomadation-wrapper mt-4 padd-bottom-70">
                <div className="container-lg">
                    <div className="row">
                        <div className="col-lg-9 order-lg-2">
                            <div className="accomadation-sortby-block mb-3">
                                <ul>
                                    <li><span>Sort By</span></li>
                                    <li><button type="button" className="btn" onClick={SortByName}>Name A-Z <i className={isActiveSortName ? "bi bi-caret-up-fill" : "bi bi-caret-down-fill"}></i></button></li>
                                    <li><button type="button" className="btn" onClick={SortByDistance}>Distance <i className={isActiveSortDistance ? "bi bi-caret-up-fill" : "bi bi-caret-down-fill"}></i></button></li>
                                    <li><button type="button" className="btn" onClick={SortByRating}>Rating <i className={isActiveSortRating ? "bi bi-caret-up-fill" : "bi bi-caret-down-fill"}></i></button></li>
                                    <li><button type="button" className="btn" onClick={SortByPrice}>Price Lowest First <i className={isActiveSortPrice ? "bi bi-caret-up-fill" : "bi bi-caret-down-fill"}></i></button></li>
                                </ul>
                            </div>
                            <Pagination
                                className="pagination-style text-end mb-3"
                                showTitle={false}
                                showTotal={(total, range) => `Showing ${range[0]} - ${range[1]} of ${total}`}
                                onChange={PaginationChange}
                                total={propertyWiseProduct?.length}
                                current={currentPagination}
                                pageSize={sizePagination}
                                showSizeChanger={false}
                                onShowSizeChange={PerPageChange}
                                hideOnSinglePage={true}
                            />
                            <div className="hotel-list-row">
                                {isLoading ? <LoadingSpinnerOne /> : null}
                                {propertyWiseProduct && propertyWiseProduct.length > 0 ? (
                                    <>
                                        {propertyWiseProduct && propertyWiseProduct?.slice((currentPagination - 1) * sizePagination, currentPagination * sizePagination).map((item, i) => {
                                            return (
                                                <>
                                                    <div key={i} className="hotel-list-item">
                                                        <div className="hotel-img-block">
                                                            {item.thumbNailUrl ? (
                                                                <img src={item.thumbNailUrl} alt="" />
                                                            ) : (
                                                                <img src={noPictureAvailable} alt="" />
                                                            )}
                                                        </div>
                                                        <div className="hotel-content-block">
                                                            <div className="hotel-details">
                                                                <div className="hotel-name">
                                                                    <h4>{item?.hotelName} </h4>
                                                                </div>
                                                                <div className="hotels-staring">
                                                                    <ul>
                                                                        <li className="staring-box">
                                                                            <span><i className="bi bi-star-fill" aria-hidden="true"></i></span> {item?.hotelRating}
                                                                            {/* {[...Array(item?.hotelRating)].map((staringArray) => {
                                                                        return ( 
                                                                            <span><i className="bi bi-star-fill" aria-hidden="true"></i></span>
                                                                            ) 
                                                                        }
                                                                    )} */}
                                                                        </li>
                                                                        <li className="locations-box"><span className="locations-icon"><i className="bi bi-geo-alt-fill"></i></span>&nbsp;{item?.locality}</li>
                                                                        <li className="locations-box"><span className="locations-icon"><i className="bi bi-geo-fill"></i></span>&nbsp;(from center {item?.distanceValue} {item?.distanceUnit})</li>
                                                                    </ul>
                                                                </div>
                                                                <div className="room-type">
                                                                    <ul>
                                                                        <li className="hotel-type-text">
                                                                            <i className="bi bi-building"></i>&nbsp;&nbsp;{item?.propertyType}
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="hotel-fasilite">
                                                                    <ul>
                                                                        {item?.facilities?.map((facilitiesArray, i) => {
                                                                            return (<><li key={i}>{facilitiesArray}</li></>)
                                                                        })}
                                                                        {item?.facilities?.length > 3 ?
                                                                            <OverlayTrigger
                                                                                rootClose
                                                                                placement="top"
                                                                                trigger="click"
                                                                                overlay={
                                                                                    <Tooltip>
                                                                                        <div className="tooltip-scroll-y">
                                                                                            {item?.facilities?.filter((item, index) => index > 2).map((facilitiesItem, f) => {
                                                                                                return (<><div key={f} className="f-item">{facilitiesItem}</div></>)
                                                                                            })}
                                                                                        </div>
                                                                                    </Tooltip>
                                                                                }
                                                                            >
                                                                                <li style={{ cursor: "pointer" }}>+{item?.facilities?.length - 3}</li>
                                                                            </OverlayTrigger>
                                                                            : null}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="hotel-price-block">
                                                            <div className="price-pos">
                                                                {/* <div className="no-price"><strike>USD&nbsp;80.00</strike></div> */}
                                                                <div className="offer-price">{item?.currency}&nbsp;{item?.total}</div>
                                                                <p className="night">{moment(dayItsearchPrams.get("checkOutDate")).diff(moment(dayItsearchPrams.get("checkInDate")), 'days')} Night price</p>
                                                                <button onClick={(e) => gotoHotelDetails(e, item)} type="button" className="btn btn-primary-color">Choose Room</button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </>
                                            )
                                        })}
                                    </>
                                ) : (
                                    <>
                                        <div className="No-Results-Found">
                                            <h4>Start your search with us.</h4>
                                            <p>Could not find any room combination for this date, please try reloading or resetting the filters</p>
                                        </div>
                                    </>
                                )}
                            </div>
                            <Pagination
                                className="pagination-style text-end mt-3"
                                showTitle={false}
                                showTotal={(total, range) => `Showing ${range[0]} - ${range[1]} of ${total}`}
                                onChange={PaginationChange}
                                total={propertyWiseProduct?.length}
                                current={currentPagination}
                                pageSize={sizePagination}
                                showSizeChanger={false}
                                onShowSizeChange={PerPageChange}
                                hideOnSinglePage={true}
                            />

                        </div>
                        <div className="col-lg-3 accomadation-sidebar order-lg-1 mt-4 mt-lg-0">
                            {dayItsearchPrams.get("cityName") ? <div className="map-div">
                                <div className="top-map-view-block">
                                    <img src={mapViewImg} className="top-img" />
                                    <div className="map-text">
                                        <i className="bi bi-geo-alt-fill"></i>
                                        <h5>SEE A MAP VIEW</h5>
                                    </div>
                                </div>
                                <div className="hybrid-map-div">
                                    <a><i className="fa fa-users"></i> View a map of {dayItsearchPrams.get("cityName")}</a>
                                </div>
                            </div> : null}
                            {/* <h5 className="accomadation-sidebar-title mt-4">Recommended for you in Dubai </h5>
                        <div className="chk-hotel-list mt-3">
                            <div className="chk-hotel-item">
                                <div className="chk-hotel-img-block">
                                    <img src="https://cdn.hotelbeds.com/giata/72/729343/729343a_hb_a_005.jpg" alt="" />
                                </div>
                                <div className="chk-hotel-info-block">
                                    <h5>Wehda Hotel</h5>
                                    <div className="staring-box"><span><i className="bi bi-star-fill" aria-hidden="true"></i></span> 5</div>
                                    <p>Al Sabkha Rd Dubai United Arab Emirates, 118-770</p>
                                </div>
                            </div>
                        </div>
                        <div className="chk-hotel-list mt-3">
                            <div className="chk-hotel-item">
                                <div className="chk-hotel-img-block">
                                    <img src="https://cdn.hotelbeds.com/giata/72/729343/729343a_hb_a_005.jpg" alt="" />
                                </div>
                                <div className="chk-hotel-info-block">
                                    <h5>Wehda Hotel</h5>
                                    <div className="staring-box"><span><i className="bi bi-star-fill" aria-hidden="true"></i></span> 5</div>
                                    <p>Al Sabkha Rd Dubai United Arab Emirates, 118-770</p>
                                </div>
                            </div>
                        </div> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default StayList;


