import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {getItinerariesApi} from '../../../services/AdminService';
import {googleApiKey, googlePhotoUrl, imageUrlOthers} from '../../../services/CommonUrl';
import noPictureAvailable from '../../../assets/images/no-picture-available.jpg';
import {LoadingSpinnerTwo} from "../../../loader/Loader";
import moment from 'moment';
import ModalMap from './ModalMap';
import { useAuth } from '../../../contexts/AuthProvider';

const ItineraryItem = () => {
    const { currencyData } = useAuth();
    const photoUrl = `${googlePhotoUrl}`;
    const apikey = `&key=${googleApiKey}`;
    const location = useLocation();
    const searchPrams = new URLSearchParams(location.search);
    const [isLoading, setIsLoading] = useState(false);
    const [ItinerariesData, setItinerariesData] = useState();
    const [itineraryDayData, setItineraryDayData] = useState([]);
    const [showMapModal, setShowMapModal] = useState(false);

    const getDaysPreviewData = async ()=>{
        setIsLoading(true);
        const Obj = {
            userId: searchPrams.get("userId"),
            itineraryId: searchPrams.get("itineraryId"),
            newCurrency: currencyData?.currency
        }
        await getItinerariesApi(Obj, async (res) => {
            // console.log("res getItinerariesApi ====", res);
            if (res.status === 404) {
                console.log('Something went wrong');
            }
            if (res.status === 200) {
                // console.log(res.data.data.message);
                if(res.data.data.data === null) return
                setItinerariesData(res.data.data.data);
                setIsLoading(false);
            }
        });
    }

    const handleMapModalClose = () => setShowMapModal(false);
    const handleMapModalShow = () => setShowMapModal(true);
    const getItineraryDaysDetails = async (data, index) => {
        setItineraryDayData(data);
        handleMapModalShow();
    }

    const dayNumber =(i, d)=>{
        let s = 0;
        for(let j =0; j < i ; j++){
          s += Number(ItinerariesData?.destinationCityDetails[j]?.itineraryDatys?.length);
        }
        return s + d
    }

    useEffect(() => {
        getDaysPreviewData();
    }, []);

    return ( 
        <>
        {isLoading ? <LoadingSpinnerTwo/> : !ItinerariesData ? <p className="text-center">No results found.</p> : null}
        {ItinerariesData?.estimated_Cost ? <p className="mt-3 txt-cost"> Estimated cost: {Math.floor(ItinerariesData?.currencyConvert?.new_amount) +" "+ ItinerariesData?.currencyConvert?.new_currency}</p> : null}
        {!ItinerariesData ? null : ItinerariesData?.destinationCityDetails?.map((destinationitem, i) =>{
            return (
            <div className="mt-3">
                <h4 className="d-nav-tabs-title">Destination City  -  <b>{destinationitem?.destination_city?.city}</b></h4>
                <div className="row">
                    {destinationitem?.itineraryDatys?.map((daysitem, d) =>{
                        return (
                        <div className="col-lg-4 mt-3" key={daysitem._id}>
                            <div className="civ-item-bx">
                                <div className="civ-day-date d-flex justify-content-between">
                                    <div>
                                        <span className="civ-day-txt">Day {i > 0 ? dayNumber(i, daysitem.day) : daysitem.day}</span>
                                        <span>{moment(daysitem.date).format("DD MMMM yyyy")}</span>
                                    </div>
                                    {daysitem?.itineraryDaysDetails?.length === 0 ? null : <button type="button" className="btn btn-secondary-color" onClick={()=>getItineraryDaysDetails(daysitem?.itineraryDaysDetails, i)}><i className="bi bi-geo-alt"></i></button>}
                                </div>
                                {daysitem?.itineraryDaysDetails?.length === 0 ? <p className="text-center found-txt">No activity found.</p>: null}
                                <div className="civ-list-row">
                                    {daysitem?.itineraryDaysDetails?.sort((a, b) => a.time > b.time ? 1 : -1).map((item, dt) =>{ 
                                        return (
                                            <>
                                            {item.days_type === "Hotel" ?
                                                <>
                                                <div className="civ-list-item">
                                                    <div className="civ-item-top-flex">
                                                        <div className="civ-item-img">
                                                            {item.hotelDetails.response_data.hotelImages === "" ? <img src={`${noPictureAvailable}`} alt=""/>: <img src={`${item.hotelDetails.response_data.hotelImages[0].url}`} alt=""/>}
                                                        </div>
                                                        <div className="civ-item-info">
                                                            <div className="civ-item-icon-check">
                                                                <span><img width={14} src={ require("../../../assets/images/Stays-icon-2.png") } alt="icon" /> Stays</span>
                                                            </div>
                                                            <h5>{item.hotelDetails.response_data.name}</h5>
                                                            {item.amount === undefined || item.amount === null ? "": <p className="civ-item-price">{item.currencyConvert ? Math.floor(item.currencyConvert.new_amount) : Math.floor(item.amount)} {item.currencyConvert ? item.currencyConvert.new_currency : item.currency}</p>}
                                                            <p className="civ-time-count">{item.time}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                </>
                                            : ""}
                                            {item.days_type === "Food" ?
                                                <>
                                                <div className="civ-list-item">
                                                    <div className="civ-item-top-flex">
                                                        <div className="civ-item-img">
                                                            {item.icon === "" ? <img src={`${noPictureAvailable}`} alt=""/>: <img src={`${photoUrl}maxwidth=400&photoreference=${item.icon}${apikey}`} alt=""/>}  
                                                        </div>
                                                        <div className="civ-item-info">
                                                            <div className="civ-item-icon-check">
                                                                <span><img width={10} src={ require("../../../assets/images/Food-icon-2.png") } alt="icon" /> Food</span>
                                                            </div>
                                                            <h5>{item.name}</h5>
                                                            {item.amount === undefined || item.amount === null ? "": <p className="civ-item-price">{item.currencyConvert ? Math.floor(item.currencyConvert.new_amount) : Math.floor(item.amount)} {item.currencyConvert ? item.currencyConvert.new_currency : item.currency}</p>}
                                                            <p className="civ-time-count">{item.time}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                </>
                                            : ""}
                                            {item.days_type === "Activity" ?
                                                <>
                                                <div className="civ-list-item">
                                                    <div className="civ-item-top-flex">
                                                        <div className="civ-item-img">
                                                            {item.icon === "" ? <img src={`${noPictureAvailable}`} alt=""/>: <img src={`${photoUrl}maxwidth=400&photoreference=${item.icon}${apikey}`} alt=""/>}  
                                                        </div>
                                                        <div className="civ-item-info">
                                                            <div className="civ-item-icon-check">
                                                                <span><img width={11} src={ require("../../../assets/images/Activity-icon-2.png") } alt="icon" /> Activity</span>
                                                            </div>
                                                            <h5>{item.name}</h5>
                                                            {item.amount === undefined || item.amount === null ? "": <p className="civ-item-price">{item.currencyConvert ? Math.floor(item.currencyConvert.new_amount) : Math.floor(item.amount)} {item.currencyConvert ? item.currencyConvert.new_currency : item.currency}</p>}
                                                            <p className="civ-time-count">{item.time}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                </>
                                            : ""}
                                            {item.days_type === "Others" ?
                                                <>
                                                <div className="civ-list-item">
                                                    <div className="civ-item-top-flex">
                                                        <div className="civ-item-img">
                                                            {item.icon === "" ? <img src={`${noPictureAvailable}`} alt=""/>: <img src={`${imageUrlOthers+item.icon}`} alt=""/>}  
                                                        </div>
                                                        <div className="civ-item-info">
                                                            <div className="civ-item-icon-check">
                                                                <span><img width={9} src={ require("../../../assets/images/others-icon.png") } alt="icon" /> Others</span>
                                                            </div>
                                                            <h5>{item.name}</h5>
                                                            {item.amount === undefined || item.amount === null ? "": <p className="civ-item-price">{item.currencyConvert ? Math.floor(item.currencyConvert.new_amount) : Math.floor(item.amount)} {item.currencyConvert ? item.currencyConvert.new_currency : item.currency}</p>}
                                                            <p className="civ-time-count">{item.time}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                </>
                                            : ""}
                                        </>
                                    )})}
                                </div>
                            </div>    
                        </div>
                    )})}
                </div>
            </div>
        )})}
        <ModalMap open={showMapModal} days={itineraryDayData} onClose={handleMapModalClose} />                 
        </>
    );
}

export default ItineraryItem;