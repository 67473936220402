import React, { useState, useEffect } from 'react';
import {Link, useHistory} from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {getPropularTravelItinerariesApi} from '../../../../services/DataService';
import {googleApiKey, googlePhotoUrl} from '../../../../services/CommonUrl';
import { LoadingSpinnerTwo } from '../../../../loader/Loader';
import likeIcon from '../../../../assets/images/like-icon.png';
import chatIcon from '../../../../assets/images/chat-icon.png';
import starIcon from '../../../../assets/images/star-icon.png';
import noPictureAvailable from '../../../../assets/images/no-picture-available.jpg';

const ptiItemSliderOptions = {
    loop: true,
    autoplay: true,
    margin: 10,
    mouseDrag: false,
    touchDrag: true,
    responsiveClass: true,
    navText: ["",""],
    responsive: {
        0: {
            items: 1,
            nav: false,
            dots: true
        },
        380: {
            items: 2,
            nav: false,
            dots: true
        },
        768: {
            items: 3,
            nav: false,
            dots: true
        },
        1024: {
            items: 4,
            nav: false,
            dots: false,
            margin: 16
        }
    }
}

const PopularTravelItineraries = () => {
    const history = useHistory();
    const photoUrl = `${googlePhotoUrl}`;
    const apikey = `&key=${googleApiKey}`;
    const [popularTravelData, setPopularTravelData] = useState([]);
    const [isPtLoading, setIsPtLoading] = useState(false);

    const getAllPropularTravelData = async ()=>{
        setIsPtLoading(true);
        await getPropularTravelItinerariesApi("", async (res) => {
            //console.log("res getPropularTravelItinerariesApi ====", res);
            if (res.status === 404) {
                console.log('Something went wrong')
                setIsPtLoading(false);
            }
            if (res.data.data.code === 200) {
                setIsPtLoading(false);
                setPopularTravelData(res.data.data.data);
            }
        });
    }

    useEffect(()=>{
        getAllPropularTravelData();
    },[]);

    return ( 
        <>
        <div className="header-title-wrapper margin-bottom-60">
            <div className="head-title">
                <h5>Recommended for you</h5>
                <h2>Popular Travel Itineraries</h2>
            </div>
            {/* <Link to="/" className="btn-view-all">View All</Link> */}
        </div>
        {isPtLoading === true ? <LoadingSpinnerTwo/> : popularTravelData?.length === 0 ? <p className="text-center">No itinerary found.</p>: 
         <OwlCarousel {...ptiItemSliderOptions} className="owl-carousel owl-theme custom-owl-theme pt-carousel">
            {popularTravelData?.map((item) =>{
                return (
                    <>
                    <div key={item._id} className="pti-item-box">
                        {item?.photo_reference === undefined || item?.photo_reference === null || item?.photo_reference === "" ?
                        <div className="pti-item-img" style={{backgroundImage: `url(${noPictureAvailable}`}}></div>
                        : <div className="pti-item-img" style={{backgroundImage: `url(${photoUrl}maxwidth=400&photoreference=${item.photo_reference}${apikey})`}}></div>}
                        <div className="pti-item-info">
                            <div>
                                <p className="tdf-trip-coutry">{item.destinationCityDetails?.map((desitem, d) =>{return <>{desitem?.destination_city?.city}{d === item?.destinationCityDetails?.length - 1? "" :", "}</>})}</p>
                                <p className="tdf-trip-days">{item.destinationCityDetails?.reduce((accumulator, current) => accumulator + current.itineraryDatys.length, 0)} Days Trip</p>
                            </div>
                            <div className="tdf-social-icons">
                                <div className="tdf-social-icon-item">
                                    <img width="15" src={likeIcon} className="tdf-like-icon" alt="Like icon"/>{item.total_like}
                                </div>
                                <div className="tdf-social-icon-item">
                                    <img width="20" src={chatIcon} className="tdf-comment-icon" alt="Comment icon"/>{item.total_comment}
                                </div>
                                <div className="tdf-social-icon-item">
                                    <img width="15" src={starIcon} className="tdf-rating-icon" alt="Rating icon"/>{Number((item.avg_rating).toFixed(1))}
                                </div>
                            </div>
                        </div>
                    </div>
                    </>
            )})}
        </OwlCarousel>}
        </>
    );
};
 
export default PopularTravelItineraries;