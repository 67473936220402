/////////////////////////// CODE ADDED FOR API CALLING: STARTS ////////////////////////////////
import axios from "axios"; 
import { baseURL } from "./CommonUrl";

export const GetAboutUsService = async(data, cb) => {
    const fullURL = baseURL + "get-aboutus-data"
    axios.get(fullURL)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}

export const GetTermsAndConditionService = async(data, cb) => {
    const fullURL = baseURL + "get-termsconditions-data"
    axios.get(fullURL)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}

export const GetPrivacyPolicyService = async(data, cb) => {
    const fullURL = baseURL + "get-privacy-data"
    axios.get(fullURL)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}

export const GetFaqService = async(data, cb) => {
    const fullURL = baseURL + "get-faqs-data"
    axios.get(fullURL)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}

export const GetHelpSupportService = async(data, cb) => {
    const fullURL = baseURL + "helpSupport"
    axios.get(fullURL)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}

export const EnquiryService = async(data, cb) => {
    const fullURL = baseURL + "add-enquiry"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}
export const ContactUsService = async(data, cb) => {
    const fullURL = baseURL + "add-contact-us"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}
export const ContactInfoService = async(data, cb) => {
    const fullURL = baseURL + "get-contact-info"
    axios.get(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}

export const getAllItineraryApi = async(data, cb) => {
    const fullURL = baseURL + "get_all_user_itinerary_list"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}
export const addItineraryLikeApi = async(data, cb) => {
    const fullURL = baseURL + "add_itinerary_like"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}
export const addItineraryRatingApi = async(data, cb) => {
    const fullURL = baseURL + "add_itinerary_rating"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}
export const addItineraryCommentApi = async(data, cb) => {
    const fullURL = baseURL + "add_itinerary_comment"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}
export const addItineraryItemRequestApi = async(data, cb) => {
    const fullURL = baseURL + "add_private_itinerary_view_request"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}
export const publicDetailServiceApi = async(data, cb) => {
    const fullURL = baseURL + "get_itinerary_details"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}
export const getAllCountryListApi = async(data, cb) => {
    const fullURL = baseURL + "get_all_country_list"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}
export const getGetConvertListApi = async(data, cb) => {
    const fullURL = baseURL + "GetConvertList"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}
export const getPropularTravelItinerariesApi = async(data, cb) => {
    const fullURL = baseURL + "get_propular_travel_itineraries"
    axios.get(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}
export const getDestinationItinerariesApi = async(data, cb) => {
    const fullURL = baseURL + "top_destination_itineraries_list"
    axios.post(fullURL, data)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}

export const searchCitiesApi = async(data, cb) => {
    const fullURL = `https://gladiatours.com:3307/frontendRouter/cityCountry?search=${data}`
    axios.get(fullURL)
        .then((res) => {
            return cb({status: 200, data: res});
        })
        .catch((err) => {
            // You can write some error handler here 
            return cb({status: 404, data: err});
        });
}

/////////////////////////// CODE ADDEDIT FOR API CALLING: ENDS ////////////////////////////////
