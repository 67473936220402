import './MyFlights.scss';
import React from 'react';

const MyFlights = () => {
  
    return ( 
        <>
        
        <div className="content-body">
            <h1 className="d-col-title mb-3">My Flight</h1>
            <p>Coming soon</p>
        </div>
                    
        </>
    );
}
 
export default MyFlights;