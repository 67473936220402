export const getBudgetWiseProducts = (products, budget) => {
    if (budget) {
        let storeHotel = products.filter((product) => product.total >= budget.min && product.total <= budget.max)
        return storeHotel;
    } else {
        return products;
    }
}

export const getLocationWiseProducts = (products, locations) => {
    if (locations.length > 0) {
        let storeHotel = products.filter((product) => locations.includes(product.locality));
        return storeHotel;
    } else {
        return products;
    }
}

export const getRatingWiseProducts = (products, ratings) => {
    if (ratings.length > 0) {
        let storeHotel = products.filter((product) => ratings.map(Number).includes(product.hotelRating));
        return storeHotel;
    } else {
        return products;
    }
}

export const getPropertyWiseProducts = (products, property) => {
    if (property.length > 0) {
        let storeHotel = products.filter((product) => property.includes(product.propertyType));
        return storeHotel;
    } else {
        return products;
    }
}

export const getFacilitiesWiseProducts = (products, facility) => {
    let storeFacility = [];
    if (facility.length > 0) {
        products.map((product) => {
            let checkFacilityArr = product.facilities.filter(item => facility.indexOf(item) !== -1)
            if (checkFacilityArr.length > 0) {
                storeFacility.push(product)
            }
        })
        return storeFacility;
    } else {
        return products;
    }
}

export const getSearchNameWiseProducts = (products, inputSearch) => {
    if (inputSearch) {
        let storeHotel = products.filter(({ hotelName }) => hotelName.toLowerCase().includes(inputSearch.toLowerCase()))
        return storeHotel;
    } else {
        return products;
    }
}

