import { useHistory } from "react-router-dom";
import { useAuth } from "../contexts/AuthProvider";


export const RequiresAuth = ({ children }) => {
    const { authed } = useAuth();
    const history = useHistory();
    return authed.isAuth ? children : history.push({
        pathname: '/'
      });
}