import React,{useState, useEffect} from 'react';
import { Link, useLocation } from 'react-router-dom';
import NumericInput from 'react-numeric-input';
import {QuotesRequestService, QuotesContentService} from '../../../services/AdminService';
import {publicDetailServiceApi} from '../../../services/DataService';
import { Country, State, City }  from 'country-state-city';
import {LoadingSpinnerOne} from "../../../loader/Loader";
import Alert from 'react-bootstrap/Alert';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useAuth } from '../../../contexts/AuthProvider';

const RequestQuote = () => {
    const {currencyData, authed} = useAuth();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [isLoading, setIsLoading] = useState(false);
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);
    const [quotesTermsData, setquotesTermsData] = useState({});
    const [itinararyData, setItinararyData] = useState();
    const regionNames = new Intl.DisplayNames(['en'], {type: 'region'});
    const [visibleAlertSuccess, setVisibleAlertSuccess] = useState(false);

    const initialData = {
        country: "",
        city: "",
        user_name: !authed ? "" : authed?.user?.name,
        email: !authed ? "" : authed?.user?.email,
        contact_number: !authed ? "" : authed?.user?.phone,
        no_of_adult: 1,
        no_of_child: 0,
        no_of_infant: 0,
        termsAndConditions: false
    }

    const quotesContent = async ()=>{
        await QuotesContentService('quotescontent', async (res) => {
            if (res.status === 404){
                console.log('Something went wrong')
            };
            if (res.status === 200){
                // console.log('Get Quotes content!')
                setquotesTermsData(res.data.data.response_data);
            }
        });
    }

    const quoteFromik = useFormik({
        initialValues: initialData,
        enableReinitialize: true,
        validationSchema: Yup.object({
            country: Yup.string()
                .required('Please select country.'),
            city: Yup.string()
                .required('Please select city.'),
            user_name: Yup.string()
                .required('Please enter user name.'),
            email: Yup.string()
                .email('Invalid email address')
                .required('Please enter email address.'),
            contact_number: Yup.string()
                .required('Please enter contact number.'),
            termsAndConditions: Yup.bool().oneOf([true], 'You need to accept the terms and conditions.'),
        }),
        onSubmit: (values) => {
            // console.log(JSON.stringify(values))
            //setIsFormSubmitting(true);
            const itineraryUrl = `${process.env.REACT_APP_BASE_PATH}public-itinarary-details/?itineraryId=${searchParams.get("itineraryId")}`
            const obj = {
                itenaries_id: searchParams.get("itineraryId")? searchParams.get("itineraryId") : "",
                itinerary_url: itineraryUrl,
                country: regionNames.of(values.country),
                city: values.city,
                user_name: values.user_name,
                email: values.email,
                contact_number: values.contact_number,
                no_of_adult: values.no_of_adult,
                no_of_child: values.no_of_child,
                no_of_infant: values.no_of_infant,
                terms_and_condition: values.termsAndConditions
            }
            // console.log(itineraryUrl)
            // return
            QuotesRequestService(obj, async (res) => {
                if (res.status === 404){
                    console.log('Something went wrong');
                };
                if (res.status === 200){
                    setVisibleAlertSuccess(true);
                    setIsFormSubmitting(false);
                    quoteFromik.resetForm();
                }
                // console.log("Send Quotes API response: ", res);
            });
        }
    });
    const { values, handleSubmit, setFieldValue, setValues } = quoteFromik;

    const getItineraryDetailsData = async () => {
        setIsLoading(true);
        const Obj = {
            itineraryId: searchParams.get("itineraryId"),
            newCurrency: currencyData?.currency
        }
        await publicDetailServiceApi(Obj, async (res) => {
            // console.log("Itinarary Data response: ", res.data.data);
            if (res.status === 404) {
                console.log('Something went wrong')
            }
            if (res.status === 200) {
                // console.log(res.data.data.message);
                setItinararyData(res.data.data.data);
                setIsLoading(false);
            }
        });
    }

    useEffect(()=> {
        quotesContent();
        if(searchParams.get("itineraryId")){getItineraryDetailsData()}
    }, [])

 

    return ( 
        <>
        {isLoading ? <LoadingSpinnerOne /> : ""}
        <div className="content-body">
            {!itinararyData ? isLoading === true? null : <Alert variant="success">
                <p>Trip quote allows you to send itinerary information and quotes from to travelers.</p>
            </Alert> : null}
            {visibleAlertSuccess && <Alert variant="success" onClose={() => setVisibleAlertSuccess(false)} dismissible>
                            <p>Thank you for the request. We will get back to you within 24 hours.</p>
                        </Alert>}
            <h1 className="d-col-title mb-3">Request a Quote</h1>
            {!itinararyData ? null :
            <div className="tour-item-wrap mt-2 mb-2">
                <div className="tour-item-title">
                    <h4>{itinararyData.tour_name}</h4>
                    <p>{itinararyData.destinationCityDetails.reduce((accumulator, current) => accumulator + current.tour_no_of_days, 0)} days <span>|</span>  {itinararyData.travellers_type} <span>|</span>&nbsp;
                    {itinararyData.destinationCityDetails.map((desitem, d) =>{return <>{desitem.destination_city.country}, {desitem.destination_city.city}<span>&nbsp;|&nbsp;</span></>})}
                    {itinararyData.no_adult_travellers} adults and {itinararyData.no_children_travellers.no_children} children  <span>|</span> Estimated cost: {itinararyData.currencyConvert.new_amount} {itinararyData.currencyConvert.new_currency}</p>
                </div>
                {/* <Link to="/" className="btn edit-icon"></Link> */}
            </div>}
            <form onSubmit={quoteFromik.handleSubmit}>
                <div className="row">
                    <div className="col-md-12 mt-2">
                        <div className="d-col-input-field">
                            <label className="form-label">Starting Country, City</label>
                        </div>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <div className="d-col-input-field">
                                    <select className="form-select" name="country" value={values.country}
                                        onChange={(e) => {
                                            setFieldValue("country", e.target.value);
                                        }}
                                        onBlur={quoteFromik.handleBlur("country")} >
                                        <option value="">Select Country</option>
                                        {Country.getAllCountries().map(function(countryitem, i){
                                            return <>
                                                <option key={i} value={countryitem.isoCode}>{countryitem.name}</option>
                                            </>
                                        })}
                                    </select>
                                    {quoteFromik.touched.country && quoteFromik.errors.country ? <p className="errors-msg">{quoteFromik.errors.country}</p> : null}
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <div className="d-col-input-field">
                                    <select className="form-select" name="city" value={values.city}
                                        onChange={(e) => {
                                            setFieldValue("city", e.target.value);
                                        }}
                                        onBlur={quoteFromik.handleBlur("city")} >
                                        <option value="">Select City</option>
                                        {City.getCitiesOfCountry(values.country).map(function(cityitem, i){
                                            return <>
                                                <option key={i} value={cityitem.name}>{cityitem.name}</option>
                                            </>
                                        })}
                                    </select>
                                    {quoteFromik.touched.city && quoteFromik.errors.city ? <p className="errors-msg">{quoteFromik.errors.city}</p> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="d-col-input-field">
                            <label className="form-label">Contact Details</label>
                        </div>
                        <div className="row">
                            <div className="col-md-4 mb-3">
                                <div className="d-col-input-field">
                                    <input onChange={quoteFromik.handleChange} onBlur={quoteFromik.handleBlur} value={quoteFromik.values.user_name} name="user_name" type="text" className="form-control" placeholder="User Name"/>
                                </div>
                                {quoteFromik.touched.user_name && quoteFromik.errors.user_name ? <p className="errors-msg">{quoteFromik.errors.user_name}</p> : null}
                            </div>
                            <div className="col-md-4 mb-3">
                                <div className="d-col-input-field">
                                    <input onChange={quoteFromik.handleChange} onBlur={quoteFromik.handleBlur} value={quoteFromik.values.email} name="email" type="email" className="form-control" placeholder="Email"/>
                                </div>
                                {quoteFromik.touched.email && quoteFromik.errors.email ? <p className="errors-msg">{quoteFromik.errors.email}</p> : null}
                            </div>
                            <div className="col-md-4 mb-3">
                                <div className="d-col-input-field">
                                    <PhoneInput
                                        country={'us'}
                                        onChange={value => quoteFromik.setFieldValue("contact_number", value)} 
                                        onBlur={quoteFromik.handleBlur}
                                        name="contact_number" 
                                        value={quoteFromik.values.contact_number}
                                        placeholder="Enter Phone Number"
                                        containerClass="custom-ep-input"
                                    />
                                </div>
                                {quoteFromik.touched.contact_number && quoteFromik.errors.contact_number ? <p className="errors-msg">{quoteFromik.errors.contact_number}</p> : null}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="d-col-input-field">
                            <label className="form-label">Number of traveller </label>
                        </div>
                    </div>
                    <div className="col-md-4 mb-3">
                        <div className="d-col-number-days d-col-input-field">
                            <label className="form-label">Adult</label>
                            <div className="d-col-number-field">
                                <NumericInput onChange={value => quoteFromik.setFieldValue("no_of_adult", value)} onBlur={quoteFromik.handleBlur("no_of_adult")} name="no_of_adult" mobile={false} value={quoteFromik.values.no_of_adult} min={1} type="number" strict={true} />
                            </div>
                            {quoteFromik.touched.no_of_adult && quoteFromik.errors.no_of_adult ? <p className="errors-msg">{quoteFromik.errors.no_of_adult}</p> : null}
                        </div>
                    </div>
                    <div className="col-md-4 mb-3">
                        <div className="d-col-number-days d-col-input-field">
                            <label className="form-label">Child</label>
                            <div className="d-col-number-field">
                                <NumericInput onChange={value => quoteFromik.setFieldValue("no_of_child", value)} onBlur={quoteFromik.handleBlur("no_of_child")} name="no_of_child" mobile={false} value={quoteFromik.values.no_of_child} min={0} type="number" strict={true} />
                            </div>
                            {quoteFromik.touched.no_of_child && quoteFromik.errors.no_of_child ? <p className="errors-msg">{quoteFromik.errors.no_of_child}</p> : null}
                        </div>
                    </div>
                    <div className="col-md-4 mb-3">
                        <div className="d-col-number-days d-col-input-field">
                            <label className="form-label">Infant</label>
                            <div className="d-col-number-field">
                                <NumericInput onChange={value => quoteFromik.setFieldValue("no_of_infant", value)} onBlur={quoteFromik.handleBlur("no_of_infant")} name="no_of_infant" mobile={false} value={quoteFromik.values.no_of_infant} min={0} type="number" strict={true} />
                            </div>
                            {quoteFromik.touched.no_of_infant && quoteFromik.errors.no_of_infant ? <p className="errors-msg">{quoteFromik.errors.no_of_infant}</p> : null}
                        </div>
                    </div>
                </div>
                <div className="form-check-group">
                    <div className="form-check">
                        <input className="form-check-input" onChange={quoteFromik.handleChange} onBlur={quoteFromik.handleBlur} name="termsAndConditions" checked={values.termsAndConditions} type="checkbox"/>
                        <div className="form-check-label qs-content-label">
                            <div dangerouslySetInnerHTML={{ __html: quotesTermsData ? quotesTermsData.content : "" }}></div>
                        </div>
                    </div>
                    {quoteFromik.touched.termsAndConditions && quoteFromik.errors.termsAndConditions ? <p className="errors-msg">{quoteFromik.errors.termsAndConditions}</p> : null}
                </div>
                <div className="form-btn-groups d-flex justify-content-end mt-3">
                    <button type="submit" disabled={isFormSubmitting === true || !itinararyData} className="btn btn-primary-color">{isFormSubmitting? "Sending.." : "Send"}</button>
                </div>
            </form>
        </div>
                   
        </>
    );
}
 
export default RequestQuote;