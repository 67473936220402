import React, {useState, useEffect} from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {updateItineraryPublishApi} from '../../../services/AdminService';
import ItineraryItem from './ItineraryItem';
import Modal from 'react-bootstrap/Modal';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {toast, Slide} from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
toast.configure();
const options = {
    position: "top-center",
    hideProgressBar: true,
    transition: Slide,
    theme: "colored",
    closeButton: false,
    autoClose: 2000,
    showDuration: 300,
    hideDuration: 1000
}

const ItineraryPreview = () => {
    const history = useHistory();
    const location = useLocation();
    const searchPrams = new URLSearchParams(location.search);
    const [showPublishModal, setShowPublishModal] = useState(false);

    const handlePublishModalShow = (event) => {
        setShowPublishModal(true);
        formik.resetForm();
    }
    const handlePublishModalClose = (event) => {
        setShowPublishModal(false);
        formik.resetForm();
    }
    const formik = useFormik({
        initialValues: {
            type_of_itinerary: ""
        },
        validationSchema: Yup.object({
            type_of_itinerary: Yup.string().required('Please select type of Itineraries.'),
        }),
        onSubmit: async (values) => {  
            //console.log(values)
            const publishObj = {
                itineraryId: searchPrams.get("itineraryId"),
                type_of_itinerary: values.type_of_itinerary,
            }
            await updateItineraryPublishApi(publishObj, async (res) => {
                // console.log("res updateItineraryPublishApi ====", res);
                if (res.status === 404) {
                    console.log('Something went wrong')
                }
                if (res.status === 200) {
                    // console.log("Itineraries type added successfully.");
                    setShowPublishModal(false);
                    formik.resetForm();
                    history.push({pathname:'/admin/itineraries-list/'});
                }
            });
        }
    });

    useEffect(() => {}, []);

    return ( 
        <>
        <div className="content-body">
            <div className="d-flex align-items-center mb-2">
                <button type="button" className="btn btn-secondary-color btn-border-color btn-width-30 me-2" onClick={() => history.push(`/admin/create-itinerary/?userId=${searchPrams.get("userId")}&&itineraryId=${searchPrams.get("itineraryId")}`)}><i className="bi bi-arrow-left"></i></button> 
                <h1 className="d-col-title">Preview itinerary</h1>
            </div>
            <ItineraryItem />
            <div className="form-btn-groups d-flex justify-content-end mt-4">
                <button type="button" className="btn btn-secondary-color btn-border-color" onClick={() => history.push(`/admin/create-itinerary/?userId=${searchPrams.get("userId")}&&itineraryId=${searchPrams.get("itineraryId")}`)}><i className="bi bi-arrow-left"></i>&nbsp;&nbsp;Back</button> 
                <button type="button" onClick={() => handlePublishModalShow()} className="btn btn-primary-color ms-3">Publish</button>
            </div>
        </div>
        <Modal
        show={showPublishModal}
        onHide={handlePublishModalClose}
        keyboard={false}
        size="sm"
        className="add-travellers-modal publish-modal-style"
        centered
        >
            <Modal.Body>
                <form onSubmit={formik.handleSubmit}>
                    <div className="d-col-radio-field flex-column align-items-start">
                        <div className="form-check me-0">
                            <input className="form-check-input" onChange={formik.handleChange} onBlur={formik.handleBlur} name="type_of_itinerary" value="Public" type="radio" id="Public"/>
                            <label className="form-check-label" htmlFor="Public">
                                <h6>Public</h6>
                                <p>Your itinerary can be seen by any registered user on Gladiatours. (No personal details will be visible).</p>
                            </label>
                        </div>
                        <div className="form-check me-0">
                            <input className="form-check-input" onChange={formik.handleChange} onBlur={formik.handleBlur} name="type_of_itinerary" value="Private" type="radio" id="Private"/>
                            <label className="form-check-label" htmlFor="Private">
                                <h6>Private</h6>
                                <p>Your itinerary can only be seen by you.</p>
                            </label>
                        </div>
                    </div>
                    {formik.touched.type_of_itinerary && formik.errors.type_of_itinerary ? <p style={{color:'red', fontSize: "13px", textAlign: "center"}}>{formik.errors.type_of_itinerary}</p> : null}
                    <div className="justify-content-end d-flex mt-3">
                        <button type="button" onClick={handlePublishModalClose} className="btn btn-secondary-color btn-border-color">Close</button>
                        <button type="submit" className="btn btn-primary-color btn-border-color ms-2">Submit</button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
        </>
    );
}

export default ItineraryPreview;