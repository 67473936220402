import "./Footer.scss";
import React from 'react';
import { Link } from 'react-router-dom';
import siteLogo from '../../../../assets/images/logo.png';

const Footer = () => {
    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
        <>
            <footer className="ft-hold-wrapper">
                <div className="container-lg">
                    <div className="ft-middle">
                        <Link className="ft-logo" to="/"><img src={siteLogo} alt="Gladia Tour" className="img-responsive" /></Link>
                        <ul className="ft-links">
                            <li><Link to="/about">About</Link></li>
                            <li><Link to="/terms-conditions">Terms & Conditions</Link></li>
                            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                            <li><Link to="/contact">Contact</Link></li>
                            <li><Link to="/help-support">Help & Support</Link></li>
                            <li><Link to="/faq">FAQ</Link></li>
                        </ul>
                        <div className="ft-social-icons">
                            <button type="button" className="btn facebook-icon" onClick={() => openInNewTab("https://www.facebook.com/")}></button>
                            <button type="button" className="btn instagram-icon" onClick={() => openInNewTab("https://www.instagram.com/")}></button>
                            <button type="button" className="btn twitter-icon" onClick={() => openInNewTab("https://twitter.com/")}></button>
                        </div>
                    </div>
                    <p className="copyright-txt"> © copyright 2023 Gladiatours. All Rights Reserved.</p>
                </div>
            </footer>
        </>
    );
}

export default Footer;