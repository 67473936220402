import React, { useState, useEffect } from 'react';
import "./Contact.scss";
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import {ContactUsService, ContactInfoService} from '../../../services/DataService';
import {LoadingSpinnerOne} from "../../../loader/Loader";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {toast} from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
toast.configure();

const Contact = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [contactInfoData, setContactInfoData] = useState();
    const [activeMarker, setActiveMarker] = useState(null);
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            phone: "",
            subject: "",
            message: ""
        },
        validationSchema: Yup.object({
            name: Yup.string()
              .max(20, 'Name should not exceed 20 Characters.')
              .required('Please enter name.'),
            email: Yup.string()
              .email('Invalid email address.')
              .required('Please enter email address.'),
            phone: Yup.string()
              .matches(phoneRegExp, 'Phone number is not valid.')
              .required('Please enter phone number.'),
            subject: Yup.string()
            .required('Please enter a subject.'),
            message: Yup.string()
            .required('Please enter message.'),
        }),
        onSubmit: values => {  
            // console.log("contact value====",values)
            ContactUsService(values, async (res) => {
                // console.log("contact us response: ", res);
                if (res.status === 404){
                  console.log('Something went wrong')
                } 
                if(res.status === 200){
                    toast.success('Thank you for contacting us we will get back to you soon.');
                    formik.resetForm();
                }
            });
        }
    });
    const markers = { lat: 18.5204, lng: 73.8567 };
    const handleActiveMarker = (marker) => {
        if (marker === activeMarker) {
          return;
        }
        setActiveMarker(marker);
    };

    useEffect(()=>{
        getContactInfotApi();
    },[]);

    const getContactInfotApi = async () => {
        setIsLoading(true);
        await ContactInfoService("contactinfo", async (res) => {
            if (res.status === 404) console.log("Something went wrong");
            await setContactInfoData(res.data.data.data);
            setIsLoading(false)
        });
    };

    return ( 
        <>
        <section className="inner-title-block inner-mrt-80">
            <div className="container-lg text-center">
                <h1>Contact Us</h1>
            </div>
        </section>
        <section className="contact-info-block padd-top-70">
            {isLoading ? <LoadingSpinnerOne/> : null}
            <div className="container-lg">
                <div className="row">
                    <div className="col-sm-6 col-lg-3 top-contact-item">
                        <i className="bi bi-geo-alt"></i>
                        <span>Address</span>
                        <p>{contactInfoData?.address? contactInfoData?.address : "Address not found."}</p>
                    </div>
                    <div className="col-sm-6 col-lg-3 top-contact-item">
                        <i className="bi bi-phone"></i>
                        <span>Call us</span>
                        <p>{contactInfoData?.phone? contactInfoData?.phone : "Phone No. not found."}</p>
                    </div>
                    <div className="col-sm-6 col-lg-3 top-contact-item">
                        <i className="bi bi-envelope"></i>
                        <span>Email us</span>
                        <p>{contactInfoData?.email ? contactInfoData?.email : "Email not found."}</p>
                    </div>
                    <div className="col-sm-6 col-lg-3 top-contact-item">
                        <i className="bi bi-telephone"></i>
                        <span>Toll Free</span>
                        <p>{contactInfoData?.tollFree ? contactInfoData?.tollFree: "Toll free No. not found."}</p>
                    </div>
                </div>
            </div>
        </section>
        <section className="contact-wrapper padd-top-70 padd-bottom-70">
            <div className="container-lg">
                <div className="row contact-form-row">
                    <div className="col-md-5">
                        <div className="contact-map">
                            <GoogleMap
                                onLoad={(map) => {
                                    const bounds = new window.google.maps.LatLngBounds({
                                        lat: markers.lat, 
                                        lng: markers.lng
                                    });
                                    map.fitBounds(bounds);
                                }}
                                onClick={() => setActiveMarker(null)}
                                mapContainerStyle={{ width: "100%", height: "100%" }}
                                options={{ disableDefaultUI:true }}
                                >
                                <Marker
                                    position={{lat: markers.lat, lng: markers.lng}}
                                    //icon={MarkerIcon}
                                    onClick={() =>handleActiveMarker(1)}
                                    onMouseOver={() =>handleActiveMarker(1)}
                                    onMouseOut={() => setActiveMarker(null)}
                                >
                                    {activeMarker === 1 ? (
                                        <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                                            <div>{contactInfoData?.address}</div>
                                        </InfoWindow>
                                    ) : null}
                                </Marker>
                            </GoogleMap>
                        </div>
                    </div>
                    <div className="col-md-7 mt-4 mt-md-0">
                        <div className="contact-title-block">
                            <h3>Get in touch</h3>
                            {/* <p>Do fill in the form below to help us know your requirement better.</p> */}
                        </div>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="cmn-form-input-fields mt-3">
                                <input onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.name} name="name" className="form-control f-user-icon" type="text" id="Name" placeholder="Name"/>
                                {formik.touched.name && formik.errors.name ? <p className="errors-msg">{formik.errors.name}</p> : null}
                            </div>
                            
                            <div className="cmn-form-input-fields mt-3">
                                <input onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email} name="email" className="form-control f-mail-icon" type="email" id="Email" placeholder="Email"/>
                                {formik.touched.email && formik.errors.email ? <p className="errors-msg">{formik.errors.email}</p> : null}
                            </div>
                            <div className="cmn-form-input-fields mt-3">
                                <input onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.phone} name="phone" className="form-control f-call-icon" type="text" id="ContactNumber" placeholder="Contact Number"/>
                                {formik.touched.phone && formik.errors.phone ? <p className="errors-msg">{formik.errors.phone}</p> : null}
                            </div>
                            <div className="cmn-form-input-fields mt-3">
                                <input onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.subject} name="subject" className="form-control f-subject-icon" type="text" id="Subject" placeholder="Subject"/>
                                {formik.touched.subject && formik.errors.subject ? <p className="errors-msg">{formik.errors.subject}</p> : null}
                            </div>
                            <div className="cmn-form-input-fields mt-3">
                                <textarea onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.message} name="message" rows="4" className="form-control f-message-icon" id="YourMessage" placeholder="Your Message"></textarea>
                                {formik.touched.message && formik.errors.message ? <p className="errors-msg">{formik.errors.message}</p> : null}
                            </div>
                            <div className="form-btn-fields mt-3">
                                <button type="submit" className="btn btn-primary-color submit-btn">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}

export default Contact;