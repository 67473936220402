import React, { useState, useEffect } from 'react';
import "./About.scss";
import {GetAboutUsService} from '../../../services/DataService';
import {LoadingSpinnerOne} from "../../../loader/Loader";

const About = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [userData, setUserData] = useState();

    useEffect(()=>{
        getAboutUsApi();
    },[]);

    const getAboutUsApi = async () => {
        setIsLoading(true);
        await GetAboutUsService("about", async (res) => {
        if (res.status === 404) console.log("Something went wrong");
            setUserData(res.data.data.data);
            setIsLoading(false);
        });
    };

    return ( 
        <>
        <section className="inner-title-block inner-mrt-80">
            <div className="container-lg text-center">
                <h1>About Us</h1>
            </div>
        </section>
        <section className="cmn-info-wrapper padd-top-70 padd-bottom-70">
            {isLoading ? <LoadingSpinnerOne/> : !userData ? <p className="text-center">No data found.</p> : null}
            <div className="container-lg clearfix aboutus-info">
                {/* <div className="AboutImg-bx">
                    <img src={AboutImg} alt=""/>
                </div> */}
                {!userData ? null :<div dangerouslySetInnerHTML={{ __html: userData.content }}></div>}
            </div>
        </section>
        </>
    );
}
 
export default About;