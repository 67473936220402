import './PaymentCancelled.scss';
import React, {useState, useEffect} from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {cancelBookingApi} from '../../../../services/StayService';
import {toast} from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
toast.configure();

const PaymentCancelled = () => {
    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [cancelBookingData, setCancelBookingData] = useState();

    const hotelBookedData = async () => {
        const Obj = {
            supplierConfirmationNum: searchParams.get("supplierConfirmationNum"),
            referenceNum: searchParams.get("referenceNum")
        }
        await cancelBookingApi(Obj, async (res) => {
            // console.log("cancel Booking response: ", res.data.data);
            if (res.status === 404) {
                console.log('Something went wrong')
            }
            if (res.status === 200) {
                // console.log(res.data.data.message);
                setCancelBookingData(res.data.data.data);
            }
        });
    }

    useEffect(() => {
        hotelBookedData();
    }, []);

    return ( 
        <>
        <section className="inner-mrt-80 padd-top-70 padd-bottom-70">
            <div className="container-lg">
                <div className="cmn-card-info-block text-center">
                    <div className="cmn-card-info-content payment-cancelled">
                        <i className="bi bi-exclamation-circle-fill"></i>
                        <h3>Payment Cancelled</h3>
                        <p>Cancel reference number: {cancelBookingData?.cancelReferenceNum}</p>
                        <p>{cancelBookingData?.message}</p>
                        <Link to="/" className="btn btn-primary-color mt-4">Back to home</Link>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}
 
export default PaymentCancelled;