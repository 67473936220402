import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import noPictureAvailable from '../../../../assets/images/no-picture-available.jpg';
import { getDestinationItinerariesApi } from '../../../../services/DataService';
import { googleApiKey, googlePhotoUrl } from '../../../../services/CommonUrl';
import { LoadingSpinnerTwo } from '../../../../loader/Loader';
import { useAuth } from '../../../../contexts/AuthProvider';

const TdfItemSliderOptions = {
    loop: true,
    autoplay: true,
    margin: 10,
    mouseDrag: false,
    touchDrag: true,
    responsiveClass: true,
    navText: ["", ""],
    responsive: {
        0: {
            items: 1,
            nav: false,
            dots: true
        },
        380: {
            items: 2,
            nav: false,
            dots: true
        },
        768: {
            items: 3,
            nav: false,
            dots: true
        },
        1024: {
            items: 4,
            nav: false,
            dots: false,
            margin: 16
        }
    }
}

const TopDestinationItineraries = () => {
    const { currencyData } = useAuth();
    const history = useHistory();
    const photoUrl = `${googlePhotoUrl}`;
    const apikey = `&key=${googleApiKey}`;
    const [destinationsData, setDestinationsData] = useState([]);
    const [isTdLoading, setIsTdLoading] = useState(false);

    const getTopDestinationData = async () => {
        setIsTdLoading(true);
        await getDestinationItinerariesApi({ newCurrency: currencyData?.currency }, async (res) => {
            // console.log("res getDestinationItinerariesApi ====", res);
            if (res.status === 404) {
                console.log('Something went wrong')
                setIsTdLoading(false);
            }
            if (res.data.data.code === 200) {
                setIsTdLoading(false);
                setDestinationsData(res.data.data.data);
            }
        });
    }

    useEffect(() => {
        getTopDestinationData();
    }, []);

    return (
        <>
            <div className="header-title-wrapper margin-bottom-60">
                <div className="head-title">
                    <h5>Recommended for you</h5>
                    <h2>Top Destinations for you</h2>
                </div>
                {/* <Link to="/" className="btn-view-all">View All</Link> */}
            </div>
            {isTdLoading === true ? <LoadingSpinnerTwo /> : destinationsData?.length === 0 ? <p className="text-center">No itinerary found.</p> : 
             <OwlCarousel {...TdfItemSliderOptions} className="owl-carousel owl-theme custom-owl-theme pt-carousel">
                {destinationsData && destinationsData?.map((item, i) => {
                    return (
                        <>
                            <div key={i} className="tdf-item-box">
                                {item?.fields?.photo_reference === undefined || item?.fields?.photo_reference === null || item?.fields?.photo_reference === "" ?
                                    <div className="tdf-item-img" style={{ backgroundImage: `url(${noPictureAvailable}` }}></div>
                                    : <div className="tdf-item-img" style={{ backgroundImage: `url(${photoUrl}maxwidth=400&photoreference=${item?.fields?.photo_reference}${apikey})` }}></div>}
                                <div className="tdf-item-info">
                                    <div className="tdf-item-info-header">
                                        <h4>{item?.fields?.total_destination_city?.city}, {item?.fields?.total_destination_city?.country}</h4>
                                        {/* <span className="tdf-item-price">{field.currencyConvert.new_amount} {field.currencyConvert.new_currency}</span>  */}
                                    </div>
                                    <p className="tdf-trip-count">{item?.fields?.total_days} Days Trip</p>
                                </div>
                            </div>
                        </>
                    )
                })}
            </OwlCarousel>}
        </>
    );
};

export default TopDestinationItineraries;