import "./Navbar.scss";
import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Header from '../../../Main/shared/header/Header';

const Navbar = () => {
  const history = useHistory();

  const toggleOffcanvas = ()=> {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
    document.querySelector('.sidebar-overlay').classList.toggle('active');
  }
  
  // const toggleRightSidebar = ()=> {
  //   document.querySelector('.right-sidebar').classList.toggle('open');
  // }

  useEffect(() => {
    toggleOffcanvas();
    document.querySelector('.sidebar-offcanvas').classList.remove('active');
    document.querySelector('.sidebar-overlay').classList.remove('active');
    // toggleRightSidebar();
  },[]);

  const onClickLogout = ()=>{
    // console.log('logout')
    localStorage.clear();
    history.push("/");
  }
  return (
    <>
    <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div className="navbar-menu-wrapper d-flex align-items-center">
      <Header/>
        {/* <Link className="logo" to="/"><img src={ require("../../../assets/images/logo.png") } alt="Gladia Tour" className="img-responsive"/></Link>
        <div className="navbar-nav-right d-flex align-items-center justify-content-end">
          <button className="btn btn-secondary-color btn-border-color d-logout-btn" type="button" onClick={onClickLogout}>Logout</button>
          <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={toggleOffcanvas}>
            <span className="bi bi-list"></span>
          </button>
        </div> */}
        <button className="navbar-toggler-admin navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={toggleOffcanvas}>
          <span className="bi bi-list"></span>
        </button>
      </div>
    </nav>
    </>
  );
}

export default Navbar;
