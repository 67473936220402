import './AdminContainer.scss';
import React from 'react';
import Sidebar from '../../views/Admin/shared/sidebar/Sidebar';
import Navbar from '../../views/Admin/shared/navbar/Navbar';

const AdminContainer = ({ children }) => {
    return (
        <>
            <div className="container-scroller">
                <Navbar />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminContainer;

