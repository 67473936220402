import './FlightBookingSummary.scss';
import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';


const FlightBookingSummary = () => {
    const history = useHistory();
    const location = useLocation();

    return (
        <>
        <section className="inner-title-block stay-search-form-block inner-mrt-80">
            <div className="flight-h-block container-lg">
                <div className="flight-h-left">
                    <h5>Berlin All Airports, Germany - Warsaw, Poland(One Way)</h5>
                    <p>Class:  Economy / Travellers:  1 Passenger/s / Dates:  Tue, 10 Jan 2023</p>
                </div>
                <div className="flight-h-right">
                    <button type="button" className="btn btn-primary-color"><i className="fa fa-caret-left"></i>&nbsp;&nbsp;Previous Day</button>
                    <button type="button" className="btn btn-primary-color">Next Day&nbsp;&nbsp;<i className="fa fa-caret-right"></i></button>
                </div>
            </div>
        </section>
        <section className="flight-result-block">
            <div className="container-lg d-flex justify-content-between">
                <div className="flight-filter-left">
                    <a href="javascript:void(0);" className="btn btn-primary-color width-100">Back to results</a>
                    <Accordion alwaysOpen>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Price</Accordion.Header>
                            <Accordion.Body>
                                CDazsf
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>NO. OF STOPS</Accordion.Header>
                            <Accordion.Body>
                                dfgfd
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>FARE TYPE</Accordion.Header>
                            <Accordion.Body>
                                dfgfd
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>BRAND TYPE</Accordion.Header>
                            <Accordion.Body>
                                dfgfd
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>LAYOVER TIME</Accordion.Header>
                            <Accordion.Body>
                                dfgd
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header>TRAVEL TIME</Accordion.Header>
                            <Accordion.Body>
                                dfgd
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="6">
                            <Accordion.Header>AIRLINES</Accordion.Header>
                            <Accordion.Body>
                                fdg
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="7">
                            <Accordion.Header>LAYOVER AIRPORTS</Accordion.Header>
                            <Accordion.Body>
                                fgh
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
                <div className="flight-filter-right">
                    sdfgd
                    
                </div>
            </div>
        </section>
        </>
    );
}

export default FlightBookingSummary;