import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Nav from "react-bootstrap/Nav";
import Spinner from "react-bootstrap/Spinner";
import {getUserItineraryListApi, getUserItineraryCityListApi} from "../../../../services/AdminService";
import {saveUserHotelBook} from "../../../../services/StayService";
import moment from "moment";
import { useAuth } from "../../../../contexts/AuthProvider";

const AddItineraryModal = (props) => {
  const {authed} = useAuth();
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [getItineraryId, setGetItineraryId] = useState();
  const [userItineraryList, setUserItineraryList] = useState();
  const [userItineraryCityList, setUserItineraryCityList] = useState();
  const [dayIdArray, setDayIdArray] = useState([]);

  const getUserItineraryData = async () => {
    await getUserItineraryListApi({userId: authed?.user?._id}, async (res) => {
      // console.log("API Get itinerary list response: ", res);
      if (res.status === 404) {
        console.log("Error 404");
        return;
      }
      if (res.status === 200) {
        const result = res.data.data.data.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1)
        await setUserItineraryList(result);
      }
    });
  };

  const onChangeItinerary = async (e) => {
    e.preventDefault();
    setIsDataLoading(true);
    setDayIdArray([]);
    if (e.target.value) {
      setGetItineraryId(e.target.value);
      const obj = {
        userId: authed?.user?._id,
        itineraryId: e.target.value,
      };
      await getUserItineraryCityListApi(obj, async (res) => {
        // console.log("API Get itinerary city list response: ", res);
        if (res.status === 404) {
          console.log("Error 404");
          return;
        }
        if (res.status === 200) {
          setIsDataLoading(false);
          setUserItineraryCityList(res.data.data.data);
        }
      });
    } else {
      setUserItineraryCityList(null);
      setIsDataLoading(false);
    }
  };
 
  const getDayItemData = (e, itineraryId) => {
    setIsDataLoading(true);
    const saveHotelCheckState = {
      userId: authed?.user?._id,
      clientRef: "TDB85454",
      customerEmail: authed?.user?.email,
      customerPhone: authed?.user?.phone,
      bookingNote: "",
      time: "00:00",
      occupancy: JSON.parse(searchParams.get("occupancy")),
      city_name: searchParams.get("cityName"),
      country_name: searchParams.get("countryName"),
      paxDetails: props.paxDetails? props.paxDetails : [],
      hotelDetails: props.hotelData,
      netPrice: props.roomData.netPrice,
      sessionId: searchParams.get("sessionId"),
      tokenId: searchParams.get("tokenId"),
      productId: searchParams.get("productId"),
      rateBasisId: props.roomData.rateBasisId,
      itineraryId: itineraryId,
      daysId: dayIdArray,
      checkIn: searchParams.get("checkInDate"),
      checkOut: searchParams.get("checkOutDate"),
    };
    // console.log(saveHotelCheckState)
    // return
    //console.log('saveHotelCheckState==============', saveHotelCheckState)
    saveUserHotelBook(saveHotelCheckState, async (res) => {
      // console.log("res saveUserHotelService====", res.data.data);
      if (res.status === 404) {
        console.log("Something went wrong");
      }
      if (!res.data.data.success) {
        console.log(res.data.data.message);
      }
      if (res.status === 200) {
        // console.log(res.data.data.message);
        props.onClose();
        setIsDataLoading(false);
        history.push({
          pathname: "/admin/create-itinerary/",
          search: `userId=${authed?.user?._id}&&itineraryId=${itineraryId}`,
        });
      }
    });
  };

  const handleDayId =(e, dayid, itineraryid)=>{
    if(e.target.checked){
      setDayIdArray([...dayIdArray, {daysId:dayid, itineraryId:itineraryid}])
    }
    else{
      setDayIdArray( dayIdArray.filter(d=>{
        return d.daysId !== dayid
      }))
    }
  }

  const dayNumber =(i, d)=>{
    let s = 0;
    for(let j =0; j < i ; j++){
      s += Number(userItineraryCityList[j]?.itineraryDatys?.length);
    }
    return s + d
  }

  useEffect(() => {
    getUserItineraryData();
    if(props.onClose) {
      setUserItineraryCityList(null);
      setIsDataLoading(false);
    }
  }, [props.open]);

  return (
    <>
      <Modal
        show={props.open}
        onHide={props.onClose}
        keyboard={false}
        size="md"
        centered
        className="EnquirecustomModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add to Itinerary</Modal.Title>
        </Modal.Header>
        <Modal.Body className="select-itinerary-body">
          <div className="select-itinerary-tp">
            <select
              className="form-select"
              onChange={(e) => {
                onChangeItinerary(e);
              }}
            >
              <option value="">Select Itinerary</option>
              {userItineraryList?.length > 0 ? (
                userItineraryList.map((item, i) => {
                  return (
                    <option value={`${item._id}`}>{item.tour_name}</option>
                  );
                })
              ) : (
                <option disabled>No Itinerary Found.</option>
              )}
            </select>
          </div>
          <div className="itinarary-days-nav">
            {userItineraryCityList &&
              userItineraryCityList.map((item, i) => {
                return (
                  <>
                    <h6 className="mb-2 mt-3">
                      Destination City - <b>{item.destination_city.city}</b>
                    </h6>
                    <Nav variant="pills" className="d-nav-tabs">
                      {item.itineraryDatys.map((daysitem, d) => {
                        return (
                          <>
                            <Nav.Item key={d}>
                              {moment(searchParams.get("checkInDate")).format("DD-MM-YYYY") <= moment(daysitem.date).format("DD-MM-YYYY") && moment(searchParams.get("checkOutDate")).format("DD-MM-YYYY") >= moment(daysitem.date).format("DD-MM-YYYY") ?
                              <input type="checkbox" className="form-check-input" id={`idc${i+"_"+d}`} onClick={(e) => handleDayId(e, daysitem._id, item.itineraryId)}/>:null}
                              <label className="nav-link" htmlFor={`idc${i+"_"+d}`}>
                                Day {i > 0 ? dayNumber(i, daysitem.day): daysitem.day}
                                <span>{daysitem.date? moment(daysitem.date).format("DD MMMM yyyy"): null}</span>
                              </label>
                            </Nav.Item>
                          </>
                        );
                      })}
                    </Nav>
                  </>
                );
            })}

            {userItineraryCityList? 
            <div className="text-center mt-3">
              <button type="button" className="btn btn-primary-color" disabled={dayIdArray?.length < 1} onClick={(e) => getDayItemData(e, getItineraryId)}>Add</button>
            </div>: null}
          </div>
          <div className="select-itinerary-btm">
            <div className="or-divider"></div>
            <Link to="/admin/create-itinerary" className="btn btn-primary-color">
              Create Itinerary
            </Link>
          </div>
          {isDataLoading ? <>
            <div className="stay-added-loading">
              <Spinner animation="border" variant="primary" />
            </div>
          </> :null}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddItineraryModal;
