import "./HomeSlider.scss";
import React from "react";
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { scroller } from "react-scroll";
import BackgroundImage1 from '../../../../../assets/images/banner-img-1.jpg';
import { useAuth } from "../../../../../contexts/AuthProvider";

const MainBannerSliderOptions = {
    loop: false,
    autoplay: false,
    responsiveClass: true,
    items: 1,
    nav: false,
    dots: false
}
export const HomeSlider = (props) => {
    const { authed } = useAuth();
    const customizeTrip = () => {
        props.toggleTrip();
    };

    return (
        <>
            <OwlCarousel {...MainBannerSliderOptions} lazyLoad={true} className="owl-carousel owl-theme banner-owl-theme">
                <div className="item" style={{ backgroundImage: `url(${BackgroundImage1})` }}>
                    <div className="banner-info-content">
                        <h2>Gladiatours</h2>
                        <p>Bookings.Travel Plans. Itinerary. Budget...Made Easy</p>
                        <div className="banner-btns">
                            {authed?.isAuth === true ?
                                <>
                                    <Link to="/admin/create-itinerary" className="btn btn-primary-color">Plan a new trip</Link>
                                    <button onClick={customizeTrip} className="btn btn-primary-color b-btn-2">Customize existing trip</button>
                                </>
                                :
                                <>
                                    <Link to="/signin" className="btn btn-primary-color">Plan a new trip</Link>
                                    <Link to="/signin" className="btn btn-primary-color b-btn-2">Customize existing trip</Link>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </OwlCarousel>
        </>
    )
}