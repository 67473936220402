import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import {EnquiryService} from '../../../../services/DataService';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {toast} from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
toast.configure();

const EnquiryForm = (props) => {
    const [isDataLoading, setIsDataLoading] = useState(false);

    const handleClose = () => {
        props.close(false);
        setIsDataLoading(false);
        formik.resetForm();
    }
   
    useEffect(() => {

    },[props]);

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            phone: "",
            subject: "",
            message: ""
        },
        validationSchema: Yup.object({
            name: Yup.string()
              .max(20, 'Name should not exceed 20 Characters')
              .required('Please enter name'),
            email: Yup.string()
              .email('Invalid email address')
              .required('Please enter email address'),
            phone: Yup.string()
              .matches(phoneRegExp, 'Phone number is not valid')
              .required('Please enter phone number'),
            subject: Yup.string()
            .required('Please enter subject'),
            message: Yup.string()
            .required('Please enter message'),
        }),
        onSubmit: values => {  
            // console.log("Enquiry value====",values)
            setIsDataLoading(true);
            EnquiryService(values, async (res) => {
                // console.log("contact us response: ", res);
                if (res.status === 404){
                  toast.error('Something went wrong')
                } 
                if(res.status === 200){
                    toast.success('Thank you for contacting us we will get back to you soon.');
                    props.close(false);
                    setIsDataLoading(false);
                    formik.resetForm(); 
                }
            });
        }
    });

    return ( 
        <>
        <Modal
            show={props.open}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="md"
            centered
            className="EnquirecustomModal"
        >
            <Modal.Header closeButton>
            <Modal.Title>Enquiry Form</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={formik.handleSubmit}>
                    <div className="cmn-input-field">
                        <FloatingLabel label="Full name">
                            <Form.Control type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.name} name="name" placeholder="Enter name" />
                        </FloatingLabel>
                        {formik.touched.name && formik.errors.name ? <p className="errors-msg">{formik.errors.name}</p> : null}
                    </div>
                    <div className="cmn-input-field mrt-15">
                        <FloatingLabel label="Email address">
                            <Form.Control type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email} name="email" placeholder="Enter email" />
                        </FloatingLabel>
                        {formik.touched.email && formik.errors.email ? <p className="errors-msg">{formik.errors.email}</p> : null}
                    </div>
                    <div className="cmn-input-field enquiryphone-input-field mrt-15">
                        <FloatingLabel label="Enter Phone Number">
                            <input className="form-control" type="text" style={{cursor: "none"}}/>
                            <PhoneInput
                                country={'us'}
                                onChange={e => formik.setFieldValue("phone", e)} 
                                onBlur={formik.handleBlur}
                                name="phone" 
                                value={formik.values.phone}
                                placeholder="Enter Phone Number"
                                containerClass="custom-ep-input enquiry-input-tel"
                            />
                        </FloatingLabel>
                        {formik.touched.phone && formik.errors.phone ? <p className="errors-msg">{formik.errors.phone}</p> : null}
                    </div>
                    <div className="cmn-input-field mrt-15">
                        <FloatingLabel label="Subject">
                            <Form.Control onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.subject} name="subject" type="text" placeholder="Enter subject" />
                        </FloatingLabel>
                        {formik.touched.subject && formik.errors.subject ? <p className="errors-msg">{formik.errors.subject}</p> : null}
                    </div>
                    <div className="cmn-input-field mrt-15">
                        <FloatingLabel controlId="floatingTextarea2" label="Comments">
                            <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Leave a comment here"
                            onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.message} name="message"
                            />
                        </FloatingLabel>
                        {formik.touched.message && formik.errors.message ? <p className="errors-msg">{formik.errors.message}</p> : null}
                    </div>
                    <div className="cmn-input-field mrt-15 d-flex justify-content-end">
                        <button type="button" onClick={handleClose} className="btn btn-secondary-color btn-border-color b-mr-2">Cancel</button>
                        <button type="submit" disabled={isDataLoading === true} className="btn btn-primary-color btn-border-color">{isDataLoading ? "Loading...":"Submit"}</button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    </>
    );
}
 
export default EnquiryForm;