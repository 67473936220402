import React, {useState, useEffect} from 'react';
import "./HelpSupport.scss";
import Accordion from 'react-bootstrap/Accordion';
import {GetHelpSupportService} from '../../../services/DataService';
import {LoadingSpinnerOne} from "../../../loader/Loader";

const HelpSupport = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [helpSupportData, setHelpSupportData] = useState([]);

    useEffect(()=>{
        getHelpSupportData();
    },[]);

    const getHelpSupportData = async () => {
        setIsLoading(true);
        await GetHelpSupportService("helpsupport", async (res) => {
        if (res.status === 404) console.log("Something went wrong");
            // console.log(res.data.data.data)
            await setHelpSupportData(res.data.data.data);
            setIsLoading(false);
        });
    };

    return ( 
        <>
        <section className="inner-title-block inner-mrt-80">
            <div className="container-lg text-center">
                <h1>Help & Support</h1>
            </div>
        </section>
        <section className="faq-wrapper padd-top-70 padd-bottom-70">
            <div className="container-lg">
                {isLoading ? <LoadingSpinnerOne/> : helpSupportData.length === 0 ? <p className="text-center">No data found.</p> : null}
                <Accordion>
                    {helpSupportData?.length > 0 && helpSupportData?.map((data, i) => {
                        return (
                            <Accordion.Item key={i} eventKey={i}>
                                <Accordion.Header>{data.question} </Accordion.Header>
                                <Accordion.Body>
                                    {data.answer}
                                </Accordion.Body>
                            </Accordion.Item>
                            )
                        }
                    )}
                </Accordion>
            </div>
        </section>
        </>
    );
}
 
export default HelpSupport;