import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Dropdown from 'react-bootstrap/Dropdown';
import moment from 'moment';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import WhiteArrowIcon from '../../../../assets/images/white-arrow.png';
import { searchCitiesApi } from '../../../../services/DataService';

const StaySearchForm = () => {
    const history = useHistory();
    const [roomArr, setRoomArr] = useState([{ "room_no": 1, "adult": 1, "child": 0, "child_age": [] }]);
    const [travellersResult, setTravellersResult] = useState();
    const [roomArrLoad, setroomArrLoad] = useState(true);
    const [citiesData, setCitiesData] = useState([]);

    const handleChangeGoingTo = async (e) => {
        e.stopPropagation();
        stayFormFormik.setFieldValue("goingTo", e.target.value);
        if (citiesData.length > 0) {
            document.querySelector('.cities-search-dropdown').classList.remove('d-none');
        }
        if (e.target.value.length - 1 || e.target.value.length === 0) {
            document.querySelector('.cities-search-dropdown').classList.remove('d-none');
        }
        await searchCitiesApi(e.target.value, (res) => {
            // console.log("goingTo res==", res.data.data)
            setCitiesData(res.data.data.data);
        })
    }

    const onSelectItem = (searchTerm) => {
        stayFormFormik.setFieldValue("goingTo", searchTerm.city_name + ", " + searchTerm.country_name);
        stayFormFormik.setFieldValue("city_name", searchTerm.city_name);
        stayFormFormik.setFieldValue("country_name", searchTerm.country_name);
        console.log("search ", searchTerm);
    };
    const handleRoomChange = async (e) => {
        setroomArrLoad(false)
        let arr = roomArr;
        let roomNo = e.target.value;
        let roomArrLen = roomArr.length;
        if (roomNo > roomArr.length) {
            for (let i = 0; i < roomNo - roomArrLen; i++) {
                arr.push({ "room_no": roomArr.length + 1, "adult": 1, "child": 0, "child_age": [] });
            }
            await setRoomArr(arr);
        } else {
            let popArrLen = - (roomNo - roomArr.length)
            for (let i = 0; i < popArrLen; i++) {
                await arr.pop();
            }
            await setRoomArr(arr);
        }
        setroomArrLoad(true)
    }
    const handleAdultChange = async (e, i) => {
        setroomArrLoad(false)
        roomArr[i].adult = Number(e.target.value);
        await setRoomArr([...roomArr]);
        setroomArrLoad(true);
    }
    const handleChildrenChange = async (e, i) => {
        setroomArrLoad(false);
        let childAgeNo = Number(e.target.value);
        let childArrAgeStore = roomArr[i].child_age.length;
        if (childAgeNo > childArrAgeStore) {
            for (let a = 0; a < childAgeNo - childArrAgeStore; a++) {
                roomArr[i].child_age.push("0");
            }
        } else {
            let popArrLenChildage = - (childAgeNo - childArrAgeStore);
            for (let a = 0; a < popArrLenChildage; a++) {
                roomArr[i].child_age.pop();
            }
        }
        roomArr[i].child = Number(e.target.value);
        await setRoomArr([...roomArr]);
        setroomArrLoad(true);
    }

    const handleChildAgeChange = async (e, y, i) => {
        roomArr[i].child_age[y] = e.target.value
        await setRoomArr([...roomArr]);
    }
    const totalTravellersSubmit = async (event) => {
        //event.preventDefault();
        let adultTotal = roomArr.reduce(function (prev, cur) {
            return prev + Number(cur.adult);
        }, 0);
        let childernTotal = roomArr.reduce(function (prev, cur) {
            return prev + Number(cur.child);
        }, 0);
        await setTravellersResult(adultTotal + childernTotal);
        stayFormFormik.setFieldValue("occupancy", roomArr);

    }

    const stayFormFormik = useFormik({
        initialValues: {
            goingTo: "",
            checkin: "",
            checkout: "",
            city_name: "",
            country_name: "",
            occupancy: [],
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            goingTo: Yup.string()
                .required('Please enter going to.'),
            checkin: Yup.date()
                .nullable()
                .transform((curr, orig) => orig === '' ? null : curr)
                .required('Please enter check-in date.'),
            checkout: Yup.date()
                .nullable()
                .transform((curr, orig) => orig === '' ? null : curr)
                .required('Please enter check-out date.'),

        }),
        onSubmit: values => {
            // console.log(values)
            // console.log(values.goingTo.match(","))
            if(stayFormFormik.values.goingTo.match(",") === null){
                stayFormFormik.setFieldValue("goingTo", "");
            }else{
                history.push({
                    pathname: '/stays-results/',
                    search: (`cityName=${values.city_name}&&countryName=${values.country_name}&&checkInDate=${moment(values.checkin).format("YYYY-MM-DD")}&&checkOutDate=${moment(values.checkout).format("YYYY-MM-DD")}&&occupancy=${JSON.stringify(values.occupancy)}`),
                });
            }
        }
    });


    useEffect(() => {
        totalTravellersSubmit();
    }, []);

    return (
        <>
            <form onSubmit={stayFormFormik.handleSubmit}>
                <div className="form-row">
                    <div className="form-col">
                        <div className="cmn-form-input-fields">
                            <div className="cities-search-box-field">
                                <input onChange={(e) => handleChangeGoingTo(e)} onBlur={stayFormFormik.handleBlur('goingTo')} value={stayFormFormik.values.goingTo} name="goingTo" className="form-control location-icon" type="text" placeholder="Going to" autoComplete="off" />
                                <div className='cities-search-dropdown'>
                                    {citiesData?.length > 0 ?
                                        <div className="cities-search-list">
                                            {citiesData?.map((item, index) => {
                                                return (
                                                    <div key={index} onClick={() => onSelectItem(item)} className="cities-search-item">
                                                        <span>{item.city_name}</span>, {item.country_name}
                                                    </div>
                                                )
                                            })}
                                        </div> : null}
                                </div>
                            </div>
                            {stayFormFormik.touched.goingTo && stayFormFormik.errors.goingTo ? <p className="errors-msg">{stayFormFormik.errors.goingTo}</p> : null}
                        </div>
                    </div>
                    <div className="form-col">
                        <div className="cmn-form-input-fields">
                            <DatePicker
                                selectsStart
                                value={stayFormFormik.values.checkin ? moment(stayFormFormik.values.checkin).format("YYYY-MM-DD") : null}
                                onChange={value => stayFormFormik.setFieldValue("checkin", value)}
                                onBlur={stayFormFormik.handleBlur('checkin')}
                                name="checkin"
                                dateFormat="dd MMMM yyyy"
                                autoComplete="off"
                                customInput={<input className="form-control calendar-icon" type="text" id="CheckIn" />}
                                placeholderText="Enter check-in date"
                                minDate={new Date()}
                            />
                            {stayFormFormik.touched.checkin && stayFormFormik.errors.checkin ? <p className="errors-msg">{stayFormFormik.errors.checkin}</p> : null}
                        </div>
                    </div>
                    <div className="form-col">
                        <div className="cmn-form-input-fields">
                            <DatePicker
                                selectsEnd
                                value={stayFormFormik.values.checkout ? moment(stayFormFormik.values.checkout).format("YYYY-MM-DD") : null}
                                onChange={value => stayFormFormik.setFieldValue("checkout", value)}
                                onBlur={stayFormFormik.handleBlur('checkout')}
                                name="checkout"
                                dateFormat="dd MMMM yyyy"
                                autoComplete="off"
                                customInput={<input className="form-control calendar-icon" type="text" id="CheckOut" />}
                                placeholderText="Enter check-out date"
                                minDate={stayFormFormik.values.checkin ? new Date(stayFormFormik.values.checkin).setDate(new Date(stayFormFormik.values.checkin).getDate() + 1) : new Date()}
                            />
                            {stayFormFormik.touched.checkout && stayFormFormik.errors.checkout ? <p className="errors-msg">{stayFormFormik.errors.checkout}</p> : null}
                        </div>
                    </div>
                    <div className="form-col">
                        <Dropdown className="custom-travellers-drp">
                            <Dropdown.Toggle variant="trasparent">
                                <div className="cmn-form-input-fields">
                                    <input className="form-control user-icon" value={`${travellersResult ? travellersResult + ' Travellers' : ""}`} type="text" id="Travellers" placeholder="Enter travellers" readOnly />
                                </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <div>
                                    <div className="travellers-frm-rw">
                                        <span className="travellers-frm-label travellers-frm-col-50">Rooms:</span>
                                        <div className="travellers-frm-col-50">
                                            <select className="form-select" onChange={handleRoomChange}>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                            </select>
                                        </div>
                                    </div>

                                    {roomArrLoad && roomArr.length > 0 && roomArr.map((x, i) => {
                                        return (
                                            <div key={i} className="travellers-frm-rw mt-2">
                                                <span className="travellers-frm-label travellers-frm-col-30">Room {i + 1} :</span>
                                                <div className="travellers-frm-col-30">
                                                    <span className="travellers-frm-in-label">Adult</span>
                                                    <select className="form-select" value={roomArr[i].adult} onChange={(e) => handleAdultChange(e, i)}>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="4">4</option>
                                                        <option value="5">5</option>
                                                        <option value="6">6</option>
                                                    </select>
                                                </div>
                                                <div className="travellers-frm-col-30">
                                                    <span className="travellers-frm-in-label">Children</span>
                                                    <select className="form-select" value={roomArr[i].child} onChange={(e) => handleChildrenChange(e, i)}>
                                                        <option value="0">0</option>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                    </select>
                                                </div>
                                                <div className="travellers-frm-col-100">
                                                    <div className="travellers-frm-rw">
                                                        {roomArrLoad && x.child_age.length > 0 && x.child_age.map((staringArray, y) => {
                                                            return (
                                                                <div key={y} className="travellers-frm-col-50 mt-2">
                                                                    <span className="travellers-frm-in-label">Age of Child {y + 1}</span>
                                                                    <select className="form-select" value={roomArr[i].child_age[y]} onChange={(e) => handleChildAgeChange(e, y, i)}>
                                                                        <option value="0">Select Age</option>
                                                                        <option value="1">1</option>
                                                                        <option value="2">2</option>
                                                                        <option value="3">3</option>
                                                                        <option value="4">4</option>
                                                                        <option value="5">5</option>
                                                                        <option value="6">6</option>
                                                                        <option value="7">7</option>
                                                                        <option value="8">8</option>
                                                                        <option value="9">9</option>
                                                                        <option value="10">10</option>
                                                                        <option value="11">11</option>
                                                                        <option value="12">12</option>
                                                                    </select>
                                                                </div>
                                                            )
                                                        }
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    )}

                                    <div className="d-flex justify-content-end mt-3">
                                        <Dropdown.Item type="button" onClick={totalTravellersSubmit} className="btn done-btn btn-primary-color">Done</Dropdown.Item>
                                    </div>
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                <div className="form-btns">
                    <div className="form-radio-groups">
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="RadioDefault" id="AddaFlight" />
                            <label className="form-check-label" htmlFor="AddaFlight">
                                Add a Flight
                            </label>
                        </div>
                        {/* <div className="form-check">
                        <input className="form-check-input" type="radio" name="RadioDefault" id="AddaCar"/>
                        <label className="form-check-label" htmlFor="AddaCar">
                            Add a Car
                        </label>
                    </div> */}
                    </div>
                    <button type="submit" className="btn btn-primary-color">Search <img src={WhiteArrowIcon} alt="" /></button>
                </div>
            </form>
        </>
    );
};

document.body.addEventListener('click', (e) => {
    document.querySelector('.cities-search-dropdown').classList.add('d-none');
})

export default StaySearchForm;