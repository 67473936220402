import './ManageProfile.scss';
import React, {useState, useEffect} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {EditProfileService} from '../../../services/AuthService';
import {imageUrlProfile} from '../../../services/CommonUrl';
import Alert from 'react-bootstrap/Alert';
import BlankAvatar from '../../../assets/images/blank-avatar.png';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import PlacesAutocomplete from 'react-places-autocomplete';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import {toast, Slide} from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from '../../../contexts/AuthProvider';
toast.configure();
const options = {
    position: "top-center",
    hideProgressBar: true,
    transition: Slide,
    theme: "colored",
    closeButton: false,
    autoClose: 2000,
    showDuration: 300,
    hideDuration: 1000
}
const ManageProfile = () => {
    const {authed, setAuthed} = useAuth();
    const [visibleAlertSuccess, setVisibleAlertSuccess] = useState(false);
    const [getImage, setGetImage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [userData, setUserData] = useState({
        profileImage: "",
        name: "",
        phone: "",
        dob: "",
        address: ""
    });
   
    const initialData = {
        name: userData?.name ? userData?.name : "",
        phone: userData?.phone ? userData?.phone : "",
        dob: userData?.dob? userData?.dob: "",
        address: userData?.address ? userData?.address: "",
    }
    const formik = useFormik({
        initialValues: initialData,
        enableReinitialize: true,
        validationSchema: Yup.object({
            name: Yup.string()
              .max(20, 'Name should not exceed 20 Characters.')
              .required('Please enter full name.'),
            // email: Yup.string()
            //   .email('Invalid email address')
            //   .required('Please enter email address'),
            phone: Yup.string()
              .required('Please enter phone number.'),
            // dob: Yup.date()
            // .nullable()
            // .transform((curr, orig) => orig === '' ? null : curr)
            // .required('Please enter date of birth.'),
            //address: Yup.string().required('Please enter address.'),
            
        }),
        onSubmit: values => {  
            // console.log(values)
            setIsLoading(true);
            let formData = new FormData();
            if(userData.profileImage === undefined || userData.profileImage === null ||  userData.profileImage === ""){
                formData.append("profileImage", "");
            }else{
                formData.append("profileImage", userData.profileImage);
            }
            formData.append("name", values.name);
            formData.append("phone", values.phone);
            formData.append("dob", values.dob);
            formData.append("address", values.address);
            EditProfileService(formData, async (res) => {
                // console.log("API 1 response: ", res);
                if (res.status === 404) {
                    console.log("Error 404");
                    setIsLoading(false);
                return;
                }
                if (res.data.data.success) {
                    setIsLoading(false);
                    let dataObj = res.data.data.data;
                    let tokenObj = res.data.data.token;
                    localStorage.setItem("login_gw", JSON.stringify(dataObj));
                    localStorage.setItem("token_gw", JSON.stringify(tokenObj));
                    setAuthed({
                        isAuth: true, 
                        token: tokenObj, 
                        user: dataObj
                    });
                    setVisibleAlertSuccess(true);
                    setTimeout(()=>{
                        setVisibleAlertSuccess(false);
                    }, 5000);
                    // toast.success("Profile details update successfully", options);
                } else {
                    // console.log("Error");
                    setIsLoading(false);
                }
            });
        }
    });

    useEffect(()=>{
        setUserData(authed?.user);
    },[]);


    const selectProfileImage = async (event) => {
        // console.log('get file ================', event)
        if (event["target"].files.length > 0) {
            const file = event["target"].files[0];
            setUserData({...userData, profileImage : file});
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (event) => {
            setGetImage(reader.result);
          };
        }
    };

    return ( 
        <>
            <div className="content-body">
                <div className="row justify-content-center">
                    <div className="col-md-7">
                        {visibleAlertSuccess && <Alert variant="success" onClose={() => setVisibleAlertSuccess(false)} dismissible>
                            <p>Profile details updated successfully.</p>
                        </Alert>}
                        <h1 className="d-col-title mb-3">Manage Profile</h1>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="manage-profile-image">
                                {userData.profileImage && !getImage ? (
                                    <>
                                    {userData.loginType === "social" ?
                                    <div className="profile-img" style={{ backgroundImage: "url(" + userData.profileImage + ")"}}></div>
                                    :<div className="profile-img" style={{ backgroundImage: "url(" + imageUrlProfile + userData.profileImage + ")"}}></div>}
                                    </>) : (
                                    <>
                                    {getImage ? (
                                        <div className="profile-img" style={{ backgroundImage: "url(" + getImage + ")" }}></div>
                                    ) : (
                                        <div className="profile-img" style={{ backgroundImage: "url(" + BlankAvatar + ")" }}></div>
                                    )}
                                    </>
                                )}
                                {userData.loginType === "social" ? "":
                                <div className="upload-profileimage-btn">
                                    <i className="bi bi-images"></i>
                                    <input onChange={selectProfileImage} onClick={(event)=> event.target.value = null} accept="image/x-png,image/gif,image/jpeg" type="file" name="file"/>
                                </div>}
                            </div>
                            <div className="row">
                                <div className="mt-3 d-col-input-field col-md-12">
                                    <label className="form-label">Full Name</label>
                                    <input onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.name} type="text" name="name" className="form-control" placeholder="Enter Full Name"/>
                                    {formik.touched.name && formik.errors.name ? <p className="errors-msg">{formik.errors.name}</p> : null}
                                </div>
                                <div className="mt-3 d-col-input-field col-md-6">
                                    <label className="form-label">Phone Number</label>
                                    <PhoneInput
                                        country={'us'}
                                        onChange={value => formik.setFieldValue("phone", value)}
                                        onBlur={formik.handleBlur("phone")} 
                                        name="phone" 
                                        value={formik.values.phone}
                                        placeholder="Enter Phone Number"
                                        containerClass="custom-ep-input"
                                    />
                                    {formik.touched.phone && formik.errors.phone ? <p className="errors-msg">{formik.errors.phone}</p> : null}
                                </div>
                                <div className="mt-3 d-col-input-field col-md-6">
                                    <label className="form-label">Date of Birth</label>
                                    <DatePicker 
                                        //selected={moment(formik.values.dob).format("DD-MM-YYYY")}
                                        value={formik.values.dob? moment(formik.values.dob).format("DD-MM-YYYY"): null}
                                        onChange={value => formik.setFieldValue("dob", value)}
                                        onBlur={formik.handleBlur('dob')} 
                                        name="dob"
                                        dateFormat="dd-MM-yyyy"
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        autoComplete="off"
                                        customInput={<input className="form-control" type="text" id="CheckIn"/>}
                                        placeholderText="DOB"
                                        maxDate={new Date()}
                                        />
                                    {/* {formik.touched.dob && formik.errors.dob ? <p className="errors-msg">{formik.errors.dob}</p> : null} */}
                                </div>
                            </div>
                            <div className="mt-3 d-col-input-field rp-d-flex">
                                <label className="form-label">Address</label>
                                <div className="profile-address-field">
                                    <PlacesAutocomplete
                                        value={formik.values.address}
                                        name="address"
                                        onChange={value => formik.setFieldValue("address", value)}
                                        onSelect={value => formik.setFieldValue("address", value)}
                                        onBlur={formik.handleBlur('address')} 
                                        minLength={2} // minimum length of text to search
                                        //searchOptions={{ types: ['locality'] }}
                                        >
                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                            <>
                                            <textarea
                                            {...getInputProps({
                                                placeholder: 'Enter Your Address',
                                                className: 'form-control',
                                            })}
                                            ></textarea>
                                            <div className="autocomplete-dropdown-container">
                                                {loading && <div>Loading...</div>}
                                                {suggestions.map(suggestion => {
                                                    const className = suggestion.active
                                                    ? 'suggestion-item-active'
                                                    : 'suggestion-item';
                                                    return (
                                                    <div key={suggestion.placeId}
                                                        {...getSuggestionItemProps(suggestion, {
                                                        className,
                                                        })}
                                                    >
                                                        <strong>
                                                        {suggestion.formattedSuggestion.mainText}
                                                        </strong>{" "}
                                                        <small>
                                                        {suggestion.formattedSuggestion.secondaryText}
                                                        </small>
                                                    </div>
                                                    );
                                                })}
                                            </div>
                                            </>
                                        )}
                                    </PlacesAutocomplete>
                                    {/* {formik.touched.address && formik.errors.address ? <p className="errors-msg">{formik.errors.address}</p> : null} */}
                                </div>
                            </div>
                            <div className="form-btn-groups d-flex justify-content-end mt-3">
                                <button type="submit" disabled={isLoading === true} className="btn btn-primary-color">{isLoading? "Updating.." : "Update"}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div> 
        </>
    );
}
 
export default ManageProfile;