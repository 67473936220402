import './Dashboard.scss';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getUserItineraryCountApi } from '../../../services/AdminService';
import { LoadingSpinnerTwo } from '../../../loader/Loader';
import { useAuth } from '../../../contexts/AuthProvider';

const Dashboard = () => {
    const [getCount, setGetCount] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const { authed } = useAuth();

    const getCountList = async () => {
        setIsLoading(true);
        await getUserItineraryCountApi({ userId: authed?.user?._id }, async (res) => {
            if (res.status === 404) {
                console.log('Something went wrong')
            }
            if (res.status === 200) {
                await setGetCount(res.data.data);
                setIsLoading(false);
            }
        });
    }


    useEffect(() => {
        getCountList();
    }, []);


    return (
        <>

            <div className="pt-3">
                <h1 className="d-col-title">Dashboard</h1>
                <div className="row">
                    <div className="col-md-3 mt-3">
                        <div className="dash-item-bx dash-item-bg-1" onClick={() => history.push(`/admin/itineraries-list`)}>
                            <p className="dash-item-title">My Itineraries</p>
                            <span className="dash-item-count">{isLoading === true ? <LoadingSpinnerTwo /> : getCount ? Number(getCount?.publicItinerary + getCount?.privateItinerary) : "0"}</span>
                        </div>
                    </div>
                    <div className="col-md-3 mt-3">
                        <div className="dash-item-bx dash-item-bg-2" onClick={() => history.push(`/admin/itineraries-list`)}>
                            <p className="dash-item-title">Public Itineraries</p>
                            <span className="dash-item-count">{isLoading === true ? <LoadingSpinnerTwo /> : getCount ? Number(getCount?.publicItinerary) : "0"}</span>
                        </div>
                    </div>
                    <div className="col-md-3 mt-3">
                        <div className="dash-item-bx dash-item-bg-3" onClick={() => history.push(`/admin/itineraries-list`)}>
                            <p className="dash-item-title">Private Itineraries</p>
                            <span className="dash-item-count">{isLoading === true ? <LoadingSpinnerTwo /> : getCount ? Number(getCount?.privateItinerary) : "0"}</span>
                        </div>
                    </div>
                    <div className="col-md-3 mt-3">
                        <div className="dash-item-bx dash-item-bg-2" onClick={() => history.push(`/admin/stays`)}>
                            <p className="dash-item-title">My Stays</p>
                            <span className="dash-item-count">{isLoading === true ? <LoadingSpinnerTwo /> : getCount ? Number(getCount?.staysData) : "0"}</span>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Dashboard;